import { FaAngleDown, FaAngleUp, FaUser, FaTrash, FaTimes, FaInfoCircle } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import Switch from 'react-switch';
import AnimateHeight, { Height } from "react-animate-height"
import { CompanyData, UserData, LLMAPIData, UserGroupData, APIWithContingentData, PricingPreset } from "../SettingsTypedefs";
import APIAccessTile from "../../../Components/APIAccessTile";
import { Tooltip } from "react-tooltip";

export default function CompanyAccountTile({ userData, availableLLMs, companyAccountData, apiURL, onUserDataChanged, companyData, accountGroups, pricingPresetOptions, isMobile, ...props }:
    { userData: UserData, availableLLMs: LLMAPIData[], companyAccountData: UserData, apiURL: string, onUserDataChanged: Function, companyData: CompanyData, accountGroups: UserGroupData[], pricingPresetOptions: PricingPreset[], isMobile:boolean }) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [height, setHeight] = useState<Height>('auto');
    const [personalAPIAccess, setPersonalAPIAccess] = useState<APIWithContingentData[]>([]);
    const contentDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        console.log("AvailableLLms", availableLLMs)
    }, [availableLLMs])

    useEffect(() => {
        if (expanded) {
            fetchPersonalAPIAccess();
        }
    }, [expanded])

    useEffect(()=> {
        console.log("personalAPI Access:", personalAPIAccess)
    }, [personalAPIAccess])

    async function deleteAccount() {
        try {
            const response = await fetch(apiURL + "/delete_account?userID=" + companyAccountData.userID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                alert("Error: " + response.status);
            } else {
                if (companyAccountData.userID === userData.userID) {
                    localStorage.removeItem("token");
                    window.location.href = "/impressum"
                }
                onUserDataChanged();
            }
        } catch (error) {
            console.log("Error occurred while updating 2 Fa setting:", error);
        }
    }

    async function changeUserRole() {
        const newRole = companyAccountData.userRole === 1 ? 2 : 1;
        const queryString = apiURL + "/change_user_role?userID=" + companyAccountData.userID + "&newRole=" + newRole
        try {
            const response = await fetch(
                queryString,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
                onUserDataChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function fetchPersonalAPIAccess() {
        const queryString = apiURL + "/get_unlocked_apis_for_user?userID=" + companyAccountData.userID
        try {
            const response = await fetch(
                queryString,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log("Fetched personal API Access:", data.llm_ids)
                setPersonalAPIAccess(data.llm_ids);
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function updatePersonalAPIAccess(updated_llms: any) {
        const queryString = apiURL + "/update_personal_api_access_for_user"
        console.log("UpdatedLLMACCESS:",updated_llms)
        try {
            const response = await fetch(
                queryString,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "userID": companyAccountData.userID,
                            "userLLMs": updated_llms,
                        }
                    )
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
                onUserDataChanged();
                setTimeout(()=> {
                    fetchPersonalAPIAccess();
                }, 200)
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function postRemoveUserFromGroup(groupID: number) {
        const queryString = apiURL + "/remove_user_from_group?userID=" + companyAccountData.userID + "&groupID=" + groupID
        try {
            const response = await fetch(
                queryString,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
                onUserDataChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }


    return (
        <AnimateHeight
            {...props}
            height={height}
            contentClassName="auto-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={isMobile ? { width: "100%", marginBottom: "10px", justifyContent:"center", alignItems:"center" }: { width: "90%", marginBottom: "10px", justifyContent:"center", alignItems:"center" }}>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <div key={companyAccountData.userID} className="settings-user-tile"
                    onClick={() => {
                        if (!expanded) {
                            setExpanded(true)
                        }
                    }}
                    style={expanded ? {} : { cursor: 'pointer' }}
                    ref={contentDiv}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <FaUser style={{ marginRight: '5px' }} />
                        {companyAccountData.name}
                        {expanded ?
                            <FaAngleUp
                                onClick={() => {
                                    setExpanded(false)
                                }}
                                style={{ cursor: 'pointer' }}
                            /> :
                            <FaAngleDown />
                        }
                    </div>
                    {
                        expanded &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', margin: "5px", wordBreak: "break-all" }}>
                                <div style={isMobile ? { marginRight: "5px" } : { fontWeight: 'bold', marginRight: "15px" }}>
                                    Email:
                                </div>
                                {companyAccountData.email}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', margin: "5px", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={isMobile ? { marginRight: "5px" } : { fontWeight: 'bold', marginRight: "15px" }}>
                                    Admin Rigths:
                                </div>
                                {companyAccountData.userID === userData.userID &&
                                    <div style={{ fontSize: "12px" }}>
                                        You can not Change the rigths of your own Account
                                    </div>
                                }
                                <Switch
                                    checked={companyAccountData.userRole === 1}
                                    onChange={() => {
                                        changeUserRole();
                                    }}
                                    disabled={companyAccountData.userID === userData.userID}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: "5px", justifyContent: "space-between", alignItems: "flex-start" }}>
                                <div style={{ display:"flex", flexDirection:"row", fontWeight: 'bold', marginBottom: "10px", alignSelf: 'center', alignItems:"center", justifyContent:"center" }}>
                                <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>Personal LLM API Access Rigths</div>
                                    <div data-tooltip-id="CompanyNPPDTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                            <FaInfoCircle />
                                            <Tooltip id="CompanyNPPDTooltip" className="custom-tooltip">
                                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                    Here you can unlock the Large Language Model APIs for ur users and assign a contingent to them. Be aware, that even if deactivated here a user can still have access over a group.
                                                </div>
                                            </Tooltip>
                                    </div>
                                </div>
                                {availableLLMs.filter((llm) => { return llm.is_company_api }).map((llm) => (
                                    <APIAccessTile
                                        key={"ApiAccessfor" + llm.api_id}
                                        llm={llm}
                                        accessActivated={personalAPIAccess.map(awc => awc.api_id).includes(llm.api_id)}
                                        contingent={personalAPIAccess.filter(awc => awc.api_id === llm.api_id)[0] ? personalAPIAccess.filter(awc => awc.api_id === llm.api_id)[0].contingent : -1}
                                        pricingPresetsOptions={pricingPresetOptions}
                                        onAccessChanged={(accessActivated, contingent, selectedPresetID) => {
                                            console.log("Access changed to", accessActivated, contingent);
                                            console.log("Constrained:", personalAPIAccess.map(awc => awc.api_id).includes(llm.api_id) ? personalAPIAccess.filter(awc => awc.api_id === llm.api_id)[0].contingent : -1);

                                            const updatedList = [...personalAPIAccess];
                                            const index = updatedList.findIndex(awc => awc.api_id === llm.api_id);

                                            if (accessActivated) {
                                                if (index === -1) {
                                                    updatedList.push({
                                                        api_id: llm.api_id,
                                                        contingent: -1,
                                                        pricingPresetID: selectedPresetID
                                                    });
                                                } else {
                                                    updatedList[index] = {
                                                        ...updatedList[index],
                                                        contingent: contingent,
                                                        pricingPresetID: selectedPresetID
                                                    };
                                                }
                                            } else {
                                                if (index !== -1) {
                                                    updatedList.splice(index, 1);
                                                }
                                            }
                                            console.log("Updated List:", updatedList)
                                            setPersonalAPIAccess(updatedList);
                                            updatePersonalAPIAccess(updatedList)
                                        }}
                                        selectedPricingPresetID={personalAPIAccess.filter(awc => awc.api_id === llm.api_id)[0] ? personalAPIAccess.filter(awc => awc.api_id === llm.api_id)[0].pricingPresetID : -1}
                                        isMobile={isMobile}
                                    />
                                ))}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: "5px", justifyContent: "space-between", alignItems: "flex-start" }}>
                            <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>
                                    Group member in:
                                </div>
                                {accountGroups.length > 0 ? accountGroups.map((group) => (
                                    <div
                                        style={{ display: 'flex', flexDirection: 'row', margin: "5px", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                        key={"User" + companyAccountData.userID + "LLM" + group.groupID}
                                    >
                                        <div style={{ fontWeight: "bold" }}>{group.groupName}</div>
                                        <div style={{ display: "flex", flexDirection: "row", }}>
                                            <div className={`settings-button ${isMobile? "mobile" : ""}`} style={{ color: "red", borderColor: "red", fontSize: "14px" }}
                                                onClick={() => {
                                                    postRemoveUserFromGroup(group.groupID);
                                                }}
                                            >
                                                Remove from group
                                                <FaTimes style={{ marginLeft: "10px" }}></FaTimes>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div style={{ display: "flex", flexDirection: "row", width: "100%", alignSelf: "center", justifyContent: "center", textAlign: "center", fontSize: "12px" }}> This account is not a member of any user group. </div>}
                            </div>
                            <div className={`settings-button ${isMobile? "mobile" : ""}`} style={companyData.pp_admin_mail === companyAccountData.email ? { color: "gray", borderColor: "gray" } : { color: "red", borderColor: "red" }}
                                onClick={companyData.pp_admin_mail === companyAccountData.email ? () => { } :
                                    () => {
                                        deleteAccount();
                                    }}
                            >
                                <FaTrash style={{ marginRight: '5px', }} />
                                Delete Account
                            </div>
                            {companyData.pp_admin_mail === companyAccountData.email &&
                                <div style={isMobile ? { fontSize: "10px", maxWidth: "60vw", textAlign: "center", alignSelf: "center" } : { fontSize: "10px", maxWidth: "20vw", textAlign: "center", alignSelf: "center" }}>
                                    The currently registered company admin can not be deleted. Please change the company admin to delete this account.
                                </div>
                            }
                        </div>
                    }
                </div>                
            </div>

        </AnimateHeight>
    )
}

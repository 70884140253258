import { useEffect } from "react"
import { FaExclamationTriangle } from "react-icons/fa";
import "./../Pages/Settings/Settings.css"
import "./../Pages/Home/Home.css"
export default function WarningPopUp({ text, delayHide, onHidePopUp, cssClassName, ...props }:
    { text: string, delayHide: number, onHidePopUp: Function, cssClassName: string }) {
    useEffect(() => {
        console.log("Warningpopup recived text:", text)
        setTimeout(() => {
            console.log("Warningpopup goes into hiding")
            onHidePopUp();
        }, delayHide)
    }, [text])
    return (
        <div className={cssClassName}>
            <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "30px" }}>
                <FaExclamationTriangle />
            </div>
            <div style={{ flex: "3" }}>
                {text}
            </div>
        </div>
    )
}

import { FaEdit, FaCheck, FaTimes, FaInfoCircle, FaPlusCircle } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import Switch from 'react-switch';
import Select from "react-select";
import "./../Settings.css"
import { Tooltip } from 'react-tooltip';

export default function CompanySettings({ userData, companyData, companyUsers, onCompanyDataChanged, apiURL, isMobile }) {
    const [editNameMode, setEditNameMode] = useState(false);
    const [editedName, setEditedName] = useState("");
    const amigousFileInputRef = useRef(null);
    useEffect(() => {
        if (companyData) {
            setEditedName(companyData.companyName);
        }
    }, [companyData])

    async function postUpdatedCompanyData(updatedCompanyData) {
        try {
            const response = await fetch(
                apiURL + "/update_company_data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(updatedCompanyData)
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                setTimeout(() => {
                    onCompanyDataChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function deleteAmbigouityText(fileName) {
        try {
            const response = await fetch(
                apiURL + "/delete_ambigouity_text?fileName=" + fileName,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    }
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                setTimeout(() => {
                    onCompanyDataChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function addAmbigouityText(fileObjcts) {
        console.log("File Objcts:", fileObjcts)
        const formData = new FormData();

        // Append each file to the FormData object
        for (let i = 0; i < fileObjcts.length; i++) {
            formData.append('ambigouity_texts', fileObjcts[i]);
        }
        try {
            const response = await fetch(apiURL + "/add_ambigouity_text", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formData,
            });

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const data = await response.json();  // Parse response
                console.log("Success:", data.message);
                setTimeout(() => {
                    onCompanyDataChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
        if (amigousFileInputRef.current) {
            amigousFileInputRef.current.files = null;
        }
    }

    return (
        <div className="subsetting-content">
            <center><h4>Company Settings</h4></center>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {editNameMode ?
                    <div style={isMobile ? { display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px', fontSize: "22px", flexDirection: "column" } :
                        { display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px', fontSize: "22px", flexDirection: "row" }}>
                        <input
                            type="text"
                            className="settings-input"
                            style={isMobile ? { fontWeight: 'bold', fontSize: "18px" } : { fontWeight: 'bold' }}
                            value={editedName}
                            onChange={(e) => {
                                setEditedName(e.target.value)
                            }}
                        />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                style={{ fontSize: "16px" }}
                                onClick={() => {
                                    setEditNameMode(false);
                                }}>
                                Cancel
                                <FaTimes className="cancel-delete-icon" />
                            </div>
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                style={{ fontSize: "16px" }}
                                onClick={() => {
                                    const updatedCompanyData = { ...companyData };
                                    updatedCompanyData.companyName = editedName;
                                    postUpdatedCompanyData(updatedCompanyData);
                                    setEditNameMode(false);
                                }}>
                                Confirm Changes
                                <FaCheck className="confirm-icon" />
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <h2 style={{ marginRight: "20px" }}>{companyData ? companyData.companyName : ""}</h2>
                        <FaEdit fontSize={22}
                            onClick={() => {
                                setEditNameMode(true);
                            }}
                        />
                    </div>}
                {/* Contact Person Admin Selection */}
                <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '90%' } :
                    { display: 'flex', flexDirection: 'row', width: '90%' }}>
                    <div style={{ display: "flex", flexDirection: "row", flex: "4" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                            <div style={isMobile ? { flex: "3" } : { flex: "2", fontWeight: "bold" }}>
                                Admin Name:
                            </div>
                            <div style={isMobile ? { flex: "3" } : { flex: "2", fontWeight: "bold" }}>
                                Admin Mail:
                            </div>
                        </div>
                        <div style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} data-tooltip-id="CompanyContactTooltip">
                            <FaInfoCircle />
                            <Tooltip id="CompanyContactTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The selected admin will be shown to all users as contact person. Also the selected account can not be deleted to ensure that alwas at least one admin is registered.
                                </div>
                            </Tooltip>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                            <div style={{ flex: "4", wordBreak: "break-word" }}>
                                {companyData.pp_admin_name}
                            </div>
                            <div style={{ flex: "4", wordBreak: "break-all" }}>
                                {companyData.pp_admin_mail}
                            </div>
                        </div>
                    </div>

                    {userData.userRole === 1 && companyUsers && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "5px" }}>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                    margin: "5px"
                                })
                            }}
                            options={companyUsers.filter(user => user.userRole === 1).map(user => ({
                                value: user.userID,
                                label: user.name,
                                email: user.email
                            }))}
                            onChange={(selectedOption) => {
                                console.log(selectedOption);
                                const updatedCompanyData = { ...companyData };
                                updatedCompanyData.pp_admin_name = selectedOption.label;
                                updatedCompanyData.pp_admin_mail = selectedOption.email;
                                postUpdatedCompanyData(updatedCompanyData);
                            }}
                            disabled={userData.userRole > 1}
                            placeholder={"Select new Admin"}
                        />

                    </div>
                    }
                </div>

                {/*  Company Two Fa Mandatory */}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', width: '90%' }}>
                    <div style={isMobile ? { flex: '2' } : { fontWeight: 'bold', flex: '2' }}> Two Fa Auth Mandatory: </div>
                    <div data-tooltip-id="CompanyTFAMTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                        <FaInfoCircle />
                        <Tooltip id="CompanyTFAMTooltip" className="custom-tooltip">
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                This overwrites the User preferences for two Factor Authentification. If activated every user has to use two factor authentification when the login token is expired.
                            </div>
                        </Tooltip>
                    </div>
                    {!isMobile && <div style={{ marginLeft: '5px', flex: '2' }}> {companyData.twoFaMandatory ? "Activated" : "Deactivated"} </div>}
                    <div style={{ marginLeft: '15px', display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center' }}>
                        <Switch
                            disabled={userData.userRole > 1}
                            checked={companyData.twoFaMandatory}
                            onChange={() => {
                                const updatedCompanyData = { ...companyData };
                                updatedCompanyData.twoFaMandatory = !updatedCompanyData.twoFaMandatory;
                                postUpdatedCompanyData(updatedCompanyData);
                            }}
                        />
                    </div>
                </div>

                {/* ALLOW REGISTRATION */}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', width: '90%' }}>
                    <div style={isMobile ? { flex: '2' } : { fontWeight: 'bold', flex: '2' }}> Allow registration: </div>
                    <div data-tooltip-id="CompanyARTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                        <FaInfoCircle />
                        <Tooltip id="CompanyARTooltip" className="custom-tooltip">
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                If activated users can register themself with only the URL of the PrivacyPrompt API. If deactivated user registration is only possible for admins in the "Company Accounts" tab of the Settings.
                            </div>
                        </Tooltip>
                    </div>
                    {!isMobile && <div style={{ marginLeft: '5px', flex: '2' }}>{companyData.allowRegistration ? "Activated" : "Deactivated"}</div>}
                    <div style={{ marginLeft: '15px', display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center' }}>
                        <Switch
                            disabled={userData.userRole > 1}
                            checked={companyData.allowRegistration}
                            onChange={() => {
                                const updatedCompanyData = { ...companyData };
                                updatedCompanyData.allowRegistration = !updatedCompanyData.allowRegistration;
                                postUpdatedCompanyData(updatedCompanyData);
                            }}
                        />
                    </div>
                </div>

                {/*  Hidden auto anonymize */}
                <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', width: '90%' }}>
                        <div style={isMobile ? { flex: '2' } : { fontWeight: 'bold', flex: '2' }}> Hidden automatic anonymization: </div>
                        <div data-tooltip-id="CompanyARTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                            <FaInfoCircle />
                            <Tooltip id="CompanyARTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    With automatic anonymization the user wont be shown the critical content and be prompted to correct it. Instead the critical content will be replaced by tags that are retranslated in the answer. Some context migth be lost for the LLM.
                                </div>
                            </Tooltip>
                        </div>
                        {!isMobile && <div style={{ marginLeft: '5px', flex: '2' }}>{companyData.hiddenAutoAnonymize ? "Activated" : "Deactivated"}</div>}
                        <div style={{ marginLeft: '15px', display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center' }}>
                            <Switch
                                disabled={userData.userRole > 1}
                                checked={companyData.hiddenAutoAnonymize}
                                onChange={() => {
                                    const updatedCompanyData = { ...companyData };
                                    updatedCompanyData.hiddenAutoAnonymize = !updatedCompanyData.hiddenAutoAnonymize;
                                    postUpdatedCompanyData(updatedCompanyData);
                                }}
                            />
                        </div>
                    </div>
                    {companyData.hiddenAutoAnonymize &&
                        <div style={{ display: "flex", flexDirection: "row", width: '90%', marginTop: "20px" }}>
                            <div style={isMobile ? { flex: '2' } : { fontWeight: 'normal', flex: '2' }}> Anonymization Hints:</div>
                            <div data-tooltip-id="HAAHintsTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                                <FaInfoCircle />
                                <Tooltip id="HAAHintsTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        Here you can configure, if the users should be shown a hint as anonymization takes place.
                                    </div>
                                </Tooltip>
                            </div>
                            {!isMobile && <div style={{ marginLeft: '5px', flex: '2' }}>{companyData.hiddenAutoAnonymize ? "Activated" : "Deactivated"}</div>}
                            <div style={{ marginLeft: '15px', display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center' }}>
                                <Switch
                                    disabled={userData.userRole > 1}
                                    checked={companyData.hiddenAutoAnonymizeHints}
                                    onChange={() => {
                                        const updatedCompanyData = { ...companyData };
                                        updatedCompanyData.hiddenAutoAnonymizeHints = !updatedCompanyData.hiddenAutoAnonymizeHints;
                                        postUpdatedCompanyData(updatedCompanyData);
                                    }}
                                />
                            </div>
                        </div>

                    }
                </div>


                {/* Ambigoutiy Check Files */}
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "90%", border: "1px solid var(--text-icons)", borderRadius: "10px", padding: "10px", marginTop: "15px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} data-tooltip-id="AmbigoutyTooltip" >
                        <h4>Ambiguity Check Texts</h4>
                        <FaInfoCircle style={{ marginLeft: "15px" }} />
                        <Tooltip id="AmbigoutyTooltip" className="custom-tooltip" delayShow={800}>
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                After changes to rules or secured data contained by rules, the data will be checked for ambigous words using the large texts displayed here. You can add texts that will be added to this check. Ambigous words will be marked in the Secure Data Page and can be set as exceptions, that wont cause rule violations.
                            </div>
                        </Tooltip>
                    </div>
                    {
                        companyData.ambigouity_texts && companyData.ambigouity_texts.map((fileName, index) =>
                            <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "90%", maxWidth: "400px", marginBottom: "5px" }}>
                                <div>
                                    {fileName}
                                </div>
                                <div data-tooltip-id="DeleteAmbigoutyTooltip"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        deleteAmbigouityText(fileName);
                                    }}>
                                    <FaTimes style={{ color: "red" }} />
                                    <Tooltip id="DeleteAmbigoutyTooltip" className="custom-tooltip" delayShow={800}>
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Here you can remove a file from the ambigouty checks
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    }
                    <div data-tooltip-id="AddAmbigoutyTooltip"
                        className="settings-button"
                        onClick={() => {
                            if (amigousFileInputRef.current) {
                                amigousFileInputRef.current.click();
                            }
                        }}>
                        Add new Text
                        <FaPlusCircle className="add-element-icon" />
                        <Tooltip id="AddAmbigoutyTooltip" className="custom-tooltip" delayShow={800}>
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                Here you can add a file to the ambigouty checks. Only .txt files are accepted for this.
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <input
                type="file"
                ref={amigousFileInputRef}
                style={{ display: 'none' }}
                accept=".txt"
                onChange={(e) => {
                    addAmbigouityText(e.target.files)
                }}
            />
        </div>
    );
}

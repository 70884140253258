import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Range } from "quill";
import React, { useEffect, useState } from "react";


interface APIDocumentEditingMenuProps {
    selectedRange: Range;
    rigthClickMenuRef: React.RefObject<HTMLDivElement>;
    prompt: string;
    editRigth: Boolean;
    onPromptChanged: (prompt: string) => void;
    onSelectionPrompt: (selectedRange:Range, selectionPrompt:string, isReplacementCallBack:Boolean)=> void;
}

const APIDocumentEditingMenu: React.FC<APIDocumentEditingMenuProps> = ({ selectedRange, rigthClickMenuRef, prompt, editRigth, onPromptChanged, onSelectionPrompt }) => {

    useEffect(()=> {
        handleTextareaChange();
    }, [prompt])

    const handleTextareaChange = () => {
        const textarea = document.getElementById("docAPIEditInput");
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = textarea.scrollHeight + "px";
        }
    };



    return (
        <div
            className="doc-chat-rigth-click-menu" // Capture right-click event
            ref={rigthClickMenuRef}
        >
            {/* Prompt Input */}
            <div className="chat-input-container">
                <textarea
                    id="docAPIEditInput"
                    className="new_privat_data_input"
                    style={{ height: "100%", borderRadius: "25px" }}
                    placeholder="Enter Text to prompt selection"
                    value={prompt}
                    maxLength={50000}
                    onChange={(e) => {
                        onPromptChanged(e.target.value)
                    }}
                />
            </div>
            {/* Action Selection () */}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", marginBottom: "10px" }}>
                <div className="subcontroll-button"
                    onClick={()=> {
                        onSelectionPrompt(selectedRange, prompt, false);
                    }}>
                    <div>
                        Question to selection
                    </div>
                    <FontAwesomeIcon icon={faShareFromSquare} size="1x" style={{ marginLeft: "5px" }} />
                </div>
                { editRigth && 
                <div className="subcontroll-button"
                    onClick={()=> {
                        onSelectionPrompt(selectedRange, prompt, true);
                    }}>
                    <div>
                        Replace with models answer
                    </div>
                    <FontAwesomeIcon icon={faShareFromSquare} size="1x" style={{ marginLeft: "5px" }} />
                </div>
                }
            </div>
        </div>
    );
};

export default APIDocumentEditingMenu;

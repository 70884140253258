import { LLMAPIData, PricingPreset } from "../Pages/Settings/SettingsTypedefs";
import { useState, useRef, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import Switch from 'react-switch';
import Select from "react-select";
import { Tooltip } from "react-tooltip";

export default function APIAccessTile({ llm, accessActivated, contingent, pricingPresetsOptions, selectedPricingPresetID, onAccessChanged, isMobile, ...props }:
    { llm: LLMAPIData, accessActivated: boolean, contingent: number, pricingPresetsOptions: PricingPreset[], selectedPricingPresetID: number, onAccessChanged: (accessActivated: boolean, contingent: number, selectedPresetID: number) => void, isMobile: boolean }) {
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);
    const [newInputConstraintNumber, setNewInputConstraintNumber] = useState(contingent);

    const [selectedPricingPreset, setSelectedPricingPreset] = useState<PricingPreset | null>(pricingPresetsOptions ? pricingPresetsOptions.filter(preset => preset.contingentType === llm.contingentType && preset.id === selectedPricingPresetID)[0] : null);
    const [controlledByPreset, setControlledByPreset] = useState(selectedPricingPreset !== null);


    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        console.log("Useeffect on Contingent")
        if (llm && contingent) {
            setNewInputConstraintNumber(contingent)
        }
    }, [contingent])

    useEffect(() => {
        if (selectedPricingPreset) {
            setControlledByPreset(true);

        } else {
            setControlledByPreset(false);
        }
    }, [selectedPricingPreset])

    useEffect(() => {
        if (selectedPricingPresetID === -1) {
            setSelectedPricingPreset(null);
        } else {
            setSelectedPricingPreset(pricingPresetsOptions ? pricingPresetsOptions.filter(preset => preset.contingentType === llm.contingentType && preset.id === selectedPricingPresetID)[0] : null);
        }
    }, [selectedPricingPresetID])

    return (
        <AnimateHeight
            {...props}
            height={height}
            contentClassName="auto-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ width: "90%", alignItems: "center", justifyContent: "center", overflow: "visible", border: "1px solid var(--text-icons-2)", borderRadius: "15px", padding: "10px", marginBottom: "10px", fontWeight: "normal" }}
        >
            <div key={"LLMCONTINGENT" + llm.api_id}
                style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                ref={contentDiv}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: "10px", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <div style={{ fontWeight: "bold" }}>{llm.api_name}</div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ marginRight: "5px" }}>
                            {accessActivated ? "Access activated" : "No Access"}
                        </div>
                        <Switch
                            checked={accessActivated}
                            onChange={() => {
                                onAccessChanged(!accessActivated, -1, selectedPricingPreset ? selectedPricingPreset.id : -1);
                            }}
                        />
                    </div>

                </div>
                {accessActivated &&
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginTop: "10px" }}>
                            <div style={{ marginRight: "5px", flex: "2" }}>
                                {contingent === -1 ? "No constraints" : "Constrained Access"}
                            </div>
                            <div style={{ flex: "1", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <Switch
                                    checked={contingent !== -1}
                                    onChange={() => {
                                        if (contingent === -1) {
                                            contingent = 500
                                            onAccessChanged(true, contingent, selectedPricingPreset ? selectedPricingPreset.id : -1);
                                        } else {
                                            contingent = -1
                                            onAccessChanged(true, contingent, selectedPricingPreset ? selectedPricingPreset.id : -1);
                                        }
                                    }}
                                />
                                <div data-tooltip-id="ConstraintsTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="ConstraintsTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            With no constraints acitvated the user can send as many questions to the Large Language Model API as he wants. Constraints can either be a custom number of Requests/Tokens or the number can be controlled by a Pricing Preset.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div style={{ flex: "2" }}></div>
                        </div>
                        {contingent !== -1 &&
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "10px" }}>
                                <div style={isMobile ? { display: "flex", flexDirection: "column-reverse", alignItems: "center", justifyContent: "flex-start", minHeight: "50px", width: "100%" } :
                                    { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", minHeight: "50px", width: "100%" }}>
                                    <div style={{ width: "93%", height: "100%", marginRight: "15px" }}>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: "var(--background)",
                                                    color: "var(--text-icons)",
                                                    zIndex: 10
                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: 110
                                                }),
                                                group: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    zIndex: 110
                                                }),
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)"
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)",
                                                }),
                                                multiValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: 10
                                                }),
                                                multiValueLabel: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "100%",
                                                    margin: "5px",
                                                })
                                            }}
                                            getOptionLabel={(preset) => preset.name}
                                            getOptionValue={(preset) => preset.name}
                                            options={pricingPresetsOptions.filter(preset => preset.contingentType === llm.contingentType)}

                                            onChange={(selectedOptions) => {
                                                setSelectedPricingPreset(selectedOptions)
                                                contingent = selectedOptions!.contingent
                                                onAccessChanged(true, contingent, selectedOptions!.id);
                                            }}
                                            value={selectedPricingPreset}
                                            menuPortalTarget={document.body}
                                            menuPosition={'absolute'}
                                            menuPlacement={'auto'}
                                            isMulti={false}
                                        />
                                    </div>

                                    <div style={{ flex: "1", marginLeft: "10px" }}>
                                        {controlledByPreset ? "Controlled by Preset" : "Custom Contingent"}
                                    </div>

                                </div>
                                <div style={isMobile ? { display: "flex", flexDirection: "column-reverse", alignItems: "center", justifyContent: "flex-start", minHeight: "50px", width: "100%" } : 
                                { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", minHeight: "50px", width: "100%", marginRight:"15px" }}>
                                    <input
                                        value={newInputConstraintNumber}
                                        onChange={(e => {
                                            setNewInputConstraintNumber(Number(e.target.value));
                                            setSelectedPricingPreset(null);
                                            contingent = Number(e.target.value);
                                            onAccessChanged(true, contingent, selectedPricingPreset ? selectedPricingPreset.id : -1);
                                        })}
                                        className="settings-input"
                                        style={isMobile ? {width:"93%" , marginLeft:"5px"} : {width:"75%", marginLeft:"10px"}}
                                        type="number"
                                    />
                                    <div style={{ marginLeft: "10px", flex: "2" }}>
                                        {llm.contingentType ? "Tokens per month" : "Requests per month"}
                                    </div>
                                </div>
                            </div>}
                    </div>}
            </div>
        </AnimateHeight>
    )
}

import { useEffect, useState, useRef } from 'react';
import './Impressum.css'
import { Link } from 'react-router-dom';
import { CompanyData, UserData } from '../Settings/SettingsTypedefs';
import { FaAngleDown, FaAngleUp, FaUser} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faArrowRightToBracket, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import AnimateHeight, { Height } from "react-animate-height"

function AccountDropdown({ userData, companyData, onLogout, ...props }: { userData: UserData | null, companyData: CompanyData | null, onLogout: Function }) {
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);


    return (
        <div className="account-dropdown-container" >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                onClick={() => {
                    setExpanded(!expanded)
                }}>
                <FaUser style={{ marginRight: "5px" }} />
                <div style={{ marginRight: "5px" }}>
                    {userData ? userData.name : "Login"}
                </div>
                {!expanded ?
                    <FaAngleDown style={{ marginRight: "5px" }} />
                    :
                    <FaAngleUp style={{ marginRight: "5px" }} />
                }
            </div>
            <AnimateHeight
                {...props}
                height={height}
                contentClassName="animate-heigth-content"
                contentRef={contentDiv}
                disableDisplayNone
                style={{ position: "relative" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {expanded &&

                        <div ref={contentDiv}
                            style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            {userData ?
                                <button className='account-dropdow-button'
                                onClick={()=> {
                                    onLogout();
                                }}>
                                    Logout
                                    <FontAwesomeIcon icon={faRightFromBracket} />
                                </button>
                                :
                                <Link className='account-dropdow-button'
                                 to={"/login"}>
                                    Login
                                    <FontAwesomeIcon icon={faArrowRightToBracket} />
                                </Link>
                                }
                            {(userData && companyData) ?
                                <Link className='account-dropdow-button'
                                to={"/login"}>
                                    Chat
                                   <FontAwesomeIcon icon={faCommentDots} />
                               </Link>
                                : companyData?.allowRegistration?
                                <Link className='account-dropdow-button'
                                to={"/login"}
                                state={{ isRegisterRedirect: true }}>
                                    Register
                               </Link> : <></>


                            }
                        </div>

                    }
                </div>
            </AnimateHeight>

        </div >
    );
}

export default AccountDropdown;
import { FaSearch, FaPlusCircle, FaTimes, FaAngleLeft, FaAngleDoubleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";
import { useEffect, useState } from "react";
import CompanyAccountTile from "./CompanyAccountTile";
import { Tooltip } from "react-tooltip";
import { Circles } from 'react-loader-spinner';
export default function CompanyAccountSettings({ userData, companyLLMs, apiURL, companyData, companyUsers, onCompanyUsersChanged, onUserGroupsChanged, pricingPresetOptions, isMobile }) {
    const [userGroups, setUserGroups] = useState([]);
    const [userSeacrhText, setUserSearchText] = useState("");

    const [newUserName, setNewUserName] = useState("");
    const [newUserMail, setNewUserMail] = useState("");
    const [newUserPwd, setNewUserPwd] = useState("");
    const [newUserPwdRepeat, setNewUserPwdRepeat] = useState("");
    const [newUserMode, setNewUserMode] = useState(false);
    const [newUserHintText, setNewUserHintText] = useState("");
    const [isInRegisterCall, setIsInRegisterCall] = useState(false)


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const filteredUsers = companyUsers.filter((user) =>
        user.name.includes(userSeacrhText) || user.email.includes(userSeacrhText) || userSeacrhText === ""
    );

    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
    const paginatedUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    useEffect(() => {
        fetchUserGroups();
    }, [])

    useEffect(() => {
        fetchUserGroups();
    }, [userData, apiURL])

    useEffect(() => {
        console.log("CompanyUsers:", companyUsers)
    }, [companyUsers])

    async function fetchUserGroups() {
        if (apiURL && userData) {
            try {
                const response = await fetch(
                    apiURL + "/get_user_groups",
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                if (response.status !== 200) {
                    const errorData = await response.json();
                    console.log(errorData);
                    if (response.status === 401 && errorData.detail == "not logged in") {
                        console.log("Catched expired Session");
                        window.location.href = "/session_expired";
                    } else {
                        alert("Error: " + response.status);
                    }
                } else {
                    const data = await response.json();
                    setUserGroups(data.user_groups);
                }
            } catch (error) {
                console.log(error);
                //alert("Error: " + error);
            }
        }
    }

    async function postNewUser() {
        if (checkPwdSave(newUserPwd, newUserPwdRepeat)) {
            setIsInRegisterCall(true);
            try {
                const response = await fetch(
                    apiURL + "/register",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify(
                            {
                                "userID": -1,
                                "userRole": 2,
                                "email": newUserMail,
                                "name": newUserName,
                                "password": newUserPwd,
                                "otp_activated": false,
                                "otp_token": "",
                                "auto_read_aloud_activated": false

                            }
                        )
                    }
                );

                if (response.status !== 200 && response.status !== 201) {
                    console.log("Response status: " + response.status);
                    const data = await response.json()
                    setNewUserHintText(data.detail)
                    setIsInRegisterCall(false);
                } else {
                    setNewUserName("");
                    setNewUserMail("");
                    setNewUserPwd("");
                    setNewUserPwdRepeat("");
                    setNewUserMode(false);
                    const data = await response.json();
                    console.log(data)
                    setTimeout(() => {
                        onCompanyUsersChanged()
                        setIsInRegisterCall(false);
                    }, 200)

                }
            } catch (error) {
                console.log(error);
                //alert("Error: " + error);
                setIsInRegisterCall(false);
            }
        }
    }

    function checkPwdSave(pwd, pwdRepeat) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
        const passwordValid = regex.test(pwd);
        if (!passwordValid) {
            setNewUserHintText("Passwords needs to be at least 8 characters long, contain at least one upper and lowercaase letter and one special character");
            return false
        } else {
            if (pwd !== pwdRepeat) {
                setNewUserHintText("Passwords are not equal");
                return false
            }
            else {
                setNewUserHintText("");
                return passwordValid
            }
        }
    }

    return (
        <div className="subsetting-content">
            <center><h4>Company Accounts</h4></center>
            <div className={`company-accounts-users-container ${isMobile ? "mobile" : ""}`} >
                {!newUserMode ?
                    <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                        onClick={() => {
                            setNewUserMode(true);
                        }}
                        data-tooltip-id="AddUserTooltip"
                    >
                        Add new Company User
                        <FaPlusCircle className="add-element-icon" />
                        <Tooltip id="AddUserTooltip" className="custom-tooltip" delayShow={800}>
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                Here you can add a new user for your company. The user will recive a email with a QR-Code for the 2Fa authentification.
                            </div>
                        </Tooltip>
                    </div>
                    :
                    <div className="setting-add-element-container">
                        <div style={{ margin: "5px", fontWeight: 'bold' }}>User Name </div>
                        <input
                            value={newUserName}
                            onChange={(e => {
                                setNewUserName(e.target.value);
                            })}
                            className="settings-input"
                            placeholder="New User"
                        />
                        <div style={{ margin: "5px", fontWeight: 'bold' }}>User Email </div>
                        <input
                            value={newUserMail}
                            onChange={(e => {
                                setNewUserMail(e.target.value);
                            })}
                            className="settings-input"
                            placeholder="New Email"
                        />
                        <div style={{ margin: "5px", fontWeight: 'bold' }}> Password </div>
                        <input
                            type="password"
                            value={newUserPwd}
                            onChange={(e => {
                                setNewUserPwd(e.target.value);
                            })}
                            className="settings-input"
                            placeholder="Password"
                        />
                        <div style={{ margin: "5px", fontWeight: 'bold' }}> Password Repeat </div>
                        <input
                            type="password"
                            value={newUserPwdRepeat}
                            onChange={(e => {
                                setNewUserPwdRepeat(e.target.value);
                            })}
                            className="settings-input"
                            placeholder="Repeat"
                        />
                        {
                            newUserHintText !== "" &&
                            <div>
                                {newUserHintText}
                            </div>
                        }
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                onClick={() => {
                                    setNewUserMode(false);
                                }}>
                                Cancel
                                <FaTimes className="cancel-delete-icon" />
                            </div>
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                onClick={isInRegisterCall ? () => { } : () => {
                                    postNewUser();
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>Add User</div>
                                    {isInRegisterCall ?
                                        <div style={{ marginLeft: "5px" }}>
                                            <Circles
                                                height="20"
                                                width="20"
                                                color="var(--text-icons)"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        </div> :
                                        <FaPlusCircle className="confirm-icon" />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="settings-search-bar">
                    <input
                        className="settings-search-bar-input"
                        value={userSeacrhText}
                        onChange={(e) => {
                            setUserSearchText(e.target.value);
                        }}
                        placeholder="Search for user"
                    />
                    <FaSearch />
                </div>



                {/* Render CompanyAccountTiles */}
                {paginatedUsers.map((user) => (
                    <CompanyAccountTile
                        key={"Tile" + user.userID}
                        apiURL={apiURL}
                        availableLLMs={companyLLMs}
                        companyAccountData={user}
                        userData={userData}
                        onUserDataChanged={() => {
                            onCompanyUsersChanged();
                            fetchUserGroups();
                        }}
                        companyData={companyData}
                        accountGroups={userGroups.filter((group) => group.user_ids.includes(user.userID))}
                        pricingPresetOptions={pricingPresetOptions}
                        onUserGroupsChanged={()=> {
                            onUserGroupsChanged();
                        }}
                        isMobile={isMobile}
                    />
                ))}

                {/* Pagination Controls */}
                {filteredUsers.length > itemsPerPage &&
                    <div className="pagination-controls">
                        <button
                            onClick={()=> {
                                setCurrentPage(1)
                            }}
                            disabled={currentPage === 1}
                            className="pagination-button"
                        >
                            <FaAngleDoubleLeft />
                        </button>
                        <button
                            onClick={()=>{
                                setCurrentPage(currentPage - 1)
                            }}
                            disabled={currentPage === 1}
                            className="pagination-button"
                        >
                            <FaAngleLeft />
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={()=> {
                                setCurrentPage(currentPage + 1)
                            }}
                            disabled={currentPage === totalPages}
                            className="pagination-button"
                        >
                            <FaAngleRight />
                        </button>
                        <button
                            onClick={()=> {
                                setCurrentPage(totalPages)
                            }}
                            disabled={currentPage === totalPages}
                            className="pagination-button"
                        >
                            <FaAngleDoubleRight />
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

import { useState, useEffect } from 'react';
import { ModelModus } from '../SettingsTypedefs';
import { FaCheck, FaTrashAlt, FaEdit, FaTimes } from 'react-icons/fa';

export default function ModelModusTile({ apiURL, modelModusData, onModusChanged, isMobile, ...props }:
    { apiURL: string, modelModusData: ModelModus, onModusChanged: Function, isMobile: boolean }) {
    const [editMode, setEditMode] = useState(false);
    const [editedModusName, setEditedModusName] = useState(modelModusData.name);
    const [editedModusPrePrompt, setEditedModusPrePrompt] = useState(modelModusData.prePrompt);
    useEffect(() => {
        setEditedModusName(modelModusData.name);
        setEditedModusPrePrompt(modelModusData.prePrompt);
    }, [modelModusData]);


    async function deleteModelModus() {
        try {
            const response = await fetch(
                apiURL + "/delete_model_modus?modusID=" + modelModusData.modusID,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                  console.log("Catched expired Session");
                  window.location.href = "/session_expired";
                } else {
                  alert("Error: " + response.status);
                }
              } else {
                onModusChanged();
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function updateModelModus() {
        try {
            const response = await fetch(
                apiURL + "/update_model_modus?modusID=" + modelModusData.modusID + "&modusName=" + editedModusName + "&modusPrePrompt=" + editedModusPrePrompt,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                  console.log("Catched expired Session");
                  window.location.href = "/session_expired";
                } else {
                  alert("Error: " + response.status);
                }
              }  else {
                onModusChanged();
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    return (
        <div
            style={isMobile ? { padding: "10px", width: "100%", border: "solid 1px var(--text-icons-2)", borderRadius: "10px", marginBottom: "10px", display: "flex", flexDirection: "column" } :
                { padding: "10px", width: "90%", border: "solid 1px var(--text-icons-2)", borderRadius: "10px", marginBottom: "10px", display: "flex", flexDirection: "row" }}
        >
            {/* Labels or Inputs */}
            {editMode ?
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
                    <div style={{ marginBottom: "5px" }}>
                        <input
                            value={editedModusName}
                            onChange={(e) => {
                                setEditedModusName(e.target.value);
                            }}
                            placeholder='Modus Name'
                            className='settings-input'
                        />
                    </div>
                    <div style={{ marginLeft: "5px", marginRight: "5px", borderTop: "1px solid var(--gray-500)", width: "100%" }}>
                        <textarea
                            value={editedModusPrePrompt}
                            onChange={(e) => {
                                setEditedModusPrePrompt(e.target.value)
                            }}
                            className="settings-input"
                            style={{ height: "100%" }}
                        />
                    </div>
                </div>
                :
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
                    <div style={{ marginBottom: "5px" }}>
                        {modelModusData.name}
                    </div>
                    <div style={{ marginLeft: "5px", marginRight: "5px", borderTop: "1px solid var(--gray-500)", width: "100%" }}>
                        {modelModusData.prePrompt}
                    </div>
                </div>
            }
            {/* Buttons */}
            <div style={isMobile ? { display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", marginTop:"20px" } :
                { display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
                {editMode ?
                    /* Confirm */
                    <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                        onClick={() => {
                            updateModelModus();
                        }}>
                        Confirm
                        <FaCheck className="confirm-icon" />
                    </div> :
                    /* Edit */
                    <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                        onClick={() => {
                            setEditMode(true);
                        }}
                    >
                        Edit
                        <FaEdit style={{ marginLeft: "10px" }} />
                    </div>
                }
                {/* Delete */}
                {editMode ?
                    <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                        onClick={() => {
                            setEditMode(false);
                        }}>
                        Cancel
                        <FaTimes className="cancel-delete-icon" />
                    </div>
                    :

                    <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                        onClick={() => {
                            deleteModelModus();
                        }}>
                        Delete
                        <FaTrashAlt className="cancel-delete-icon" />
                    </div>}
            </div>
        </div>
    );
}

import './../Home/Home.css'
import PrivcyPromptLogo from './../../assets/privacyprompt.svg'
function SessionExpired() {
    return (
        <div className="page-container">
            <div style={{ height: "100%", width: "100%", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                <img src={PrivcyPromptLogo} alt="PrivacyPrompt Logo" style={{ maxHeight: "60px" }} />
                <div style={{ fontSize: "20px", fontWeight: "bolder" }}>
                    Your session expired please log in again to continue...
                </div>
                <div
                    style={{
                        "background-color": "var(--background-3)",
                        "padding":"10px",
                        "border-radius": "15px",
                        "display": "flex",
                        "flex-direction": "row",
                        "justify-content": "center",
                        "align-items": "center",
                        "box-shadow": "0 4px 10px rgba(0, 0, 0, 1)",
                        "fontWeight": "bold",
                        "marginTop": "20px",
                        "cursor": "pointer"
                    }}
                onClick={()=> {
                    window.location.href = "/login";
                }}>
                    Log In
                </div>
            </div>

        </div>
    );
}

export default SessionExpired;
import { useRef } from 'react';
import './DocumentChat.css'

function Resizer({ leftContainerRef, rigthContainerRef }: 
    { leftContainerRef: React.RefObject<HTMLDivElement>, rigthContainerRef: React.RefObject<HTMLDivElement> }) {
    const isDragging = useRef(false);
    const resizerRef = useRef<HTMLDivElement | null>(null);
    const MIN_WIDTH_PX = 500; // Minimum width in pixels

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        isDragging.current = true;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging.current || !leftContainerRef.current || !rigthContainerRef.current) return;

        const totalWidth = leftContainerRef.current.parentNode as HTMLElement;
        const totalWidthPx = totalWidth.offsetWidth;
        const newChatWidthPx = e.clientX; // Width in pixels from the left

        // Apply minimum width constraint (min 450px for left and right containers)
        if (newChatWidthPx < MIN_WIDTH_PX || (totalWidthPx - newChatWidthPx) < MIN_WIDTH_PX) {
            return;
        }

        // Set the new width for left and right containers (in pixels)
        leftContainerRef.current.style.flexBasis = `${newChatWidthPx}px`;
        rigthContainerRef.current.style.flexBasis = `${totalWidthPx - newChatWidthPx}px`;
    };

    const handleMouseUp = () => {
        isDragging.current = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    return (
        <div
            ref={resizerRef}
            className="resizer"
            onMouseDown={handleMouseDown}
        />
    );
}

export default Resizer;

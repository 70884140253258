import { FaPlusCircle } from "react-icons/fa";
import { useState, useRef } from "react";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./../Settings.css"
import { Tooltip } from 'react-tooltip';
import { AmbigousWord, PrivacyRule, SecuredDataTable } from "../SettingsTypedefs";
import SecuredTableTile from "./SecuredTableTile";
import WarningPopUp from "../../../Components/WarningPopUp";

library.add(faRepeat)

export default function SecureDataSettings({ apiURL, securedTables, securityRules, onSecureDataChanged, ambigousWords, onAmbigousWordsChanged, isMobile }:
    { apiURL: string, securedTables: SecuredDataTable[] | null, securityRules: PrivacyRule[], onSecureDataChanged: Function, ambigousWords: AmbigousWord[], onAmbigousWordsChanged: Function, isMobile: boolean }) {

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [ruleWarningText, setRuleWarningText] = useState<null | string>(null);


    async function postNewSecureDataTable(file: File) {
        try {
            const formData = new FormData();
            formData.append('secure_data_file', file);
            const response = await fetch(
                apiURL + "/add_secure_data_table",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: formData,
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                  console.log("Catched expired Session");
                  window.location.href = "/session_expired";
                } else {
                  alert("Error: " + response.status);
                }
              } else {
                onSecureDataChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Handle File input Change")
        if (event.target.files && event.target.files.length === 1) {
            postNewSecureDataTable(event.target.files[0])
        } else {
            console.log("ToDO Set Info Text Here")
        }
        event.target.value = '';
    }

    return (
        <div className="subsetting-content">
            <center><h4>Secure Data</h4></center>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div
                    data-tooltip-id="NewTableTooltip"
                    className={`settings-button ${isMobile ? "mobile" : ""}`}
                    onClick={() => {
                        if (fileInputRef.current) {
                            fileInputRef.current.click()
                        }
                    }}
                >
                    Add Secured Data Table
                    <FaPlusCircle className="add-element-icon" />
                    <Tooltip id="NewTableTooltip" className="custom-tooltip" delayShow={800}>
                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                            Here you can add a new data table you can then define rules which entries of the table are not allowed in which context.
                        </div>
                    </Tooltip>
                </div>

            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className={`settings-subheader ${isMobile ? "mobile" : ""}`}>Secured Tables</div>
                    {securedTables && securedTables.map((sdTable) => (
                        <SecuredTableTile
                            apiURL={apiURL}
                            securedTable={sdTable}
                            securityRules={securityRules}
                            onTableChanged={onSecureDataChanged}
                            onRulesChangedWarning={(warning) => {
                                console.log("Recived Warning after deleting Table:", warning)
                                setRuleWarningText(warning);
                            }}
                            key={"table" + sdTable.tableID}
                            isMobile={isMobile}
                            ambigousWords={ambigousWords.filter(amb => sdTable.columns.map(col => col.id).includes(amb.columnID))}
                        />
                    ))}
                </div>
            </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
                accept=".csv"
            />
            {ruleWarningText &&
                <WarningPopUp
                    text={typeof ruleWarningText === "string" ? ruleWarningText : ""}
                    delayHide={15000}
                    onHidePopUp={() => {
                        setRuleWarningText(null);
                    }}
                    cssClassName="warning-popup"
                />
            }
        </div>
    );
}

import React, { useEffect, useState, useRef } from "react";
import { FaTrash } from 'react-icons/fa';
import { FaEllipsisV } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import './TopicSelection.css'
export default function TopicSelectionTile({ topic, isSelected, topicIndex, category, onTopicSelected, onTopicRemoved, apiURL }) {
    const [topicMenuActive, setTopicMenuActive] = useState(false);
    const [isRenameMode, setIsRenameMode] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [renameText, setRenameText] = useState("")
    const topicMenuRef = useRef(null);
    const renameInputRef = useRef(null);

    useEffect(() => {
        updateMenuPosition();
    },[])

    useEffect(() => {
        function handleClickOutside(event) {
            if (topicMenuActive && topicMenuRef.current && !topicMenuRef.current.contains(event.target)) {
                setTopicMenuActive(false);
            }
            if (isRenameMode && renameInputRef.current && !renameInputRef.current.contains(event.target)) {
                setIsRenameMode(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isRenameMode, topicMenuActive]);

    useEffect(() => {    
        if (topicMenuActive) {
          updateMenuPosition();
          const scrollableParent = document.querySelector(".topic-selection-content");
          scrollableParent.addEventListener("scroll", updateMenuPosition);
          return () => {
            scrollableParent.removeEventListener("scroll", updateMenuPosition);
          };
        }
      }, [topicMenuActive]);

      function updateMenuPosition() {
        const button = document.getElementById("topicMenuButton"+topicIndex+ category);
        if (button) {
          const rect = button.getBoundingClientRect();
          setMenuPosition({
            top: rect.top + window.scrollY + rect.height,
            left: rect.left + rect.width,
          });
        }
      }

    async function remove_topic(topicID) {
        var apiLink = apiURL + "/delete_topic?topicID=" + topicID;
        fetch(apiLink, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((response) => {
            if (response.status !== 200) {
                const errorData = response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                  console.log("Catched expired Session");
                  window.location.href = "/session_expired";
                } else {
                  alert("Error: " + response.status);
                }
              }  else {
                // success
                response.json().then((data) => {
                    //console.log(data);
                    onTopicRemoved();
                });
            }
        });
    }

    async function rename_topic() {
        console.log("Renaming call")
        const encodedTopic = encodeURIComponent(renameText);
        var apiLink = apiURL + "/rename_topic?topic_name=" + encodedTopic + "&topic_id=" + topic.topic_id;
        fetch(apiLink, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((response) => {
            if (response.status !== 200) {
                const errorData = response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                  console.log("Catched expired Session");
                  window.location.href = "/session_expired";
                } else {
                  alert("Error: " + response.status);
                }
              }  else {
                // success
                response.json().then((data) => {
                    //console.log(data);
                    onTopicRemoved();
                });
            }
        });

    }

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            {isRenameMode ? <div className="topic-bubble-container-selected" style={{ alignItems: 'center', justifyContent: 'center' }}>
                <input
                    ref={renameInputRef}
                    className="rename-topic-input"
                    type="text"
                    value={renameText}
                    onChange={(e) => setRenameText(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            rename_topic();
                            setIsRenameMode(false); // Exit rename mode after Enter key is pressed
                        }
                    }}
                ></input>
            </div> :
                <div className={isSelected || topicMenuActive ? 'topic-bubble-container-selected' : 'topic-bubble-container'} key={'topic-' + topicIndex}>
                    <button className="topic-bubble" key={topicIndex}
                        onClick={() => {
                            onTopicSelected();
                        }}>
                        {topic.topic_name}
                    </button>
                    <button id= {"topicMenuButton"+topicIndex + category} className="topic-menu-button" onClick={(event) => {
                        event.stopPropagation();
                        //console.log("Sttiong Topic Menu active with:", !topicMenuActive)
                        setTopicMenuActive(!topicMenuActive);
                        //remove_topic(topic[0])
                    }}>
                        <FaEllipsisV className="customize-menu-icon" />
                    </button>

                </div>}
            {topicMenuActive &&
                <div className="topic-menu-wrapper" ref={topicMenuRef} style={{ position: "fixed", top: menuPosition.top, left: menuPosition.left }}>
                    <div className="topic-menu">
                        <div
                            className="topic-menu-tile"
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsRenameMode(true);
                                setRenameText(topic.topic_name);
                                setTopicMenuActive(false);
                            }
                            }>
                            <FaPen className="customize-trash-icon" style={{ margin: '20px' }} />
                            <label style={{ marginRight: '20px' }}>Rename Topic</label>
                        </div>
                        <div
                            className="topic-menu-tile"
                            style={{ color: 'red' }}
                            onClick={() => {
                                remove_topic(topic.topic_id);
                                onTopicRemoved();
                                setTopicMenuActive(false);
                            }}>
                            <FaTrash className="customize-trash-icon" style={{ margin: '20px' }} />
                            <label style={{ marginRight: '20px' }}>Delete Topic</label>
                        </div>
                    </div>
                </div>}
        </div>
    );
}

import { FaAngleDown, FaAngleUp, FaUserFriends, FaUser, FaNetworkWired, FaTrashAlt, FaTimes, FaInfoCircle } from "react-icons/fa";
import { UserData, LLMAPIData, UserGroupData, SecurityLevelData, PricingPreset, UserInfoModel } from "../SettingsTypedefs";
import { useState, useRef, useEffect } from "react";
import AnimateHeight, { Height } from "react-animate-height"
import Select from "react-select";
import APIAccessTile from "../../../Components/APIAccessTile";
import { Tooltip } from "react-tooltip";

export default function UserGroupTile({ userData, availableLLMs, companyUsers, userGroupData, apiURL, onUserGroupsChanged, securityLevelOptions, pricingPresetOptions, isMobile, ...props }:
    { userData: UserData, availableLLMs: LLMAPIData[], companyUsers: UserInfoModel[], userGroupData: UserGroupData, apiURL: string, onUserGroupsChanged: Function, securityLevelOptions: SecurityLevelData[], pricingPresetOptions: PricingPreset[], isMobile:boolean }) {
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    async function deleteUserGroup() {
        try {
            const response = await fetch(
                apiURL + "/delete_user_group?groupID=" + userGroupData.groupID,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                  console.log("Catched expired Session");
                  window.location.href = "/session_expired";
                } else {
                  alert("Error: " + response.status);
                }
              }  else {
                const data = await response.json();
                console.log(data)
                onUserGroupsChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function updateUserGroup() {
        try {
            const response = await fetch(
                apiURL + "/update_user_group",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "groupID": userGroupData.groupID,
                            "groupName": userGroupData.groupName,
                            "groupUsers": userGroupData.user_ids,
                            "groupLLMs": userGroupData.llm_ids,
                            "security_level": userGroupData.security_level
                        }
                    )
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                  console.log("Catched expired Session");
                  window.location.href = "/session_expired";
                } else {
                  alert("Error: " + response.status);
                }
              }  else {
                const data = await response.json();
                console.log(data)
                onUserGroupsChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    return (
        <AnimateHeight
            {...props}
            height={height}
            contentClassName="auto-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ width: "100%", alignItems: "center", justifyContent: "center", overflow: "visible" }}
        >
            <div
                key={userGroupData.groupID}
                onClick={() => {
                    if (!expanded) {
                        setExpanded(true)
                    }
                }}
                ref={contentDiv}
                style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
            >
                <div className="setting-user-group">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: "3" }}>
                            <FaUserFriends style={{ marginRight: '5px' }} />
                            {userGroupData.groupName}
                        </div>
                        <div style={{ flex: "1" }}>
                            <FaUser style={{ marginRight: '5px' }} />
                            {userGroupData.user_ids.length}
                        </div>
                        <div style={{ flex: "1" }}>
                            <FaNetworkWired style={{ marginRight: '5px' }} />
                            {userGroupData.llm_ids.length}
                        </div>
                        {expanded ?
                            <FaAngleUp
                                onClick={() => {
                                    setExpanded(false)
                                }}
                                style={{ cursor: 'pointer' }}
                            /> :
                            <FaAngleDown />
                        }
                    </div>
                    {
                        expanded &&
                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: "center", justifySelf: "centers", width: "100%" }}>



                            <div style={{ marginTop: "10px", alignSelf: "center", justifySelf: "center", width: "100%" }}>
                            <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>
                                    User Group Members
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: "10px", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <div style={{ flex: "1" }}>
                                        Add User to Group
                                    </div>
                                    <div style={{ flex: "3" }}>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: "var(--background)",
                                                    color: "var(--text-icons)",
                                                    zIndex: 10
                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: 110
                                                }),
                                                group: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    zIndex: 110
                                                }),
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)"
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)",
                                                }),
                                                multiValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: 10
                                                }),
                                                multiValueLabel: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "100%",
                                                    margin: "5px",
                                                })
                                            }}

                                            options={companyUsers}
                                            getOptionLabel={(user) => user.name}
                                            getOptionValue={(user) => user.name}

                                            onChange={(selectedOptions) => {
                                                console.log("selectedOptions:", selectedOptions);
                                                const updated_user_list = selectedOptions.map(option => option.userID)
                                                userGroupData.user_ids = updated_user_list;
                                                updateUserGroup();
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition={'absolute'}
                                            menuPlacement={'auto'}
                                            isMulti={true}
                                            value={companyUsers.filter((user) => userGroupData.user_ids.includes(user.userID))}

                                        />
                                    </div>
                                </div>
                                {companyUsers && companyUsers.filter((user) => { return userGroupData.user_ids.includes(user.userID) }).map((user) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: "10px", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                        key={"Group" + userGroupData.groupID + "User" + user.userID}
                                    >
                                        <div style={isMobile ? { fontWeight: "normal", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }:
                                            { fontWeight: "bold", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "60%" }}>
                                            {user.name}
                                            <FaTimes
                                                style={{ color: "red", cursor: "pointer" }}
                                                onClick={() => {
                                                    const updatedUserList = userGroupData.user_ids.filter(fil_user => fil_user !== user.userID);
                                                    userGroupData.user_ids = updatedUserList;
                                                    updateUserGroup();
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>



                            <div style={{ marginTop: "10px", alignSelf: "center", justifySelf: "center", width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "15px" }}>
                                <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>
                                        User Group LLM APIs Access Rigths
                                    </div>
                                    <div data-tooltip-id="GroupAccessTooltip">
                                        <FaInfoCircle />
                                    </div>
                                    <Tooltip id="GroupAccessTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Here you can modify the access rigths of the group regarding your different Large Language Models. Every member of the group will get a contingent according to the defined contingent of the access.
                                        </div>
                                    </Tooltip>
                                </div>
                                {availableLLMs.filter((llm) => { return llm.is_company_api }).map((llm) => (
                                    <APIAccessTile
                                        key={"Group" + userGroupData.groupID + "llm" + llm.api_id}
                                        llm={llm}
                                        contingent={userGroupData.llm_ids.filter(awc => awc.api_id === llm.api_id)[0] ? userGroupData.llm_ids.filter(awc => awc.api_id === llm.api_id)[0].contingent : -1}
                                        pricingPresetsOptions={pricingPresetOptions}
                                        onAccessChanged={(accessActivated, contingent, selectedPresetID) => {
                                            if (accessActivated) {
                                                const index = userGroupData.llm_ids.findIndex(awc => awc.api_id === llm.api_id);
                                                if (index === -1) {
                                                    userGroupData.llm_ids = [
                                                        ...userGroupData.llm_ids,
                                                        {
                                                            api_id: llm.api_id,
                                                            contingent: contingent,
                                                            pricingPresetID: selectedPresetID
                                                        }
                                                    ];
                                                } else {
                                                    userGroupData.llm_ids[index] = {
                                                        ...userGroupData.llm_ids[index],
                                                        contingent: contingent,
                                                        pricingPresetID: selectedPresetID
                                                    };
                                                }
                                            } else {
                                                userGroupData.llm_ids = userGroupData.llm_ids.filter(awc => awc.api_id !== llm.api_id)
                                            }
                                            updateUserGroup();
                                        }}
                                        accessActivated={userGroupData.llm_ids.filter(awc => awc.api_id === llm.api_id).length > 0}
                                        selectedPricingPresetID={userGroupData.llm_ids.filter(awc => awc.api_id === llm.api_id)[0] ? userGroupData.llm_ids.filter(awc => awc.api_id === llm.api_id)[0].pricingPresetID : -1}
                                        isMobile={isMobile}
                                    />
                                ))}
                            </div>

                            <div style={{ marginTop: "10px", alignSelf: "center", justifySelf: "center", width: "100%" }}>
                            <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>
                                    User Group Security Level
                                </div>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: "var(--background)",
                                            color: "var(--text-icons)",
                                            zIndex: 10
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 110
                                        }),
                                        group: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            zIndex: 110
                                        }),
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)"
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)",
                                        }),
                                        multiValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 10
                                        }),
                                        multiValueLabel: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        container: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: "100%",
                                            margin: "5px",
                                        })
                                    }}
                                    getOptionLabel={(sec_level) => sec_level.security_level_name}
                                    getOptionValue={(sec_level) => sec_level.security_level_name}
                                    options={securityLevelOptions}

                                    onChange={(selectedOptions) => {
                                        userGroupData.security_level = selectedOptions!.security_level
                                        updateUserGroup();
                                    }}
                                    value={securityLevelOptions.find(ele => ele.security_level === userGroupData.security_level)}
                                    menuPortalTarget={document.body}
                                    menuPosition={'absolute'}
                                    menuPlacement={'auto'}
                                    isMulti={false}
                                />
                            </div>


                            <div className={`settings-button ${isMobile? "mobile" : ""}`} style={{ borderColor: "red", color: "red" }}
                                onClick={() => {
                                    deleteUserGroup();
                                }}
                            >
                                Delete User Group
                                <FaTrashAlt style={{ color: "red", margin: "5px" }} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </AnimateHeight>
    )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --background: #fff;
    --background-1: #ececec;
    --background-2: #cdcdcd;
    --background-3: #b4b4b4;
    --background-4: #9b9b9b;
    --gray-500: #676767;
    --text-icons-4: #424242;
    --text-icons-3: #2f2f2f;
    --text-icons-2: #212121;
    --text-icons-1: #171717;
    --text-icons: #0d0d0d;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,SAAS;IACT;;gBAEY;IACZ,mCAAmC;IACnC,kCAAkC;IAClC,kBAAkB;EACpB;;EAEA;IACE;eACW;EACb","sourcesContent":[":root {\r\n    --background: #fff;\r\n    --background-1: #ececec;\r\n    --background-2: #cdcdcd;\r\n    --background-3: #b4b4b4;\r\n    --background-4: #9b9b9b;\r\n    --gray-500: #676767;\r\n    --text-icons-4: #424242;\r\n    --text-icons-3: #2f2f2f;\r\n    --text-icons-2: #212121;\r\n    --text-icons-1: #171717;\r\n    --text-icons: #0d0d0d;\r\n}\r\n\r\nbody {\r\n    margin: 0;\r\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n      sans-serif;\r\n    -webkit-font-smoothing: antialiased;\r\n    -moz-osx-font-smoothing: grayscale;\r\n    position: relative;\r\n  }\r\n  \r\n  code {\r\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n      monospace;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useState } from "react";
import { FaPlusCircle, FaTimes, FaInfoCircle } from "react-icons/fa";
import { PricingPreset } from "../SettingsTypedefs";
import Switch from 'react-switch';
import PricingPresetTile from "./PricingPresetTile";
import { Tooltip } from "react-tooltip";

export default function PriceControlSettings({ apiURL, pricingPresets, onPricingPresetsChanged, isMobile, ...props }:
    { apiURL: string, pricingPresets: PricingPreset[], onPricingPresetsChanged: Function, isMobile:boolean }) {

    const [newPresetMode, setNewPresetMode] = useState(false);
    const [newPresetName, setNewPresetName] = useState("");
    const [newPresetContignent, setNewPresetContingent] = useState(500);
    const [newPresetContingentType, setNewPresetContingentType] = useState(false)

    async function postNewPrest() {
        try {
            const response = await fetch(
                apiURL + "/add_price_preset",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "name": newPresetName,
                            "contingent": newPresetContignent,
                            "contingentType": newPresetContingentType
                        }
                    )
                }
            );
            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                setNewPresetMode(false);
                setNewPresetContingent(500);
                setNewPresetContingentType(false);
                setNewPresetName("");
                setTimeout(() => {
                    onPricingPresetsChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }

    }


    return (
        <div className="subsetting-content">
            <center><h4>Price Control</h4></center>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                {!newPresetMode ?
                    <div className={`settings-button ${isMobile? "mobile" : ""}`}
                        onClick={() => {
                            setNewPresetMode(true);
                        }}
                        data-tooltip-id="AddPricingPresetTooltip"
                    >
                        Add new Pricing Preset
                        <FaPlusCircle className="add-element-icon" />
                        <Tooltip id="AddPricingPresetTooltip" className="custom-tooltip" delayShow={800}>
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                Price controll Presets can be defined here to increase controllability of your Companys Large languange Model API usage. If an API access of a user or a group is controlled by a preset you can adjust the contingent of the access by adjusting the presets contingent.
                            </div>
                        </Tooltip>
                    </div>
                    :
                    <div className="setting-add-element-container">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ margin: "5px", fontWeight: 'bold' }}>Pricing Preset Name</div>
                            <div data-tooltip-id="NPPPNTooltip">
                                <FaInfoCircle />
                                <Tooltip id="NPPPNTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        The name under which this preset will be shown in further settings.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <input
                            value={newPresetName}
                            onChange={(e => {
                                setNewPresetName(e.target.value);
                            })}
                            className="settings-input"
                            placeholder="New Preset Name"
                        />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ margin: "5px", fontWeight: 'bold' }}>Preset Contingent</div>
                            <div data-tooltip-id="NPPPCTooltip">
                                <FaInfoCircle />
                                <Tooltip id="NPPPCTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        The contingent that will be assigned for the Large Language Models Accesses controlled by this preset. If the contingent is updated in the Preset it will be updated for all accesses as well.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <input
                            type="number"
                            value={newPresetContignent}
                            onChange={(e => {
                                setNewPresetContingent(Number(e.target.value));
                            })}
                            className="settings-input"
                        />

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ margin: "5px", fontWeight: 'bold' }}>Contingent Type</div>
                            <div data-tooltip-id="NPPCTTooltip">
                                <FaInfoCircle />
                                <Tooltip id="NPPCTTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        Large Language Model costs are either calculated per Request or per Token. This preset will only be shown for access to those APIs, that use the same contingent type. For token calculation we use cl100k_base tokenization.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", minHeight: "50px" }}>
                            <div style={{ marginRight: "15px" }}>
                                <Switch
                                    checked={newPresetContingentType}
                                    onChange={() => {
                                        setNewPresetContingentType(!newPresetContingentType);
                                    }}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <div>
                                    {!newPresetContingentType ? "Contingent per Request" : "Contingent per Token"}
                                </div>
                            </div>

                        </div>
                        <div style={{ fontSize: "16px" }}>The contingent type can not be changed after creating a Pricing Preset</div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div
                                className={`settings-button ${isMobile? "mobile" : ""}`}
                                onClick={() => {
                                    setNewPresetMode(false);
                                }}>
                                Cancel
                                <FaTimes className="cancel-delete-icon" />
                            </div>
                            <div
                                className={`settings-button ${isMobile? "mobile" : ""}`}
                                onClick={() => {
                                    postNewPrest();
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>Add Preset</div>
                                    <FaPlusCircle className="confirm-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>Existing Presets</div>
                {pricingPresets.map((preset) => (
                    <PricingPresetTile
                        key={"presettile" + preset.id}
                        apiURL={apiURL}
                        preset={preset}
                        onPresetChanged={onPricingPresetsChanged}
                        isMobile={isMobile}
                    />
                ))}
            </div>

        </div>
    );
}

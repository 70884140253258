import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import Quill from 'quill';
import { QuillOptions } from 'quill';
import 'quill/dist/quill.snow.css';
import { Delta, Range, Op } from 'quill/core';
import QuillCursors, { Cursor } from 'quill-cursors';
import { ConnectedClient, UserData } from '../Settings/SettingsTypedefs';
import APIDocumentEditingMenu from './APIDocumentEditingMenu';
import markdownToDelta from "markdown-to-quill-delta";

Quill.register('modules/cursors', QuillCursors);

interface DocumentEditorProps {
    onTextChange: (delta: Delta, oldDelta: Delta) => void;
    onSelectionChange: (range: Range, content: string) => void;
    onSelectionPrompt: (selectedRange: Range, selectedText: string, isReplacementCallBack: Boolean) => void;
    onPromptChanged: (prompt: string) => void;
    prompt: string;
    connectedClients: ConnectedClient[];
    editAllowed: Boolean;
    user: UserData;
    selectedText: string | null;
    isInReplacementRequest: Boolean;
}

export interface DocEditorRef {
    updateEditorText: (delta: Delta, oldDelta: Delta) => void;
    updateEditorSelection: (index: number, length: number, clientID: number) => void;
    setEditorText: (newText: Delta) => void;
    getEditorContents: () => Delta;
    getEditorText: () => string;
    writeTextToLastCursorPosition: (text: string) => void;
    streamToLastCursorPosition: (text: string, showReplacementMenu: Boolean, done: Boolean) => Promise<void>;
}

// Use forwardRef to expose Quill instance modify functionsto parent
const DocumentEditor = forwardRef<DocEditorRef, DocumentEditorProps>(({ onTextChange, onSelectionPrompt, onSelectionChange, onPromptChanged, prompt, connectedClients, editAllowed, user, selectedText, isInReplacementRequest }, ref) => {
    const [cursorIDs, setCursorIDs] = useState<string[]>([]);
    const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const [selectedRange, setSelectedRange] = useState<Range>(new Range(0, 0));
    const [replacementMenuVisible, setReplacementMenuVisible] = useState<Boolean>(false);
    const [replacementMenuPosition, setReplacementMenuPosition] = useState<{ x: number, y: number } | null>(null);
    const oldReplacedRangeRef = useRef<Range | null>(null);
    const newReplacedRangeRef = useRef<Range | null>(null);
    const editorRef = useRef<HTMLDivElement>(null);
    const quillInstanceRef = useRef<Quill | null>(null);
    const rigthClickMenuRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const cursorsRef = useRef<any>(null);


    const userColors = [
        "#FF5733",  // Bright Red
        "#33A1FF",  // Sky Blue
        "#FFC300",  // Golden Yellow
        "#8D33FF",  // Vivid Purple
        "#28A745",  // Bright Green
        "#FF33A6",  // Hot Pink
        "#33FF57",  // Lime Green
        "#FF914D",  // Orange
        "#33FFE0",  // Aquamarine
        "#FF338B",  // Magenta
        "#FFD633",  // Mustard
        "#3358FF",  // Bright Blue
        "#FF5733",  // Coral
        "#FF33C4",  // Fuchsia
        "#33FFB5"   // Mint Green
    ];


    useEffect(() => {
        console.log("EffectHook Editor 1")
        if (editorRef.current && !quillInstanceRef.current) {
            const options: QuillOptions = {
                debug: 'error',
                readOnly: !editAllowed,
                modules: {
                    toolbar: true,
                    cursors: true,
                },
                placeholder: '...',
                theme: 'snow',
            };
            // Initialize Quill with the editor container
            quillInstanceRef.current = new Quill(editorRef.current, options);
            // Initalize cursors with new Quill
            cursorsRef.current = quillInstanceRef.current.getModule('cursors');
            {/* Textchange callback handling */ }
            quillInstanceRef.current.on('text-change', (delta, oldDelta, source) => {
                if (source === 'api') {
                    //console.log('Quill OnChange Callback by API');
                } else if (source === 'user') {
                    //console.log('Quill OnChange Callback by User Delta:', delta, 'OldDelta:', oldDelta);
                    onTextChange(delta, oldDelta);
                    if (cursorsRef.current) {
                        const currentCursors = cursorsRef.current.cursors();
                        currentCursors.forEach((cursor: Cursor) => {
                            if (cursor.id !== user.userID.toString() && cursor.id !== "Old" && cursor.id !== "New") {
                                if (cursor.range) {
                                    const cursorPosition = cursor.range.index;
                                    const updatedCursorPosition = delta.transformPosition(cursorPosition, false);
                                    //console.log("Moving Cursor from initial Useeffect")
                                    cursorsRef.current.moveCursor(cursor.id, {
                                        index: updatedCursorPosition,
                                        length: cursor.range.length
                                    });
                                }
                            }
                        });
                    }
                }
            });
            {/* Selection Change calback handling */ }
            quillInstanceRef.current.on('selection-change', (range, oldRange, source) => {
                if (source == 'api') {
                    //console.log("API Selection changed to:", range)
                }
                else {
                    if (range && quillInstanceRef.current) {
                        //console.log("Editor cal onSelectionChange with:", range)
                        const selectionCHangeContent = quillInstanceRef.current.getText(range)
                        onSelectionChange(range, selectionCHangeContent)
                        setSelectedRange(range);
                        if (range.length > 0) {
                            const selectionBounds = quillInstanceRef.current.getBounds(range.index, range.length);
                            if (selectionBounds && editorRef.current) {
                                //console.log("SelectionBounds:", selectionBounds)
                                const editorBounds = editorRef.current.getBoundingClientRect()
                                //console.log("EditorBounds:", editorBounds)
                                const selectedX = selectionBounds.right
                                const menuWidth = 500;
                                var calcX = selectedX;
                                if (selectedX + menuWidth > editorBounds.width) {
                                    //console.log("Adjusting X Position PromptSelectionMenu cause out of Bounds")
                                    calcX = 0;
                                }
                                var calcY = selectionBounds.bottom + 50
                                if (calcY + 200 > editorBounds.height) {
                                    //console.log("Adjusting Y Position PromptSelectionMenu cause out of Bounds")
                                    calcY = editorBounds.height - 400;
                                }
                                //console.log("CalcX:", calcX)
                                setContextMenuPosition({ x: calcX, y: calcY });
                            }
                        }

                    } else {
                        //console.log('Cursor not in the editor');
                    }
                }
            });
        }

        // Cleanup function to destroy Quill instance on unmount
        return () => {
            const toolbars = document.getElementsByClassName("ql-toolbar");
            while (toolbars.length > 0) {
                toolbars[0].remove(); // Remove each toolbar
            }
            if (quillInstanceRef.current) {
                quillInstanceRef.current = null;
            }
        };
    }, [editAllowed]);

    {/* Cursor for large language model response if its replacement response */ }
    useEffect(() => {
        console.log("EffectHook Editor 2")
        if (cursorsRef.current) {
            if (isInReplacementRequest) {
                //console.log("Creating Cursor for API answer")
                cursorsRef.current.createCursor("LLM", "Large Language Model API", "#3358FF");
                cursorsRef.current.moveCursor("LLM", {
                    index: selectedRange.index,
                    length: 0
                });
            } else {
                console.log("Removing Cursor for API answer")
                cursorsRef.current.removeCursor("LLM");
            }
        }
    }, [isInReplacementRequest])


    useEffect(() => {
        console.log("EffectHook Editor 3")
        //console.log("Connected Clients changed in Editor checking cursors")
        const clientIDStrings = connectedClients.map(client => client.user_id.toString()).sort((a, b) => Number(a) - Number(b));;
        clientIDStrings.forEach((clientID, index) => {
            if (cursorIDs.includes(clientID)) {
                //Nothing to do here
            } else {
                // Create new coop Cursor here#
                const color = userColors[index % userColors.length]; // Assign a color
                cursorsRef.current.createCursor(clientID, connectedClients[index].user_name, color);
                setCursorIDs(prev => [...prev, clientID]); // Add to state
                //console.log(`Cursor created for client ${clientID} with color ${color}`);
            }
        })
        cursorIDs.forEach((cursorID) => {
            if (!clientIDStrings.includes(cursorID)) {
                // Remove Cursor from UI
                cursorsRef.current.removeCursor(cursorID);
                setCursorIDs(prev => prev.filter(id => id !== cursorID)); // Remove from state
                //console.log(`Cursor removed for client ${cursorID}`);
            }
        })
    }, [connectedClients])



    // Expose the quill instance via the ref to the parent
    useImperativeHandle(ref, () => ({
        updateEditorText: (delta: Delta, old_delta: Delta) => {
            //console.log("Update Editor Text Call recived updating with:", delta);
            if (quillInstanceRef.current) {
                //quillInstanceRef.current.setContents(old_delta, "api")
                quillInstanceRef.current.updateContents(delta, "api")
            }
            // Moving Cursors if cursors are placed behind the update
            if (cursorsRef.current) {
                const currentCursors = cursorsRef.current.cursors();
                currentCursors.forEach((cursor: Cursor) => {
                    if (cursor.id !== user.userID.toString() && cursor.id !== "New") {
                        if (cursor.range) {
                            const cursorPosition = cursor.range.index;
                            const updatedCursorPosition = delta.transformPosition(cursorPosition, false);
                            cursorsRef.current.moveCursor(cursor.id, {
                                index: updatedCursorPosition,
                                length: cursor.range.length
                            });
                        }
                    }
                });
            }
        },
        updateEditorSelection: (index: number, length: number, clientID: number) => {
            console.log("Update Editor Selection Call recived with Index:", index, " Length:", length, " clientID:", clientID)
            if (cursorsRef.current) {
                console.log("Moving Cursor now")
                const newRange = new Range(index, length)
                cursorsRef.current.moveCursor(clientID.toString(), newRange)
            }
        },
        setEditorText: (newText: Delta) => {
            console.log("Set Editor Text recived new Editor Text:", newText);
            if (quillInstanceRef.current) {
                quillInstanceRef.current.setContents(newText, "api")
            }
        },
        getEditorContents: () => {
            console.log("Get Editor Contents called")
            var current_contents = new Delta()
            if (quillInstanceRef.current) {
                current_contents = quillInstanceRef.current.getContents()
            }
            return current_contents
        },
        getEditorText: () => {
            if (quillInstanceRef.current) {
                return quillInstanceRef.current.getText();
            } else {
                return "QuillInstanceRef Null Exception"
            }
        },
        writeTextToLastCursorPosition: async (text: string) => {
            //console.log("Received API Chunk to write to editor:", text);
            if (quillInstanceRef.current && selectedRange) {
                const textAsDelta = await convertToDelta(text);
                const delta = new Delta().retain(selectedRange.index).concat(textAsDelta);
                quillInstanceRef.current.updateContents(delta, "user");

                {/* Update of cursor positions */ }
                if (cursorsRef.current) {
                    const currentCursors = cursorsRef.current.cursors();
                    currentCursors.forEach((cursor: Cursor) => {
                        if (cursor.id !== user.userID.toString()) {
                            if (cursor.range) {
                                const cursorPosition = cursor.range.index;
                                const updatedCursorPosition = delta.transformPosition(cursorPosition, false);
                                //console.log("Moving cursor in writeTextToLastCursorPosition")
                                cursorsRef.current.moveCursor(cursor.id, {
                                    index: updatedCursorPosition,
                                    length: cursor.range.length
                                });
                            }
                        }
                    });
                }
                selectedRange.index += text.length;
                quillInstanceRef.current.setSelection(selectedRange.index, 0, "api");
            }
        },
        streamToLastCursorPosition: async (text: string, showReplacementMenu: Boolean, done: Boolean) => {
            //console.log("Stream called")
            //console.log("Received API Chunk to write to editor:", text);
            const textAsDelta = await convertToDelta(text);
            {/* Check newReplacedRangeRef for Value to identify initial need of creating cursors and placing the Replacementmenu */ }
            let newReplacedRange: Range | null = newReplacedRangeRef.current ? newReplacedRangeRef.current : null;
            if (!newReplacedRange && showReplacementMenu && selectedRange) {
                {/* Creating Cursor for old part with selected Range */ }
                oldReplacedRangeRef.current = selectedRange;
                //console.log("Creating Cursor for OLD replace")
                cursorsRef.current.createCursor("Old", "Old", "#FF33C4");
                cursorsRef.current.moveCursor("Old", oldReplacedRangeRef.current);
                {/* Creating Cursor for new part from selected Range and delta */ }
                newReplacedRange = new Range(selectedRange.index + selectedRange.length, 0);
                //console.log("Intial newReplacedRange:", newReplacedRange)
                newReplacedRangeRef.current = newReplacedRange;
                cursorsRef.current.createCursor("New", "New", "#33FFE0");
                //console.log("Moving Cursor initially")
                cursorsRef.current.moveCursor("New", newReplacedRange);
            }

            {/* Adjusting Replacement Menus Position / Initially Setting visibility */ }
            if (!replacementMenuPosition && newReplacedRange && showReplacementMenu && !replacementMenuVisible) {
                const selectionBounds = quillInstanceRef.current!.getBounds(newReplacedRange.index, newReplacedRange.length);
                if (selectionBounds && editorRef.current) {
                    //console.log("SelectionBounds:", selectionBounds)
                    const editorBounds = editorRef.current.getBoundingClientRect()
                    //console.log("EditorBounds:", editorBounds)
                    var calcY = selectionBounds.top + 40
                    setReplacementMenuPosition({ x: 0, y: calcY });
                }
                setReplacementMenuVisible(true);
            }

            {/* Actually Writing to the Editor */ }
            if (quillInstanceRef.current && newReplacedRange) {
                {/* Prepending Linebreaks to textAsDelta to make visible room for the replacement Menu */ }
                if (showReplacementMenu) {
                    //console.log("Inserting addintional linbreaks during replacement")
                    if (textAsDelta.ops[0].insert) {
                        textAsDelta.ops[0].insert = "\n\n\n\n\n" + textAsDelta.ops[0].insert
                    } else if (textAsDelta.ops.length > 1) {
                        if (textAsDelta.ops[1].insert)
                            textAsDelta.ops[1].insert = "\n\n\n\n\n" + textAsDelta.ops[1].insert
                    }
                }
                //console.log("Deleting signs with newReplacedRange length:", newReplacedRange)
                const writeDelta = new Delta()
                    .retain(newReplacedRange.index)
                    .delete(newReplacedRange.length)
                    .concat(textAsDelta);

                newReplacedRange = new Range(newReplacedRange.index, textAsDelta.length());
                newReplacedRangeRef.current = newReplacedRange;
                quillInstanceRef.current.updateContents(writeDelta, "user");
                cursorsRef.current.moveCursor("New", newReplacedRange);

                {/*Adjust Cursor Positions after Text update */ }
                if (cursorsRef.current) {
                    const currentCursors = cursorsRef.current.cursors();
                    currentCursors.forEach((cursor: Cursor) => {
                        if (cursor.id !== user.userID.toString() && cursor.id !== "New" && cursor.id !== "Old" && cursor.range) {
                            const cursorPosition = cursor.range.index;
                            const updatedCursorPosition = writeDelta.transformPosition(cursorPosition, false);
                            cursorsRef.current.moveCursor(cursor.id, {
                                index: updatedCursorPosition,
                                length: cursor.range.length,
                            });
                        }
                    });
                }
            }
            // if (done) {
            //     console.log("Resetting replacedRangeRef")
            //     newReplacedRangeRef.current = null;
            // }
        }

    }));


    const convertToDelta = async (text: string): Promise<Delta> => {
        //console.log("Trying to convert text:", text)
        var ops: any[] = []
        try {
            ops = markdownToDelta(text);
        }
        catch {
            console.log("markdownToDelta Failed")
            ops = []
        }
        const qops = ops.map(op => {
            const transferop: Op = {
                insert: op.insert as string,
                attributes: op.attributes || {}
            }
            return transferop
        })
        let delta = new Delta();
        delta.ops = qops;
        return delta;
    };

    const removeTextByRange = (rangeToDelete: Range) => {
        console.log("Removing Range:", rangeToDelete)
        if (quillInstanceRef.current) {
            console.log("Really removing now")
            quillInstanceRef.current.deleteText(rangeToDelete, "user")
        }
    }


    return (
        <div style={{ position: 'relative', height: "100%" }}>
            {/* Editor */}
            <div ref={editorRef} style={{ height: '100%', width: '100%', overflowY: 'hidden' }}>
                {/* Custom Replacement menu */}
                {replacementMenuVisible && (
                    <div
                        style={replacementMenuPosition ? {
                            position: 'absolute',
                            top: replacementMenuPosition.y,
                            left: replacementMenuPosition.x,
                            zIndex: 2000,
                            display: "flex",
                            width: "100%",
                            pointerEvents: "all"
                        } : { display: "none" }}>
                        <div className='doc-chat-replacement-menu'>
                            <div className='doc-chat-replacement-menu-button'
                                onPointerDown={(event) => {
                                    event.stopPropagation();
                                    setReplacementMenuVisible(false)
                                    setReplacementMenuPosition(null)
                                    if (newReplacedRangeRef.current) {
                                        removeTextByRange(newReplacedRangeRef.current);
                                    } else {
                                        console.log("newReplacedRangeRef is null")
                                    }
                                    newReplacedRangeRef.current = null;
                                    oldReplacedRangeRef.current = null;
                                    if (cursorsRef.current) {
                                        cursorsRef.current.removeCursor("New")
                                        cursorsRef.current.removeCursor("Old")
                                    }
                                }}
                            >
                                Keep Old
                            </div>
                            <div className='doc-chat-replacement-menu-button'
                                onPointerDown={(event) => {
                                    event.stopPropagation();
                                    setReplacementMenuVisible(false)
                                    setReplacementMenuPosition(null)
                                    if (oldReplacedRangeRef.current) {
                                        removeTextByRange(oldReplacedRangeRef.current);
                                    } else {
                                        console.log("oldReplacedRangeRef is null")
                                    }
                                    newReplacedRangeRef.current = null;
                                    oldReplacedRangeRef.current = null;
                                    if (cursorsRef.current) {
                                        cursorsRef.current.removeCursor("New")
                                        cursorsRef.current.removeCursor("Old")
                                    }
                                }}
                            >
                                Keep New
                            </div>
                            <div className='doc-chat-replacement-menu-button'
                                onPointerDown={(event) => {
                                    event.stopPropagation();
                                    setReplacementMenuVisible(false)
                                    setReplacementMenuPosition(null)
                                    newReplacedRangeRef.current = null;
                                    oldReplacedRangeRef.current = null;
                                    if (cursorsRef.current) {
                                        cursorsRef.current.removeCursor("New")
                                        cursorsRef.current.removeCursor("Old")
                                    }
                                }}
                            >
                                Keep Both
                            </div>
                        </div>
                    </div>
                )}

            </div>

            {/* Custom context menu */}
            {selectedText && selectedText !== "" && (
                <div
                    style={{
                        position: 'absolute',
                        top: contextMenuPosition.y,
                        left: contextMenuPosition.x,
                        zIndex: 1000,
                    }}
                    ref={menuRef}
                >
                    <APIDocumentEditingMenu
                        selectedRange={selectedRange}
                        rigthClickMenuRef={rigthClickMenuRef}
                        onSelectionPrompt={onSelectionPrompt}
                        prompt={prompt}
                        editRigth={editAllowed}
                        onPromptChanged={onPromptChanged}
                    />
                </div>)}


        </div>
    );
});

export default DocumentEditor;

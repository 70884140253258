import "./../Settings.css"
import { useState, useRef, useEffect } from "react";
import { FaPlusCircle, FaTimes, FaInfoCircle, FaAngleUp, FaAngleDown } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import Select from "react-select";
import Switch from 'react-switch';
import LLMIcon from "../../../Components/Icons/LLMIcon";
import { Circles } from 'react-loader-spinner';
import { SecurityLevelOption } from "../SettingsTypedefs";
import AnimateHeight, { Height } from "react-animate-height"

export default function AddAPI({ apiURL, onAPIAccessChanged, onAddAPIClosed, securityLevelOptions, isAdminView, isMobile, ...props }:
    { apiURL: string, onAPIAccessChanged: Function, onAddAPIClosed: Function, securityLevelOptions: SecurityLevelOption[], isAdminView: Boolean, isMobile: boolean }) {
    const [newAPIName, setNewAPIName] = useState("");
    const [newAPIModelName, setNewAPIModelName] = useState("");
    const [newAPIURL, setNewAPIURL] = useState("");
    const [newAPIKey, setNewAPIKey] = useState("");
    const [newAPISecLevel, setNewAPISecLevel] = useState<SecurityLevelOption | null>(null);
    const [newAPIContingentType, setNewAPIContingentType] = useState(false);
    const [newAPIIconNumber, setNewAPIIconNumber] = useState(0);
    const [newAPIStream, setNewAPIStream] = useState(true);
    const [newAPIImagesModality, setNewAPIImagesModality] = useState(false);
    const [newAPIHistory, setNewAPIHistory] = useState(true);
    const [newAPISystemMessages, setNewAPISystemMessages] = useState(true)
    const [newAPIRequestJsonString, setNewAPIRequestJsonString] = useState("");
    const [newAPIResponseJsonString, setNewAPIResponseJsonString] = useState("");

    const [requestJsonHints, setRequestJsonHints] = useState<string[]>([]);
    const [responseJsonHints, setResponseJsonHints] = useState<string[]>([]);



    const [isChangeIconMode, setIsChangeIconMode] = useState(false);
    const popupRef = useRef(null);
    const [isInAddAPICall, setIsInAddAPICall] = useState(false);
    const [advancedOptionsExpanded, setAdvancedOptionsExpanded] = useState(false);

    const contentDiv = useRef<HTMLDivElement | null>(null);
    const [height, setHeight] = useState<Height>('auto');
    useEffect(() => {
        console.log("Checking Request Json String")
        var requestHints: string[] = []
        if (!newAPIRequestJsonString.includes("<PROMPT>")) {
            requestHints = [...requestHints, "<PROMPT> Tag is missing in Request structure."]
        }
        if (newAPIImagesModality && !newAPIRequestJsonString.includes("<IMAGES>")) {
            requestHints = [...requestHints, "<IMAGES> Tag is missing in Request structure."]
        }
        if (newAPIHistory && !newAPIRequestJsonString.includes("<HISTORICPROMPT>")) {
            requestHints = [...requestHints, "<HISTORICPROMPT> Tag is missing in Request structure."]
        } else if (!/\[[^\[\]]*<HISTORICPROMPT>[^\[\]]*\]/.test(newAPIRequestJsonString) && newAPIHistory) {
            requestHints = [...requestHints, "<HISTORICPROMPT> Tag is not part of a List. To recive the history the Model should expect a List of historic mesages."]
        }
        if (newAPIHistory && !newAPIRequestJsonString.includes("<HISTORICANSWER>")) {
            requestHints = [...requestHints, "<HISTORICANSWER> Tag is missing in Request structure."]
        } else if (!/\[[^\[\]]*<HISTORICANSWER>[^\[\]]*\]/.test(newAPIRequestJsonString) && newAPIHistory) {
            requestHints = [...requestHints, "<HISTORICANSWER> Tag is not part of a List. To recive the history the Model should expect a List of historic mesages."]
        }
        if (newAPISystemMessages && !newAPIRequestJsonString.includes("<SYSTEMMESSAGES>")) {
            requestHints = [...requestHints, "<SYSTEMMESSAGES> Tag is missing in Request structure."]
        } else if (!/\[[^\[\]]*<SYSTEMMESSAGES>[^\[\]]*\]/.test(newAPIRequestJsonString) && newAPISystemMessages) {
            requestHints = [...requestHints, "<SYSTEMMESSAGES> Tag is not part of a List. To recive the history the Model should expect a List of historic mesages."]
        }
        if (newAPIModelName !== "" && !newAPIRequestJsonString.includes("<MODELNAME>")) {
            requestHints = [...requestHints, "<MODELNAME> Tag is missing in request structure. If your API does not need a model name leave the model name field empty."]
        }
        try {
            JSON.parse(newAPIRequestJsonString);
        } catch (error) {
            requestHints = [...requestHints, "Structure is not Json Serializable."]
        }
        console.log("Request Json hints:", requestJsonHints)
        setRequestJsonHints(requestHints)

        console.log("Checking Response Json String")
        var responseHints: string[] = [];
        if (newAPIStream) {
            if (!newAPIResponseJsonString.includes("<CONTENT>")) {
                responseHints = [...responseHints, "<CONTENT> Tag is missing in chunk json structure."]
            }
            if (!newAPIResponseJsonString.includes("<DONE>")) {
                responseHints = [...responseHints, "<DONE> Tag is missing in chunk json structure. This will be the position where the done signal of the stream will be expected"]
            }
        } else {
            if (!newAPIResponseJsonString.includes("<ANSWER>")) {
                responseHints = [...responseHints, "<ANSWER> Tag is missing in chunk json structure."]
            }
        }
        try {
            JSON.parse(newAPIResponseJsonString);
        } catch (error) {
            responseHints = [...responseHints, "Structure is not Json Serializable"]
        }
        console.log("Response Json Hints:", responseHints)
        setResponseJsonHints(responseHints)
    }, [newAPIRequestJsonString, newAPIResponseJsonString, newAPIModelName, newAPIHistory, newAPIImagesModality, newAPIStream, newAPISystemMessages])

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;
        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });
        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
    }, []);


    async function postNewAPIAccess() {
        console.log("Posting new API access with iscompanyapi:", isAdminView);
        setIsInAddAPICall(true);
        try {
            const response = await fetch(
                apiURL + "/add_llm_api?is_company_api=" + isAdminView,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "api_id": -1,
                            "api_name": newAPIName,
                            "api_model_name": newAPIModelName,
                            "is_company_api": isAdminView,
                            "api_url": newAPIURL,
                            "api_key": newAPIKey,
                            "security_level": isAdminView ? newAPISecLevel ? newAPISecLevel.value : 1 : 1,
                            "contingentType": isAdminView ? newAPIContingentType : false,
                            "icon_number": newAPIIconNumber,
                            "stream": newAPIStream,
                            "images": newAPIImagesModality,
                            "history": newAPIHistory,
                            "system_messages": newAPISystemMessages,
                            "requestJsonString": newAPIRequestJsonString,
                            "responseJsonString": newAPIResponseJsonString
                        }
                    )
                }
            );

            if (response.status !== 200) {
                setIsInAddAPICall(false);
                console.log("Response status: " + response.status);
            } else {
                setIsInAddAPICall(false);
                onAddAPIClosed();
                setNewAPIKey("");
                setNewAPIName("");
                setNewAPIURL("");
                setNewAPIModelName("");
                setTimeout(() => {
                    onAPIAccessChanged();
                }, 200);
            }
        } catch (error) {
            setIsInAddAPICall(false);
            console.log(error);
            alert("Error: " + error);
        }
    }

    function apply_default_settings(ai_service: string) {
        console.log("Applying default settings for service:", ai_service)
        if (ai_service === "gpt") {
            if (!newAPIModelName) {
                setNewAPIModelName("gpt-4o");
            }
            if (!newAPIURL) {
                setNewAPIURL("https://api.openai.com/v1/chat/completions");
            }
            if (!newAPIName) {
                setNewAPIName("ChatGPT 4")
            }
            setNewAPIStream(true);
            setNewAPIHistory(true);
            setNewAPIImagesModality(false);
            setNewAPISystemMessages(true);
            setNewAPIIconNumber(7);
            setNewAPIRequestJsonString(`{
    "model":"<MODELNAME>",
    "stream": true,
    "messages": [
            {"role": "system", "content" : "<SYSTEMMESSAGES>"},
            {"role": "user", "content" : "<HISTORICPROMPT>"},
            {"role": "assistant", "content" : "<HISTORICANSWER>"},
            {"role": "user", "content" : "<PROMPT>"}
    ],
    "temperature": 0.7
}`);
            setNewAPIResponseJsonString(`{
    "id": "cmpl-6vnl9DFAu7LIDekf7HkbGiUJ",
    "object": "text_completion",
    "created": 1640927574,
    "model": "text-davinci-003",
    "choices": [
        {
        "delta": {
            "content": "<CONTENT>"
        },
        "index": 0,
        "finish_reason": "<DONE>"
        }
    ]
}`)
            return
        } else if (ai_service === "ollama") {
            if (!newAPIModelName) {
                setNewAPIModelName("llama3");
            }
            if (!newAPIName) {
                setNewAPIName("Lama")
            }
            setNewAPIStream(true);
            setNewAPIHistory(true);
            setNewAPIImagesModality(false);
            setNewAPISystemMessages(true);
            setNewAPIIconNumber(6);
            setNewAPIRequestJsonString(`{
    "model":"<MODELNAME>",
    "stream": true,
    "messages": [
            {"role": "system", "content" : "<SYSTEMMESSAGES>"},
            {"role": "user", "content" : "<HISTORICPROMPT>"},
            {"role": "assistant", "content" : "<HISTORICANSWER>"},
            {"role": "user", "content" : "<PROMPT>"}
    ],
    "options": {
        "temperature": 0.7
    }
}`);
            setNewAPIResponseJsonString(`{
    "model": "llama3",
    "created_at": "2023-08-04T08:52:19.385406455-07:00",
    "message": {
        "role": "assistant",
        "content": "<CONTENT>"
    },
    "done": "<DONE>"
}`)
            return
        } else {
            console.log("Recived unexpected ai serve string in set defaults")
        }
    }


    return (
        <div className="setting-add-element-container">
            <div style={isMobile ? { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '100%' } :
                { display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start', width: '100%' }}>
                <div style={isMobile ? { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: "100%" } :
                    { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', flex: '3' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ fontSize: '15px', marginRight: "5px" }}>API Name</div>
                            <div data-tooltip-id="NLLMAPINTooltip">
                                <FaInfoCircle />
                            </div>
                            <Tooltip id="NLLMAPINTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The name this API will be refered to in further settings.
                                </div>
                            </Tooltip>
                        </div>
                        <input
                            className="settings-input"
                            value={newAPIName}
                            placeholder="New API Name"
                            onChange={(e) => {
                                setNewAPIName(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ fontSize: '15px', marginRight: "5px" }}>API URL</div>
                            <div data-tooltip-id="NLLMAPIUTooltip">
                                <FaInfoCircle />
                            </div>
                            <Tooltip id="NLLMAPIUTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The URL of the API that is used. Example for ChatGPT: https://api.openai.com/v1/chat/completions
                                </div>
                            </Tooltip>
                        </div>
                        <input
                            className="settings-input"
                            value={newAPIURL}
                            placeholder="New API URL"
                            onChange={(e) => {
                                setNewAPIURL(e.target.value);
                            }}
                        />

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ fontSize: '15px', marginRight: "5px" }}>API Security Level</div>
                            <div data-tooltip-id="NLLMAPISLTooltip">
                                <FaInfoCircle />
                            </div>
                            <Tooltip id="NLLMAPISLTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The Security Level for this API. Requests for this API will be chacked according to the Rules and Policys assigned to the Security Level. If no Security Level is selected, the default Security Level will be assigned.
                                </div>
                            </Tooltip>
                        </div>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "var(--background)",
                                    color: "var(--text-icons)",
                                    zIndex: 10
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    zIndex: 110
                                }),
                                group: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    zIndex: 110
                                }),
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: "var(--background)"
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: "var(--background)",
                                }),
                                multiValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    zIndex: 10
                                }),
                                multiValueLabel: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                }),
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                    margin: "5px",
                                })
                            }}
                            options={securityLevelOptions}
                            onChange={(selectedOptions) => {
                                console.log("selectedOptions:", selectedOptions);
                                setNewAPISecLevel(selectedOptions);
                            }}
                            value={securityLevelOptions.find(ele => ele.value === newAPISecLevel?.value)}
                        />
                    </div>
                </div>
                <div style={isMobile ? { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: "100%" } :
                    { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', flex: '3' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '1' }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ fontSize: '15px', marginRight: "5px" }}>API Model Name</div>
                            <div data-tooltip-id="NLLMAPIMNTooltip">
                                <FaInfoCircle />
                            </div>
                            <Tooltip id="NLLMAPIMNTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    Most Large Language Model provider give access to different models which can be specified by the model name. Example for ChatGPT: gpt-3.5-turbo
                                </div>
                            </Tooltip>
                        </div>
                        <input
                            className="settings-input"
                            value={newAPIModelName}
                            placeholder="New API Model Name"
                            onChange={(e) => {
                                setNewAPIModelName(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '1' }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ fontSize: '15px', marginRight: "5px" }}>API Key</div>
                            <div data-tooltip-id="NLLMAPIKTooltip">
                                <FaInfoCircle />
                            </div>
                            <Tooltip id="NLLMAPIKTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The API can key can be empty for APIs without authorization. If a value is assigned it can only be accessed by system Admins.
                                </div>
                            </Tooltip>
                        </div>
                        <input
                            className="settings-input"
                            value={newAPIKey}
                            placeholder="New API Key"
                            onChange={(e) => {
                                setNewAPIKey(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '1' }}>
                        <label style={{ fontSize: '15px' }}>API Contingent Type</label>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                            <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{newAPIContingentType ? "Contingent per token" : "Contingent per request"}</div>
                            <div style={{ flex: "1" }}>
                                <Switch
                                    checked={newAPIContingentType}
                                    onChange={() => {
                                        setNewAPIContingentType(!newAPIContingentType)
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div style={isMobile ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "100%", marginTop: "20px" } :
                    { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1', height: "100%", marginRight: "20px" }}>
                    <LLMIcon
                        iconNumber={newAPIIconNumber}
                        maxHeight="100px"
                        maxWidth="100px"
                    />
                    <div
                        className={`settings-button ${isMobile ? "mobile" : ""}`}
                        onClick={() => {
                            setIsChangeIconMode(true);
                        }}>
                        Change Icon
                    </div>
                    {
                        isChangeIconMode &&
                        <div style={{ position: "relative" }}>

                            <div style={{
                                position: "absolute",
                                padding: "20px",
                                border: "3px solid var(--text-icons-3)",
                                borderRadius: "15px",
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "var(--background)",
                                zIndex: "111",
                                overflow: "auto",
                                right: "-100px",
                                top: "0"
                            }}
                                ref={popupRef}>
                                {[0, 1, 2, 3].map(index =>
                                    <div
                                        key={index}
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{ margin: "10px", cursor: "pointer" }}
                                            onClick={() => {
                                                setNewAPIIconNumber(index * 2)
                                                setIsChangeIconMode(false);
                                            }}>
                                            <LLMIcon
                                                iconNumber={index * 2}
                                                maxHeight="50px"
                                                maxWidth="50px"
                                            />
                                        </div>
                                        <div style={{ margin: "10px", cursor: "pointer" }}
                                            onClick={() => {
                                                setNewAPIIconNumber(index * 2 + 1)
                                                setIsChangeIconMode(false);
                                            }}>
                                            <LLMIcon
                                                iconNumber={index * 2 + 1}
                                                maxHeight="50px"
                                                maxWidth="50px"
                                            />
                                        </div>

                                    </div>
                                )}
                                Select your icon
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="advanced-llm-settings-container">
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                    onClick={() => {
                        setAdvancedOptionsExpanded(!advancedOptionsExpanded);
                    }}
                >
                    <div> Advanced Settings</div>
                    {
                        advancedOptionsExpanded ?
                            <FaAngleUp /> :
                            <FaAngleDown />
                    }
                </div>
                <AnimateHeight
                    {...props}
                    height={height}
                    contentClassName="animate-heigth-content"
                    contentRef={contentDiv}
                    disableDisplayNone
                    style={{ width: "100%", alignSelf: "center", alignItems: "center", justifyContent: "center", justifySelf: "center", overflow: "visible" }}>
                    <div className={`advanced-llm-settings-content ${advancedOptionsExpanded ? 'expanded' : ''}`} ref={contentDiv}>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                            <div className="settings-button"
                                onClick={() => {
                                    apply_default_settings("gpt");
                                }}>
                                Apply default GPT Settings
                                <div style={{ marginLeft: "10px" }}>
                                    <LLMIcon iconNumber={7} maxHeight="30px" maxWidth="30px" />
                                </div>
                            </div>
                            <div className="settings-button"
                                onClick={() => {
                                    apply_default_settings("ollama");
                                }}>
                                Apply default Ollama Settings
                                <div style={{ marginLeft: "10px" }}>
                                    <LLMIcon iconNumber={6} maxHeight="30px" maxWidth="30px" />
                                </div>
                            </div>
                        </div>

                        {/* Model Capability Switches */}
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ flex: "1", marginLeft: "30px", display: "flex", flexDirection: "row", marginTop: "15px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: '1' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <label style={{ fontSize: '15px', marginRight: "5px" }}>Stream</label>
                                        <div data-tooltip-id="StreamSwitchTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="StreamSwitchTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                Here you can switch between streamed and normal Model responses. In case of streamed Responses the Json Structure of the streamed chunks need to be defined. In case of normal responses this is the Case for the json structure of the normal response.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{newAPIStream ? "Model will stream Response as Chunks." : "Model will return answer as message."}</div>
                                        <div style={{ flex: "1" }}>
                                            <Switch
                                                checked={newAPIStream}
                                                onChange={() => {
                                                    setNewAPIStream(!newAPIStream)
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: '1' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <label style={{ fontSize: '15px', marginRight: "5px" }}>Images</label>
                                        <div data-tooltip-id="ImagesSwitchTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="ImagesSwitchTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                {"Here you can define if the Model is capable of processing Images as well as text. If activated the image data will be passed as a list of base64 encoded strings and the Request Json Structure needs to include a <IMAGES> tag that will be replaced with the Data."}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                        <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{newAPIImagesModality ? "Model can take Base64 encoded Images" : "No Image upload to Model possible"}</div>
                                        <div style={{ flex: "1" }}>
                                            <Switch
                                                checked={newAPIImagesModality}
                                                onChange={() => {
                                                    setNewAPIImagesModality(!newAPIImagesModality);
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: "1", marginLeft: "30px", display: "flex", flexDirection: "row", marginTop: "15px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: '1' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <label style={{ fontSize: '15px', marginRight: "5px" }}>History</label>
                                        <div data-tooltip-id="HistoricSwitchTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="HistoricSwitchTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                {"Here you can define if the model should take in the previous messages of the current conversation as additional Data. If History is activated, there needs to be a list witch contains <HISTORICPROMPT> and <HISTORICANSWER> in some objects. The <PROMPT> tag can be part of this list as well."}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                        <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{newAPIHistory ? "History can be passed to model" : "Chat History will be ignored"}</div>
                                        <div style={{ flex: "1" }}>
                                            <Switch
                                                checked={newAPIHistory}
                                                onChange={() => {
                                                    setNewAPIHistory(!newAPIHistory)
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: '1' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <label style={{ fontSize: '15px', marginRight: "5px" }}>System Messages</label>
                                        <div data-tooltip-id="SystemMessagesSwitchTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="SystemMessagesSwitchTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                {"Here you can define if the model is capable of processing system messages. If this is activated, a <SYSTEMMESSAGE> Tag is expectet inside of the Request Json Strucutre. This Tag needs to be placed in a List to enable multiple System Messages."}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                        <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{newAPISystemMessages ? "System Messages enabled" : "System Messages disabled"}</div>
                                        <div style={{ flex: "1" }}>
                                            <Switch
                                                checked={newAPISystemMessages}
                                                onChange={() => {
                                                    setNewAPISystemMessages(!newAPISystemMessages)
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '2' }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ fontSize: '15px', marginRight: "5px" }}>Request Json Structure</div>
                                <div data-tooltip-id="RequestJsonTooltip">
                                    <FaInfoCircle />
                                </div>
                                <Tooltip id="RequestJsonTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        Here you can define the Json structure of the Large Language Model Request depending on the models capabilitys you need to define the parameters that are shown on the rigth inside of this Json
                                    </div>
                                </Tooltip>
                            </div>
                            <textarea
                                className="json-structure-input"
                                value={newAPIRequestJsonString}
                                placeholder='{
                                                "model": "gpt-4o-mini",
                                                "messages": [{"role": "user", "content": "Say this is a test!"}],
                                                "temperature": 0.7
                                            }'
                                onChange={(e) => {
                                    setNewAPIRequestJsonString(e.target.value);
                                }}
                            />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {requestJsonHints.map(hint =>
                                    <div style={{ color: "yellow" }} key={hint}>{hint}</div>
                                )}
                            </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '2' }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ fontSize: '15px', marginRight: "5px" }}>{newAPIStream ? "Chunk Json Structure" : "Response Json Structure"}</div>
                                <div data-tooltip-id="ResponseJsonTooltip">
                                    <FaInfoCircle />
                                </div>
                                <Tooltip id="ResponseJsonTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        Here you can define the Json structure of the APIs Response. The response needs to either contain the answer if the response is not streamed, or chunks with the parts of the Aswer that are streamed as well as a done trigger.
                                    </div>
                                </Tooltip>
                            </div>
                            <textarea
                                className="json-structure-input"
                                value={newAPIResponseJsonString}
                                placeholder='{
                                                "model": "gpt-4o-mini",
                                                "messages": [{"role": "user", "content": "Say this is a test!"}],
                                                "temperature": 0.7
                                            }'
                                onChange={(e) => {
                                    setNewAPIResponseJsonString(e.target.value);
                                }}
                            />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {responseJsonHints.map(hint =>
                                    <div style={{ color: "yellow" }} key={hint}>{hint}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </AnimateHeight>
            </div>
            {/* New Company API Footer */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div
                    className={`settings-button ${isMobile ? "mobile" : ""}`}
                    onClick={() => {
                        onAddAPIClosed();
                    }}>
                    Cancel
                    <FaTimes className="cancel-delete-icon" />
                </div>
                <div
                    className={`settings-button ${isMobile ? "mobile" : ""}`}
                    onClick={isInAddAPICall ? () => { } : () => {
                        postNewAPIAccess();
                    }}>
                    Add API Access
                    {!isInAddAPICall ?
                        <FaPlusCircle className="confirm-icon" />
                        :
                        <Circles
                            height="20"
                            width="20"
                            color="var(--text-icons)"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

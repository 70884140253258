import { FaAngleDown, FaAngleUp, FaTrashAlt, FaCheck, FaEyeSlash, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Switch from "react-switch"
import { LLMAPIData, OptionType } from "../SettingsTypedefs";
import { Tooltip } from 'react-tooltip';
import { Circles } from 'react-loader-spinner';
import LLMIcon from "../../../Components/Icons/LLMIcon";
import "./../Settings.css"

export default function APITile({ apiAccessData, apiURL, onAPIAccessChanged, isAdminView, securityLevelOptions, isMobile, ...props }:
    { apiAccessData: LLMAPIData, apiURL: string, onAPIAccessChanged: Function, isAdminView: Boolean, securityLevelOptions: OptionType[], isMobile: boolean }) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);

    const [advancedOptionsExpanded, setAdvancedOptionsExpanded] = useState<boolean>(false);
    const [advancedOptionsHeigth, setAdvancedOptionsHeigth] = useState<Height>('auto');
    const advancedOptionsDiv = useRef<HTMLDivElement | null>(null);


    const [editedAPIName, setEditedAPIName] = useState<string>(apiAccessData ? apiAccessData.api_name   !== null ? apiAccessData.api_name : "" : "");
    const [editedAPIModelName, setEditedAPIModelName] = useState<string>(apiAccessData ? apiAccessData.api_model_name !== null  ? apiAccessData.api_model_name : "" : "");
    const [editedAPIUrl, setEditedAPIUrl] = useState<string>(apiAccessData ? apiAccessData.api_url !== null ? apiAccessData.api_url : "" : "");
    const [editedAPIKey, setEditedAPIKey] = useState<string>(apiAccessData ? apiAccessData.api_key !== null  ? apiAccessData.api_key : "" : "");
    const [editedSecurityLevel, setEditedSecurityLevel] = useState<number>(apiAccessData ? apiAccessData.security_level !== null ? apiAccessData.security_level : 1 : 1);
    const [editedContingentType, setEditedContingentType] = useState<boolean>(apiAccessData ? apiAccessData.contingentType !== null  ? apiAccessData.contingentType : false : false);
    const [editedIconNumber, setEditedIconNumber] = useState<number>(apiAccessData ? apiAccessData.icon_number !== null  ? apiAccessData.icon_number : 0 : 0)
    const [editedAPIStream, setEditedAPIStream] = useState<boolean>(apiAccessData ? apiAccessData.stream  !== null ? apiAccessData.stream : true : true);
    const [editedAPIImagesModality, setEditedAPIImagesModality] = useState<boolean>(apiAccessData ? apiAccessData.images  !== null ? apiAccessData.images : false : false);
    const [editedAPIHistory, setEditedAPIHistory] = useState<boolean>(apiAccessData ? apiAccessData.history  !== null ? apiAccessData.history : true : true);
    const [editedAPISystemMessages, setEditedAPISystemMessages] = useState<boolean>(apiAccessData ? apiAccessData.system_messages  !== null ? apiAccessData.system_messages : true : true);
    const [editedAPIRequestJsonString, setEditedAPIRequestJsonString] = useState<string>(apiAccessData ? apiAccessData.requestJsonString  !== null ? apiAccessData.requestJsonString : "Failed APITILE read data" : "Failed APITILE read data");
    const [editedAPIResponseJsonString, setEditedAPIResponseJsonString] = useState<string>(apiAccessData ? apiAccessData.responseJsonString  !== null ? apiAccessData.responseJsonString : "Failed APITILE read data" : "failed APITIle read data");

    const [requestHints, setRequestHints] = useState<string[]>([]);
    const [responseHints, setResponseHints] = useState<string[]>([]);


    const [isIconSelectionMode, setIsIconSelectionMode] = useState<boolean>(false);
    const popupRef = useRef<HTMLDivElement | null>(null);

    const [showAPIKey, setShowAPIKey] = useState<boolean>(false);
    const [showContingentTypeHint, setShowContingentTypeHint] = useState<boolean>(false);
    const [checkingStatusAfterUpdate, setCheckingStatusAfterUpdate] = useState<boolean>(false)


    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            const target = event.target as Node;
            if (popupRef.current && !popupRef.current.contains(target)) {
                setIsIconSelectionMode(false);
            }
        };
        if (isIconSelectionMode) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isIconSelectionMode]);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;
        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });
        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        const element = advancedOptionsDiv.current as HTMLDivElement;
        if (element) {
            const resizeObserver = new ResizeObserver(() => {
                setAdvancedOptionsHeigth(element.clientHeight);
            });
            resizeObserver.observe(element);
            return () => resizeObserver.disconnect();
        }
    }, []);


    useEffect(() => {
        console.log("API Access Data in Tile changed:", apiAccessData)
        if (apiAccessData) {
            setEditedAPIName(apiAccessData.api_name ? apiAccessData.api_name : "");
            setEditedAPIKey(apiAccessData.api_key ? apiAccessData.api_key : "");
            setEditedAPIModelName(apiAccessData.api_model_name ? apiAccessData.api_model_name : "");
            setEditedAPIUrl(apiAccessData.api_url ? apiAccessData.api_url : "");
            setEditedContingentType(apiAccessData.contingentType)
            setEditedIconNumber(apiAccessData.icon_number);
            setEditedAPIStream(apiAccessData.stream);
            setEditedAPIImagesModality(apiAccessData.images);
            setEditedAPISystemMessages(apiAccessData.system_messages);
            setEditedAPIHistory(apiAccessData.history);
            setEditedAPIRequestJsonString(apiAccessData.requestJsonString ? apiAccessData.requestJsonString : "Failed to read request json");
            setEditedAPIResponseJsonString(apiAccessData.responseJsonString ? apiAccessData.responseJsonString : "Failed to read response json")
        }
        setCheckingStatusAfterUpdate(false);
    }, [apiAccessData])

    useEffect(() => {
        console.log("Options in TIle:", securityLevelOptions)
    }, [securityLevelOptions])

    function checkAPIAccessEdited() {
        if (!apiAccessData) {
            return false;
        }
        return editedAPIName !== apiAccessData.api_name || editedAPIUrl !== apiAccessData.api_url || editedAPIKey !== apiAccessData.api_key || editedAPIModelName !== apiAccessData.api_model_name || editedContingentType !== apiAccessData.contingentType || editedIconNumber !== apiAccessData.icon_number;
    }


    useEffect(() => {
        console.log("Checking Request Json String")
        var requestHints: string[] = []
        if (!editedAPIRequestJsonString.includes("<PROMPT>")) {
            requestHints = [...requestHints, "<PROMPT> Tag is missing in Request structure."]
        }
        if (editedAPIImagesModality && !editedAPIRequestJsonString.includes("<IMAGES>")) {
            requestHints = [...requestHints, "<IMAGES> Tag is missing in Request structure."]
        }
        if (editedAPIHistory && !editedAPIRequestJsonString.includes("<HISTORICPROMPT>")) {
            requestHints = [...requestHints, "<HISTORICPROMPT> Tag is missing in Request structure."]
        } else if (!/\[[^\[\]]*<HISTORICPROMPT>[^\[\]]*\]/.test(editedAPIRequestJsonString) && editedAPIHistory) {
            requestHints = [...requestHints, "<HISTORICPROMPT> Tag is not part of a List. To recive the history the Model should expect a List of historic mesages."]
        }
        if (editedAPIHistory && !editedAPIRequestJsonString.includes("<HISTORICANSWER>")) {
            requestHints = [...requestHints, "<HISTORICANSWER> Tag is missing in Request structure."]
        } else if (!/\[[^\[\]]*<HISTORICANSWER>[^\[\]]*\]/.test(editedAPIRequestJsonString) && editedAPIHistory) {
            requestHints = [...requestHints, "<HISTORICANSWER> Tag is not part of a List. To recive the history the Model should expect a List of historic mesages."]
        }
        if (editedAPISystemMessages && !editedAPIRequestJsonString.includes("<SYSTEMMESSAGES>")) {
            requestHints = [...requestHints, "<SYSTEMMESSAGES> Tag is missing in Request structure."]
        } else if (!/\[[^\[\]]*<SYSTEMMESSAGES>[^\[\]]*\]/.test(editedAPIRequestJsonString) && editedAPISystemMessages) {
            requestHints = [...requestHints, "<SYSTEMMESSAGES> Tag is not part of a List. To recive the history the Model should expect a List of historic mesages."]
        }
        if (editedAPIModelName !== "" && !editedAPIRequestJsonString.includes("<MODELNAME>")) {
            requestHints = [...requestHints, "<MODELNAME> Tag is missing in request structure. If your API does not need a model name leave the model name field empty."]
        }
        try {
            JSON.parse(editedAPIRequestJsonString);
        } catch (error) {
            requestHints = [...requestHints, "Structure is not Json Serializable."]
        }
        console.log("Request Json hints:", requestHints)
        setRequestHints(requestHints)

        console.log("Checking Response Json String")
        var responseHints: string[] = [];
        if (editedAPIStream) {
            if (!editedAPIResponseJsonString.includes("<CONTENT>")) {
                responseHints = [...responseHints, "<CONTENT> Tag is missing in chunk json structure."]
            }
            if (!editedAPIResponseJsonString.includes("<DONE>")) {
                responseHints = [...responseHints, "<DONE> Tag is missing in chunk json structure. This will be the position where the done signal of the stream will be expected"]
            }
        } else {
            if (!editedAPIResponseJsonString.includes("<ANSWER>")) {
                responseHints = [...responseHints, "<ANSWER> Tag is missing in chunk json structure."]
            }
        }
        try {
            JSON.parse(editedAPIResponseJsonString);
        } catch (error) {
            responseHints = [...responseHints, "Structure is not Json Serializable"]
        }
        console.log("Response Json Hints:", responseHints)
        setResponseHints(responseHints)
    }, [editedAPIRequestJsonString, editedAPIResponseJsonString, editedAPIModelName, editedAPIHistory, editedAPIImagesModality, editedAPIStream, editedAPISystemMessages])


    async function updateAPIAccess() {
        console.log("Updating API access");
        setCheckingStatusAfterUpdate(true)
        try {
            const response = await fetch(
                apiURL + "/update_llm_api",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "api_id": apiAccessData.api_id,
                            "api_name": editedAPIName,
                            "api_model_name": editedAPIModelName,
                            "is_company_api": apiAccessData.is_company_api,
                            "api_url": editedAPIUrl,
                            "api_key": editedAPIKey,
                            "security_level": editedSecurityLevel,
                            "contingentType": editedContingentType,
                            "icon_number": editedIconNumber,
                            "stream" : editedAPIStream,
                            "images" : editedAPIImagesModality,
                            "history": editedAPIHistory,
                            "system_messages": editedAPISystemMessages,
                            "requestJsonString": editedAPIRequestJsonString,
                            "responseJsonString": editedAPIResponseJsonString
                        }
                    )
                }
            );

            if (response.status !== 200 && response.status !== 409) {
                console.log("Response status: " + response.status);
            } else if (response.status === 409) {
                setShowContingentTypeHint(true)
                setTimeout(() => {
                    onAPIAccessChanged();
                }, 200);
            } else {
                setShowContingentTypeHint(false)
                setTimeout(() => {
                    onAPIAccessChanged();
                }, 200);
                //setExpanded(false);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function deleteAPIAccess() {
        console.log("Deleting API access");
        try {
            const response = await fetch(
                apiURL + "/remove_llm_api?llmID=" + apiAccessData.api_id,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                setExpanded(false);
                setTimeout(() => {
                    onAPIAccessChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }


    return (
        <AnimateHeight
            {...props}
            height={height}
            contentClassName="animate-heigth-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ width: "100%", alignSelf: "center", alignItems: "center", justifyContent: "center", justifySelf: "center", overflow: "visible" }}>
            <div className="api-tile" ref={contentDiv}>
                <div
                    style={(expanded || !isAdminView) ? { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px' } :
                        { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px', cursor: "pointer" }}
                    onClick={(expanded || !isAdminView) ? () => { console.log("Empty Function") } : () => {
                        setExpanded(!expanded);
                    }}
                >
                    {!expanded ?
                        <div style={{ width: '70%', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ marginRight: "20px", flex: "4" }}>{editedAPIName}</div>
                            <div style={{ flex: "1" }}>
                                <LLMIcon
                                    iconNumber={editedIconNumber}
                                    maxHeight="30px"
                                    maxWidth="30px"
                                />
                            </div>
                        </div>
                        :
                        <div style={{ width: '70%', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div style={isMobile ? { marginLeft: "5px", fontWeight: 'normal', width: "40%" } :
                                { marginLeft: "5px", fontWeight: 'bold', width: "40%" }}>
                                LLM Name:
                            </div>
                            <input
                                className="settings-input"
                                value={editedAPIName}
                                placeholder="New API Name"
                                onChange={(e) => {
                                    setEditedAPIName(e.target.value);
                                }}
                            />
                            <div data-tooltip-id="LLMNameTooltip">
                                <FaInfoCircle />
                                <Tooltip id="LLMNameTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw" }}>
                                        The name under which this API will be displayed to your Users and in further settings.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    }
                    <div style={{ width: "15%" }}>
                        {!checkingStatusAfterUpdate ?
                            <div style={apiAccessData.is_alive ? { color: 'green' } : { color: 'red' }}>
                                Status: {apiAccessData.is_alive ? "Alive" : "Dead"}
                            </div> :
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <Circles
                                    height="20"
                                    width="20"
                                    color="var(--text-icons)"
                                    ariaLabel="circles-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        }
                    </div>
                    {isAdminView && <div style={{ marginRight: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        {expanded ? <FaAngleUp /> : <FaAngleDown />}
                    </div>}
                </div>
                {expanded && <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                    <div style={isMobile ? { display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" } :
                        { display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', padding: '5px', position: "relative" }}>
                            <div className={`api-tile-input-row ${isMobile ? "mobile" : ""}`}>
                                <div>
                                    API URL:
                                </div>
                                <input
                                    className="settings-input"
                                    value={editedAPIUrl}
                                    placeholder="New API URL"
                                    onChange={(e) => {
                                        setEditedAPIUrl(e.target.value);
                                    }}
                                />
                                <div data-tooltip-id="LLMURLTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="LLMURLTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            The URL where the anonymized prompts will be send to. Example for ChatGPT: https://api.openai.com/v1/chat/completions
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`api-tile-input-row ${isMobile ? "mobile" : ""}`}>
                                <div>
                                    API Model Name:
                                </div>
                                <input
                                    className="settings-input"
                                    value={editedAPIModelName}
                                    placeholder="New API Model Name"
                                    onChange={(e) => {
                                        setEditedAPIModelName(e.target.value);
                                    }}
                                />
                                <div data-tooltip-id="LLMModelNameTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="LLMModelNameTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Most Large Language Model provider give access to different models which can be specified by the model name. Example for ChatGPT: gpt-3.5-turbo
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`api-tile-input-row ${isMobile ? "mobile" : ""}`}>
                                <div>
                                    API Key:
                                </div>
                                <input
                                    type={showAPIKey ? "text" : "password"}
                                    className="settings-input"
                                    value={editedAPIKey}
                                    placeholder="New API Key"
                                    onChange={(e) => {
                                        setEditedAPIKey(e.target.value);
                                    }}
                                />
                                <FaEyeSlash
                                    onClick={() => {
                                        setShowAPIKey(!showAPIKey);
                                    }}
                                    style={{ marginRight: "5px" }}
                                />
                                <div data-tooltip-id="LLMKeyTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="LLMKeyTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            The API can key can be empty for APIs without authorization. If a value is assigned it can only be accessed by system Admins.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`api-tile-input-row ${isMobile ? "mobile" : ""}`}>
                                <div>
                                    Security Level:
                                </div>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: "var(--background)",
                                            color: "var(--text-icons)",
                                            zIndex: 10
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 110
                                        }),
                                        group: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            zIndex: 110
                                        }),
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)"
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)",
                                        }),
                                        multiValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 10
                                        }),
                                        multiValueLabel: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        container: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: "100%",
                                            margin: "5px",
                                        })
                                    }}
                                    options={securityLevelOptions}
                                    onChange={(selectedOptions) => {
                                        console.log("selectedOptions:", selectedOptions);
                                        setEditedSecurityLevel(selectedOptions!.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuPosition={'absolute'}
                                    menuPlacement={'auto'}
                                    value={securityLevelOptions.find(ele => ele.value === editedSecurityLevel)}
                                    isMulti={false}
                                />
                                <div data-tooltip-id="LLMSecLvlTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="LLMSecLvlTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Every Security Level is connected to rules and policys. If no Security Level is assigned the Default Security level will be selected. Every prompt send to this APIs will be checked with the rules and policys assigned to the Security Level.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`api-tile-input-row ${isMobile ? "mobile" : ""}`}>
                                <div>
                                    Contingent Type:
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                    <div style={{ flex: "1" }}>
                                        <Switch
                                            checked={editedContingentType}
                                            onChange={() => {
                                                setEditedContingentType(!editedContingentType)
                                            }}
                                        />

                                        {showContingentTypeHint &&
                                            <div style={{ fontSize: "12px", color: "yellow" }}>
                                                Update failed because there is a Pricing Preset with a different Contingent Type connected to this LLM API
                                            </div>}
                                    </div>
                                    {!isMobile &&
                                        <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>
                                            {editedContingentType ? "Contingent per token" : "Contingent per request"}
                                        </div>
                                    }
                                    <div data-tooltip-id="LLMConTypeTooltip">
                                        <FaInfoCircle />
                                        <Tooltip id="LLMConTypeTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The contingenttype is relevant for the usage calculation of users with restrained API access. Most Large Language Model Provider calculate theire usage per Token. Activated means contingents per token deactivated per request.
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1', height: "100%", marginRight: "20px", width: "100%" }}>
                            <LLMIcon
                                iconNumber={editedIconNumber}
                                maxHeight="100px"
                                maxWidth="100px"
                            />
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                onClick={() => {
                                    setIsIconSelectionMode(true);
                                }}>
                                Change Icon
                            </div>
                            {
                                isIconSelectionMode &&
                                <div style={{ position: "relative", overflow: "visible" }}>
                                    <div style={{
                                        position: "absolute",
                                        padding: "20px",
                                        border: "3px solid var(--text-icons-3)",
                                        borderRadius: "15px",
                                        display: "flex",
                                        flexDirection: "column",
                                        backgroundColor: "var(--background)",
                                        zIndex: "111",
                                        overflow: "auto",
                                        right: "-100px",
                                        top: "0"
                                    }}
                                        ref={popupRef}>
                                        {[0, 1, 2, 3].map(index =>
                                            <div
                                                key={index}
                                                style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ margin: "10px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setEditedIconNumber(index * 2)
                                                        setIsIconSelectionMode(false);
                                                    }}>
                                                    <LLMIcon
                                                        iconNumber={index * 2}
                                                        maxHeight="50px"
                                                        maxWidth="50px"
                                                    />
                                                </div>
                                                <div style={{ margin: "10px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setEditedIconNumber(index * 2 + 1)
                                                        setIsIconSelectionMode(false);
                                                    }}>
                                                    <LLMIcon
                                                        iconNumber={index * 2 + 1}
                                                        maxHeight="50px"
                                                        maxWidth="50px"
                                                    />
                                                </div>

                                            </div>
                                        )}
                                        Select your icon
                                    </div>
                                </div>
                            }
                        </div>


                    </div>
                    <div className="advanced-llm-settings-container">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                            onClick={() => {
                                setAdvancedOptionsExpanded(!advancedOptionsExpanded);
                            }}
                        >
                            <div> Advanced Settings</div>
                            {
                                advancedOptionsExpanded ?
                                    <FaAngleUp /> :
                                    <FaAngleDown />
                            }
                        </div>
                        <AnimateHeight
                            {...props}
                            height={advancedOptionsHeigth}
                            contentClassName="animate-heigth-content"
                            contentRef={contentDiv}
                            disableDisplayNone
                            style={{ width: "100%", alignSelf: "center", alignItems: "center", justifyContent: "center", justifySelf: "center", overflow: "visible" }}>
                            <div className={`advanced-llm-settings-content ${advancedOptionsExpanded ? 'expanded' : ''}`} ref={advancedOptionsDiv}>
                                {/* Model Capability Switches */}
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ flex: "1", marginLeft: "30px", display: "flex", flexDirection: "row", marginTop: "15px" }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: '1' }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                <label style={{ fontSize: '15px', marginRight: "5px" }}>Stream</label>
                                                <div data-tooltip-id="StreamSwitchTooltip">
                                                    <FaInfoCircle />
                                                </div>
                                                <Tooltip id="StreamSwitchTooltip" className="custom-tooltip">
                                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                        Here you can switch between streamed and normal Model responses. In case of streamed Responses the Json Structure of the streamed chunks need to be defined. In case of normal responses this is the Case for the json structure of the normal response.
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{editedAPIStream ? "Model will stream Response as Chunks." : "Model will return answer as message."}</div>
                                                <div style={{ flex: "1" }}>
                                                    <Switch
                                                        checked={editedAPIStream}
                                                        onChange={() => {
                                                            setEditedAPIStream(!editedAPIStream)
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: '1' }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                <label style={{ fontSize: '15px', marginRight: "5px" }}>Images</label>
                                                <div data-tooltip-id="ImagesSwitchTooltip">
                                                    <FaInfoCircle />
                                                </div>
                                                <Tooltip id="ImagesSwitchTooltip" className="custom-tooltip">
                                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                        {"Here you can define if the Model is capable of processing Images as well as text. If activated the image data will be passed as a list of base64 encoded strings and the Request Json Structure needs to include a <IMAGES> tag that will be replaced with the Data."}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                                <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{editedAPIImagesModality ? "Model can take Base64 encoded Images" : "No Image upload to Model possible"}</div>
                                                <div style={{ flex: "1" }}>
                                                    <Switch
                                                        checked={editedAPIImagesModality}
                                                        onChange={() => {
                                                            setEditedAPIImagesModality(!editedAPIImagesModality);
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flex: "1", marginLeft: "30px", display: "flex", flexDirection: "row", marginTop: "15px" }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: '1' }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                <label style={{ fontSize: '15px', marginRight: "5px" }}>History</label>
                                                <div data-tooltip-id="HistoricSwitchTooltip">
                                                    <FaInfoCircle />
                                                </div>
                                                <Tooltip id="HistoricSwitchTooltip" className="custom-tooltip">
                                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                        {"Here you can define if the model should take in the previous messages of the current conversation as additional Data. If History is activated, there needs to be a list witch contains <HISTORICPROMPT> and <HISTORICANSWER> in some objects. The <PROMPT> tag can be part of this list as well."}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                                <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{editedAPIHistory ? "History can be passed to model" : "Chat History will be ignored"}</div>
                                                <div style={{ flex: "1" }}>
                                                    <Switch
                                                        checked={editedAPIHistory}
                                                        onChange={() => {
                                                            setEditedAPIHistory(!editedAPIHistory)
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: '1' }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                <label style={{ fontSize: '15px', marginRight: "5px" }}>System Messages</label>
                                                <div data-tooltip-id="SystemMessagesSwitchTooltip">
                                                    <FaInfoCircle />
                                                </div>
                                                <Tooltip id="SystemMessagesSwitchTooltip" className="custom-tooltip">
                                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                        {"Here you can define if the model is capable of processing system messages. If this is activated, a <SYSTEMMESSAGE> Tag is expectet inside of the Request Json Strucutre. This Tag needs to be placed in a List to enable multiple System Messages."}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                                <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{editedAPISystemMessages ? "System Messages enabled" : "System Messages disabled"}</div>
                                                <div style={{ flex: "1" }}>
                                                    <Switch
                                                        checked={editedAPISystemMessages}
                                                        onChange={() => {
                                                            setEditedAPISystemMessages(!editedAPISystemMessages)
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '2', marginTop:"10px"}}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>Request Json Structure</div>
                                        <div data-tooltip-id="RequestJsonTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="RequestJsonTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                Here you can define the Json structure of the Large Language Model Request depending on the models capabilitys you need to define the parameters that are shown on the rigth inside of this Json
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <textarea
                                        className="json-structure-input"
                                        value={editedAPIRequestJsonString}
                                        placeholder='{
                                                "model": "gpt-4o-mini",
                                                "messages": [{"role": "user", "content": "Say this is a test!"}],
                                                "temperature": 0.7
                                            }'
                                        onChange={(e) => {
                                            setEditedAPIRequestJsonString(e.target.value);
                                        }}
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        {requestHints.map(hint =>
                                            <div style={{ color: "yellow" }} key={hint}>{hint}</div>
                                        )}
                                    </div>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '2', marginTop:"10px" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>{editedAPIStream ? "Chunk Json Structure" : "Response Json Structure"}</div>
                                        <div data-tooltip-id="ResponseJsonTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="ResponseJsonTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                Here you can define the Json structure of the APIs Response. The response needs to either contain the answer if the response is not streamed, or chunks with the parts of the Aswer that are streamed as well as a done trigger.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <textarea
                                        className="json-structure-input"
                                        value={editedAPIResponseJsonString}
                                        placeholder='{
                                                "model": "gpt-4o-mini",
                                                "messages": [{"role": "user", "content": "Say this is a test!"}],
                                                "temperature": 0.7
                                            }'
                                        onChange={(e) => {
                                            setEditedAPIResponseJsonString(e.target.value);
                                        }}
                                    />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        {responseHints.map(hint =>
                                            <div style={{ color: "yellow" }} key={hint}>{hint}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </AnimateHeight>
                    </div>
                    {!apiAccessData.is_alive &&
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", margin: "15px", color: "yellow" }}>
                                    {"Connection Problem: " + apiAccessData.connection_hint}
                                </div>
                            }
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', justifySelf: "center", alignSelf: "center", width: "100%" }}>
                        <div
                            className={`settings-button ${isMobile ? "mobile" : ""} ${checkAPIAccessEdited() ? "disbaled" : ""}`}
                            onClick={checkAPIAccessEdited() ? () => {
                                updateAPIAccess();
                            } : () => {
                                console.log("No changes to update")
                            }}
                        >
                            Confirm Changes
                            <FaCheck className="confirm-icon" />
                        </div>

                        <div
                            className={`settings-button ${isMobile ? "mobile" : ""}`}
                            style={{ color: "red", borderColor: "red" }}
                            onClick={() => {
                                deleteAPIAccess();
                            }}
                        >
                            Delete
                            <FaTrashAlt className="cancel-delete-icon" />
                        </div>

                    </div>
                </div>}
            </div>
        </AnimateHeight>
    );
}

import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { FaUser, FaRegCopy, FaFileImport, FaFile, FaRecycle } from 'react-icons/fa';
import Quill from "quill";
import { Delta, Op, Range } from 'quill/core';
import "quill/dist/quill.bubble.css";
import AudioController from './AudioController';
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import LLMIcon from '../../Components/Icons/LLMIcon';
import { Circles } from 'react-loader-spinner';
import { UserData, Attachment, LLMAPIData } from '../Settings/SettingsTypedefs';
import adjustedMarkdownToDelta from '../../utils/adjustedmarkdowntodelta/adjustedmarkdowntodelta';

interface ChatEntryProps {
    apiURL: string;
    userData: UserData;
    source: string | null;
    text: string;
    chatIndex: number;
    model: LLMAPIData;
    attachments: Attachment[];
    onReuseAtachment: (attachment: Attachment) => void;
    imageInputAllowed: Boolean;
    replaceOptionOnSelect: Boolean;
    onWriteTextToEditor: (text: string) => void;
    isInGeneration: Boolean;
}

interface ChatEntryRef {
    readAloud: () => void;
}

const ChatEntry = forwardRef<ChatEntryRef, ChatEntryProps>(({
    apiURL,
    userData,
    source,
    text,
    chatIndex,
    model,
    attachments,
    onReuseAtachment,
    imageInputAllowed,
    replaceOptionOnSelect,
    onWriteTextToEditor,
    isInGeneration
}, ref) => {
    const editorRef = useRef<Quill | null>(null);
    const textContainerRef = useRef<HTMLDivElement | null>(null);
    const audioControllerRef = useRef<{ autoReadAlaoud: () => void } | null>(null);
    const [isTemplateSelection, setIsTemplateSelction] = useState(false);
    const [selectedRange, setSelectedRange] = useState<Range | null>(null);
    const contextMenuPositionRef = useRef<{ x: number, y: number }>({ x: 0, y: 0 });
    const options = {
        readOnly: true,
        theme: 'bubble',
        modules: {
            toolbar: false,
            clipboard: {
                matchVisual: false,
            },
        },
    };

    {/* Debug only */ }
    //useEffect(() => {
    //    console.log("Recived Source:", source, " Model:", model)
    //    console.log("Attachments in ChatEntry:", attachments)
    //}, [source, model, attachments])



    useEffect(() => {
        console.log("EffectHook ChatEntry 1")
        if (!editorRef.current && textContainerRef.current) {
            console.log("Recreating editor")
            const textContainer = textContainerRef.current;
            editorRef.current = new Quill(textContainer, options);
            if (replaceOptionOnSelect) {
                editorRef.current.on('selection-change', (range, oldRange, source) => {
                    if (source == 'api') {
                        //console.log("API Selection changed to:", range)
                    }
                    else {
                        if (range && editorRef.current) {
                            //console.log("Editor cal onSelectionChange with:", range)
                            const selectionCHangeContent = editorRef.current.getText(range)
                            if (range.length > 0) {
                                setSelectedRange(range);
                                const selectionBounds = editorRef.current.getBounds(range.index, range.length);
                                if (selectionBounds && editorRef.current) {
                                    const editorBounds = textContainer.getBoundingClientRect()
                                    console.log("SelectionBounds:", selectionBounds)
                                    console.log("EditorBounds:", editorBounds)
                                    const selectedX = selectionBounds.right;
                                    const menuWidth = 250;
                                    var calcX = selectedX;
                                    if (selectedX + menuWidth > editorBounds.width) {
                                        //console.log("Adjusting X Position PromptSelectionMenu cause out of Bounds")
                                        calcX = 0;
                                    }
                                    var calcY = selectionBounds.bottom + 25
                                    if (calcY + 50 > editorBounds.height) {
                                        //console.log("Adjusting Y Position PromptSelectionMenu cause out of Bounds")
                                        calcY = calcY - 100;
                                    }
                                    contextMenuPositionRef.current = ({ x: calcX, y: calcY });
                                }
                            } else {
                                //console.log("No range just index selected")
                                setSelectedRange(null)
                            }
                        } else {
                            //console.log('Cursor not in the editor');
                            setSelectedRange(null)
                        }
                    }
                });
            }
        }

        const removeOldButtons = () => {
            if (editorRef.current) {
                const buttonsToRemove = editorRef.current.container.querySelectorAll('.copy-code-button');
                buttonsToRemove.forEach(child => {
                    //console.log("Removed single Copy COde Button")
                    child.remove();
                });
            }
        };
        removeOldButtons();
        if (text && editorRef.current) {
            // Trying with delta formatting
            editorRef.current.setContents(convertToDelta(text));

            const containerRefChildren = editorRef.current.container.childNodes;

            let codeBlocksInMessage: HTMLElement[] = [];
            if (containerRefChildren.length > 0) {
                codeBlocksInMessage = Array.from(containerRefChildren[0].childNodes).filter(node => {
                    return node.nodeType === Node.ELEMENT_NODE && (node as Element).classList.contains('ql-code-block-container');
                }) as HTMLElement[];
            }

            //console.log("Number of code Blocks in Message:", codeBlocksInMessage);
            const editorContainerRect = editorRef.current.container.getBoundingClientRect();

            codeBlocksInMessage.forEach((codeBlock, index) => {
                //console.log("Adding single Copy Code Button")
                if (editorRef.current) {
                    codeBlock.style.position = 'relative';
                    const testContainer = editorRef.current.addContainer('ql-custom');
                    testContainer.innerHTML = `<div class="copy-code-button"">
                                                <i class="fas fa-copy" id="custom-button" style="padding: 3px;"></i>
                                            </div>`;

                    const button = testContainer.querySelector('#custom-button');
                    button!.id = "copybutton" + index + "c" + chatIndex;

                    const codeBlockRect = codeBlock.getBoundingClientRect();
                    const topPosition = codeBlockRect.top - editorContainerRect.top;
                    const rightPosition = 25;
                    testContainer.style.position = 'absolute';
                    testContainer.style.top = `${topPosition}px`;
                    testContainer.style.right = `${rightPosition}px`;
                    button!.addEventListener('click', () => {
                        console.log('Button inside custom container clicked!');
                        copyToClipboard(codeBlock.innerText)
                    });
                }
            });
        }
        if (isInGeneration) {
            //console.log("Newest Message with Text", text)
        }
        return () => {
            removeOldButtons();
        };
    }, [text]);

    useImperativeHandle<ChatEntryRef, ChatEntryRef>(ref, () => ({
        readAloud() {
            if (audioControllerRef.current) {
                audioControllerRef.current.autoReadAlaoud();
            } else {
                console.log("AudioControllerRef not set");
            }
        }
    }));

    const convertToDelta = (text: string): Delta => {
        //console.log("Trying to convert text:", text)
        var ops: any[] = []
        try {
            ops = adjustedMarkdownToDelta(text);
        }
        catch {
            console.log("ERROR CONVERTING TO DELTA")
            ops = []
        }
        //console.log("Ops from Module conversion:", ops)
        const qops = ops.map(op => {
            const transferop: Op = {
                insert: op.insert as string,
                attributes: op.attributes || {}
            }
            return transferop
        })
        let delta = new Delta();
        delta.ops = qops;
        return delta;
    };

    const formatTextCopy = (text: string) => {
        if (text) {
            // Simplified version of formatText for clipboard copy
            const boldRegex = /\*\*(.*?)\*\*/g;
            const boldText = text.replace(boldRegex, (match, p1) => `${p1}`);

            const italicRegex = /_([\s\S]*?)_/g;
            const italicedText = boldText.replace(italicRegex, (match, p1) => `${p1}`);

            const codeRegex = /```([\s\S]*?)```/g;
            const codedtext = italicedText.replace(codeRegex, (match, p1) => `${p1}`);

            const blockCodeRegex = /`([\s\S]*?)`/g;
            const blockCodedText = codedtext.replace(blockCodeRegex, (match, p1) => `${p1}`);

            const strikeThroughRegex = /~(.*?)~/g;
            const strikedThroughText = blockCodedText.replace(strikeThroughRegex, (match, p1) => `${p1}`);

            const majorHeaderRegEx = /###([\s\S]*?)\n/g;
            const majorHeadedText = strikedThroughText.replace(majorHeaderRegEx, (match, p1) => `${p1}`);

            const mediumHeaderRegEx = /##([\s\S]*?)\n/g;
            const mediumHeadedText = majorHeadedText.replace(mediumHeaderRegEx, (match, p1) => `${p1}`);

            const minorHeaderRegEx = /#([\s\S]*?)\n/g;
            const minorHeadedText = mediumHeadedText.replace(minorHeaderRegEx, (match, p1) => `${p1}`);

            const lines = minorHeadedText.split('\n');
            let html = '';
            let inOrderedList = false;
            let inUnorderedList = false;

            lines.forEach((line) => {
                if (line.match(/^\s*\d+\.\s/)) {
                    if (!inOrderedList) {
                        html += '';
                        inOrderedList = true;
                    }
                    const listItem = line.replace(/^\s*\d+\.\s/, '').trim();
                    html += `${listItem}\n`;
                } else if (line.startsWith('*') || line.startsWith('t*')) {
                    if (!inUnorderedList) {
                        html += '';
                        inUnorderedList = true;
                    }
                    const listItem = line.replace(/^\s*[\t*]\s?/, '').trim();
                    html += `${listItem}\n`;
                } else {
                    if (inOrderedList) {
                        html += '\n';
                        inOrderedList = false;
                    }
                    if (inUnorderedList) {
                        html += '\n';
                        inUnorderedList = false;
                    }
                    html += line + '\n';
                }
            });

            if (inOrderedList) {
                html += '\n';
            }
            if (inUnorderedList) {
                html += '\n';
            }
            return html;
        }
    };

    const copyToClipboard = (text: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log('Text wurde in die Zwischenablage kopiert');
                })
                .catch((err) => {
                    console.error('Fehler beim Kopieren des Textes:', err);
                });
        } else {
            fallbackCopyToClipboard(text);
        }
    };

    const fallbackCopyToClipboard = (text: string) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';  // Sicherstellen, dass es unsichtbar ist, aber auswählbar
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'Text wurde in die Zwischenablage kopiert' : 'Fehler beim Kopieren des Textes';
            //console.log(msg);
        } catch (err) {
            console.error('Fallback: Fehler beim Kopieren', err);
        }

        document.body.removeChild(textArea);
    };


    async function createAndDownloadDocument(templateFileName: string) {
        console.log("Into create and download Document with Template:", templateFileName)
        try {
            const formattedText = formatTextCopy(text)
            const text_copy = encodeURIComponent(formattedText ? formattedText : "Error in Text formatting")
            console.log("Text to write into template:", text_copy)
            const response = await fetch(
                apiURL + "/write_text_into_template?templateName=" + templateFileName + "&textToWrite=" + text_copy,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'edited_template.docx';  // Adjust the name as needed
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', width: "100%" }}>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />
            <div style={{ flex: "1" }}>
                {
                    source ?
                        <div style={{ margin: "8px", width: "30px", height: "30px" }}>
                            {model &&
                                <LLMIcon
                                    iconNumber={model.icon_number}
                                    maxHeight='25px'
                                    maxWidth='25px'
                                />
                            }
                        </div> :
                        <div style={{ width: "30px", height: "30px", margin: '8px' }}>
                            <FaUser style={{ fontSize: "larger", }} />
                        </div>

                }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: "20" }}>
                {/* Source Name and Action Buttons */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ fontWeight: 'bold', marginTop: '4px', display:"flex", flexDirection:"row" }}>
                        <div>
                            {source ? model ? model.api_name : "Model not available" : "You"}
                        </div>
                        {isInGeneration &&
                            <div style={{ marginLeft: "5px" }}>
                                <Circles
                                    height="20"
                                    width="20"
                                    color="var(--text-icons)"
                                    ariaLabel="circles-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        }
                    </div>
                    {/* Entry Action Buttons (COPY AUDIO DOWNLOADDOC) */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {userData.template_files && userData.template_files.length > 0 &&
                            <div>
                                {!isTemplateSelection ?
                                    <FaFileImport style={{ margin: "5px", cursor: "pointer" }}
                                        data-tooltip-id={"DownloadDocTooltip" + chatIndex}
                                        onClick={() => {
                                            if (userData.template_files!.length > 1) {
                                                setIsTemplateSelction(true);
                                            } else {
                                                createAndDownloadDocument(userData.template_files![0])
                                            }
                                        }} />
                                    :
                                    <div style={{ marginRight: "10px" }}>
                                        <Select
                                            styles={
                                                {
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    menu: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: "var(--background)"
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: "var(--background)"
                                                    }),
                                                    singleValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: "var(--background)"
                                                    }),
                                                    multiValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    multiValueLabel: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    container: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        width: "100%",
                                                        margin: "5px"
                                                    })
                                                }}
                                            options={userData.template_files.map(template_file => ({ label: template_file, value: template_file }))}
                                            getOptionLabel={(template_file) => template_file.label}
                                            getOptionValue={(template_file) => template_file.value}
                                            onChange={(selectedOptions) => {
                                                console.log("Selected option for Template:", selectedOptions)
                                                setIsTemplateSelction(false);
                                                createAndDownloadDocument(selectedOptions!.value)
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        <AudioController textToRead={formatTextCopy(text)!} ref={audioControllerRef} />
                        <FaRegCopy style={{ margin: '5px', cursor: 'pointer' }} onClick={() => {
                            copyToClipboard(formatTextCopy(text)!)
                        }}
                            data-tooltip-id={"CopyClipTooltip" + chatIndex} />
                    </div>
                </div>
                {/* Selections for Doc Chat */}
                {attachments && attachments.filter(att => att.attachmentType === 3 || att.attachmentType === 2).length > 0 &&
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", borderBottom: "1px solid var(--background-3)", paddingBottom: "5px" }}>
                        <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                            Prompt regarding selection
                        </div>
                        <div style={{ width: "90%" }}>
                            {
                                attachments.filter(att => att.attachmentType === 3 || att.attachmentType === 2)[0]
                                    .encodedData.split("Of following document")[0]
                                    .replace("The following content is a question regarding this Section:", "")
                                    .trim()
                                    .split("\n").map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))
                            }
                        </div>
                    </div>
                }
                {/* Attachements */}
                {attachments && attachments.filter(att => att.attachmentType !== 3 && att.attachmentType !== 2).length > 0 &&
                    <div className='chat-entry-attachments-container'>
                        <div style={{ width: "100%", textAlign: "center" }}>Message attachments:</div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            {attachments.filter(att => att.attachmentType !== 3).map((attachment, att_indx) =>
                                <div key={"attachment" + att_indx} className='entry-attachment'>
                                    {attachment.attachmentType === 0 ?
                                        <div style={{ position: "relative", width: "max-content" }}
                                            onClick={imageInputAllowed ? () => {
                                                onReuseAtachment(attachment)
                                            } : () => { }}
                                        >
                                            <img
                                                src={`data:image/png;base64,${attachment.encodedData}`}
                                                alt={`Uploaded preview`}
                                                className='entry-attachemnt-image'
                                            /> {imageInputAllowed &&
                                                <div className='entry-reuse-overlay'>
                                                    <FaRecycle />
                                                </div>}
                                        </div> :
                                        <FaFile />
                                    }
                                    <div style={{
                                        maxWidth: "200px",
                                        width: "inherit",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}> {attachment.attachmentFileName} </div>

                                </div>
                            )}
                        </div>
                    </div>
                }
                <div
                    ref={textContainerRef}
                    id={source! + chatIndex}
                    className="chat-text">


                    {/* Custom context menu */}
                    {selectedRange && replaceOptionOnSelect && (
                        <div
                            style={{
                                position: 'absolute',
                                top: contextMenuPositionRef.current.y,
                                left: contextMenuPositionRef.current.x,
                                zIndex: 2000,
                                display: "flex",
                                pointerEvents: "all"
                            }}
                        >
                            <div className='subcontroll-button write-to-doc'
                                onPointerDown={(event) => {
                                    event.stopPropagation();
                                    console.log("Butoon clicked")
                                    if (editorRef.current) {
                                        const selectedText = editorRef.current.getText(selectedRange)
                                        onWriteTextToEditor(selectedText)
                                        setSelectedRange(null);
                                    }
                                }}
                            >
                                Write to Cursor Position
                                <FaFileImport style={{ marginLeft: "5px" }} />
                            </div>
                        </div>
                    )}
                </div>

            </div>
            <Tooltip id={"DownloadDocTooltip" + chatIndex} className="custom-tooltip" delayShow={800}>
                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                    Here you can download this message into one of your prepared template documents.
                </div>
            </Tooltip>

            <Tooltip id={"CopyClipTooltip" + chatIndex} className="custom-tooltip" delayShow={800}>
                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                    Copy this message to your clipboard.
                </div>
            </Tooltip>
        </div>
    );
});

export default ChatEntry


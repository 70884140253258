import { useRef, useState, useEffect } from 'react';
import { FaAngleUp, FaAngleDown, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { BucketColumn } from "../SettingsTypedefs";
import Switch from 'react-switch';
import { Tooltip } from 'react-tooltip';




export default function BucketColumnTile({ bucketColumn, onBucketColumnTileChanged, ...props }:
    { bucketColumn: BucketColumn, onBucketColumnTileChanged: (updatedBucketColumn: BucketColumn) => void }) {
    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    return (
        <AnimateHeight
            {...props}
            height={height}
            contentRef={contentDiv}
            disableDisplayNone
            style={{ padding: "10px", width: "90%", border: "solid 1px var(--text-icons-1)", fontWeight: "normal", borderRadius: "10px", marginBottom: "10px", overflow: "hidden", backgroundColor: "var(--background-1)" }}>
            <div ref={contentDiv}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                >   <div style={{ width:"70%", maxWidth:"70%", textOverflow:"ellipsis", overflowX:"hidden" }}>
                        {bucketColumn.name}
                    </div>
                    <div style={{ width:"20%", textOverflow:"ellipsis", overflowX:"hidden"  }}>
                        {bucketColumn.phoneticCheck && bucketColumn.spellCheck ?
                            "Extra Content Check" :
                            bucketColumn.phoneticCheck ? "Phonetic Check" :
                                bucketColumn.spellCheck ? "Spell Check" :
                                    "No extra checks"
                        }
                    </div>
                    <div style={{ width:"10%", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                        {expanded ?
                            <FaAngleUp onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} /> :
                            <FaAngleDown />
                        }
                    </div>
                </div>
                <div style={expanded ? { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginTop: "10px", overflow:"hidden" } :
                    { display: "none" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ margin: "5px", fontWeight: 'normal' }}>Phonetic Check </div>
                            <div data-tooltip-id={"TooltipPhonetic"} >
                                <FaInfoCircle />
                                <Tooltip id={"TooltipPhonetic"} className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        {"Additional anonymization for words that are phoneticly equal (Words whose sound is equal)"}
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <Switch
                            checked={bucketColumn.phoneticCheck}
                            onChange={() => {
                                bucketColumn.phoneticCheck = !bucketColumn.phoneticCheck;
                                onBucketColumnTileChanged(bucketColumn);
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ margin: "5px", fontWeight: 'normal' }}>Spell Check </div>
                            <div data-tooltip-id={"TooltipSpell"} >
                                <FaInfoCircle />
                                <Tooltip id={"TooltipSpell"} className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        {"Additional anonymization for words that are not in database, but that migth be missspelled database entrys"}
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <Switch
                            checked={bucketColumn.spellCheck}
                            onChange={() => {
                                bucketColumn.spellCheck = !bucketColumn.spellCheck;
                                onBucketColumnTileChanged(bucketColumn);
                            }}
                        />
                    </div>
                </div>
            </div>
        </AnimateHeight>
    );
}

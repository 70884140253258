import { useEffect, useState } from "react";
import { FaPlusCircle, FaInfoCircle } from "react-icons/fa";
import SystemMessageTile from "./SystemMessageTile";
import { Tooltip } from 'react-tooltip';
import ModelModusTile from "./ModelModusTile";
export default function ChatConfigSettings({ userData, availableLLMs, apiURL, isMobile, onModelModiChanged }) {
    const [systemMessages, setSystemMessages] = useState(null);
    const [newSystemMessageHints, setNewSystemMessageHints] = useState(null);
    useEffect(() => {
        fetchSystemMessages();
    }, [])

    useEffect(() => {
        if (availableLLMs) {
            setNewSystemMessageHints(availableLLMs.map((llm) => {
                return {
                    "llmID": llm.api_id,
                    "hint": null
                }
            }))
            console.log("NewSystemMessagehints:", availableLLMs.map((llm) => {
                return {
                    "llmID": llm.api_id,
                    "hint": null
                }
            }))
        }
    }, [availableLLMs])


    async function postNewSystemMessage(inputID, apiID) {
        const newSystemMessageInput = document.getElementById(inputID)
        const newMessage = newSystemMessageInput.value;
        try {
            const encodedMessage = encodeURIComponent(newMessage);
            const response = await fetch(
                apiURL + "/add_new_system_message?message=" + encodedMessage + "&apiID=" + apiID,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
                if (response.status === 409) {
                    const errorData = await response.json();
                    const updatedHints = newSystemMessageHints.map((entry) => {
                        return {
                            "llmID": entry.llmID,
                            "hint": entry.llmID === apiID ? errorData.detail : entry.hint
                        }
                    })
                    setNewSystemMessageHints(updatedHints);
                    console.log("Updating Hints with:", updatedHints)
                }
            } else {
                newSystemMessageInput.value = ""
                const updatedHints = newSystemMessageHints.map((entry) => {
                    return {
                        "llmID": entry.llmID,
                        "hint": entry.llmID === apiID ? null : entry.hint
                    }
                })
                setNewSystemMessageHints(updatedHints);
                setTimeout(() => {
                    fetchSystemMessages();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function postNewModelModus() {
        const newModusNameInput = document.getElementById("InputNewSystemModiName")
        const newModusPrePromptInput = document.getElementById("InputNewSystemModiPrePrompt")
        try {
            const encodedName = encodeURIComponent(newModusNameInput.value);
            const encodedPrePrompt = encodeURIComponent(newModusPrePromptInput.value);
            const response = await fetch(
                apiURL + "/add_new_model_modus?modusName=" + encodedName + "&modusPrePrompt=" + encodedPrePrompt,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            newModusNameInput.value = "";
            newModusPrePromptInput.value = "";
            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                onModelModiChanged();
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function fetchSystemMessages() {
        var apiLink = apiURL + "/get_system_messages";
        try {
            const response = await fetch(apiLink, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                alert("Error: " + response.status);
            } else {
                const successData = await response.json();

                const fetchedMessages = successData.system_messages.map(entry => {
                    return {
                        "ID": entry[0],
                        "apiID": entry[1],
                        "userID": entry[2],
                        "system_message": entry[3]
                    }
                })
                setSystemMessages(fetchedMessages);
            }
        } catch (error) {
            console.log("Error occurred while fetching system Messages:", error);
        }

    }

    return (
        <div className="subsetting-content">
            <center><h4>Chat Settings</h4></center>
            <center><div className={`settings-subheader ${isMobile ? "mobile" : ""}`}> Model Modi </div></center>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={isMobile ? { width: "90%" } : { width: "80%" }}>
                    Model modi can be activated an deactivated during the Chat. With them you can define texts, that are prepended to your prompt, for example you can define a pre prompt to correct letters.
                </div>
                <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: "10px" } :
                    { display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: "20px" }}>
                    <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '90%', alignItems: 'center', justifyContent: 'center' } :
                        { display: 'flex', flexDirection: 'column', width: '70%', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={isMobile ? { display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", marginTop: "10px" } :
                            { display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: "90%", marginTop: "20px", fontWeight: "bold" }}>
                            <div>
                                Modus Name
                            </div>
                            <FaInfoCircle data-tooltip-id="ChatConfigModusNameTooltip" style={{ marginLeft: "5px", alignSelf: "center" }} />
                            <Tooltip id="ChatConfigModusNameTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The name under which the Modus will be displayed in the modi selection besides the model selection.
                                </div>
                            </Tooltip>
                        </div>
                        <input
                            id={"InputNewSystemModiName"}
                            placeholder="New System Modus Name"
                            className="settings-input"
                        />
                        <div style={isMobile ? { display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", marginTop: "10px" } :
                            { display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: "90%", marginTop: "20px", fontWeight: "bold" }}>
                            <div>
                                Modus Pre Prompt
                            </div>
                            <FaInfoCircle data-tooltip-id="ChatConfigModusPrePromptTooltip" style={{ marginLeft: "5px", alignSelf: "center" }} />
                            <Tooltip id="ChatConfigModusPrePromptTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The text that will be prepended to your prompts when this modus is activated
                                </div>
                            </Tooltip>
                        </div>
                        <input
                            id={"InputNewSystemModiPrePrompt"}
                            placeholder="New System Modus Pre Prompt"
                            className="settings-input"
                        />
                    </div>
                    <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                        onClick={() => {
                            postNewModelModus();
                        }}
                    >
                        Add Modus
                        <FaPlusCircle className="confirm-icon" />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "90%" }}>
                    {userData.modelModi && userData.modelModi.map((modus) =>
                        <ModelModusTile
                            key={modus.modusID}
                            apiURL={apiURL}
                            modelModusData={modus}
                            isMobile={isMobile}
                            onModusChanged={onModelModiChanged}
                        />
                    )}
                </div>

            </div>
            <center><div className={`settings-subheader ${isMobile ? "mobile" : ""}`}> System Messages</div></center>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={isMobile ? { width: "90%" } : { width: "80%" }}>
                    Configure System Messages for your Large Language Models. These Messages are send with each Prompt and can be used to specify the wanted output.
                </div>
                {
                    availableLLMs.map((llm_data, index) => (
                        <div key={"LLM" + index}
                            style={{ width: '90%' }}
                        >
                            <h5>{llm_data.api_name}</h5>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '90%', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: "90%", marginRight: "10px" }}>
                                    <input
                                        id={"InputNewSystemMessage" + llm_data.api_name}
                                        placeholder="New System Message"
                                        className="settings-input"
                                        style={{ width: "100%" }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                postNewSystemMessage("InputNewSystemMessage" + llm_data.api_name, llm_data.api_id)
                                            }
                                        }}
                                    />
                                    {newSystemMessageHints && newSystemMessageHints.filter((hint) => hint.llmID === llm_data.api_id)[0] &&
                                        <div style={{color:"yellow"}}>
                                            {newSystemMessageHints.filter((hint) => hint.llmID === llm_data.api_id)[0].hint}
                                        </div>
                                    }
                                </div>
                                <FaPlusCircle className="confirm-icon"
                                    onClick={() => {
                                        postNewSystemMessage("InputNewSystemMessage" + llm_data.api_name, llm_data.api_id)
                                    }} />
                            </div>
                            {
                                systemMessages ? systemMessages.filter((message) => { return message.apiID === llm_data.api_id }).map((message) => (
                                    <SystemMessageTile
                                        key={"SysMessTile" + message.ID}
                                        apiURL={apiURL}
                                        message={message}
                                        onSystemMessagesChanged={() => {
                                            fetchSystemMessages();
                                        }}
                                    />
                                )) : <></>
                            }

                        </div>
                    ))
                }
            </div>
        </div>
    );
}




import { useState } from 'react'
import PrivcyPromptLogo from './../../assets/privacyprompt.svg'

import './Login.css'

function RegisterSite({ onLoginButtonPressed, onRegisterSucceeded, apiURL }) {
    const [errorText, setErrorText] = useState(""); // Add state for error text
    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");



    function checkPwdSave(pwd, pwdRepeat) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
        const passwordValid = regex.test(pwd);
        if (!passwordValid) {
            setErrorText("Passwords needs to be at least 8 characters long, contain at least one upper and lowercaase letter and one special character");
            return false
        } else {
            if (pwd !== pwdRepeat) {
                setErrorText("Passwords are not equal");
                return false
            }
            else {
                setErrorText("");
                return passwordValid
            }
        }
    }

    async function register() {
        if (checkPwdSave(password, passwordRepeat)) {
            const userData = {
                'userID': 0,
                'userRole': 2,
                'email': userEmail,
                'name': userName,
                'password': password,
                'otp_activated': true,
                'otp_veryfied': false,
                'otp_token': "",
                'auto_read_aloud_activated':false
            };
            try {
                const response = await fetch(apiURL + '/register', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(userData)
                });
                const data = await response.json();
                if (data.status === "success") {
                    // API call succeeded (status code 200)
                    console.log(JSON.stringify(data));
                    console.log("Succes Respone from Login API");
                    onRegisterSucceeded(); // Call the success callback
                } else {
                    // API call failed
                    const detail = data.detail;
                    console.log("No Succes from API Response");
                    if (Array.isArray(detail)) {
                        const errorMessages = detail.map((info) => info.msg);
                        setErrorText(errorMessages.join(" "));
                    } else {
                        setErrorText(data.detail);
                    } // Display the error message from the server
                }
            } catch (error) {
                // Handle any unexpected errors (e.g., network error)
                setErrorText("An error occurred while processing your request. Please try again later.");
            }
        }
    }



    return (
        <div className='page-container'>
            <div className='login-content'>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20vw', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={PrivcyPromptLogo} alt="PrivacyPrompt Logo" style={{ maxHeight: '20vh', maxWidth: '20vw' }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h3>Email</h3>
                        <input
                            type='text'
                            className='login-input'
                            placeholder='email'
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}>
                        </input>
                        <h3>Name</h3>
                        <input
                            type='text'
                            className='login-input'
                            placeholder='name'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}>
                        </input>
                        <h3>Password</h3>
                        <input
                            type='password'
                            className='login-input'
                            placeholder='password'
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                if (errorText !== "") {
                                    checkPwdSave(e.target.value, passwordRepeat);
                                }
                            }}>
                        </input>
                        <h3>Repeat password</h3>
                        <input
                            type='password'
                            className='login-input'
                            placeholder='repeat password'
                            value={passwordRepeat}
                            onChange={(e) => {
                                setPasswordRepeat(e.target.value);
                                if (errorText !== "") {
                                    checkPwdSave(password, e.target.value);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    register();
                                }
                            }}
                            >
                        </input>
                        {errorText && <p className="error-text" style={{ maxWidth: "30vw", width: "inherit" }}>{errorText}</p>} {/* Display error text if available */}
                        <button className="login-button" onClick={register}>
                            Register
                        </button>
                        <button className="login-button" onClick={onLoginButtonPressed}>
                            Login with exisiting account
                        </button>
                    </div>
                </div>

            </div>

        </div>

    );

}

export default RegisterSite;
import { FaPlusCircle } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import APITile from "./APITile";
import { Tooltip } from 'react-tooltip';
import AddAPI from "./AddAPI";

export default function APISettings({ userData, companyLLMs, personalLLMs, onAvailableAPIsChanged, apiURL, securityLevels, isMobile }) {
    const [newAPIMode, setNewAPIMode] = useState(null);
    const [securityLevelOptions, setSecurityLevelOptions] = useState([]);
    const [isChangeIconMode, setIsChangeIconMode] = useState(false);
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsChangeIconMode(false);
            }
        };

        if (isChangeIconMode) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isChangeIconMode]);

    useEffect(() => {
        console.log("New sec levels")
        if (securityLevels) {
            const options = securityLevels.map(sec_level => {
                return {
                    label: sec_level.security_level_name,
                    value: sec_level.security_level
                }
            });
            console.log("Setting sec level for new api")
            setSecurityLevelOptions(options)
        }
    }, [securityLevels])

    return (
        <div className="subsetting-content">
            <center><h4>API Access</h4></center>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                {/* COMPANY  */}
                <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>Company API Access</div>

                {newAPIMode === "company" && userData.userRole <= 1 ?
                        <AddAPI
                            apiURL={apiURL}
                            securityLevelOptions={securityLevelOptions}
                            isAdminView={true}
                            onAPIAccessChanged={()=> {
                                onAvailableAPIsChanged();
                            }}
                            onAddAPIClosed={()=> {
                                setNewAPIMode(null)
                            }}
                            isMobile={isMobile}
                        />
                        : userData.userRole <= 1 ?
                        <div>
                            <div
                                className={`settings-button ${isMobile? "mobile" : ""}`}
                                onClick={() => {
                                    setNewAPIMode("company")
                                }}
                                data-tooltip-id="AddCompanyAccessHint"
                            >
                                Add new Company LLM API Access
                                <FaPlusCircle className="add-element-icon" />
                            </div>
                            <Tooltip id="AddCompanyAccessHint" delayShow={800} className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", zIndex: "101" }}>
                                    Here you can define the URL the API key and other Parameters for a large language model. To use this API a user has to be authorized personally or hast to be in a group that has been authorized.
                                </div>
                            </Tooltip>
                        </div> : <></>}
                {companyLLMs &&
                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                        {/* ADD MAPPING OF APIS HERE */}
                        {companyLLMs.map((llm, index) => (
                            <APITile
                                key={"companyLLM" + index}
                                apiURL={apiURL}
                                onAPIAccessChanged={() => {
                                    onAvailableAPIsChanged();
                                }}
                                apiAccessData={llm}
                                isAdminView={userData.userRole <= 1}
                                securityLevelOptions={securityLevelOptions}
                                isMobile={isMobile}
                            />
                        ))}
                    </div>
                }
                {/* PERSONAL */}
                <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>Personal API Access</div>
                {newAPIMode === "personal" ?
                        <AddAPI
                        apiURL={apiURL}
                        securityLevelOptions={securityLevelOptions.filter(option => option.value === 1)}
                        isAdminView={false}
                        onAPIAccessChanged={()=> {
                            onAvailableAPIsChanged();
                        }}
                        onAddAPIClosed={()=> {
                            setNewAPIMode(null)
                        }}
                        isMobile={isMobile}
                    />
                    :
                    <div
                    className={`settings-button ${isMobile? "mobile" : ""}`}
                        onClick={() => {
                            setNewAPIMode("personal")
                        }}
                        data-tooltip-id="AddPersonalAccessHint"
                    >
                        Add new Personal LLM API Acces
                        <FaPlusCircle className="add-element-icon" />
                        <Tooltip id="AddPersonalAccessHint" delayShow={800} className="custom-tooltip">
                            <div style={{ maxWidth: "20vw" }}>
                                A personal API access is only visible and editable for the User that created it. Personal API accesses always use the default security level and have no restraints regarding the amount of prompts.
                            </div>
                        </Tooltip>
                    </div>}
                {personalLLMs &&
                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                        {/* ADD MAPPING OF APIS HERE */}
                        {personalLLMs.map((llm, index) => (
                            <APITile
                                key={"privateLLM" + index}
                                apiURL={apiURL}
                                onAPIAccessChanged={() => {
                                    onAvailableAPIsChanged();
                                }}
                                apiAccessData={llm}
                                isAdminView={true}
                                securityLevelOptions={securityLevelOptions}
                                isMobile={isMobile}
                            />
                        ))}
                    </div>
                }
            </div>
        </div>
    );
}

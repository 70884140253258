import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate } from 'react-router-dom';
import { UserData, LLMAPIData, ModelModus, ChatDocument, UserInfoModel, ConnectedClient, UserGroupData, Conversation, Attachment } from '../Settings/SettingsTypedefs';
import Navbar from "./../../Components/Navbar.js"
import './DocumentChat.css'
import Resizer from './Resizer';
import DocumentsControlPanel from './DocumentControlPanel';
import DocumentEditor, { DocEditorRef } from './DocumentEditor';
import { Delta, Range } from 'quill/core';
import DocChatInput, { DocChatInputRef } from './DocChatInput';
import { pdfExporter } from 'quill-to-pdf';
import * as quillToWord from 'quill-to-word';
import ChatEntry from '../Home/ChatEntry';
import { Circles } from 'react-loader-spinner';
import EditableQuestionField from '../Home/EditableQuestionField';


interface QuillToWordConfig {
    exportAs: "blob" | "doc" | "buffer" | "base64";
}

function DocumentsChat({ onLogout,
    user,
    apiURL,
    availableLLMs,
    isMobile,
    showAnonymazationHints,
    companyUsers,
    userGroups }
    : {
        onLogout: Function,
        user: UserData | null,
        apiURL: string,
        availableLLMs: LLMAPIData[],
        isMobile: boolean,
        showAnonymazationHints: boolean,
        companyUsers: UserInfoModel[],
        userGroups: UserGroupData[]
    }
) {
    const [queryText, setQueryText] = useState("");

    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [visibleCount, setVisibleCount] = useState<number>(10);
    const [selectedModel, setSelectedModel] = useState<LLMAPIData | null>(null);
    const [selesctedModelModiPrompts, setSelectedModelModiPrompts] = useState<string[]>([]);
    const [documentTextSelection, setDocumentTextSelection] = useState<string | null>(null);
    const [readAloudIndex, setReadAloudIndex] = useState<number | null>(null)
    const [imageInputAllowed, setImageInputAllowed] = useState<Boolean>(false);
    const [contingentReached, setContingentReached] = useState(false);
    const [availableDocuments, setAvailableDocuments] = useState<ChatDocument[] | null>(null);
    const [selectedDocument, setSelectedDocument] = useState<ChatDocument | null>(null)
    const [connectedClients, setConnectedClients] = useState<ConnectedClient[]>([]);
    const [isConnected, setIsConnected] = useState<Boolean>(false);
    const [editAllowed, setEditAllowed] = useState(false);
    const [isInReplacementRequest, setIsInReplacementRequest] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false)


    const scrollDummyRef = useRef<HTMLDivElement | null>(null);
    const readAloudAnswerRef = useRef(null);
    const chatInputRef = useRef<DocChatInputRef | null>(null);
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const docContainerRef = useRef<HTMLDivElement | null>(null);
    const editorRef = useRef<DocEditorRef | null>(null);
    const socketRef = useRef<WebSocket | null>(null);

    {/* Websocket Cleanup */}
    useEffect(() => {
        return () => {
            console.log("UnMounting DocChat Closing websocket")
            socketRef.current?.close();
            setIsConnected(false);
        };
    }, []);

    {/* Initial fetching of docs once neccessary props are initialized */ }
    useEffect(() => {
        if (user && apiURL) {
            fetchDocuments()
        }
    }, [user, apiURL])

    {/* Websocket / Edit Rigths / Conversation fetching */ }
    useEffect(() => {
        console.log("selected doc changed", selectedDocument)
        if (selectedDocument) {
            {/* Web Socket definition all WebSocket Actions will be defined here */ }
            const token = localStorage.getItem("token");
            const wsProtocol = apiURL.startsWith('https') ? 'wss' : 'ws';
            const wsUrl = `${wsProtocol}://${new URL(apiURL).host}/ws/${selectedDocument.documentID}?token=${token}`;
            if (selectedDocument.documentID && socketRef.current?.url !== wsUrl) {
                console.log("Current Websocket URL:", socketRef.current?.url)
                console.log("Creating web socket with URL:", wsUrl)

                // Create WebSocket connection
                socketRef.current?.close();
                socketRef.current = new WebSocket(wsUrl);
                socketRef.current.onopen = () => {
                    console.log("OnOpenWebsocket Callback")
                    setIsConnected(true);
                }
                socketRef.current.onclose = () => {
                    console.log("OnCloseWebsocket Callback")
                    setIsConnected(false);
                }
                socketRef.current.onerror = (error) => {
                    console.error("WebSocket encountered an error:", error);
                    setIsConnected(false);
                };
                socketRef.current.onmessage = (event: MessageEvent) => {
                    const data = JSON.parse(event.data);
                    console.log("Received Socket data: ", data);
                    if (data.action === null || data.action === undefined) {
                        console.log("Recived Socket data has missing action")
                    }
                    if (data.action === "text_update") {
                        console.log("Updating DocEditor from recived Socket update action")
                        if (editorRef.current) {
                            const new_delta = new Delta(data.delta.ops)
                            const old_delta = new Delta(data.old_delta.ops)
                            editorRef.current.updateEditorText(new_delta, old_delta);
                        }
                    }
                    if (data.action === "client_update") {
                        console.log("Updating Active clients from recived Socket update Clients:", data.clients)
                        setConnectedClients(data.clients)
                    }
                    if (data.action === "selection_change") {
                        console.log("Updating client Selection from Socket data", data.index, data.length)
                        if (editorRef.current) {
                            editorRef.current.updateEditorSelection(data.index, data.length, data.client)
                        }
                    }
                    if (data.action === "invalid_state") {
                        console.log("INVALID STATE DETECTED updating with new state", data.current_state)
                        if (editorRef.current) {
                            const currentState = new Delta(data.current_state.ops)
                            editorRef.current.setEditorText(currentState)
                        }
                    }
                    if (data.action === "metaDataUpdate") {
                        console.log("Socket inidcates Metadata update refetching documents")
                        if (availableDocuments) {
                            const documentID = data.documentID;
                            const documentIndex = availableDocuments.findIndex(doc => doc.documentID == documentID);

                            if (documentIndex !== -1) {
                                const updatedDocuments = [...availableDocuments];
                                updatedDocuments[documentIndex] = {
                                    ...updatedDocuments[documentIndex],
                                    documentName: data.documentName,
                                    ownerID: data.ownerID,
                                    usersWithAccess: data.usersWithAccess,
                                    groupsWithAccess: data.groupsWithAccess
                                };
                                setAvailableDocuments(updatedDocuments);
                            }
                        }
                    }
                };
            }
            // Checking Edit Rigths on selected Doc Change
            if (selectedDocument.usersWithAccess && user) {
                if (selectedDocument.ownerID === user.userID) {
                    setEditAllowed(true);
                } else {
                    const userWithAccessInstance = selectedDocument.usersWithAccess.filter(uwa => uwa.userID === user.userID)
                    if (userWithAccessInstance.length > 0) {
                        setEditAllowed(userWithAccessInstance[0].allowEdit!)
                    } else {
                        // Should not reach into this Block
                        console.log("ERROR current user not found in users with access")
                        setEditAllowed(false)
                    }
                }
            }

            if (selectedDocument.documentID) {
                fetchConversationForDocument();
            }
        }
    }, [selectedDocument]);

    {/* ModelSelection Effects */ }
    useEffect(() => {
        console.log("Checking for image input with availablellms: ", availableLLMs, "Selected:", selectedModel)
        if (selectedModel && availableLLMs) {
            const currentllm = availableLLMs.filter(llm => llm.api_id === selectedModel.api_id)[0]
            console.log("Setting images allowed to:", currentllm.images)
            setImageInputAllowed(currentllm.images)
        }
    }, [selectedModel])

    {/* Websocket pupblish functions */ }
    const publishTextChangeToWebSocket = async  (delta: Delta, old_delta: Delta) => {
        //console.log("In puplish Text")
        if (socketRef.current?.readyState === WebSocket.OPEN) {
            setIsConnected(true);
            //console.log("Sending delta to websocket: ", delta, "  oldDelta:", old_delta);
            socketRef.current.send(JSON.stringify({ "action": "text_update", "delta": delta, "old_delta": old_delta }));
        } else {
            console.error("WebSocket is not open.");
            setIsConnected(false);
        }
    };

    const publishSelectionChangeToWebSocket =  async (range: Range) => {
        console.log("In puplish Selection")
        if (socketRef.current?.readyState === WebSocket.OPEN) {
            setIsConnected(true);
            console.log("Sending Selection to websocket: index=", range.index, " length=", range.length);
            socketRef.current.send(JSON.stringify({ "action": "selection_change", "index": range.index, "length": range.length, "client": user?.userID }));
        } else {
            console.error("WebSocket is not open.");
            setIsConnected(false);
        }
    };

    const publishMetaDataUpdateToWebSocket =  async  (updatedDocument: ChatDocument) => {
        console.log("In publish MetadataChange")
        if (socketRef.current?.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify({
                "action": "metaDataUpdate",
                "documentID": updatedDocument.documentID,
                "documentName": updatedDocument.documentName,
                "ownerID": updatedDocument.ownerID,
                "usersWithAccess": updatedDocument.usersWithAccess,
                "groupsWithAccess": updatedDocument.groupsWithAccess
            }))
            {/* Update Local State */ }
            if (availableDocuments) {
                const documentID = updatedDocument.documentID;
                const documentIndex = availableDocuments.findIndex(doc => doc.documentID == documentID);

                if (documentIndex !== -1) {
                    const updatedDocuments = [...availableDocuments];
                    updatedDocuments[documentIndex] = {
                        ...updatedDocuments[documentIndex],
                        documentName: updatedDocument.documentName,
                        ownerID: updatedDocument.ownerID,
                        usersWithAccess: updatedDocument.usersWithAccess,
                        groupsWithAccess: updatedDocument.groupsWithAccess
                    };
                    setAvailableDocuments(updatedDocuments);
                }
            }
        } else {
            console.error("WebSocket is not open.");
            setIsConnected(false);
        }
    }



    const handleDocumentDownload = async (fileType: string, templateName: string | null) => {
        console.log("HandleDocDownload called Filetype: ", fileType, " templateName:", templateName)
        const downloadFile = (blob: Blob, fileName: string) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        };
        if (editorRef.current) {
            const currentFileContents = editorRef.current.getEditorContents()
            console.log("Current File Contents:", currentFileContents)
            const deltaOps = currentFileContents.ops || [];
            const plainText = deltaOps.map((op: any) => op.insert).join('');
            if (fileType !== "template") {
                if (fileType === ".pdf") {
                    const pdfBlob = await pdfExporter.generatePdf(currentFileContents);
                    downloadFile(pdfBlob, 'document.pdf');
                }
                if (fileType === ".docx") {
                    const quillToWordConfig: QuillToWordConfig = {
                        exportAs: 'blob'
                    };
                    const docAsBlob = await quillToWord.generateWord(currentFileContents, quillToWordConfig);
                    downloadFile(docAsBlob as Blob, 'document.docx');
                }
                if (fileType === ".txt") {
                    const txtBlob = new Blob([plainText], { type: 'text/plain' });
                    downloadFile(txtBlob, 'document.txt');
                }
            } else {
                console.log("Writing into template: ", templateName)
                try {
                    const text_copy = encodeURIComponent(plainText)
                    console.log("Text to write into template:", text_copy)
                    const response = await fetch(
                        apiURL + "/write_text_into_template?templateName=" + templateName + "&textToWrite=" + text_copy,
                        {
                            method: "POST",
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    );
                    if (response.status !== 200) {
                        const errorData = await response.json();
                        console.log(errorData);
                        if (response.status === 401 && errorData.detail == "not logged in") {
                            console.log("Catched expired Session");
                            window.location.href = "/session_expired";
                        } else {
                            alert("Error: " + response.status);
                        }
                    } else {
                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = 'edited_template.docx';  // Adjust the name as needed
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                } catch (error) {
                    console.log(error);
                    //alert("Error: " + error);
                }
            }
        }
    }

    async function setHiddenAutoAnonymizeHint(hints: any) {
        console.log("TODO Implementation")
    }


    async function fetchDocuments() {
        const apiLink = apiURL + '/get_chat_documents';
        try {
            const response = await fetch(apiLink, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                setAvailableDocuments(data.chat_documents);
                if (data.chat_documents.length > 0) {
                    setSelectedDocument(data.chat_documents[0]);
                }
            } else {
                const errorData = await response.json();
                console.error(errorData);
            }
        } catch (error) {
            console.error('Error occurred while fetching documents:', error);
        }
    }

    async function fetchConversationForDocument() {
        if (selectedDocument && selectedDocument.documentID) {
            var apiLink = apiURL + "/get_conversations_for_document?docID=" + selectedDocument.documentID;
            //console.log("Fetching Conversation Data");
            try {
                const response = await fetch(apiLink, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if (response.status !== 200) {
                    const errorData = await response.json();
                    console.log(errorData);
                    if (response.status === 401 && errorData.detail == "not logged in") {
                        console.log("Catched expired Session");
                        window.location.href = "/session_expired";
                    } else {
                        alert("Error: " + response.status);
                    }
                } else {
                    const successData = await response.json();
                    //console.log("Success fetching conversation for Topic data:");
                    //console.log(successData);

                    const conversations = successData.conversations;
                    const sorted_messages = [...conversations].sort((message_a, message_b) => message_a.chatIndex - message_b.chatIndex);
                    //console.log("Setting conversation with:", sorted_messages);
                    setConversations(sorted_messages);
                    //console.log("deciding if scroll is neccessary messages length:", messages.length)
                    setTimeout(() => {
                        document.getElementById("scrollDummy")?.scrollIntoView({ behavior: 'smooth' });
                    }, 100)

                }
            } catch (error) {
                console.log("Error occurred while fetching conversation data:", error);
            }
        } else {
            setConversations([]);
        }
    }

    async function send_corrected_question(corrected_question: string, chatIndex: number, attachments: Attachment[]) {
        setIsGenerating(true);
        console.log("Recived attachments in send intial question: ", attachments)
        console.log("Selected doc in sendInitialQuestion:", selectedDocument?.documentID)
        const queryWithPrePrompts = selesctedModelModiPrompts.length > 0 ? selesctedModelModiPrompts.join("\n") + "\n" + queryText : queryText
        console.log("Query with pre prompts:", queryWithPrePrompts)
        const apiLink = `${apiURL}/post_updated_question`;
        if (!availableLLMs || availableLLMs.length === 0 || !user) {
            return
        }
        // Add selected Text plus Complete Doc as Contex for LLM
        var attachmentsWithDocContext = attachments;
        if (documentTextSelection && documentTextSelection !== "") {
            const currentEditorText = editorRef.current ? editorRef.current.getEditorText() : "EditorRefNullException"
            const docContextAsAttachment = {
                attachmentType: 3,
                encodedData: "The following content is a question regarding this Section:\n" + documentTextSelection + "\n\n Of following document" + currentEditorText,
                attachmentFileName: "",
                attachmentID: null,
                conversationID: null,
                file: null
            }
            attachmentsWithDocContext = [...attachmentsWithDocContext, docContextAsAttachment]
        }
        const newConversation = {
            conversationID: null,
            userID: user.userID,
            question: corrected_question,
            topicID: -1,
            questionSave: true,
            questionCriticalContent: [],
            questionHint: [],
            answer: "",
            chatIndex: chatIndex,
            answerSource: selectedModel!.api_id,
            attachments: attachmentsWithDocContext,
            anonymizedQuestion: null,
            anonymizedAnswer: null,
            textMarkingMatches: null,
            isInGeneration: true
        };
        setReadAloudIndex(conversations.length)  // Actually -1 but ok beacause state update not done yet

        console.log("Updating conversations with new:", newConversation)

        setConversations(prevConversations => {
            const updatedConversations = [...prevConversations];
            updatedConversations[chatIndex] = { ...newConversation };
            return updatedConversations;
        });

        try {
            const rData = {
                "updatedQuestion": corrected_question,
                "queryIndex": chatIndex,
                "modelID": selectedModel ? selectedModel.api_id : -1,
                "topicID": -1,
                "attachments": attachments,
                "documentID": selectedDocument?.documentID
            }
            setIsInReplacementRequest(true);
            const response = await fetch(apiLink, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify(rData),
            });
            setDocumentTextSelection(null);
            setQueryText("");
            if (response.status !== 200 && response.status !== 201 && response.status !== 451) {
                console.log("NO 200 Response Intial Question Status:", response.status);
                alert(`Error: ${response.status}`);
                setIsGenerating(false)
            } else if (response.status === 201) {
                console.log("Status indicates unsafe Question")
                const data = await response.json();
                //console.log("Unsafe Question answer:", data);
                newConversation.answer = data.conversation.answer
                newConversation.questionSave = data.conversation.questionSave
                newConversation.questionHint = data.conversation.questionHint
                newConversation.attachments = data.conversation.attachments
                newConversation.textMarkingMatches = data.conversation.textMarkingMatches
                setConversations(prevConversations => {
                    const updatedConversations = [...prevConversations];
                    updatedConversations[chatIndex] = { ...newConversation };
                    return updatedConversations;
                });
                setIsGenerating(false)
            } else if (response.status === 451) {
                console.log("Status indicates Contingent Reached")
                setContingentReached(true);
                setIsGenerating(false)
            } else {
                console.log("Start reading response")
                const reader = response.body!.getReader();
                const decoder = new TextDecoder();

                let done = false;
                let buffer = "";
                //console.log("Reader:", reader)
                while (!done) {
                    //console.log("Done Loop")
                    const { value, done: doneReading } = await reader.read();
                    //console.log("Reading outputs:", value, done )
                    done = doneReading;
                    buffer += decoder.decode(value, { stream: !doneReading });
                    let boundary = buffer.indexOf('\n');
                    //console.log("Boundary:", boundary)
                    while (boundary !== -1) {
                        const chunkValue = buffer.slice(0, boundary);
                        buffer = buffer.slice(boundary + 1);
                        try {

                            const parsedChunk = JSON.parse(chunkValue);
                            //console.log("Pased Chunk", parsedChunk)
                            if (newConversation.answer !== null) {
                                newConversation.answer += parsedChunk.content;
                            } else {
                                newConversation.answer = parsedChunk.content;
                            }

                            setConversations(prevConversations => {
                                const updatedConversations = [...prevConversations];
                                updatedConversations[chatIndex] = { ...newConversation, isInGeneration: !parsedChunk.done };
                                return updatedConversations;
                            });
                            if (parsedChunk.done) {
                                done = true;
                                if (editorRef.current && isInReplacementRequest) {
                                    editorRef.current.writeTextToLastCursorPosition("\n\n")
                                }
                                setIsInReplacementRequest(false);
                            }
                            if (parsedChunk.haaHints && showAnonymazationHints) {
                                console.log("Haa Hints detected");
                                setHiddenAutoAnonymizeHint(parsedChunk.haaHints);
                            }
                        } catch (e) {
                            console.log("JSON parse error:", e);
                        }
                        boundary = buffer.indexOf('\n');
                    }
                }
                setIsGenerating(false)
            }
        } catch (error) {
            console.log("Error occurred while sending initial question:", error);
        }
    }

    async function send_initial_question(attachments: Attachment[], isReplacementRequest: Boolean) {
        setIsGenerating(true);
        console.log("Recived attachments in send intial question: ", attachments)
        console.log("Selected doc in sendInitialQuestion:", selectedDocument?.documentID)
        const queryWithPrePrompts = selesctedModelModiPrompts.length > 0 ? selesctedModelModiPrompts.join("\n") + "\n" + queryText : queryText
        console.log("Query with pre prompts:", queryWithPrePrompts)
        const apiLink = `${apiURL}/filter_query_text`;
        if (!availableLLMs || availableLLMs.length === 0 || !user) {
            return
        }
        // Add selected Text plus Complete Doc as Contex for LLM
        var attachmentsWithDocContext = attachments;
        if (documentTextSelection && documentTextSelection !== "") {
            const currentEditorText = editorRef.current ? editorRef.current.getEditorText() : "EditorRefNullException"
            const docContextAsAttachment = {
                attachmentType: isReplacementRequest ? 2 : 3,
                encodedData: "The following content is a question regarding this Section:\n" + documentTextSelection + "\n\n Of following document" + currentEditorText,
                attachmentFileName: "",
                attachmentID: null,
                conversationID: null,
                file: null
            }
            attachmentsWithDocContext = [...attachmentsWithDocContext, docContextAsAttachment]
        }
        const newConversation = {
            conversationID: null,
            userID: user.userID,
            question: queryWithPrePrompts,
            topicID: -1,
            questionSave: true,
            questionCriticalContent: [],
            questionHint: [],
            answer: "",
            chatIndex: conversations.length,
            answerSource: selectedModel!.api_id,
            attachments: attachmentsWithDocContext,
            anonymizedQuestion: null,
            anonymizedAnswer: null,
            textMarkingMatches: null,
            isInGeneration: true
        };
        setReadAloudIndex(conversations.length)  // Actually -1 but ok beacause state update not done yet
        console.log("NewConversation at init:", newConversation)
        setConversations(prevConversations => [...prevConversations, newConversation]);

        try {
            const formData = new FormData();
            formData.append("queryText", queryWithPrePrompts ? queryWithPrePrompts : ".");
            formData.append("topicID", "-2");
            formData.append("chatIndex", conversations.length.toString());
            formData.append("selectedModelID", selectedModel!.api_id.toString());
            formData.append("attachments", JSON.stringify(attachmentsWithDocContext))
            formData.append("documentID", selectedDocument!.documentID.toString());
            // Attach each file to the FormData object
            attachments.forEach((attachment, index) => {
                if (attachment.file) {
                    formData.append(`files`, attachment.file);
                }
            });
            setIsInReplacementRequest(true);
            const response = await fetch(apiLink, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
            });

            setDocumentTextSelection(null);
            setQueryText("");
            if (response.status !== 200 && response.status !== 201 && response.status !== 451) {
                console.log("NO 200 Response Intial Question Status:", response.status);
                alert(`Error: ${response.status}`);
            } else if (response.status === 201) {
                console.log("Status indicates unsafe Question")
                const data = await response.json();
                console.log("Unsafe Question answer:", data);
                newConversation.answer = data.conversation.answer
                newConversation.questionSave = data.conversation.questionSave
                newConversation.questionHint = data.conversation.questionHint
                newConversation.attachments = data.conversation.attachments
                newConversation.textMarkingMatches = data.conversation.textMarkingMatches
                setConversations(prevConversations => {
                    const updatedConversations = [...prevConversations];
                    updatedConversations[updatedConversations.length - 1] = { ...newConversation };
                    return updatedConversations;
                });
                setIsGenerating(false);
            } else if (response.status === 451) {
                console.log("Status indicates Contingent Reached")
                setContingentReached(true);
                setIsGenerating(false);
            } else {
                console.log("Start reading response")
                console.log("newConv at start reading response:", newConversation)
                const reader = response.body!.getReader();
                const decoder = new TextDecoder();

                let done = false;
                let buffer = isReplacementRequest ? "\n\n" : "";
                //console.log("Reader:", reader)
                while (!done) {
                    //console.log("Done Loop")
                    const { value, done: doneReading } = await reader.read();
                    //console.log("Reading outputs:", value, done )
                    done = doneReading;
                    buffer += decoder.decode(value, { stream: !doneReading });
                    let boundary = buffer.indexOf('\n');
                    //console.log("Boundary:", boundary)
                    while (boundary !== -1) {
                        const chunkValue = buffer.slice(0, boundary);
                        buffer = buffer.slice(boundary + 1);
                        try {

                            const parsedChunk = JSON.parse(chunkValue);
                            //console.log("Pased Chunk", parsedChunk)
                            if (newConversation.answer !== null) {
                                newConversation.answer += parsedChunk.content;
                            } else {
                                newConversation.answer = parsedChunk.content;
                            }
                            console.log("Conversations before write chunk:" , conversations)
                            setConversations(prevConversations => {
                                const updatedConversations = [...prevConversations];
                                updatedConversations[updatedConversations.length - 1] = { ...newConversation, isInGeneration: !parsedChunk.done };
                                return updatedConversations;
                            });

                            if (isReplacementRequest) {
                                //console.log("Trying to pass Chunk to editor")
                                if (editorRef.current) {
                                    editorRef.current.streamToLastCursorPosition(newConversation.answer, true, parsedChunk.done);
                                }
                            }
                            if (parsedChunk.done) {
                                done = true;
                                if (editorRef.current && isReplacementRequest) {
                                    editorRef.current.writeTextToLastCursorPosition("\n\n")
                                }
                                setIsInReplacementRequest(false);
                            }
                            if (parsedChunk.haaHints && showAnonymazationHints) {
                                console.log("Haa Hints detected");
                                setHiddenAutoAnonymizeHint(parsedChunk.haaHints);
                            }
                        } catch (e) {
                            console.log("JSON parse error:", e);
                        }
                        boundary = buffer.indexOf('\n');
                    }
                }
                setIsGenerating(false)
            }
        } catch (error) {
            setIsGenerating(false)
            console.log("Error occurred while sending initial question:", error);
        }
    }

    async function handleSelectionPrompt(selectedRange: Range, selectedText: string, isReplacementCallBack: Boolean) {
        console.log("In handle SelectionPrompt with :", selectedRange, selectedText, isReplacementCallBack)
        var input_attachments: Attachment[] = [];
        if (chatInputRef.current) {
            input_attachments = chatInputRef.current.get_attachments();
            send_initial_question(input_attachments, isReplacementCallBack);
            chatInputRef.current.flush_attachments();
        }
    }

    const handleLoadOlder = () => {
        console.log("In scrollMessageLoader")
        console.log("Current visible countref current:  ", visibleCount, " conv ref current length:  ", conversations.length)
        const new_visible_number_of_messages = Math.min(visibleCount + 5, conversations.length)
        console.log("Increasing Messages rendered to:", new_visible_number_of_messages)
        setVisibleCount(new_visible_number_of_messages);
    }

    if (!user) {
        console.log("Redirectiong to login cause user is not set");
        return <Navigate to="/login" />
    }
    return (
        <div className="doc-chat-page-container">
            <Navbar
                isHome={false}
                isDocChat={true}
                availableLLMs={availableLLMs}
                onSelectedLLMChanged={(modelID: number) => {
                    const filterModels = availableLLMs.filter(fllm => fllm.api_id === modelID)
                    if (filterModels.length > 0) {
                        setSelectedModel(filterModels[0]);
                    }
                }}
                availableModi={user ? user.modelModi : []}
                onSelectedModiChanged={(updatedModelModiPrompts: ModelModus[]) => {
                    console.log("Selected Model Modi Changed to:", updatedModelModiPrompts)
                    setSelectedModelModiPrompts(updatedModelModiPrompts.map((modus) => modus.prePrompt))
                }} />
            <div className='document-chat-container'>
                {/* Chat  */}
                <div className='chat-container-flex' ref={chatContainerRef}>
                    <div className='chat-scroll-container'>
                        <div className='chat-messages-container' ref={chatContainerRef}>
                            {selectedDocument && conversations.length > 0 && visibleCount < conversations.length &&
                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <div className='load-older-button' onClick={handleLoadOlder}>
                                        <div>Load older messages</div>
                                        <FontAwesomeIcon icon={faRotateRight} />
                                    </div>
                                </div>
                            }
                            {selectedDocument && conversations.length > 0 && conversations.slice(-visibleCount).map((conversation, index) => (
                                <div className="chat-pair" key={'Pair- ' + index}>
                                    {conversation.questionSave ?
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <ChatEntry
                                                apiURL={apiURL}
                                                key={'question' + index}
                                                source={null}
                                                text={conversation.question}
                                                chatIndex={index}
                                                userData={user}
                                                attachments={conversation.attachments}
                                                onReuseAtachment={(attachment) => {
                                                    console.log("Recived Attachment to reuse in Home:", attachment)
                                                    console.log("Ref:", chatInputRef, " current: ", chatInputRef.current)
                                                    if (chatInputRef && chatInputRef.current) {
                                                        console.log("Calling imp handle")
                                                        chatInputRef.current.add_attachment(attachment);
                                                    }
                                                }}
                                                imageInputAllowed={imageInputAllowed}
                                                model={selectedModel!}
                                                replaceOptionOnSelect={false}
                                                onWriteTextToEditor={(text) => { }}
                                                isInGeneration={false}
                                            />
                                            {conversation.answer !== null || conversation.answer === "" ?
                                                <ChatEntry
                                                    apiURL={apiURL}
                                                    ref={index === readAloudIndex ? readAloudAnswerRef : null}
                                                    key={'answer' + index}
                                                    source={conversation.answerSource.toString()}
                                                    text={conversation.answer}
                                                    chatIndex={index}
                                                    userData={user}
                                                    model={availableLLMs.filter(llm => llm.api_id === conversation.answerSource)[0]}
                                                    imageInputAllowed={imageInputAllowed}
                                                    attachments={[]}
                                                    onReuseAtachment={() => { }}
                                                    replaceOptionOnSelect={true}
                                                    onWriteTextToEditor={(text) => {
                                                        console.log("WritetoDocRecived")
                                                        if (editorRef.current) {
                                                            editorRef.current.writeTextToLastCursorPosition(text);
                                                        }
                                                    }}
                                                    isInGeneration={conversation.isInGeneration ? true : false}
                                                />
                                                :
                                                <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                                    <Circles
                                                        height="20"
                                                        width="20"
                                                        color="var(--text-icons)"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />
                                                </div>
                                            }



                            
                                        </div>
                                        :
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <EditableQuestionField
                                                conversation={conversation}
                                                onSendCorrectedQuestion={
                                                    (correctedQuestion: string, attachments: Attachment[]) => {
                                                        if(!isGenerating) {
                                                            send_corrected_question(correctedQuestion, conversation.chatIndex, attachments);
                                                        }
                                                    }
                                                }
                                                onAttachmentDeleted={(updatedAttachments: Attachment[]) => {
                                                    console.log("Attchment delete button pressed")
                                                    const updatedConversations = [...conversations];
                                                    updatedConversations[index] = { ...updatedConversations[index], attachments: updatedAttachments};
                                                    setConversations(updatedConversations);
                                                }}
                                                api_url={apiURL}
                                                selectedModelID={selectedModel?.api_id}
                                                selectedTopicID={3}
                                                documentID={selectedDocument ? selectedDocument.documentID : -1} 
                                                isInGeneration={isGenerating}/>
                                        </div>
                                    }
                                </div>
                            ))}

                            <div
                                id='scrollDummy'
                                ref={scrollDummyRef}
                            ></div>
                            {(!availableLLMs || availableLLMs.length === 0) &&
                                <div style={{ alignItems: "center", justifyContent: "space-around", display: "flex", color: "orange" }}>
                                    <FaExclamationTriangle style={{ fontSize: "x-large", flex: "1" }} />
                                    <div style={{ textAlign: "center", flex: "3" }}> Warning no LLM access available please contact an administrator to provide access or register your own private LLM access in the settings</div>
                                </div>
                            }
                            {availableLLMs && selectedModel?.api_id && availableLLMs.filter(llm => llm.api_id === selectedModel.api_id).length > 0 &&
                                <div>
                                    {!availableLLMs.filter(llm => llm.api_id === selectedModel.api_id)[0].is_alive &&
                                        <div style={{ alignItems: "center", justifyContent: "space-around", display: "flex", color: "orange" }}>
                                            <FaExclamationTriangle style={{ fontSize: "x-large", flex: "1" }} />
                                            <div style={{ textAlign: "center", flex: "3" }}> No answer from selected model recived</div>
                                        </div>
                                    }
                                </div>
                            }
                            {contingentReached &&
                                <div style={{ alignItems: "center", justifyContent: "space-around", display: "flex", color: "orange" }}>
                                    <FaExclamationTriangle style={{ fontSize: "x-large", flex: "1" }} />
                                    <div style={{ textAlign: "center", flex: "3" }}> Chat contingent for Model is reached</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <DocChatInput
                            apiURL={apiURL}
                            ref={chatInputRef}
                            queryText={queryText}
                            onChatInputChanged={(text) => {
                                setQueryText(text);
                            }}
                            onChatInputSubmit={(attachments) => {
                                console.log("OnChatInputSubmit")
                                if (selectedDocument) {
                                    send_initial_question(attachments, false);
                                }
                                else {
                                    console.log("Failed to send intial question. No document selected")
                                }
                                setQueryText("");
                            }}
                            imagesAllowed={false}
                            selectedTextContent={documentTextSelection ? documentTextSelection : ""}
                            onSelectedTextContentReset={() => { setDocumentTextSelection(null) }}
                            isGenerating={isGenerating}
                        />
                    </div>

                </div>

                {/* Resizer */}
                <Resizer
                    leftContainerRef={chatContainerRef}
                    rigthContainerRef={docContainerRef}
                />

                {/* Document */}
                <div className='doc-container-flex' ref={docContainerRef}>
                    <div>
                        <DocumentsControlPanel
                            apiURL={apiURL}
                            companyUsers={companyUsers}
                            isMobile={isMobile}
                            user={user}
                            onSelectedDocumentChanged={(docID: number) => {
                                console.log("Selected Doc Changed From control Panel new ID:", docID)
                                const selectedDoc = availableDocuments?.filter(doc => doc.documentID == docID)
                                if (selectedDoc ){
                                    if(selectedDoc.length > 0) {
                                        setSelectedDocument(selectedDoc[0])
                                    }
                                }
                            }}
                            isConnected={isConnected}
                            connectedClients={connectedClients}
                            userGroups={userGroups}
                            onDownloadButtonClicked={handleDocumentDownload}
                            availableDocuments={availableDocuments}
                            onAvailableDocumentsChanged={() => {
                                fetchDocuments();
                            }}
                            onDocumentMetaDataChanged={publishMetaDataUpdateToWebSocket}
                        />
                    </div>


                    {/* Doc Editor */}
                    <div className='doc-editor-container'>
                        <DocumentEditor
                            ref={editorRef}
                            onTextChange={(delta, oldDelta) => {
                                //console.log("DocChat Recived Quill OnTextChange Callback Delta:", delta, "Olddelta:", oldDelta)
                                publishTextChangeToWebSocket(delta, oldDelta);
                            }}
                            onSelectionChange={(range, content) => {
                                //console.log("DocChat Recived Quill OnSelectionChange Callback Index:", range.index, "Length:", range.length, "Content:", content)
                                publishSelectionChangeToWebSocket(range);
                                //console.log("Pre imp set chatInputRef.current:", chatInputRef.current)
                                setDocumentTextSelection(content);
                            }}
                            onSelectionPrompt={handleSelectionPrompt}
                            connectedClients={connectedClients}
                            user={user}
                            editAllowed={editAllowed}
                            prompt={queryText}
                            onPromptChanged={(updatedPrompt: string) => {
                                setQueryText(updatedPrompt);
                            }}
                            selectedText={documentTextSelection}
                            isInReplacementRequest={isInReplacementRequest}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentsChat;
import { useRef, useState, useEffect } from 'react';
import { FaPlusCircle, FaAngleUp, FaAngleDown, FaTimes, FaInfoCircle, FaArrowRight, FaCheck, FaTrashAlt } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { PrivacyRule, Column, AmbigousWord } from '../SettingsTypedefs';
import { Tooltip } from 'react-tooltip';




export default function SecuredColumnTile({ apiURL, column, ambigousWords, securityRules, onColumnChanged, isMobile, ...props }:
    { apiURL: string, column: Column, ambigousWords: AmbigousWord[], securityRules: PrivacyRule[], onColumnChanged: Function, isMobile: boolean }) {
    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);
    const [editedWordsExpanded, setEditedWordsExpanded] = useState(false);
    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);


    async function postNewAlias(columnID: number, alias: string) {
        try {
            const response = await fetch(
                apiURL + "/add_column_alias",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "columnID": columnID,
                        "alias": alias
                    }),
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                onColumnChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function deleteAlias(aliasID: number) {
        try {
            const response = await fetch(
                apiURL + "/delete_column_alias?aliasID=" + aliasID,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json"
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                onColumnChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function postAddNewAmbigousWord(word: string) {
        const newAmbigousWord = {
            "columnID": column.id,
            "word": word,
            "allowed": true,
            "edited": true
        }
        try {
            const response = await fetch(
                apiURL + "/add_ambigous_word",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(newAmbigousWord)
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                onColumnChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function postDeleteAmbigousWord(wordID: number) {
        try {
            const response = await fetch(
                apiURL + "/delete_ambigous_word?wordID=" + wordID,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json"
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                onColumnChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function postUpdateAmbigousWord(word: AmbigousWord) {
        try {
            const response = await fetch(
                apiURL + "/update_ambigous_word",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(word)
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                onColumnChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }



    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", border: "1px solid var(--text-icons-4)", padding: "5px", borderRadius: "5px", marginBottom: "5px" }}>
            <div key={"Column" + column.id}
                style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}
            >
                <div style={{ flex: "1", lineBreak: "anywhere" }}>
                    {column.name}
                </div>
                <div style={{ flex: "2", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <input
                            id={"newAliasC" + column.id}
                            type='text'
                            placeholder='New alias'
                            className='settings-input'
                            style={{ width: "70%" }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    const inputElement = document.getElementById(`newAliasC${column.id}`) as HTMLInputElement;
                                    if (inputElement && inputElement.value && inputElement.value !== "") {
                                        postNewAlias(column.id, inputElement.value);
                                        inputElement.value = "";
                                    }
                                }
                            }}
                        />
                        <FaPlusCircle
                            className="add-element-icon"
                            data-tooltip-id={"NewAliasTooltip" + column.id}
                            onClick={() => {
                                const inputElement = document.getElementById(`newAliasC${column.id}`) as HTMLInputElement;
                                if (inputElement && inputElement.value && inputElement.value !== "") {
                                    postNewAlias(column.id, inputElement.value);
                                    inputElement.value = "";
                                }
                            }} />
                        <Tooltip id={"NewAliasTooltip" + column.id} className="custom-tooltip" delayShow={800}>
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                Here you can add a new alias for the Column that will be send to the Large Language Model API instead of the Table entries that were matched. Better aliases will lead to a better Context understanding of the Model.
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{ width: "80%" }}>
                        {column.aliases && column.aliases.map((alias) =>
                            <div key={alias.id}
                                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "5px", borderTop: "solid 1px var(--gray-500)" }}>
                                <div style={{ maxWidth: "70%" }}>
                                    {alias.alias}
                                </div>
                                <FaTimes className='cancel-delete-icon'
                                    onClick={() => {
                                        deleteAlias(alias.id!)
                                    }} />
                            </div>
                        )}
                    </div>
                </div>
                {!isMobile &&
                    <div style={{ display: "flex", flexDirection: "column", marginRight: "15px", flex: "1" }}>
                        {securityRules
                            .filter(rule =>
                                rule.buckets.some(bucket =>
                                    bucket.columns.some(Bcolumn => Bcolumn.columnID === column.id)
                                )
                            )
                            .map((rule) =>
                                <div key={rule.id}>
                                    {rule.name}
                                </div>
                            )}
                    </div>
                }
            </div>
            <div style={{ borderTop: "1px solid var(--text-icons-4)", marginTop: "5px", marginBottom: "5px" }}></div>
            <AnimateHeight
                height={height}
                contentClassName="auto-content"
                contentRef={contentDiv}
                disableDisplayNone>
                <div style={{ display: "flex", width: "100%", flexDirection: "column" }}
                    ref={contentDiv}
                >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", cursor: "pointer" }}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >

                        <div style={{ marginLeft: "5px" }}>
                            Ambigouity and exceptions
                        </div>
                        {!expanded &&
                            <div>
                                {ambigousWords.filter(aWord => !aWord.edited).length + " new Ambigous Words"}
                            </div>}
                        {!expanded &&
                            <div>
                                {ambigousWords.filter(aWord => aWord.edited && aWord.allowed).length + " Exceptions"}
                            </div>}
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            {expanded ?
                                <FaAngleUp onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} /> :
                                <FaAngleDown />
                            }
                        </div>
                    </div>
                </div>
                {expanded &&
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", flex: "1" }}>
                            <div style={{ width: "95%", marginLeft: "5px" }}>
                                {ambigousWords.filter(aWord => !aWord.edited).length + " new Ambigous Words detected"}
                            </div>
                            {ambigousWords.filter(aWord => !aWord.edited).map((word, idx) =>
                                <div key={"ambg" + word.id}
                                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: "90%", margin: "5px", border: "1px solid var(--text-icons-3)", borderRadius: "5px", padding: "5px" }}
                                >
                                    <div style={{ flex: "4" }}>
                                        {word.word}
                                    </div>
                                    <div
                                        data-tooltip-id={"Disallowambg" + idx}
                                        style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "12px", cursor: "pointer", minWidth: "40px" }}
                                        onClick={() => {
                                            const uWord = {
                                                "id": word.id,
                                                "columnID": word.columnID,
                                                "word": word.word,
                                                "allowed": false,
                                                "edited": true
                                            }
                                            postUpdateAmbigousWord(uWord)
                                        }}>
                                        <FaTimes />
                                        <FaTrashAlt />
                                    </div>
                                    <div
                                        data-tooltip-id={"Allowambg" + idx}
                                        style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "12px", cursor: "pointer", minWidth: "40px" }}
                                        onClick={() => {
                                            const uWord = {
                                                "id": word.id,
                                                "columnID": word.columnID,
                                                "word": word.word,
                                                "allowed": true,
                                                "edited": true
                                            }
                                            postUpdateAmbigousWord(uWord)
                                        }}>
                                        <FaCheck />
                                        <FaArrowRight />
                                    </div>
                                    <Tooltip id={"Disallowambg" + idx} className="custom-tooltip" delayShow={500}>
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            This will keep this word as critical content that wont be allowed in chat messages according to the specified rules.
                                        </div>
                                    </Tooltip>
                                    <Tooltip id={"Allowambg" + idx} className="custom-tooltip" delayShow={500}>
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            This will allow the usage of this word in chat messages.
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                            {ambigousWords.filter(aWord => aWord.edited && !aWord.allowed).length > 0 &&
                                <div style={{ marginTop: "10px", borderTop: "1px solid var(--text-icons-3", width: "100%", padding: "5px" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                                    onClick={()=> {
                                        setEditedWordsExpanded(!editedWordsExpanded);
                                    }}
                                    >
                                        <div>
                                            {"Already disallowed Words  " + ambigousWords.filter(aWord => aWord.edited && !aWord.allowed).length}
                                        </div>
                                        {editedWordsExpanded ?
                                            <FaAngleUp /> :
                                            <FaAngleDown />
                                        }
                                    </div>
                                    {editedWordsExpanded && ambigousWords.filter(aWord => aWord.edited && !aWord.allowed).map(
                                        (disAllowedWord, idx) => 
                                            <div key={"ambg" + disAllowedWord.id}
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: "90%", margin: "5px", border: "1px solid var(--text-icons-3)", borderRadius: "5px", padding: "5px" }}
                                    >
                                        <div style={{ flex: "4" }}>
                                            {disAllowedWord.word}
                                        </div>
                                        <div
                                            data-tooltip-id={"Allowambg" + idx}
                                            style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "12px", cursor: "pointer", minWidth: "40px" }}
                                            onClick={() => {
                                                const uWord = {
                                                    "id": disAllowedWord.id,
                                                    "columnID": disAllowedWord.columnID,
                                                    "word": disAllowedWord.word,
                                                    "allowed": true,
                                                    "edited": true
                                                }
                                                postUpdateAmbigousWord(uWord)
                                            }}>
                                            <FaCheck />
                                            <FaArrowRight />
                                        </div>
                                        <Tooltip id={"Disallowambg" + idx} className="custom-tooltip" delayShow={500}>
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                This will keep this word as critical content that wont be allowed in chat messages according to the specified rules.
                                            </div>
                                        </Tooltip>
                                        <Tooltip id={"Allowambg" + idx} className="custom-tooltip" delayShow={500}>
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                This will allow the usage of this word in chat messages.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    )}
                                </div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", flex: "1" }}>
                            <div style={{ width: "95%", marginLeft: "5px", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                {"Exceptions"}
                                <FaInfoCircle style={{ marginLeft: "5px" }} data-tooltip-id={"NewExceptionTooltip" + column.id} />
                            </div>
                            {/* Add Ambiugous Word */}
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <input
                                    id={"newException" + column.id}
                                    type='text'
                                    placeholder='New Exception'
                                    className='settings-input'
                                    style={{ width: "70%" }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            const inputElement = document.getElementById(`newException${column.id}`) as HTMLInputElement;
                                            if (inputElement && inputElement.value && inputElement.value !== "") {
                                                postAddNewAmbigousWord(inputElement.value);
                                                inputElement.value = "";
                                            }
                                        }
                                    }}
                                />
                                <FaPlusCircle
                                    className="add-element-icon"
                                    data-tooltip-id={"NewExceptionTooltip" + column.id}
                                    onClick={() => {
                                        const inputElement = document.getElementById(`newException${column.id}`) as HTMLInputElement;
                                        if (inputElement && inputElement.value && inputElement.value !== "") {
                                            postAddNewAmbigousWord(inputElement.value);
                                            inputElement.value = "";
                                        }
                                    }} />
                                <Tooltip id={"NewExceptionTooltip" + column.id} className="custom-tooltip" delayShow={800}>
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        Here you can add a Exception that wont be recognized as a word from this column.
                                    </div>
                                </Tooltip>
                            </div>
                            {/* List of Exceptions */}
                            {ambigousWords.filter(word => word.edited && word.allowed).map((word, idx) =>
                                <div key={"ambg" + word.id}
                                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: "90%", margin: "5px", border: "1px solid var(--text-icons-3)", borderRadius: "5px", padding: "5px" }}
                                >
                                    <div style={{ flex: "4" }}>
                                        {word.word}
                                    </div>
                                    <div
                                        data-tooltip-id={"Disallowambg" + idx}
                                        style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "12px", cursor: "pointer", minWidth: "40px" }}
                                        onClick={() => {
                                            postDeleteAmbigousWord(word.id);
                                        }}>
                                        <FaTimes />
                                        <FaTrashAlt />
                                    </div>
                                    <Tooltip id={"Disallowambg" + idx} className="custom-tooltip" delayShow={500}>
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            This will delete the given exception from this column.
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </AnimateHeight>
        </div>

    );
}

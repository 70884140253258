import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/free-regular-svg-icons";
import PrivcyPromptLogo from './../../assets/privacyprompt.svg'
import { FaCheck, FaExclamationTriangle, FaSpellCheck, FaFile, FaTimes } from 'react-icons/fa';
import { MagnifyingGlass } from 'react-loader-spinner';
import Quill from "quill";
import "quill/dist/quill.bubble.css";

export default function EditableQuestionField({ conversation, onSendCorrectedQuestion, selectedModelID, api_url, selectedTopicID, documentID, onAttachmentDeleted, isInGeneration }) {
  const [editedQuestion, setEditedQuestion] = useState(null);
  const [updatedAttachments, setUpdatedAttachments] = useState(conversation.attachments ? conversation.attachments : [])
  const [questionHint, setQuestionHint] = useState(conversation.questionHint ? conversation.questionHint : null);
  const [questionMarking, setQuestionMarkings] = useState([]);
  const [isQuestionOk, setIsQuestionOk] = useState(false);
  const hintRef = useRef(questionHint);
  const markingRef = useRef(questionMarking)
  const editorRef = useRef(null);
  const containerRef = useRef(null);


  var lastKeyHitTimestamp = Date.now();

  var updateCounter = 1;
  var newestUpdateCounter = 0;
  const [isMostRecentUpdateRequestComplete, setIsMostRecentUpdateRequestComplete] = useState(false);

  useEffect(() => {
    console.log("Updated Attachments changed to :", updatedAttachments)
  }, [updatedAttachments])

  useEffect(() => {
    console.log("Useeffect Editorref")
    if (editorRef.current) {
      checkSafetyUpdateNeccesary(editorRef.current.getText())
    }
  }, [editorRef])

  useEffect(() => {
    hintRef.current = questionHint;
  }, [questionHint]);

  useEffect(() => {
    markingRef.current = questionMarking;
  }, [questionMarking]);

  useEffect(() => {
    console.log("Coneversation changed in EditableQuestionField to:", conversation)
    if (conversation.attachments) {
      console.log("Setting attachments in editable question field with:", conversation.attachments)
      setUpdatedAttachments(conversation.attachments)
    }
    if (conversation.textMarkingMatches && !editedQuestion) {
      setQuestionMarkings(conversation.textMarkingMatches)
    }
    const container = document.getElementById("editor" + conversation.chatIndex);
    containerRef.current = container;

    const options = {
      theme: 'bubble',
      modules: {
        toolbar: false,
        clipboard: {
          matchVisual: false,
        },
      },
      placeholder: "Enter your question here...",
    };
    if (!editorRef.current) {
      console.log("Creating ne Editor")
      editorRef.current = new Quill(container, options);
    }

    const editor = editorRef.current;

    if (!editedQuestion && conversation.question) {
      console.log("Setting editor text with dangerouslyPasteHTML: ", conversation.question)
      editor.setText(conversation.question);
    }

    const handleTextChange = (delta, oldDelta, source) => {
      console.log("Handle Text Change delta:", delta, "Old delta:", oldDelta, "Source:", source);
      let position = 0;

      delta.ops.forEach((op) => {
        if (op.retain) {
          position += op.retain;
        }
        if (op.insert) {
          console.log("Insert OP:", op.insert);
          const insertLength = op.insert.length;
          markingRef.current = markingRef.current.map(({ rule, matches }) => {
            return {
              rule,
              matches: matches.map(match => {
                if (match.position >= position) {
                  match.position += insertLength;
                } else if (position > match.position && position <= match.position + match.length) {
                  match.length = 0;
                }
                return match;
              })
            };
          });
          position += insertLength;
        }
        if (op.delete) {
          console.log("Delete OP:", op.delete);
          const deleteLength = op.delete;
          markingRef.current = markingRef.current.map(({ rule, matches }) => {
            return {
              rule,
              matches: matches.map(match => {
                if (match.position >= position) {
                  match.position -= deleteLength;
                } else if (position > match.position && position <= match.position + match.length) {
                  match.length = 0;
                }
                return match;
              })
            };
          });
        }
      });

      if (source === 'user') {
        highlightPrivateText(editor, markingRef.current);
        setEditedQuestion(editor.getText());
        checkSafetyUpdateNeccesary(editor.getText());
      }

    };

    editor.on('text-change', handleTextChange);

    highlightPrivateText(editor, markingRef.current);
    return () => {
      editor.off('text-change', handleTextChange);
    };
  }, [conversation, questionMarking]);


  async function checkSafetyUpdateNeccesary(text) {
    setIsMostRecentUpdateRequestComplete(false);
    updateCounter = updateCounter + 1;
    lastKeyHitTimestamp = Date.now();
    setTimeout(() => {
      const moreThanTwoSecsSinceLastUpdate = Date.now() - lastKeyHitTimestamp > 2000;
      if (moreThanTwoSecsSinceLastUpdate) {
        checkTextUpdateSave(text, updatedAttachments);
      }
    }, 2100);
  }


  function highlightPrivateText(editor, textMarkingMatches) {
    editor.formatText(0, editor.getLength(), 'color', 'var(--text-icons)');
    if (textMarkingMatches && textMarkingMatches.length > 0) {
      console.log("Highlighting the following parts:", textMarkingMatches);
      textMarkingMatches.forEach(({ rule, matches }) => {
        matches.forEach(({ bucketID, columnID, row, position, length }) => {
          const startIndex = position;
          const matchLength = length;
          editor.formatText(startIndex, matchLength, 'color', 'red');
        });
      });
    } else {
      console.log("Got None for Highlights");
    }
  }

  async function checkTextUpdateSave(updated_question, updated_attachments) {
    console.log("Checking with attachements:", updated_attachments)
    var apiLink = api_url + "/check_updated_question"
    var response = await fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(
        {
          "updatedQuestion": updated_question,
          "queryIndex": updateCounter,
          "modelID": selectedModelID,
          "topicID": conversation.topicID && conversation.topicID !== -1 ? conversation.topicID : selectedTopicID,
          "attachments": updated_attachments,
          "documentID": documentID ? documentID : -1
        }
      )
    })
    if (response.status !== 200) {
      const errorData = await response.json();
      console.log(errorData);
      if (response.status === 401 && errorData.detail == "not logged in") {
        console.log("Catched expired Session");
        window.location.href = "/session_expired";
      } else {
        alert("Error: " + response.status);
      }
    } else {
      // success
      const data = await response.json()
      console.log("Query check updated text Reesponse Data", data.conversation);

      console.log("Expected query Index:", newestUpdateCounter, " Recived Query Index:", data.queryIndex);
      if (data.queryIndex > newestUpdateCounter) {
        newestUpdateCounter = data.queryIndex;
        if (data.queryIndex === updateCounter) {
          setIsMostRecentUpdateRequestComplete(true);
          setQuestionMarkings(data.conversation.textMarkingMatches)
          setQuestionHint(data.conversation.questionHint);
          setIsQuestionOk(data.conversation.questionSave);
          const updatedAttachmentsWithSafety = updated_attachments.map((attachment) => {
            const correspondingResponseAttachment = data.conversation.attachments.find(
              (responseAttachment) => responseAttachment.attachmentFileName === attachment.attachmentFileName
            );

            if (correspondingResponseAttachment) {
              return { ...attachment, safe: correspondingResponseAttachment.safe };
            }
          });
          console.log("Setting attachments after safety check:", updatedAttachmentsWithSafety)
          setUpdatedAttachments(updatedAttachmentsWithSafety);
        }
      }
    }
  }

  function anonymizeCriticalContent() {
    const textMarkingMatches = markingRef.current;
    console.log("Parts to anonymize:", textMarkingMatches);
    let updated_text = editorRef.current.getText();
    console.log("Before anonymization:", updated_text);

    // Flatten the matches
    let allMatches = [];
    textMarkingMatches.forEach(({ rule, matches }) => {
      matches.forEach(match => {
        allMatches.push(match);
      });
    });

    // Remove duplicates
    allMatches = allMatches.filter((match, index, self) =>
      index === self.findIndex((t) => (
        t.position === match.position && t.length === match.length
      ))
    );

    // Sort matches in reverse order based on position
    allMatches.sort((a, b) => b.position - a.position);

    // Initialize replacement dictionary
    let replacementDict = {}; // Key = (str BID+ CID + RID) Value = {Position ,Alias, AliasID}
    var attachment_replace_alias_difference = 0
    allMatches.forEach(marking => {
      console.log("Already replaced positions:", Object.values(replacementDict).map(replacement => replacement.position));
      console.log("attachment_replace_alias_difference: ", attachment_replace_alias_difference)
      if (!Object.values(replacementDict).some(replacement => replacement.position === marking.position)) {
        let key = `ALIAS${marking.bucketID}/${marking.columnID}/${marking.row}EOALIAS`;
        let alias = key; // Default alias if none available

        if (replacementDict[key]) {
          alias = replacementDict[key].alias;
        } else {
          if (marking.aliases.length > 0) {
            let aliasFound = false;
            marking.aliases.forEach((markingAlias, aliasIndex) => {
              if (!Object.values(replacementDict).some(replacement => replacement.aliasID === markingAlias.id)) {
                alias = markingAlias.alias;
                aliasFound = true;
                replacementDict[key] = {
                  position: marking.position,
                  alias: alias,
                  aliasID: markingAlias.id,
                  original: updated_text.substring(marking.position, marking.position + marking.length)
                };
              }
              if (aliasIndex === marking.aliases.length - 1 && !aliasFound) {
                alias = markingAlias.alias + Object.values(replacementDict).filter(replacement => replacement.aliasID === markingAlias.id).length;
                replacementDict[key] = {
                  position: marking.position,
                  alias: alias,
                  aliasID: markingAlias.id,
                  original: updated_text.substring(marking.position, marking.position + marking.length)
                };
              }
            });
          } else {
            replacementDict[key] = {
              position: marking.position,
              alias: alias,
              aliasID: -1,
              original: updated_text.substring(marking.position, marking.position + marking.length)
            };
          }
        }

        console.log("Replacing with replacement dict:", replacementDict);

        if (marking.position >= 0) {
          // Replace in the query string
          updated_text = updated_text.substring(0, marking.position) + alias + updated_text.substring(marking.position + marking.length);
        } else {
          console.log("Replacing in attachment")
          console.log("Attachments Encoded Data:", updatedAttachments)
          // Calc hit attachemt index
          var att_range_index = 0
          const attachment_ranges = updatedAttachments.map(att => {
            att_range_index = att_range_index - att.encodedData.length
            return {
              "start": att_range_index + att.encodedData.length,
              "end": att_range_index
            }
          })

          console.log("Attachemnt ranges:", attachment_ranges)
          const hit_attachment_index = attachment_ranges.findIndex((att_range) => {
            if (att_range.start > marking.position && att_range.end < marking.position) {
              return true
            } else {
              return false
            }
          })
          if (hit_attachment_index >= 0) {
            console.log("Hit attachement index:", hit_attachment_index)
            const positive_match_pos = marking.position - attachment_ranges[hit_attachment_index].end - attachment_replace_alias_difference
            console.log("Positive Match Index:", positive_match_pos)
            console.log("Marking Hits in encoded Data:", updatedAttachments[hit_attachment_index].encodedData.substring(positive_match_pos, positive_match_pos + marking.length))
            const hit_word = updatedAttachments[hit_attachment_index].encodedData.substring(positive_match_pos, positive_match_pos + marking.length)
            // Replace in attachments encoded data
            updatedAttachments[hit_attachment_index].encodedData = updatedAttachments[hit_attachment_index].encodedData.substring(0, positive_match_pos) + alias + updatedAttachments[hit_attachment_index].encodedData.substring(positive_match_pos + marking.length);

            // Updating a counter that represents the Index changes following replacement
            attachment_replace_alias_difference += alias.length - hit_word.length
          }
        }

      }
    });

    console.log("Anonymized text:", updated_text);
    console.log("Updated Attachments at end of anonymization:", updatedAttachments)
    setEditedQuestion(updated_text);
    editorRef.current.setText(updated_text);
    checkSafetyUpdateNeccesary(updated_text);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', width: "100%", paddingLeft: "5px" }}>
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: "40%" }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {isQuestionOk && isMostRecentUpdateRequestComplete ?
            <FaCheck style={{ marginTop: '8px', color: "green", fontSize: "larger", minHeight: "25px" }} />
            :
            <FaExclamationTriangle style={{ marginTop: '8px', color: "red", fontSize: "larger", minHeight: "25px" }} />
          }
          <img src={PrivcyPromptLogo} alt="PrivacyPrompt Logo" style={{ maxHeight: "25px", alignSelf: "flex-start", justifySelf: "flex-start", margin: "8px" }} />
          <div style={isQuestionOk && isMostRecentUpdateRequestComplete ? { color: "green", fontWeight: "bold", marginTop: "8px", textAlign: "center" } : { color: "red", fontWeight: "bold", marginTop: "8px", textAlign: "center" }}>Privacy Prompt</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {/* Attachements */}
          {conversation.attachments && conversation.attachments.length > 0 &&
            <div className='chat-entry-attachments-container'>
              <div style={{ width: "100%", textAlign: "center" }}>Message attachments:</div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                {updatedAttachments.map((attachment) =>
                  <div key={attachment.encodedData} className='entry-attachment'>
                    {attachment.attachmentType === 0 ?
                      <div style={{ position: "relative", width: "max-content" }}>
                        <img
                          src={`data:image/png;base64,${attachment.encodedData}`}
                          alt={`Uploaded preview`}
                          className='entry-attachemnt-image'
                        />
                      </div>
                      :
                      attachment.attachmentType === 2 || attachment.attachmentType === 3 ?
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", borderBottom: "1px solid var(--background-3)"}}>
                          <div style={{ alignSelf: "center", fontWeight: "bold" }}>
                            Prompt regarding selection
                          </div>
                          <div style={{ width: "90%" }}>
                            {
                              attachment.encodedData.split("Of following document")[0]
                                .replace("The following content is a question regarding this Section:", "")
                                .trim()
                                .split("\n").map((line, index) => (
                                  <React.Fragment key={index}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))
                            }
                          </div>
                        </div>
                        :
                        <FaFile />
                    }
                    <FaTimes className={attachment.attachmentType === 2 || attachment.attachmentType === 3 ? "unsafe-remove-selection-button ":"remove-attachment-button"}
                      onClick={() => {
                        setUpdatedAttachments(updatedAttachments.filter(att => att.attachmentFileName !== attachment.attachmentFileName))
                        checkTextUpdateSave(editorRef.current.getText(), updatedAttachments.filter(att => att.attachmentFileName !== attachment.attachmentFileName))
                        onAttachmentDeleted(updatedAttachments.filter(att => att.attachmentFileName !== attachment.attachmentFileName));
                      }}
                    />
                    <div style={{
                      maxWidth: "200px",
                      width: "inherit",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                      {attachment.attachmentFileName} 
                    </div>

                  </div>
                )}
              </div>
            </div>
          }
          {/* Editor */}
          <div id={"editor" + conversation.chatIndex} className="quill-container" />
        </div>

        {!isQuestionOk && <div className="content-table">
          <div className="content-label critical">What migth be wrong with your Question:</div>
          <ul className="content-list critical">
            {questionHint.map((concerning_content, idx) => (
              <li key={'AnswerPrivacyConcerningContent-' + idx}>{concerning_content}</li>
            ))}
            {updatedAttachments && updatedAttachments.filter(atcc => !atcc.safe).map((attc) => (
              <li key={'AnswerPrivacyConcerningContent-atc' + attc.attachmentFileName}>{"Attachment " + attc.attachmentFileName + " migth not be safe to disclose."}</li>
            ))}
          </ul>
        </div>}
        <div>
          {!isMostRecentUpdateRequestComplete &&
            <div>
              <button
                className="submitt-button-correction"
              >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                  <center style={{ margin: '10px' }}>Checking Question</center>
                  <MagnifyingGlass
                    visible={true}
                    height="35"
                    width="45"
                    ariaLabel="magnifying-glass-loading"
                    wrapperStyle={{}}
                    wrapperClass="magnifying-glass-wrapper"
                    glassColor="var(--background-4)"
                    color="var(--text-icons-2)"
                  />
                </div>
              </button>
            </div>
          }
          {!isQuestionOk && isMostRecentUpdateRequestComplete &&
            <div>
              <button
                className="submitt-button-correction"
                onClick={() => {
                  console.log("Edited Question before Automated anonymazition:", editedQuestion)
                  anonymizeCriticalContent();
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                  <center style={{ margin: '10px' }}>Anonymize critical content</center>
                  <FaSpellCheck style={{ fontSize: "20px" }} />
                </div>
              </button>
            </div>
          }
          {isQuestionOk && isMostRecentUpdateRequestComplete &&
            <div>
              <button
                className={`submitt-button-correction ${isInGeneration ? "disabled" : ""}`}
                onClick={() => {
                  //console.log("Clicked button to send corrected query:", editedQuestion)
                  onSendCorrectedQuestion(editorRef.current.getText(), updatedAttachments);
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                  <center style={{ margin: '10px' }}>Send question</center>
                  <FontAwesomeIcon icon={faShareFromSquare} style={{ fontSize: "20px" }} />
                </div>
              </button>
            </div>}
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { ContingentData, LLMAPIData } from '../SettingsTypedefs';




export default function LLMContingentTile({ apiURL, llmData, ...props }:
    { apiURL: string, llmData: LLMAPIData }) {
    const [groupContingents, setGroupContingents] = useState<ContingentData[]>([]);
    const [personalContingent, setPersonalContingent] = useState<ContingentData | null>(null);

    useEffect(() => {
        fetchContingents();
    }, []);

    useEffect(()=> {
        if(personalContingent) {
        console.log("Personal Contingent chaned to :", personalContingent)
        console.log("Percentage should be:", personalContingent!.counter , personalContingent!.contingent )            
        }
    }, [personalContingent])

    async function fetchContingents() {
        try {
            const response = await fetch(
                apiURL + "/get_contingents_for_user_and_llm?llmID=" + llmData.api_id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json()
                console.log("ContingentData:", data.contingents)
                setGroupContingents(data.contingents.group_contingents)
                setPersonalContingent(data.contingents.personal_contingent[0])
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    return (
        <div
            style={{ padding: "10px", width: "90%", border: "solid 1px var(--text-icons-2)", borderRadius: "10px", marginBottom: "10px", overflow: "visible" }}
        >
            <div>
                {llmData.api_name}
            </div>
            {groupContingents.map((groupContingent) => (
                <div
                    key={"ConGroup" + groupContingent.groupName}
                    style={{ width: "100%", minHeight: "100px", marginBottom: "20px", display: "block", marginTop: "20px" }}
                >
                    <h5>Group Contingent: {groupContingent.groupName}</h5>
                    {groupContingent.contingent === -1 ? 
                    <div  style={{width:"100%", textAlign:"center"}}> No restraints for access</div> :
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div> {((groupContingent.counter / groupContingent.contingent) * 100).toFixed(2)}% </div>
                        <progress
                            className='contingentBar'
                            max={groupContingent.contingent}
                            value={groupContingent.counter}
                        />
                        <div>{groupContingent.counter}{" of "}{groupContingent.contingent} {llmData.contingentType ? " Tokens" : " Requests"}</div>
                    </div>
                    }

                </div>
            ))}
            {personalContingent &&
                <div>
                    <h5>Personal Contingent</h5>
                    {personalContingent.contingent === -1 ? <div style={{width:"100%", textAlign:"center"}}>No restraints for access</div> :
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div> {((personalContingent.counter / personalContingent.contingent)* 100 ).toFixed(2)}% </div>
                        <progress
                            className='contingentBar'
                            max={personalContingent.contingent}
                            value={personalContingent.counter}
                        />
                        <div>{personalContingent.counter}{" of "}{personalContingent.contingent} {llmData.contingentType ? " Tokens" : " Requests"}</div>
                    </div>
                    }
                </div>
            }
        </div>
    );
}

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import LLMIcon from './Icons/LLMIcon'
const LLMSelectValue = ({ cx, children, getStyles, innerRef, innerProps, ...props }) => {
    const { _, isFocused, isSelected } = props;
    const styles = getStyles('singleValue', props);
    return (
        <div
            ref={innerRef}
            className={cx(
                css(styles),
            )}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "18px",
                marginLeft: "3px",
                backgroundColor: isSelected ? "var(--background-2)" : isFocused ? "var(--background-3)" : "var(--background)"
            }}

            {...innerProps}
        >
            <LLMIcon
                iconNumber={children[1]}
                maxHeight={"30px"}
                maxWidth={"30px"}
            />
            <div style={{ marginLeft: "5px", textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: "hidden", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <div style={{ flex: "1" }}>
                    {children[0]}
                </div>

            </div>

        </div>
    );
};

export default LLMSelectValue;
import { useEffect, useState } from "react";
import { FaPlusCircle, FaTimes } from "react-icons/fa";
import Select from "react-select";
import { SecurityLevelData, LLMAPIData, UserData, UserGroupData, APIWithContingentData, PricingPreset, UserInfoModel } from "../SettingsTypedefs";
import UserGroupTile from "./UserGroupTile";
import APIAccessTile from "../../../Components/APIAccessTile";
import { Tooltip } from "react-tooltip";

export default function UserGroupSettings({ apiURL, userData, userGroups, companyLLMs, companyUsers, securityLevels, pricingPresetOptions, onUserGroupsChanged, isMobile, ...props }:
    { apiURL: string, userData: UserData, userGroups: UserGroupData[], companyLLMs: LLMAPIData[], companyUsers: UserInfoModel[], securityLevels: SecurityLevelData[], pricingPresetOptions: PricingPreset[], onUserGroupsChanged: Function, isMobile: boolean }) {

    const [newUserGroupName, setNewUserGroupName] = useState("");
    const [newUserGroupMode, setNewUserGroupMode] = useState(false);
    const [selectedUsersNewGroup, setSelectedUsersNewGroup] = useState<UserInfoModel[]>([]);
    const [selectedLLMAPIsNewGroup, setSelectedLLMAPIsNewGroup] = useState<APIWithContingentData[]>([]);
    const [selectedSecLevelNewGroup, setSelectedSecLevelNewGroup] = useState<SecurityLevelData | null>(null);



    async function postNewUserGroup() {
        console.log("User Group Body:", {
            "groupName": newUserGroupName,
            "groupUsers": selectedUsersNewGroup,
            "groupLLMs": selectedLLMAPIsNewGroup
        }
        )
        const selectedUserIds = selectedUsersNewGroup.map(user => user.userID);
        try {
            const response = await fetch(
                apiURL + "/add_user_group",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "groupName": newUserGroupName,
                            "groupUsers": selectedUserIds,
                            "groupLLMs": selectedLLMAPIsNewGroup,
                            "security_level": 1
                        }
                    )
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const data = await response.json();
                console.log(data)
                onUserGroupsChanged()
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    return (
        <div className="subsetting-content">
            <center><h4>User Groups</h4></center>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    {newUserGroupMode ?
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <div className="setting-add-element-container">
                                <div style={{ margin: "5px", fontWeight: 'bold' }}>User Group Name </div>
                                <input
                                    value={newUserGroupName}
                                    onChange={(e => {
                                        setNewUserGroupName(e.target.value);
                                    })}
                                    className="settings-input"
                                    placeholder="New User Group"
                                />
                                <div style={{ margin: "5px", fontWeight: 'bold' }}> User Group Members </div>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: "var(--background)",
                                            color: "var(--text-icons)",
                                            zIndex: 10
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 110
                                        }),
                                        group: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            zIndex: 110
                                        }),
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)"
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)",
                                        }),
                                        multiValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 10
                                        }),
                                        multiValueLabel: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        container: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: "100%",
                                            margin: "5px",
                                        })
                                    }}
                                    getOptionLabel={(user) => user.name}
                                    getOptionValue={(user) => user.name}
                                    value={selectedUsersNewGroup}
                                    options={companyUsers}
                                    onChange={(selectedOptions) => {
                                        console.log("selectedOptions:", selectedOptions);
                                        setSelectedUsersNewGroup([...selectedOptions]);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuPosition={'absolute'}
                                    menuPlacement={'auto'}
                                    isMulti={true}
                                />
                                <div style={{ margin: "5px", fontWeight: 'bold' }}> User Group LLM APIs Access Rigths </div>
                                {companyLLMs.filter((llm) => { return llm.is_company_api }).map((llm) => (

                                    <div style={{ display: 'flex', flexDirection: 'column' }} key={"APIAT" + llm.api_id}>
                                        <APIAccessTile
                                            llm={llm}
                                            accessActivated={selectedLLMAPIsNewGroup.map(awc => awc.api_id).includes(llm.api_id)}
                                            pricingPresetsOptions={pricingPresetOptions}
                                            contingent={selectedLLMAPIsNewGroup.map(awc => awc.api_id).includes(llm.api_id) ? selectedLLMAPIsNewGroup.filter(awc => awc.api_id === llm.api_id)[0].contingent : -1}
                                            onAccessChanged={(accessActivated, contingent, selectedPresetID) => {
                                                console.log("Access changed to", accessActivated, contingent, selectedPresetID);
                                                console.log("Constrained:", selectedLLMAPIsNewGroup.map(awc => awc.api_id).includes(llm.api_id) ? selectedLLMAPIsNewGroup.filter(awc => awc.api_id === llm.api_id)[0].contingent : -1);

                                                const updatedList = [...selectedLLMAPIsNewGroup];
                                                const index = updatedList.findIndex(awc => awc.api_id === llm.api_id);

                                                if (accessActivated) {
                                                    if (index === -1) {
                                                        updatedList.push({
                                                            api_id: llm.api_id,
                                                            contingent: -1,
                                                            pricingPresetID: selectedPresetID
                                                        });
                                                    } else {
                                                        updatedList[index] = {
                                                            ...updatedList[index],
                                                            contingent: contingent,
                                                            pricingPresetID: selectedPresetID
                                                        };
                                                    }
                                                } else {
                                                    if (index !== -1) {
                                                        updatedList.splice(index, 1);
                                                    }
                                                }
                                                console.log("Updated List:", updatedList)
                                                setSelectedLLMAPIsNewGroup(updatedList);
                                            }}
                                            selectedPricingPresetID={-1}
                                            isMobile={isMobile}
                                        />
                                    </div>
                                ))}
                                <div style={{ margin: "5px", fontWeight: 'bold' }}> User Group Security Level </div>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: "var(--background)",
                                            color: "var(--text-icons)",
                                            zIndex: 10
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 110
                                        }),
                                        group: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            zIndex: 110
                                        }),
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)"
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)",
                                        }),
                                        multiValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 10
                                        }),
                                        multiValueLabel: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        container: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: "100%",
                                            margin: "5px",
                                        })
                                    }}
                                    getOptionLabel={(sec_level) => sec_level.security_level_name}
                                    getOptionValue={(sec_level) => sec_level.security_level_name}

                                    options={securityLevels}
                                    onChange={(selectedOptions) => {
                                        setSelectedSecLevelNewGroup(selectedOptions);
                                    }}
                                    value={selectedSecLevelNewGroup}
                                    menuPortalTarget={document.body}
                                    menuPosition={'absolute'}
                                    menuPlacement={'auto'}
                                    isMulti={false}
                                />
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <div
                                        className={`settings-button ${isMobile ? "mobile" : ""}`}
                                        onClick={() => {
                                            setNewUserGroupMode(false);
                                        }}>
                                        Cancel
                                        <FaTimes className="cancel-delete-icon" />
                                    </div>
                                    <div
                                        className={`settings-button ${isMobile ? "mobile" : ""}`}
                                        onClick={() => {
                                            setNewUserGroupMode(false);
                                            postNewUserGroup();
                                            setNewUserGroupName("");
                                            setSelectedLLMAPIsNewGroup([]);
                                            setSelectedUsersNewGroup([]);
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div>Add Group</div>
                                            <FaPlusCircle className="confirm-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :

                        <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                            onClick={() => {
                                setNewUserGroupMode(true);
                            }}
                            data-tooltip-id="AddUserGroupTooltip"
                        >
                            Add new User Group
                            <FaPlusCircle className="add-element-icon" />
                            <Tooltip id="AddUserGroupTooltip" className="custom-tooltip" delayShow={800}>
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    You can add a user group and define the user group llm access rights. If contingents are defined every user of the group gets this contingent.
                                </div>
                            </Tooltip>
                        </div>
                    }
                    {userGroups &&
                        userGroups.map((group) => (
                            <UserGroupTile
                                key={"grouptile" + group.groupID}
                                userData={userData}
                                userGroupData={group}
                                availableLLMs={companyLLMs}
                                companyUsers={companyUsers}
                                apiURL={apiURL}
                                onUserGroupsChanged={() => {
                                    onUserGroupsChanged();
                                }}
                                securityLevelOptions={securityLevels}
                                pricingPresetOptions={pricingPresetOptions}
                                isMobile={isMobile}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { FaPauseCircle, FaFileAudio, FaPlayCircle } from 'react-icons/fa';
import { Tooltip } from "react-tooltip";
import "quill/dist/quill.bubble.css";

// Define the props interface
interface AudioControllerProps {
    textToRead: string;
}

// Define the ref methods interface
interface AudioControllerRef {
    autoReadAlaoud: () => void;
}

// Convert to TypeScript
const AudioController = forwardRef<AudioControllerRef, AudioControllerProps>(({ textToRead }, ref) => {
    const synth = window.speechSynthesis;
    const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(null);
    const [isSpeaking, setIsSpeaking] = useState<'idle' | 'speaking' | 'paused'>('idle');
    const textToReadRef = useRef<string>(textToRead);

    useEffect(() => {
        textToReadRef.current = textToRead;

        if (textToRead) {
            const new_utterance = new SpeechSynthesisUtterance(textToRead);
            const alreadyReadText = textToRead;

            new_utterance.onend = () => {
                if (textToReadRef.current.length > alreadyReadText.length) {
                    console.log("Utterance did not finish Text checking for consecutive:");
                    checkForConsecutiveTextToRead(alreadyReadText);
                } else {
                    setIsSpeaking('idle');
                }
            };

            new_utterance.onerror = () => {
                setIsSpeaking('idle');
            };

            setUtterance(new_utterance);
        }
    }, [textToRead]);

    // Expose methods via ref
    useImperativeHandle(ref, () => ({
        autoReadAlaoud() {
            console.log("Reading aloud:", textToRead);
            handleSpeak();
        }
    }));

    // Handle speech
    const handleSpeak = () => {
        console.log("Speak clicked Text to speak:", textToRead);
        setIsSpeaking('speaking');

        if (synth.speaking) {
            synth.cancel();
        }

        if (utterance) {
            speechSynthesis.speak(utterance);
        }
    };

    // Check for consecutive text and continue reading
    const checkForConsecutiveTextToRead = (alreadyReadText: string) => {
        console.log("Into checkForConsecutiveTextToRead");
        const current_text_to_read = textToReadRef.current;
        const current_text_length = current_text_to_read.length;
        const already_read_text_length = alreadyReadText.length;

        if (current_text_length > already_read_text_length) {
            const sliced_text_to_read = current_text_to_read.slice(already_read_text_length, current_text_length);
            console.log("New Speech utterance necessary speaking: ", sliced_text_to_read);

            const new_utterance = new SpeechSynthesisUtterance(sliced_text_to_read);

            new_utterance.onend = () => {
                if (textToReadRef.current.length > alreadyReadText.length) {
                    console.log("Utterance did not finish Text checking for consecutive:");
                    checkForConsecutiveTextToRead(current_text_to_read);
                } else {
                    setIsSpeaking('idle');
                }
            };

            new_utterance.onerror = () => {
                setIsSpeaking('idle');
            };

            speechSynthesis.speak(new_utterance);
        } else {
            setIsSpeaking('idle');
        }
    };

    return (
        <div ref={ref as React.RefObject<HTMLDivElement>} data-tooltip-id={"AudiocontrollerTooltip"} >
            {isSpeaking === "idle" ? (
                <FaFileAudio style={{ margin: '5px', cursor: 'pointer' }}
                    onClick={handleSpeak}
                />
            ) : isSpeaking === "speaking" ? (
                <FaPauseCircle style={{ margin: '5px', cursor: 'pointer' }}
                    onClick={() => {
                        setIsSpeaking('paused');
                        synth.pause();
                    }}
                />
            ) : (
                <FaPlayCircle style={{ margin: '5px', cursor: 'pointer' }}
                    onClick={() => {
                        setIsSpeaking('speaking');
                        synth.resume();
                    }}
                />
            )}
            <Tooltip id={"AudiocontrollerTooltip"} className="custom-tooltip" delayShow={800}>
                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                    Here you can play this message as audio. In the Account Settings you can set playing audios to default to play every message as soon as it's completed.
                </div>
            </Tooltip>
        </div>
    );
});

export default AudioController;

import './../Home/Home.css'
import PrivcyPromptLogo from './../../assets/privacyprompt.svg'
function ErrorPage() {
    return (
        <div className="page-container">
            <div style={{height:"100%", width:"100%", alignItems:"center", justifyContent:"center", display:"flex", flexDirection:"column"}}>
                <img src={PrivcyPromptLogo} alt="PrivacyPrompt Logo" style={{ maxHeight: "60px" }} />
                <div style={{fontSize:"20px", fontWeight:"bolder"}}>
                        OOOps something went wrong
                </div>
                <div> If this Error does not disappear after actualizing the website please contact us under m.vetter@hs-mannheim.de</div>
            </div>
           
        </div>
    );
}

export default ErrorPage;
import { useEffect, useState } from 'react';
import Navbar from "../../Components/Navbar";
import { Navigate } from 'react-router-dom';
import { useRef } from 'react';
import EditableQuestionField from './EditableQuestionField';
import './/Home.css'
import ChatInput from './ChatInput';
import TopicSelection from './TopicSelection';
import { FaExclamationTriangle } from 'react-icons/fa';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Circles } from 'react-loader-spinner';
import ChatEntry from './ChatEntry.tsx'
import WarningPopUp from '../../Components/WarningPopUp';


function Home({ onLogout, user, apiURL, availableLLMs, isMobile, showAnonymazationHints }) {
    const [selectedTopic, setSelectedTopic] = useState(-1);
    const [queryText, setQueryText] = useState("");
    const [conversations, setConversations] = useState([]);
    const [selectedModelID, setSelectedModelID] = useState(null);
    const [contingentReached, setContingentReached] = useState(false);
    const [readAloudIndex, setReadAloudIndex] = useState(null)
    const [readOutAloud, setReadOutAloud] = useState(false);
    const [topicSelectionExpanded, setTopicSelectionExpanded] = useState(false);
    const [selesctedModelModiPrompts, setSelectedModelModiPrompts] = useState([]);
    const [imageInputAllowed, setImageInputAllowed] = useState(false);
    const [hiddenAutoAnonymizeHint, setHiddenAutoAnonymizeHint] = useState(null)
    const [visibleCount, setVisibleCount] = useState(10);
    const [isGenerating, setIsGenerating] = useState(false)
    const newestMessageBottomRef = useRef(null);
    const chatContainerRef = useRef(null);
    const topicSelectionRef = useRef(null);
    const readAloudAnswerRef = useRef(null);
    const chatInputRef = useRef(null);

    useEffect(() => {
        //console.log("UseEffect selected Topic");
        if (user && selectedTopic) {
            fetchConversationForTopic();
        } else {
            setConversations([]);
        }
    }, [selectedTopic]);

    useEffect(() => {
        console.log("Checking for image input with availablellms: ", availableLLMs, "Selected:", selectedModelID)
        if (selectedModelID && availableLLMs && availableLLMs.length > 0) {
            const currentllm = availableLLMs.filter(llm => llm.api_id === selectedModelID)[0]
            console.log("Setting images allowed to:", currentllm.images)
            setImageInputAllowed(currentllm.images)
        }
    }, [selectedModelID, availableLLMs])


    useEffect(() => {
        //console.log("conversations:", conversations, "ReadOutAloud:", readOutAloud)
        if (newestMessageBottomRef.current) {
            //console.log("Scroll attempt")
            newestMessageBottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (conversations.length > 0) {
            if (conversations[conversations.length - 1].answer && readOutAloud) {
                console.log("Answer:", conversations[conversations.length - 1].answer)
                setReadOutAloud(false);
                readAloudAnswerRef.current.readAloud();
            }
        }
        //console.log("readAloudAnswerRef:", readAloudAnswerRef.current)
    }, [conversations]);


    useEffect(() => {
        console.log("Home Useeffect selected Model ID")
        if (selectedModelID) {
            setContingentReached(false);
        }
    }, [selectedModelID])


    async function fetchConversationForTopic() {
        if (selectedTopic !== -1 && selectedTopic) {
            var apiLink = apiURL + "/get_conversation?topicID=" + selectedTopic;
            //console.log("Fetching Conversation Data");
            if (selectedTopic) {
                try {
                    const response = await fetch(apiLink, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    });

                    if (response.status !== 200) {
                        const errorData = await response.json();
                        console.log(errorData);
                        if (response.status === 401 && errorData.detail == "not logged in") {
                            console.log("Catched expired Session");
                            window.location.href = "/session_expired";
                        } else {
                            alert("Error: " + response.status);
                        }
                    } else {
                        const successData = await response.json();
                        //console.log("Success fetching conversation for Topic data:");
                        //console.log(successData);

                        const conversation = successData.conversation;
                        const messages = conversation.map(entry => {
                            return {
                                "question": entry.question,
                                "questionSave": entry.questionSave,
                                "questionHint": entry.questionHint,
                                "answer": entry.answer,
                                "chatIndex": entry.chatIndex,
                                "answer_source": entry.answerSource,
                                "topicID": entry.topicID,
                                "attachments": entry.attachments,
                                "textMarkingMatches": entry.textMarkingMatches
                            }
                        });
                        const sorted_messages = [...messages].sort((message_a, message_b) => message_a.chatIndex - message_b.chatIndex);
                        //console.log("Setting conversation with:", sorted_messages);
                        setConversations(sorted_messages);
                        //console.log("deciding if scroll is neccessary messages length:", messages.length)
                        setTimeout(() => {
                            document.getElementById("scrollDummy").scrollIntoView({ behavior: 'smooth' });
                        }, 100)

                    }
                } catch (error) {
                    console.log("Error occurred while fetching conversation data:", error);
                }
            }
        } else {
            setConversations([]);
        }
    }

    async function send_initial_question(attachments) {
        console.log("Recived attachments in send intial question: ", attachments)
        const queryWithPrePrompts = selesctedModelModiPrompts.length > 0 ? selesctedModelModiPrompts.join("\n") + "\n" + queryText : queryText
        const conv_length_pre_query = conversations.length
        console.log("Query with pre prompts:", queryWithPrePrompts)
        const apiLink = `${apiURL}/filter_query_text`;
        if (!availableLLMs || availableLLMs.length === 0) {
            return
        }
        const newConversation = {
            "question": queryWithPrePrompts,
            "questionSave": true,
            "questionCriticalContent": [],
            "questionHint": [],
            "answer": null,
            "chatIndex": conversations.length,
            "answer_source": selectedModelID,
            "attachments": attachments,
            "textMarkingMatches": null,
            "isInGeneration": true
        };
        setReadAloudIndex(conversations.length)  // Actually -1 but ok beacause state update not done yet
        setConversations(prevConversations => [...prevConversations, newConversation]);


        try {
            const formData = new FormData();
            formData.append("queryText", queryWithPrePrompts ? queryWithPrePrompts : ".");
            formData.append("topicID", selectedTopic);
            formData.append("chatIndex", conversations.length);
            formData.append("selectedModelID", selectedModelID);
            formData.append("attachments", JSON.stringify(attachments))
            formData.append("documentID", -1)

            // Attach each file to the FormData object
            attachments.forEach((attachment, index) => {
                formData.append(`files`, attachment.file);
            });

            const response = await fetch(apiLink, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
            });

            if (response.status !== 200 && response.status !== 201 && response.status !== 451) {
                console.log("NO 200 Response Intial Question Status:", response.status);
                alert(`Error: ${response.status}`);
                setIsGenerating(false);
            } else if (response.status === 201) {
                console.log("Status indicates unsafe Question")
                const data = await response.json();
                //console.log("Unsafe Question answer:", data);
                newConversation.answer = data.conversation.answer
                newConversation.questionSave = data.conversation.questionSave
                newConversation.questionHint = data.conversation.questionHint
                newConversation.attachments = data.conversation.attachments
                newConversation.textMarkingMatches = data.conversation.textMarkingMatches

                setConversations(prevConversations => {
                    const updatedConversations = [...prevConversations];
                    updatedConversations[updatedConversations.length - 1] = { ...newConversation };
                    return updatedConversations;
                });

                console.log("Initial Message topic:", selectedTopic)
                if (selectedTopic === -1) {
                    topicSelectionRef.current.handle_topic_generated();
                }
                setIsGenerating(false);
            } else if (response.status === 451) {
                console.log("Status indicates Contingent Reached")
                setContingentReached(true);
                setIsGenerating(false);
            } else {
                console.log("Start reading response")
                const reader = response.body.getReader();
                const decoder = new TextDecoder();

                let done = false;
                let buffer = "";
                //console.log("Reader:", reader)
                while (!done) {
                    //console.log("Done Loop")
                    const { value, done: doneReading } = await reader.read();
                    //console.log("Reading outputs:", value, done )
                    done = doneReading;
                    buffer += decoder.decode(value, { stream: !doneReading });

                    let boundary = buffer.indexOf('\n');
                    //console.log("Boundary:", boundary)
                    while (boundary !== -1) {
                        const chunkValue = buffer.slice(0, boundary);
                        buffer = buffer.slice(boundary + 1);
                        try {

                            const parsedChunk = JSON.parse(chunkValue);
                            //console.log("Pased Chunk", parsedChunk)
                            if (newConversation.answer !== null) {
                                newConversation.answer += parsedChunk.content;
                            } else {
                                newConversation.answer = parsedChunk.content;
                            }

                            const updatedConversations = [...conversations];
                            updatedConversations[updatedConversations.length - 1] = { ...newConversation, isInGeneration: !parsedChunk.done };

                            setConversations(prevConversations => {
                                const updatedConversations = [...prevConversations];
                                updatedConversations[updatedConversations.length - 1] = { ...newConversation, isInGeneration: !parsedChunk.done };
                                return updatedConversations;
                            });

                            if (parsedChunk.done) {
                                done = true;
                            }

                            if (parsedChunk.haaHints && showAnonymazationHints) {
                                console.log("Haa Hints detected");
                                setHiddenAutoAnonymizeHint(parsedChunk.haaHints);
                            }

                        } catch (e) {
                            console.log("JSON parse error:", e);
                        }
                        boundary = buffer.indexOf('\n');
                    }
                }
                //console.log("Initial Message topic:", selectedTopic)
                if (selectedTopic === -1 || conv_length_pre_query === 0) {
                    topicSelectionRef.current.handle_topic_generated();
                }
                if (user.auto_read_aloud_activated) {
                    setReadOutAloud(true)
                }
                setIsGenerating(false);                
            }
        } catch (error) {
            console.log("Error occurred while sending initial question:", error);
        }
    }

    async function send_corrected_question(correctedQueryText, chatIndex, attachments) {
        var apiLink = apiURL + "/post_updated_question";
        const newConversation = {
            "question": correctedQueryText,
            "questionSave": true,
            "questionCriticalContent": [],
            "questionSaveContent": [],
            "answer": null,
            "chatIndex": chatIndex,
            "answer_source": selectedModelID,
            "attachments": attachments,
            "textMarkingMatches": null,
            "isInGeneration": true,
        };
        setReadAloudIndex(chatIndex);

        setConversations(prevConversations => {
            const updatedConversations = [...prevConversations];
            updatedConversations[chatIndex] = { ...newConversation };
            return updatedConversations;
        });



        var response = await fetch(apiLink, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(
                {
                    "updatedQuestion": correctedQueryText,
                    "queryIndex": chatIndex,
                    "modelID": selectedModelID,
                    "topicID": selectedTopic,
                    "attachments": attachments,
                    "documentID": -1
                }
            )
        })
        if (response.status !== 200) {
            const errorData = await response.json();
            console.log(errorData);
            if (response.status === 401 && errorData.detail == "not logged in") {
                console.log("Catched expired Session");
                window.location.href = "/session_expired";
            } else {
                alert("Error: " + response.status);
            }
            setIsGenerating(false);
        } else {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            let done = false;
            let buffer = "";
            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                buffer += decoder.decode(value, { stream: !doneReading });

                let boundary = buffer.indexOf('\n');
                while (boundary !== -1) {
                    //console.log("State Conversations:", conversations)
                    //console.log("Updated Conversation", newConversation)
                    const chunkValue = buffer.slice(0, boundary);
                    buffer = buffer.slice(boundary + 1);
                    try {
                        const parsedChunk = JSON.parse(chunkValue);
                        if (newConversation.answer !== null) {
                            newConversation.answer += parsedChunk.content;
                        } else {
                            newConversation.answer = parsedChunk.content;
                        }

                        setConversations(prevConversations => {
                            const updatedConversations = [...prevConversations];
                            updatedConversations[chatIndex] = { ...newConversation, isInGeneration: !parsedChunk.done };
                            return updatedConversations;
                        });

                        if (parsedChunk.done) {
                            done = true;
                        }
                    } catch (e) {
                        console.log("JSON parse error:", e);
                    }
                    boundary = buffer.indexOf('\n');
                }
            }
            if (user.auto_read_aloud_activated) {
                setReadOutAloud(true)
            }
            setIsGenerating(false);
        }
    }

    const handleLoadOlder = () => {
        console.log("In scrollMessageLoader")
        console.log("Current visible countref current:  ", visibleCount, " conv ref current length:  ", conversations.length)
        const new_visible_number_of_messages = Math.min(visibleCount + 5, conversations.length)
        console.log("Increasing Messages rendered to:", new_visible_number_of_messages)
        setVisibleCount(new_visible_number_of_messages);
    }

    if (!user) {
        console.log("Redirectiong to login cause user is not set");
        return <Navigate to="/login" />
    }


    return (
        <div className="page-container">
            <main className="home-content">
                <div className={!isMobile ? 'topics-account-selection' : `topics-account-selection-mobile ${topicSelectionExpanded ? 'expanded' : ''}`}>
                    <TopicSelection
                        ref={topicSelectionRef}
                        onSelcetedTopicChanged={(newTopic) => {
                            //console.log("New Topic ID in Home:", newTopic);
                            setSelectedTopic(newTopic);
                            if (newTopic === -1) {
                                setConversations([]);
                            }
                        }}
                        apiURL={apiURL}
                        onLogout={() => { onLogout() }}
                        userData={user}
                        isMobile={isMobile}
                        onExpandChanged={(expanded) => {
                            console.log("Home Topic Expand Callback:", expanded)
                            setTopicSelectionExpanded(expanded);
                        }}
                    />
                </div>
                <div className='header-chat-container' style={isMobile ? { marginLeft: "40px" } : {}}>
                    <Navbar
                        isHome={true}
                        isDocChat={false}
                        availableLLMs={availableLLMs}
                        onSelectedLLMChanged={(modelID) => {
                            setSelectedModelID(modelID);
                        }}
                        availableModi={user.modelModi}
                        onSelectedModiChanged={(updatedModelModiPrompts) => {
                            console.log("Selected Model Modi Changed to:", updatedModelModiPrompts)
                            setSelectedModelModiPrompts(updatedModelModiPrompts.map((modus) => modus.prePrompt))
                        }} />
                    <div className="chat-container" >
                        <div className='chat-scroll-container' ref={chatContainerRef}>
                            <div className='chat-messages-container'>
                                {selectedTopic && conversations.length > 0 && visibleCount < conversations.length &&
                                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <div className='load-older-button' onClick={handleLoadOlder}>
                                            <div>Load older messages</div>
                                            <FontAwesomeIcon icon={faRotateRight} />
                                        </div>
                                    </div>
                                }
                                {selectedTopic && conversations.length > 0 ? conversations.slice(-visibleCount).map((conversation, index) => (
                                    <div className="chat-pair" key={'Pair- ' + index}>
                                        {conversation.questionSave ?
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <ChatEntry
                                                    apiURL={apiURL}
                                                    key={'question' + index}
                                                    source={null}
                                                    text={conversation.question}
                                                    chatIndex={index}
                                                    userData={user}
                                                    attachments={conversation.attachments}
                                                    onReuseAtachment={(attachment) => {
                                                        console.log("Recived Attachment to reuse in Home:", attachment)
                                                        console.log("Ref:", chatInputRef, " current: ", chatInputRef.current)
                                                        if (chatInputRef && chatInputRef.current) {
                                                            console.log("Calling imp handle")
                                                            chatInputRef.current.add_attachment(attachment);
                                                        }
                                                    }}
                                                    imageInputAllowed={imageInputAllowed}
                                                    replaceOptionOnSelect={false}
                                                    onWriteTextToEditor={(text) => { }}
                                                    isInGeneration={false}
                                                />
                                                {conversation.answer !== null || conversation.answer === "" ?
                                                    <ChatEntry
                                                        apiURL={apiURL}
                                                        ref={index === readAloudIndex ? readAloudAnswerRef : null}
                                                        key={'answer' + index}
                                                        source={conversation.answer_source}
                                                        text={conversation.answer}
                                                        chatIndex={index}
                                                        userData={user}
                                                        model={availableLLMs.filter(llm => llm.api_id === conversation.answer_source)[0]}
                                                        imageInputAllowed={imageInputAllowed}
                                                        replaceOptionOnSelect={false}
                                                        onWriteTextToEditor={(text) => { }}
                                                        isInGeneration={conversation.isInGeneration ? true : false}
                                                    />
                                                    :
                                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                                        <Circles
                                                            height="20"
                                                            width="20"
                                                            color="var(--text-icons)"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                        />
                                                    </div>

                                                }

                                            </div>
                                            :
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                <EditableQuestionField
                                                    conversation={conversation}
                                                    onSendCorrectedQuestion={(correctedQuestion, attachments) => {
                                                        if(!isGenerating && !contingentReached) {
                                                            setIsGenerating(true);
                                                            send_corrected_question(correctedQuestion, conversation.chatIndex, attachments);
                                                        }
                                                    }
                                                    }
                                                    api_url={apiURL}
                                                    selectedModelID={selectedModelID}
                                                    selectedTopicID={selectedTopic}
                                                    isInGeneration={isGenerating} />
                                            </div>
                                        }
                                    </div>
                                )) :
                                    //New Chat
                                    <div style={{ height: '60vh', marginTop: "30%", width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                                        <div style={{ height: '70%', display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'space-around' }}>
                                            <center>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div style={{ fontSize: '22px', fontWeight: 'bold', color: 'var(--text-icons)' }}>
                                                        New Chat
                                                    </div>
                                                </div>
                                            </center>
                                            <center>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div className='new-chat-hint-box'>
                                                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            Filter your Requests
                                                        </div>
                                                        <div style={{ fontWeight: 'bold', fontSize: '12px', color: 'var(--background-4)' }}>
                                                            Recognize privacy concerning data in your everyday chat prompts
                                                        </div>

                                                    </div>
                                                    <div className='new-chat-hint-box'>
                                                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            Select your Model
                                                        </div>
                                                        <div style={{ fontWeight: 'bold', fontSize: '12px', color: 'var(--background-4)' }}>
                                                            Configure your API Access to access one of many possible large language models
                                                        </div>

                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div className='new-chat-hint-box'>
                                                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            Keep your private data
                                                        </div>
                                                        <div style={{ fontWeight: 'bold', fontSize: '12px', color: 'var(--background-4)' }}>
                                                            Make sure, that your privacy concerning Data does not leave your IT system
                                                        </div>

                                                    </div>
                                                    <div className='new-chat-hint-box'>
                                                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            Keep Access
                                                        </div>
                                                        <div style={{ fontWeight: 'bold', fontSize: '12px', color: 'var(--background-4)' }}>
                                                            Keep Access to the latest large language models without risking the integrity of your data
                                                        </div>

                                                    </div>
                                                </div>
                                            </center>
                                        </div>

                                        <div style={{ height: '30%' }}>
                                        </div>
                                    </div>}

                                <div
                                    id='scrollDummy'
                                    ref={newestMessageBottomRef}
                                ></div>
                                {(!availableLLMs || availableLLMs.length === 0) &&
                                    <div style={{ alignItems: "center", justifyContent: "space-around", display: "flex", color: "orange" }}>
                                        <FaExclamationTriangle style={{ fontSize: "x-large", flex: "1" }} />
                                        <div style={{ textAlign: "center", flex: "3" }}> Warning no LLM access available please contact an administrator to provide access or register your own private LLM access in the settings</div>
                                    </div>
                                }
                                {availableLLMs && selectedModelID && availableLLMs.filter(llm => llm.api_id === selectedModelID).length > 0 &&
                                    <div>
                                        {!availableLLMs.filter(llm => llm.api_id === selectedModelID)[0].is_alive &&
                                            <div style={{ alignItems: "center", justifyContent: "space-around", display: "flex", color: "orange" }}>
                                                <FaExclamationTriangle style={{ fontSize: "x-large", flex: "1" }} />
                                                <div style={{ textAlign: "center", flex: "3" }}> No answer from selected model recived</div>
                                            </div>
                                        }
                                    </div>
                                }
                                {contingentReached &&
                                    <div style={{ alignItems: "center", justifyContent: "space-around", display: "flex", color: "orange" }}>
                                        <FaExclamationTriangle style={{ fontSize: "x-large", flex: "1" }} />
                                        <div style={{ textAlign: "center", flex: "3" }}> Chat contingent for Model is reached</div>
                                    </div>
                                }
                            </div>
                        </div>

                        <ChatInput
                            apiURL={apiURL}
                            ref={chatInputRef}
                            queryText={queryText}
                            onChatInputChanged={(text) => {
                                console.log("OnChatInputChanged to:", text)
                                setQueryText(text);
                            }}
                            onChatInputSubmit={(attachments) => {
                                if(!isGenerating && !contingentReached) {
                                    setIsGenerating(true);
                                    send_initial_question(attachments);
                                    setQueryText("");                                    
                                }
                            }}
                            imagesAllowed={imageInputAllowed}
                            isGenerating={isGenerating}
                        />
                        {hiddenAutoAnonymizeHint &&
                            <WarningPopUp
                                text={hiddenAutoAnonymizeHint}
                                delayHide={10000}
                                onHidePopUp={() => {
                                    setHiddenAutoAnonymizeHint(null);
                                }}
                                cssClassName='haa-warning-popup'
                            />
                        }
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Home;
import React, { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import SettingsSelection from './SettingSelection';
import AccountTile from '../../Components/AccountTile';
import Navbar from '../../Components/Navbar';
import './Settings.css'
import AccountSettings from './Account/AccountSettings';
import CompanySettings from './Company/CompanySettings';
import ColorSettings from './ColorConfig/ColorSettings';
import UserGroupSettings from './UserGroups/UserGroupSettings';
import APISettings from './LLMs/APISettings';
import ChatConfigSettings from './ChatConfig/ChatConfigSettings';
import CompanyAccountSettings from './CompanyAccounts/CompanyAccountsSettings';
import SecurityLevelSettings from './SecurityLevels/SecurityLevelSettings';
import PriceControlSettings from './PriceControl/PriceControlSettings';
import SecurityRuleSettings from './SecurityRules/SecurityRulesSettings';
import SecurityPolicysSettings from './SecurityPolicys/SecurityPolicysSettings';
import SecureDataSettings from './SecureData/SecureDataSettings';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { UserData, CompanyData, LLMAPIData, SecurityLevelData, PricingPreset, PrivacyRule, SecuredDataTable, AmbigousWord, UserGroupData } from './SettingsTypedefs';
import StatisticsSettings from './Statistics/StatisticSettings';

function SettingsSite({ onLogout, user, companyData, availableAPIs, allCompanyAPIs, apiURL, onUserDataChanged, onCompanyDataChanged, onAvailableAPIsChanged, isMobile, ...props }:
    { onLogout: Function, user: UserData, companyData: CompanyData, availableAPIs: LLMAPIData[], allCompanyAPIs: LLMAPIData[], apiURL: string, onUserDataChanged: Function, onCompanyDataChanged: Function, onAvailableAPIsChanged: Function, isMobile: boolean }
) {
    const [settingsMode, setSettingsMode] = useState("account");
    const [companyUsers, setCompanyUsers] = useState<UserData[] | null>(null);
    const [userGroups, setUserGroups] = useState<UserGroupData[]>([]);
    const [securityLevels, setSecurityLevels] = useState<SecurityLevelData[] | null>(null);
    const [pricingPresets, setPricingPresets] = useState<PricingPreset[] | null>(null);
    const [securityRules, setSecurityRules] = useState<PrivacyRule[] | null>(null);
    const [securedTables, setSecuredTables] = useState<SecuredDataTable[] | null>(null);
    const [ambigousWords, setAmbigousWords] = useState<AmbigousWord[] | null>(null);
    const [mobileSettingsSelectionExpanded, setMobileSettingsSelectionExpanded] = useState(false);

    const accountSelectionContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        console.log(settingsMode);
    }, [settingsMode])

    useEffect(() => {
        if (user && apiURL) {
            if (user.userRole <= 1) {
                fetchCompanyUsers();
                fetchSecurityLevels();
                fetchPricingPresets();
                fetchSecuredTables();
                fetchPrivacyRules();
                fetchAmbigousWords();
                fetchUserGroups();
            }
        }
    }, [user, apiURL])

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (accountSelectionContainerRef.current) {
                const rect = accountSelectionContainerRef.current.getBoundingClientRect();
                const { clientX: x, clientY: y } = event;

                // Check if click is within the bounds of the menu
                if (x < rect.left || x > rect.right || y < rect.top || y > rect.bottom) {
                    setMobileSettingsSelectionExpanded(false);
                }
            }
        }

        if (mobileSettingsSelectionExpanded) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [mobileSettingsSelectionExpanded]);

    async function fetchAmbigousWords() {
        try {
            const response = await fetch(
                apiURL + "/get_ambigous_words",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log("Ambigous words:", data.ambigous_words)
                setAmbigousWords(data.ambigous_words)
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function fetchUserGroups() {
        if (apiURL) {
            try {
                const response = await fetch(
                    apiURL + "/get_user_groups",
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                if (response.status !== 200) {
                    console.log("Response status: " + response.status);
                } else {
                    const data = await response.json();
                    setUserGroups(data.user_groups);
                }
            } catch (error) {
                console.log(error);
                //alert("Error: " + error);
            }
        }
    }

    async function fetchPricingPresets() {
        try {
            const response = await fetch(
                apiURL + "/get_all_price_presets",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log("Pricing Presets:", data.presets)
                setPricingPresets(data.presets)
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function fetchSecurityLevels() {
        try {
            const response = await fetch(
                apiURL + "/get_security_levels",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log("Security Levels:", data)
                setSecurityLevels(data.sort((a: SecurityLevelData, b: SecurityLevelData) => a.security_level - b.security_level))
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function fetchCompanyUsers() {
        if (apiURL) {
            try {
                const response = await fetch(
                    apiURL + "/get_company_users",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                if (response.status !== 200) {
                    console.log("Response status: " + response.status);
                } else {
                    const data = await response.json();
                    console.log("Company Users:", data.users)
                    setCompanyUsers(data.users.sort((a: UserData, b: UserData) => a.userID - b.userID))
                }
            } catch (error) {
                console.log(error);
                //alert("Error: " + error);
            }
        }
    }

    async function fetchSecuredTables() {
        try {
            const response = await fetch(
                apiURL + "/get_secured_data_tables",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log("Secured tables:", data.secured_data_tables)
                setSecuredTables(data.secured_data_tables)
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function fetchPrivacyRules() {
        try {
            const response = await fetch(
                apiURL + "/get_privacy_rules",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log("Pre Sortded:", data)
                console.log("Sorted:", data.sort((a: PrivacyRule, b: PrivacyRule) => a.id! - b.id!))
                setSecurityRules(data.sort((a: PrivacyRule, b: PrivacyRule) => a.id! - b.id!));
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    if (!user) {
        console.log("Redirectiong to login cause user is not set");
        return <Navigate to="/login" />
    }

    return (
        <div className="page-container">
            <main className="settings-content">
                <div className={`settings-account-selection ${isMobile ? "mobile " : ""} ${mobileSettingsSelectionExpanded ? "expanded" : ""}`}
                    ref={accountSelectionContainerRef}>
                    <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <div className="settings-selection-container">
                                <SettingsSelection
                                    user={user}
                                    onSettingsModeChanged={(newMode: string) => {
                                        setSettingsMode(newMode);
                                    }}
                                    settingsMode={settingsMode} />
                            </div>
                            <div className="settings-account-tile-container">
                                <AccountTile userData={user} onLogout={() => {
                                    onLogout();
                                }}
                                    isSettingsContext={true} />
                            </div>
                        </div>
                        {isMobile &&
                            <div className="expand-topic-selection-button"
                                onClick={() => {
                                    setMobileSettingsSelectionExpanded(!mobileSettingsSelectionExpanded);
                                }}
                            >
                                {
                                    mobileSettingsSelectionExpanded ?
                                        <FaAngleLeft size={"larger"} style={{ color: "var(--text-icons)" }} />
                                        :
                                        <FaAngleRight size={"larger"} style={{ color: "var(--text-icons)" }} />
                                }
                            </div>
                        }
                    </div>

                </div>
                <div className='settings-content-container' style={isMobile ? { marginLeft: "30px" } : {}}>
                    <Navbar isHome={false}
                        availableLLMs={[]}
                        availableModi={[]}
                        onSelectedLLMChanged={() => { }}
                        onSelectedModiChanged={() => { }}
                    />
                    {settingsMode === "account" ?
                        <AccountSettings
                            userData={user}
                            companyData={companyData}
                            apiURL={apiURL}
                            onUserDataChanged={() => {
                                onUserDataChanged();
                            }}
                            availableLLMs={availableAPIs}
                            isMobile={isMobile} /> :
                        settingsMode === "company" ?
                            <CompanySettings
                                userData={user}
                                companyData={companyData}
                                companyUsers={companyUsers}
                                onCompanyDataChanged={() => {
                                    onCompanyDataChanged();
                                    fetchAmbigousWords();
                                }}
                                apiURL={apiURL}
                                isMobile={isMobile} /> :
                            settingsMode === "company_accounts" ?
                                <CompanyAccountSettings
                                    userData={user}
                                    apiURL={apiURL}
                                    companyLLMs={allCompanyAPIs}
                                    companyData={companyData}
                                    companyUsers={companyUsers}
                                    onCompanyUsersChanged={() => {
                                        setTimeout(() => {
                                            fetchCompanyUsers();
                                            onAvailableAPIsChanged();
                                        }, 200)
                                    }}
                                    pricingPresetOptions={pricingPresets}
                                    isMobile={isMobile}
                                /> :
                                settingsMode === "user_groups" ?
                                    <UserGroupSettings
                                        apiURL={apiURL}
                                        userData={user}
                                        userGroups={userGroups}
                                        companyLLMs={allCompanyAPIs}
                                        companyUsers={companyUsers ? companyUsers : []}
                                        securityLevels={securityLevels ? securityLevels : []}
                                        pricingPresetOptions={pricingPresets ? pricingPresets : []}
                                        onUserGroupsChanged={() => {
                                            fetchUserGroups();
                                            // Its possible the API Access of the surrent account was edited over User Groups
                                            onAvailableAPIsChanged();
                                        }}
                                        isMobile={isMobile}
                                    /> :
                                    settingsMode === "api" ?
                                        <APISettings
                                            userData={user}
                                            companyLLMs={allCompanyAPIs}
                                            personalLLMs={availableAPIs ? availableAPIs.filter(llm => !llm.is_company_api) : []}
                                            onAvailableAPIsChanged={() => {
                                                onAvailableAPIsChanged();
                                                // Its possible the group API access changed because of deleted API
                                                fetchUserGroups();
                                            }}
                                            apiURL={apiURL}
                                            securityLevels={securityLevels}
                                            isMobile={isMobile}
                                        /> :
                                        settingsMode === "price_contol" ?
                                            <PriceControlSettings
                                                apiURL={apiURL}
                                                pricingPresets={pricingPresets ? pricingPresets : []}
                                                onPricingPresetsChanged={() => {
                                                    fetchPricingPresets();
                                                }}
                                                isMobile={isMobile}
                                            /> :
                                            settingsMode === "secure_data" ?
                                                <SecureDataSettings
                                                    apiURL={apiURL}
                                                    securedTables={securedTables}
                                                    securityRules={securityRules ? securityRules : []}
                                                    onSecureDataChanged={() => {
                                                        fetchSecuredTables();
                                                        fetchPrivacyRules();
                                                        fetchAmbigousWords();
                                                    }}
                                                    isMobile={isMobile}
                                                    ambigousWords={ambigousWords ? ambigousWords : []}
                                                    onAmbigousWordsChanged={() => {
                                                        fetchAmbigousWords();
                                                    }}
                                                /> :
                                                settingsMode === "security_levels" ?
                                                    <SecurityLevelSettings
                                                        apiURL={apiURL}
                                                        securityLevels={securityLevels ? securityLevels : []}
                                                        onSecurityLevelsChanged={() => {
                                                            fetchSecurityLevels();
                                                        }}
                                                        isMobile={isMobile}
                                                    /> :
                                                    settingsMode === "security_rules" ?
                                                        <SecurityRuleSettings
                                                            apiURL={apiURL}
                                                            securityRules={securityRules ? securityRules : []}
                                                            securedTables={securedTables ? securedTables : []}
                                                            onSecurityRulesChanged={() => {
                                                                fetchPrivacyRules();
                                                                fetchSecurityLevels();
                                                                fetchAmbigousWords();
                                                            }
                                                            }
                                                            isMobile={isMobile}
                                                        /> :
                                                        settingsMode === "security_policys" ?
                                                            <SecurityPolicysSettings
                                                                apiURL={apiURL}
                                                                onSecurityPolicysChanged={fetchSecurityLevels}
                                                                isMobile={isMobile}
                                                            /> :

                                                            settingsMode === "statistics" ?
                                                                <StatisticsSettings
                                                                    apiURL={apiURL}
                                                                    companyUsers={companyUsers ? companyUsers : []}
                                                                    userGroups={userGroups ? userGroups : []}
                                                                    rules={securityRules ? securityRules : []}
                                                                    isMobile={isMobile}
                                                                /> :
                                                                settingsMode === "color" ?
                                                                    <ColorSettings
                                                                    /> :
                                                                    settingsMode === "chat" ?
                                                                        <ChatConfigSettings
                                                                            userData={user}
                                                                            availableLLMs={availableAPIs}
                                                                            apiURL={apiURL}
                                                                            isMobile={isMobile}
                                                                            onModelModiChanged={() => {
                                                                                onUserDataChanged();
                                                                            }} /> :
                                                                        <></>}
                </div>
            </main>
        </div>
    );
}

export default SettingsSite;
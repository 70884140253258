import { FaKey, FaTimes, FaTrash, FaInfoCircle, FaUpload } from "react-icons/fa";
import { useRef, useState } from "react";
import Switch from 'react-switch';
import LLMContingentTile from "./LLMContingentTile"
import { Tooltip } from 'react-tooltip';

export default function AccountSettings({ userData, companyData, apiURL, onUserDataChanged, availableLLMs, isMobile }) {
    const [changePasswordActive, setChangePasswordActive] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    const [hintText, setHintText] = useState(null);
    const fileInputRef = useRef(null)



    async function update2FaAuthSetting(updated2FaAuthSetting) {
        var apiLink = apiURL + "/update_2_fa_setting";
        try {
            const response = await fetch(apiLink + "?new_2_fa_setting=" + updated2FaAuthSetting, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                console.log("update2FaAuthSetting Success");
                onUserDataChanged();
            }
        } catch (error) {
            console.log("Error occurred while updating 2 Fa setting:", error);
        }
    }

    async function updateAutoAudio(updatedAutoAudio) {
        var apiLink = apiURL + "/update_auto_audio";
        try {
            const response = await fetch(apiLink + "?updatedAutoAudio=" + updatedAutoAudio, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                console.log("update2FaAuthSetting Success");
                onUserDataChanged();
            }
        } catch (error) {
            console.log("Error occurred while updating 2 Fa setting:", error);
        }
    }

    async function updatePassword() {
        const apiLinkPasswordVerify = apiURL + "/login";
        const apiLinkPasswordUpdate = apiURL + "/updatepassword";
        var user_model_verify = {
            "email": userData.email,
            "password": oldPassword
        };
        var user_model_update = {
            "email": userData.email,
            "password": newPassword
        };
        try {
            const response = await fetch(apiLinkPasswordVerify, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify(user_model_verify)
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                setHintText("Verification of old password failed")
            } else {
                console.log("updatePassword verify old Success");
                const update_response = await fetch(apiLinkPasswordUpdate, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(user_model_update)
                });
                if (update_response.status !== 200) {
                    const errorData = await response.json();
                    console.log(errorData);
                    alert("Error: " + response.status);
                } else {
                    console.log("Password update Success");
                    setHintText(null);
                    setChangePasswordActive(false);
                }
            }
        } catch (error) {
            console.log("Error occurred while updating password:", error);
        }
    }

    async function deleteAccount() {
        try {
            const response = await fetch(apiURL + "/delete_account?userID=" + userData.userID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                localStorage.removeItem("token");
                window.location.href = "/impressum"
            }
        } catch (error) {
            console.log("Error occurred while updating 2 Fa setting:", error);
        }
    }


    function checkNewPasswordValid() {
        setHintText(null);
        if (newPassword.length < 8) {
            setHintText("Password needs to be a minimum of 8 characters long.")
        } else if (newPassword !== newPasswordRepeat) {
            setHintText("Passwords are not equal")
            return false
        } else {
            return true
        }
    }

    async function postNewTemplateFile(file) {
        try {
            const formData = new FormData();
            formData.append('templateFile', file);
            const response = await fetch(
                apiURL + "/upload_template_file",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: formData,
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                onUserDataChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function deleteTemplateFile(fileName) {
        try {
            const response = await fetch(
                apiURL + "/delete_template_file?fileName=" + fileName,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                onUserDataChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    const handleFileInputChange = (event) => {
        console.log("Handle File input Change")
        if (event.target.files && event.target.files.length === 1) {
            postNewTemplateFile(event.target.files[0])
        } else {
            console.log("ToDO Set Info Text Here")
        }
        event.target.value = '';
    }


    return (
        <div className="subsetting-content">
            <center><h4>Account Settings</h4></center>
            {/* Account Data and Preferences */}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}>
                <div style={isMobile ? { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: "95%" } :
                    { display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: "95%", alignItems: "center" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: '2' }}>
                        <center><div className={`settings-subheader ${isMobile ? "mobile" : ""}`}> Your Account Data</div></center>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '5px', justifyContent: "space-between", alignItems: "flex-start" }}>
                            <div style={{ flex: "1" }}>
                                Name:
                            </div>
                            <div style={{ flex: "2", justifyContent: "flex-start", alignItems: "flex-start", wordBreak: "break-word" }}>
                                {userData.name}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '5px', justifyContent: "space-between", alignItems: "flex-start" }}>
                            <div style={{ flex: "1" }}>
                                Email:
                            </div>
                            <div style={{ flex: "2", justifyContent: "flex-start", alignItems: "flex-start", wordBreak: "break-all" }}>
                                {userData.email}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '5px', justifyContent: "space-between", alignItems: "flex-start" }}>
                            <div style={{ flex: "1" }}>
                                Role:
                            </div>
                            <div style={{ flex: "2", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                {userData.userRole === 1 ? "Admin" : "User"}
                            </div>
                        </div>
                    </div>

                    <div style={isMobile ? { flex: "2" } : { flex: "2", marginLeft: "20px" }}>
                        <center><div className={`settings-subheader ${isMobile ? "mobile" : ""}`}>Account Preferences</div></center>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'row', margin: '5px', justifyContent: "space-between", alignItems: "flex-start" } :
                            { display: 'flex', flexDirection: 'row', margin: '15px', justifyContent: "space-between", alignItems: "flex-start" }}>
                            <div style={{ flex: "2" }}>2 Factor Authentification</div>
                            <div style={{ flex: "1", marginRight: "5px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <div style={{ marginRight: "5px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <Switch
                                        checked={userData.otp_activated}
                                        onChange={() => {
                                            console.log("User 2 FA Auth Changed to:")
                                            update2FaAuthSetting(!userData.otp_activated);
                                        }}

                                    />
                                </div>
                                <div data-tooltip-id="LLMSecLvlTooltip" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="LLMSecLvlTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            You can save your preference if you want to use two factor authentification for the login here. This will be only relevant, if your company does not make this mandatory.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'row', margin: '5px', justifyContent: "space-between", alignItems: "flex-start" } :
                            { display: 'flex', flexDirection: 'row', margin: '15px', justifyContent: "space-between", alignItems: "flex-start" }}>
                            <div style={{ flex: "2" }}>Instant audio Play</div>
                            <div style={{ flex: "1", marginRight: "5px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <div style={{ marginRight: "5px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <Switch
                                        checked={userData.auto_read_aloud_activated}
                                        onChange={() => {
                                            console.log("User 2 FA Auth Changed to:")
                                            updateAutoAudio(!userData.auto_read_aloud_activated);
                                        }}

                                    />
                                </div>
                                <div data-tooltip-id="AutoAudioTooltip" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="AutoAudioTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            If activated the recived Messages from the Large Language Models will be converted to an Audio which will be played once recived. If not activated you can still get the audio with clicking the file to audio icon.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        {companyData.twoFaMandatory && <div>
                            User preference will be overwritten by company policy
                        </div>}
                    </div>

                </div>
            </div>

            {/* Delete Account and Change password */}
            {!changePasswordActive ?
                <div style={!isMobile ? { display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center" } :
                    { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                        onClick={() => {
                            setChangePasswordActive(true);
                        }}
                    >
                        <FaKey style={{ marginRight: '5px' }} />
                        Change your password
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div className={`settings-button ${isMobile ? "mobile" : ""}`} style={companyData.pp_admin_mail === userData.email ? { color: "gray", borderColor: "gray" } : { color: "red", borderColor: "red", alignSelf: "flex-start" }}
                            onClick={companyData.pp_admin_mail === userData.email ? () => { } :
                                () => {
                                    deleteAccount();
                                }}
                        >
                            <FaTrash style={{ marginRight: '5px', }} />
                            Delete Account
                        </div>
                        {companyData.pp_admin_mail === userData.email &&
                            <div style={isMobile ? { fontSize: "10px", maxWidth: "80%", textAlign: "center" } :
                                { fontSize: "10px", maxWidth: "20vw", textAlign: "center" }}>
                                The currently registered company admin can not delete his account. Please change the company admin to delete this account.
                            </div>
                        }
                    </div>
                </div>
                :
                <div className="change-password-container">
                    <div style={!isMobile ? { display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' } :
                        { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                        <div className="password-input-column">
                            Old Password
                            <input
                                type="password"
                                className="settings-input"
                                value={oldPassword}
                                onChange={(e) => {
                                    setOldPassword(e.target.value);
                                }}></input>
                        </div>
                        <div className="password-input-column">
                            New Password
                            <input
                                type="password"
                                className="settings-input"
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                }}></input>
                            Repeat new Password
                            <input
                                type="password"
                                className="settings-input"
                                value={newPasswordRepeat}
                                onChange={(e) => {
                                    setNewPasswordRepeat(e.target.value);
                                }}></input>
                            {hintText &&
                                <div style={{ fontSize: '12px', color: 'red' }}>{hintText}</div>}
                        </div>
                    </div>
                    <div style={isMobile ? { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" } :
                        { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                            onClick={() => {
                                setChangePasswordActive(false)
                            }}
                        >
                            <FaTimes style={{ marginRight: '5px', color: "red" }} />
                            Cancel
                        </div>
                        <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                            onClick={() => {
                                if (checkNewPasswordValid()) {
                                    updatePassword();
                                }
                            }}
                        >
                            <FaKey style={{ marginRight: '5px' }} />
                            Confirm password change
                        </div>
                    </div>
                </div>
            }

            {/* Template Upload */}
            <div style={isMobile ? { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "10px" } :
                { display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "10px" }}>
                {userData.template_files &&
                    <div style={isMobile ? { display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", border: "solid 1px var(--text-icons-3)", padding: "10px", borderRadius: "10px", width: "80%" } :
                        { display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", border: "solid 1px var(--text-icons-3)", padding: "10px", borderRadius: "10px", width: "40%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div className={`settings-subheader ${isMobile ? "mobile" : ""}`}>Your Template Files</div>
                            <div data-tooltip-id="TemplatesTooltip" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <FaInfoCircle style={{ marginLeft: "5px" }} />
                                <Tooltip id="TemplatesTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        You can directly download your questions or the Models answers into a .docx template if you provide a template. Inside the there has to be a paragraph containing the text "TOREPLACE" which will be replaced.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        {userData.template_files.map((template, index) =>
                            <div
                                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginBottom: "5px" }}
                                key={"Template" + index}>
                                <div>
                                    {template}
                                </div>
                                <FaTrash style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                        deleteTemplateFile(template)
                                    }}
                                />

                            </div>
                        )}
                    </div>
                }
                <div className={`settings-button ${isMobile ? "mobile" : ""}`}
                    data-tooltip-id="UploadTooltip"
                    onClick={() => {
                        if (fileInputRef.current) {
                            fileInputRef.current.click();
                        }
                    }}
                >
                    <FaUpload style={{ marginRight: '5px' }} />
                    Upload Template File
                </div>
                <Tooltip id="UploadTooltip" className="custom-tooltip" delayShow={800}>
                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                        Here you can upload your template files. Only .docx files are allowed as file format.
                    </div>
                </Tooltip>
            </div>

            {/* Model Access Display and Contingents */}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <div style={{ margin: "15px", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <div className={`settings-subheader ${isMobile ? "mobile" : ""}`}>Your Languange Model Access and Contingents</div>
                    <div data-tooltip-id="LLMContinentTooltip" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <FaInfoCircle style={{ marginLeft: "5px" }} />
                        <Tooltip id="LLMContinentTooltip" className="custom-tooltip">
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                Here you can see the Large Language Model APIs that were unlocked for you. Group contingents are used first, then the personal contingent. If there is any unrestrained access the contingents wont be touched.
                            </div>
                        </Tooltip>
                    </div>
                </div>
                {availableLLMs.map((llm) => (
                    <LLMContingentTile
                        key={"Contingents" + llm.api_id}
                        apiURL={apiURL}
                        llmData={llm}
                    />
                ))}
            </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".docx"
                onChange={handleFileInputChange}
            />
        </div>
    );
}

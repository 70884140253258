import React, { useState } from 'react';
import PrivcyPromptLogo from './../../assets/privacyprompt.svg'
import './Login.css'
function LoginSite({ onPasswordForgotten, onRegisterButtonPressed, onLoginSuceeded, companyData, apiURL }) {
    const [email, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState(""); // Add state for error text
    const [failedlogin, setFailedLogin] = useState(false);

    async function login() {
        console.log("Trying User Login");
        const userData = {
            'email': email,
            'password': password,
        };
        try {
            const response = await fetch(apiURL + '/login', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(userData)
            });
            const data = await response.json();
            if (response.status === 200) {
                // API call succeeded (status code 200)
                console.log(JSON.stringify(data));
                console.log("Succes Respone from Login API");
                console.log(data.user);
                if (data.token) {
                    localStorage.setItem("token", data.token);
                } else {
                    localStorage.removeItem("token");
                }
                console.log("Comparing with password hash Succes");
                setTimeout(()=>{onLoginSuceeded(data.user);}, 200)
            } else {
                setFailedLogin(true);
                // API call failed
                const detail = data.detail;
                console.log("No Succes from API Response");
                if (Array.isArray(detail)) {
                    const errorMessages = detail.map((info) => info.msg);
                    setErrorText(errorMessages.join(" "));
                } else {
                    setErrorText(data.detail);
                } // Display the error message from the server
            }
        } catch (error) {
            setFailedLogin(true);
            // Handle any unexpected errors (e.g., network error)
            setErrorText("Error occured during processing of login Data");
        }
    }

    return (
        <div className='page-container'>
            <div className='login-content'>
                <img src={PrivcyPromptLogo} alt="PrivacyPrompt Logo" style={{ maxHeight: '20vh', maxWidth: '20vw' }} />
                <h3>Email</h3>
                <input
                    className='login-input'
                    type='text'
                    placeholder='email'
                    value={email}
                    onChange={(e) => setUserEmail(e.target.value)}
                />
                <h3>Password</h3>
                <input
                    className='login-input'
                    type='password'
                    placeholder='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            login();
                        }
                    }}
                />
                {errorText && <p>{errorText}</p>} {/* Display error text if available */}
                <button className="login-button" onClick={login}>
                    Login
                </button>
                { companyData && companyData.allowRegistration &&
                <button className="login-button" onClick={onRegisterButtonPressed}>
                    Register
                </button>}
                {failedlogin ?
                    <button className="login-button" onClick={onPasswordForgotten}>
                        Forgot Password
                    </button> : <div></div>}
            </div>
        </div>
    );

}

export default LoginSite;

import { useEffect, useRef, useState } from 'react';
import { FaFolderOpen, FaUserFriends, FaPlusCircle, FaTrash, FaSearch, FaUpload, FaDownload } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCirclePlus, faGlasses, faPenToSquare, faTimes, faUserPlus, faCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import './DocumentChat.css';
import { ChatDocument, UserInfoModel, UserData, UserDocumentInfo, GroupDocumentInfo, ConnectedClient, UserGroupData } from '../Settings/SettingsTypedefs';
import { Tooltip } from 'react-tooltip';

function DocumentsControlPanel({ user,
    apiURL,
    isMobile,
    companyUsers,
    onSelectedDocumentChanged,
    isConnected,
    connectedClients,
    userGroups,
    onDownloadButtonClicked,
    availableDocuments,
    onAvailableDocumentsChanged,
    onDocumentMetaDataChanged
}: {
    user: UserData | null,
    apiURL: string,
    isMobile: boolean,
    companyUsers: UserInfoModel[],
    onSelectedDocumentChanged: (docID: number) => void,
    isConnected: Boolean,
    connectedClients: ConnectedClient[],
    userGroups: UserGroupData[]
    onDownloadButtonClicked: (fileType: string, templateName: string | null) => void,
    availableDocuments: ChatDocument[] | null,
    onAvailableDocumentsChanged: () => void,
    onDocumentMetaDataChanged: (updatedDocument: ChatDocument) => void;
}) {

    const [selectedDocument, setSelectedDocument] = useState<ChatDocument | null>(null);
    const [subcontrolPanelMode, setSubControlPanelMode] = useState<string | null>(null);

    const openDocControllButtonRef = useRef<HTMLDivElement | null>(null);
    const addDocControllButtonRef = useRef<HTMLDivElement | null>(null);
    const accessDocControllButtonRef = useRef<HTMLDivElement | null>(null);
    const downloadDocControllButtonRef = useRef<HTMLDivElement | null>(null);

    const openDocSubControllRef = useRef<HTMLDivElement | null>(null);
    const addDocSubControllRef = useRef<HTMLDivElement | null>(null);
    const accessDocSubControllRef = useRef<HTMLDivElement | null>(null);
    const downloadDocSubControllRef = useRef<HTMLDivElement | null>(null);

    const [newDocumentName, setNewDocumentName] = useState<string>("");

    const panelRef = useRef<HTMLDivElement | null>(null);

    const [filtertext, setFiltertext] = useState<string>("");
    const [filteredCompanyUsers, setFilteredCompanyUsers] = useState<UserInfoModel[] | null>(null);
    const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroupData[] | null>(null);

    const [displayAccessControl, setDisplayAccessControl] = useState(false)
    const uploadFileInputRef = useRef<HTMLInputElement | null>(null);

    const userColors = [
        0xFF5733,  // Bright Red
        0x33A1FF,  // Sky Blue
        0xFFC300,  // Golden Yellow
        0x8D33FF,  // Vivid Purple
        0x28A745,  // Bright Green
        0xFF33A6,  // Hot Pink
        0x33FF57,  // Lime Green
        0xFF914D,  // Orange
        0x33FFE0,  // Aquamarine
        0xFF338B,  // Magenta
        0xFFD633,  // Mustard
        0x3358FF,  // Bright Blue
        0xFF5733,  // Coral
        0xFF33C4,  // Fuchsia
        0x33FFB5   // Mint Green
    ];


    {/* Filtering of Users/Groups in Access Subcontrol Panel */ }
    useEffect(() => {
        if (filtertext === "") {
            setFilteredCompanyUsers(companyUsers);
            setFilteredUserGroups(userGroups);
        } else {
            setFilteredCompanyUsers(companyUsers.filter(cuser => cuser.name.includes(filtertext) || cuser.email.includes(filtertext)));
            setFilteredUserGroups(userGroups.filter(fgroup => fgroup.groupName.includes(filtertext)));
        }
    }, [filtertext, companyUsers, userGroups])

    {/* Check if Docs available for User else Post new for functioning UI */ }
    useEffect(() => {
        console.log("In available docs UseEffect")
        if (availableDocuments && user) {
            const previous_documentID = Number(localStorage.getItem("selectedDocumentID"))
            if (availableDocuments.length === 0) {
                const newDocumentInfo = {
                    "documentID": -1,
                    "documentName": "New Document",
                    "textContent": "",
                    "xmlContent": "",
                    "ownerID": user!.userID,
                    "ownerName": user!.name,
                    "usersWithAccess": [],
                    "groupsWithAccess": []
                }
                postNewDocument(newDocumentInfo)
            } else {
                {/* Select current document chek for previous in LocalStorage*/ }
                if (!selectedDocument) {
                    if (previous_documentID) {
                        console.log("Checking with previously selcted ID:", previous_documentID)
                        const filteredAvailable = availableDocuments.filter(doc => doc.documentID === previous_documentID)
                        if (filteredAvailable.length > 0) {
                            setSelectedDocument(filteredAvailable[0]);
                            onSelectedDocumentChanged(filteredAvailable[0].documentID);
                        } else {
                            if (availableDocuments.length > 0) {
                                setSelectedDocument(availableDocuments[0]);
                                onSelectedDocumentChanged(availableDocuments[0].documentID);
                            }
                        }
                    } else {
                        console.log("No Previous Selected Doc found in localstorage")
                        setSelectedDocument(availableDocuments[0]);
                        onSelectedDocumentChanged(availableDocuments[0].documentID)
                    }
                } else {
                    console.log("Using existing selected Document ID")
                    const prev_doc = availableDocuments.filter(doc => doc.documentID === selectedDocument.documentID).length > 0 ? availableDocuments.filter(doc => doc.documentID === selectedDocument.documentID)[0] : null
                    if (prev_doc) {
                        setSelectedDocument(prev_doc);
                        onSelectedDocumentChanged(prev_doc.documentID);
                    }
                }
            }
        }
    }, [availableDocuments, user])


    {/* Eventlistener on opened Subcontroll Panels to close them */ }
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            let current_open_sub_controllref = openDocSubControllRef.current ? openDocSubControllRef.current :
                addDocSubControllRef.current ? addDocSubControllRef.current :
                    accessDocSubControllRef.current ? accessDocSubControllRef.current :
                        downloadDocSubControllRef.current ? downloadDocSubControllRef.current : null

            if (
                current_open_sub_controllref &&
                !current_open_sub_controllref.contains(event.target as Node) &&
                panelRef.current &&
                !panelRef.current.contains(event.target as Node)
            ) {
                setSubControlPanelMode(null);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDocSubControllRef, addDocSubControllRef, accessDocSubControllRef, downloadDocSubControllRef]);

    useEffect(() => {
        console.log("Adjust Subcontroll Menu Position in UseEffect for ", subcontrolPanelMode)
        correctSubControllPanelPosition();
    }, [subcontrolPanelMode]);

    {/* Check of user invite Rigths */ }
    useEffect(() => {
        if (selectedDocument && user) {
            if (selectedDocument.ownerID === user.userID) {
                setDisplayAccessControl(true)
                return
            } else {
                if (selectedDocument.usersWithAccess) {
                    const userAccessUnsafe = selectedDocument.usersWithAccess.filter(uwa => uwa.userID === user.userID)
                    if (userAccessUnsafe.length > 0) {
                        const userAccess = userAccessUnsafe[0];
                        if (userAccess.allowInvite) {
                            setDisplayAccessControl(true);
                            return
                        } else {
                            setDisplayAccessControl(false);
                            return
                        }
                    }
                }
            }
        }
        if (subcontrolPanelMode === "docselect") {
            correctSubControllPanelPosition();
        }
        // Default if no return
        setDisplayAccessControl(false);
    }, [selectedDocument, user])

    async function correctSubControllPanelPosition() {
        let current_open_sub_controllref =
            subcontrolPanelMode === "docselect" ? openDocSubControllRef.current :
                subcontrolPanelMode === "adddoc" ? addDocSubControllRef.current :
                    subcontrolPanelMode === "accessselect" ? accessDocSubControllRef.current :
                        subcontrolPanelMode === "download" ? downloadDocSubControllRef.current : null;
        let current_clicked_button =
            subcontrolPanelMode === "docselect" ? openDocControllButtonRef.current :
                subcontrolPanelMode === "adddoc" ? addDocControllButtonRef.current :
                    subcontrolPanelMode === "accessselect" ? accessDocControllButtonRef.current :
                        subcontrolPanelMode === "download" ? downloadDocControllButtonRef.current : null;
        const panel = panelRef.current
        if (current_clicked_button && current_open_sub_controllref && panel) {
            const buttonRect = current_clicked_button.getBoundingClientRect();
            const panelRect = panel.getBoundingClientRect();
            const viewportWidth = window.innerWidth;
            const initial_top = buttonRect.height + 10;
            var initial_left = buttonRect.x - panelRect.x;
            current_open_sub_controllref.style.top = `${initial_top}px`;
            current_open_sub_controllref.style.left = `${initial_left}px`;
            const intervalId = setInterval(() => {
                if (subcontrolPanelMode && current_open_sub_controllref && current_clicked_button) {
                    const panelSubRect = current_open_sub_controllref.getBoundingClientRect();
                    if (panelSubRect.right > viewportWidth) {
                        const correctedLeft = initial_left - (panelSubRect.right - viewportWidth + 10);
                        current_open_sub_controllref.style.left = `${correctedLeft}px`;
                        initial_left = correctedLeft;
                    }
                }
            }, 50);
            setTimeout(() => {
                clearInterval(intervalId);
                console.log("Final Position:", current_open_sub_controllref!.style.left);
            }, 900);
        }
    }

    async function postNewDocument(newDocumentInfo: ChatDocument) {
        var apiLink = apiURL + "/add_new_document";
        try {
            const response = await fetch(apiLink, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify(newDocumentInfo)
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                // Setting with Zero is neccessary for instant selection of created Document
                setSelectedDocument(null);
                const data = await response.json();
                localStorage.setItem("selectedDocumentID", data.documentID.toString())
                onAvailableDocumentsChanged();
                onSelectedDocumentChanged(data.documentID)
            }
        } catch (error) {
            console.log("Error occurred while fetching conversation data:", error);
        }
    }

    async function postDeleteDocument(docID: number) {
        var apiLink = apiURL + "/delete_document?docID=" + docID;
        try {
            const response = await fetch(apiLink, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const data = await response.json();
                onAvailableDocumentsChanged();
                if (docID === selectedDocument?.documentID) {
                    setSelectedDocument(null)
                }
            }
            correctSubControllPanelPosition();
        } catch (error) {
            console.log("Error occurred while fetching conversation data:", error);
        }
    }

    async function postNewDocumentsFromFileUpload(file: File) {
        console.log("Posting file for creation of new doc")
        var apiLink = apiURL + "/create_new_document_from_file_upload";
        try {
            const formData = new FormData();
            formData.append("file", file);
            const response = await fetch(apiLink, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formData
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                setSelectedDocument(null);
                const data = await response.json();
                localStorage.setItem("selectedDocumentID", data.documentID.toString())
                onAvailableDocumentsChanged();
                onSelectedDocumentChanged(data.documentID)
            }
        } catch (error) {
            console.log("Error occurred while fetching conversation data:", error);
        }
    }


    async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const fileInput = event.target;
        const file = fileInput.files?.[0];
        if (file) {
            await postNewDocumentsFromFileUpload(file);
        }
        fileInput.value = '';
    }

    return (
        <div ref={panelRef} className={`doc-controll-panel row`}>
            {/* Document Name */}
            <input
                className='doc-controll-panel-input'
                id='documentNameInput'
                type='text'
                value={selectedDocument ? selectedDocument.documentName : 'No Document Selected'}
                onChange={(e) => {
                    if (selectedDocument) {
                        const updatedDocumentInfo = {
                            ...selectedDocument,
                            documentName: e.target.value,
                        };
                        setSelectedDocument(updatedDocumentInfo);
                        //postUpdateDocumentMetaData(updatedDocumentInfo);
                        onDocumentMetaDataChanged(updatedDocumentInfo);
                    }
                }}
            />
            <div className='doc-controll-panel-button'
                ref={openDocControllButtonRef}
                onClick={() => { setSubControlPanelMode("docselect") }}>
                <FaFolderOpen />
            </div>
            {/* Subcontrol Panel Open Document */}
            {availableDocuments !== null && selectedDocument &&
                <div className={`doc-subcontroll-panel ${subcontrolPanelMode === "docselect" ? 'active' : 'hidden'} openDocRow`} ref={openDocSubControllRef}>
                    <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>Your Documents</div>
                    {availableDocuments!.filter(doc => doc.ownerID === user?.userID).map(doc =>
                        <div key={"owndoc" + doc.documentID} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", flex: "1" }}>
                            <div className={`subcontroll-doc-select-doc-tile ${doc.documentID === selectedDocument!.documentID ? 'selected' : ''}`}
                                onClick={() => {
                                    setSelectedDocument(doc)
                                    onSelectedDocumentChanged(doc.documentID);
                                    localStorage.setItem("selectedDocumentID", doc.documentID.toString())
                                }}>
                                <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    {doc.documentName}
                                </div>

                            </div>
                            {/* Delete Button */}
                            <div style={{ flex: "1", display: "flex", justifyContent: "center", alignItems: "center", height: "100%", cursor: "pointer" }}
                                onClick={() => {
                                    postDeleteDocument(doc.documentID)
                                }}
                            >
                                <FaTrash style={{ marginLeft: "5px", color: "red" }} />
                            </div>
                        </div>
                    )}
                    {
                        availableDocuments!.filter(doc => doc.ownerID !== user?.userID).length > 0 &&
                        <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>Company Documents</div>
                    }
                    {availableDocuments!.filter(doc => doc.ownerID !== user?.userID).map(cdoc =>
                        <div key={"acessdoc" + cdoc.documentID} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", flex: "1" }}>
                            <div className={`subcontroll-doc-select-doc-tile ${cdoc.documentID === selectedDocument!.documentID ? 'selected' : ''}`}
                                onClick={() => {
                                    setSelectedDocument(cdoc)
                                    onSelectedDocumentChanged(cdoc.documentID);
                                    localStorage.setItem("selectedDocumentID", cdoc.documentID.toString());
                                }}>
                                <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    {cdoc.documentName}
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            }
            <div className='doc-controll-panel-button'
                ref={addDocControllButtonRef}
                onClick={() => { setSubControlPanelMode("adddoc") }}>
                <FontAwesomeIcon icon={faFileCirclePlus} />
            </div>
            {/* Subcontrol Panel New Doc Name */}
            <div className={`doc-subcontroll-panel ${subcontrolPanelMode === "adddoc" ? 'active' : 'hidden'} addDocRow`}
                ref={addDocSubControllRef}>
                <input
                    type='text'
                    placeholder='New Document name'
                    id='newDocNameInput'
                    className='doc-controll-panel-input'
                    value={newDocumentName}
                    onChange={(e) => {
                        setNewDocumentName(e.target.value)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') { // Check if Enter key is pressed
                            const newDocumentMetaData = {
                                documentID: -1,
                                documentName: newDocumentName === "" ? "Unnamed Document" : newDocumentName,
                                textContent: "",
                                xmlContent: "",
                                ownerID: user!.userID,
                                ownerName: user!.name,
                                usersWithAccess: [],
                                groupsWithAccess: []
                            };
                            postNewDocument(newDocumentMetaData);
                            setNewDocumentName("");
                            setSubControlPanelMode(null);
                        }
                    }}
                />
                <div className='subcontroll-button'
                    onClick={() => {
                        const newDocumentMetaData = {
                            "documentID": -1,
                            "documentName": newDocumentName === "" ? "Unnamed Document" : newDocumentName,
                            "textContent": "",
                            "xmlContent": "",
                            "ownerID": user!.userID,
                            "ownerName": user!.name,
                            "usersWithAccess": [],
                            "groupsWithAccess": []
                        }
                        postNewDocument(newDocumentMetaData);
                        setNewDocumentName("");
                        setSubControlPanelMode(null)
                    }}
                >
                    <FaPlusCircle style={{ color: "green", marginRight: "5px" }} />
                    <div>
                        Add new Document
                    </div>
                </div>
                <div className='subcontroll-button'
                    onClick={() => {
                        const currentDocIput = uploadFileInputRef.current;
                        if (currentDocIput) {
                            currentDocIput.click();
                        }

                    }}>
                    <FaUpload style={{ marginRight: "5px" }} />
                    <div>
                        Upload from device
                    </div>
                    <input
                        type='file'
                        id='newDocFileInput'
                        style={{ display: "none" }}
                        ref={uploadFileInputRef}
                        accept='.pdf, .txt'
                        onChange={handleFileUpload}
                    />
                </div>
            </div>
            <div className='doc-controll-panel-button'
                ref={accessDocControllButtonRef}
                onClick={() => { setSubControlPanelMode("accessselect") }}>
                <FaUserFriends />
            </div>
            {/* Subcontrol Panel User Access */}
            {selectedDocument && companyUsers !== null &&
                <div className={`doc-subcontroll-panel ${subcontrolPanelMode === "accessselect" ? 'active' : 'hidden'} accessDocRow`}
                    ref={accessDocSubControllRef}>
                    {/* Searchbar for filtering of users and groups */}
                    <div className="doc-subcontrol-user-filter-bar">
                        <input
                            id='userFilterTextInput'
                            className="doc-subcontrol-user-filter-bar-input"
                            value={filtertext}
                            onChange={(e) => {
                                setFiltertext(e.target.value);
                            }}
                            placeholder="Search for user or group"
                        />
                        <FaSearch />
                    </div>
                    <div className='animation-protected-text-div' style={{ fontSize: "14px", minHeight: "20px" }}>
                        {displayAccessControl ? "Edit Existing Access" : "Users with Access to this document"}
                    </div>
                    {selectedDocument.usersWithAccess && selectedDocument.usersWithAccess.length > 0 &&
                        <div className='animation-protected-text-div' style={{ fontSize: "14px", marginLeft: "5px", minHeight: "20px" }}>Users:</div>
                    }
                    {
                        selectedDocument.usersWithAccess!.filter(fuwa => fuwa.userName.includes(filtertext) || fuwa.email.includes(filtertext)).map(uwa =>
                            <div key={"userWithAccess" + uwa.userID} className='user-doc-access-tile'>
                                <div style={{ flex: "4" }}>
                                    {uwa.userName}
                                </div>
                                {displayAccessControl ?
                                    <div style={{ flex: "4", display: "flex" }}>
                                        {/* Access By Group Hint */}
                                        <div style={{ flex: "1" }}>
                                            {uwa.accessByGroup &&
                                                <div data-tooltip-id='AccessByGroupHint'>
                                                    <FontAwesomeIcon icon={faUserFriends} />
                                                    <Tooltip id="AccessByGroupHint" className="custom-tooltip" delayShow={300}>
                                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                            {"User has access by group " + uwa.accessByGroup.groupName + ". Editing user privileges will result in personal access. To remove this User from access remove the group from access"}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            }
                                        </div>
                                        {/* Button remove access */}
                                        <div style={{ flex: "1" }} data-tooltip-id='RemoveAccess'>
                                            <FontAwesomeIcon icon={faTimes}
                                                style={uwa.accessByGroup ? { color: "var(--gray-500)", padding: "3px" } : { cursor: "pointer", padding: "3px", color: "red" }}
                                                onClick={() => {
                                                    if (selectedDocument) {
                                                        const updatedUsersWithAccess = selectedDocument.usersWithAccess!.filter(fuwa => fuwa.userID !== uwa.userID);
                                                        const updatedDocumentInfo: ChatDocument = {
                                                            ...selectedDocument,
                                                            usersWithAccess: updatedUsersWithAccess
                                                        };
                                                        //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                        onDocumentMetaDataChanged(updatedDocumentInfo);
                                                    }
                                                }} />
                                        </div>
                                        <Tooltip id="RemoveAccess" className="custom-tooltip" delayShow={1000}>
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                {uwa.accessByGroup ? "To remove this user from access you have to remove the group the user is part of." : "Remove the selected user from access to this document"}
                                            </div>
                                        </Tooltip>
                                        {/* Button for add write access */}
                                        <div style={{ flex: "1" }} data-tooltip-id='EditAccessWrite'>
                                            <FontAwesomeIcon icon={faPenToSquare} style={uwa.allowEdit ? { cursor: "pointer", padding: "3px", color: "blue" } : { cursor: "pointer", padding: "3px" }}
                                                onClick={() => {
                                                    if (selectedDocument) {
                                                        const existingUsersWithAccess = [...selectedDocument.usersWithAccess!];
                                                        const userIndex = selectedDocument.usersWithAccess!.findIndex(user => user.userID === uwa.userID);
                                                        const updatedUser = {
                                                            ...existingUsersWithAccess[userIndex],
                                                            allowEdit: !uwa.allowEdit, // Toggle allowEdit
                                                            accessByGroup: null
                                                        };
                                                        const updatedUsersWithAccess = [
                                                            ...existingUsersWithAccess.slice(0, userIndex),
                                                            updatedUser,
                                                            ...existingUsersWithAccess.slice(userIndex + 1)
                                                        ];
                                                        const updatedDocumentInfo: ChatDocument = {
                                                            ...selectedDocument,
                                                            usersWithAccess: updatedUsersWithAccess
                                                        };
                                                        //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                        onDocumentMetaDataChanged(updatedDocumentInfo);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Tooltip id="EditAccessWrite" className="custom-tooltip" delayShow={1000}>
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                {uwa.allowEdit ? "Click here to remove users edit rigths. Priveleges migth be influenced by group priveleges" : "Click here to give User edit rigths on your document"}
                                            </div>
                                        </Tooltip>
                                        {/* Button for add invite access (Will include write beacuse invite can decide on write previlege) */}
                                        <div style={{ flex: "1" }} data-tooltip-id='EditAccessInvite'>
                                            <FontAwesomeIcon icon={faUserPlus}
                                                style={uwa.allowInvite ? { cursor: "pointer", padding: "3px", color: "blue" } : { cursor: "pointer", padding: "3px" }}
                                                onClick={() => {
                                                    if (selectedDocument) {
                                                        const newUser: UserDocumentInfo = {
                                                            userID: uwa.userID,
                                                            userName: uwa.userName,
                                                            email: uwa.email,
                                                            accessByGroup: null,
                                                            allowEdit: uwa.allowEdit,
                                                            allowInvite: !uwa.allowInvite
                                                        };
                                                        const existingUsersWithAccess = selectedDocument.usersWithAccess!.filter(fuwa => fuwa.userID !== uwa.userID);
                                                        const updatedUsersWithAccess = [...existingUsersWithAccess, newUser];
                                                        const updatedDocumentInfo: ChatDocument = {
                                                            ...selectedDocument,
                                                            usersWithAccess: updatedUsersWithAccess
                                                        };
                                                        //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                        onDocumentMetaDataChanged(updatedDocumentInfo);
                                                    }
                                                }} />
                                        </div>
                                        <Tooltip id="EditAccessInvite" className="custom-tooltip" delayShow={1000}>
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                {uwa.allowInvite ? "Remove the selected users invite privileges." : "Give the selected user access rigths with invite and write privileges."}
                                            </div>
                                        </Tooltip>
                                    </div> :
                                    <div style={{ flex: "1" }}></div>
                                }
                            </div>
                        )
                    }
                    {selectedDocument.groupsWithAccess && selectedDocument.groupsWithAccess.length > 0 &&
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className='animation-protected-text-div' style={{ fontSize: "14px", marginLeft: "5px", minHeight: "20px" }}>Groups:</div>
                            {/* Map Groups Access Controll Tiles*/}
                            {selectedDocument.groupsWithAccess.filter(fgroup => fgroup.groupName.includes(filtertext) || filtertext === "").map(agroup =>
                                <div key={"groupWithAccess" + agroup.groupID} className='user-doc-access-tile'>
                                    <div style={{ flex: "4" }}>
                                        {agroup.groupName}
                                    </div>
                                    {displayAccessControl ?
                                        <div style={{ display: "flex", flex: "4" }}>
                                            {/* Button remove access */}
                                            <div style={{ flex: "1" }} data-tooltip-id='AddAccessRead'>
                                                <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer", padding: "3px", color: "red" }}
                                                    onClick={() => {
                                                        if (selectedDocument) {
                                                            const updatedUsersGroupsWithAccess = selectedDocument.groupsWithAccess!.filter(fgwa => fgwa.groupID !== agroup.groupID);
                                                            const updatedDocumentInfo: ChatDocument = {
                                                                ...selectedDocument,
                                                                groupsWithAccess: updatedUsersGroupsWithAccess
                                                            };
                                                            //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                            onDocumentMetaDataChanged(updatedDocumentInfo);
                                                        }
                                                    }} />
                                            </div>
                                            <Tooltip id="AddAccessRead" className="custom-tooltip" delayShow={1000}>
                                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                    Remove the selected user group from access to this document. Users that were not added over the group will remain.
                                                </div>
                                            </Tooltip>
                                            {/* Button for add write access */}
                                            <div style={{ flex: "1" }} data-tooltip-id='AddAccessWrite'>
                                                <FontAwesomeIcon icon={faPenToSquare} style={agroup.allowEdit ? { cursor: "pointer", padding: "3px", color: "blue" } : { cursor: "pointer", padding: "3px" }}
                                                    onClick={() => {
                                                        if (selectedDocument) {
                                                            const existingGroupsWithAccess = [...selectedDocument.groupsWithAccess!];
                                                            const groupIndex = selectedDocument.groupsWithAccess!.findIndex(group => group.groupID === agroup.groupID);
                                                            const updatedGroup = {
                                                                ...existingGroupsWithAccess[groupIndex],
                                                                allowEdit: !agroup.allowEdit // Toggle allowEdit
                                                            };
                                                            const updatedGroupsWithAccess = [
                                                                ...existingGroupsWithAccess.slice(0, groupIndex),
                                                                updatedGroup,
                                                                ...existingGroupsWithAccess.slice(groupIndex + 1)
                                                            ];
                                                            const updatedDocumentInfo: ChatDocument = {
                                                                ...selectedDocument,
                                                                groupsWithAccess: updatedGroupsWithAccess
                                                            };
                                                            //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                            onDocumentMetaDataChanged(updatedDocumentInfo);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <Tooltip id="AddAccessWrite" className="custom-tooltip" delayShow={1000}>
                                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                    {agroup.allowEdit ? "Click here to remove groups edit rigths" : "Click here to give the user group edit rigths on your document"}
                                                </div>
                                            </Tooltip>
                                        </div> :
                                        <div style={{ display: "flex", flex: "1" }}></div>
                                    }
                                </div>
                            )}
                        </div>

                    }
                    {displayAccessControl &&
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className='animation-protected-text-div' style={{ fontSize: "14px", minHeight: "20px" }}>Allow Access</div>
                            <div className='animation-protected-text-div' style={{ fontSize: "14px", marginLeft: "5px", minHeight: "20px" }}>Users:</div>
                            {
                                //Users without access => Options to add user access with read | write | invite
                                companyUsers !== null && filteredCompanyUsers !== null &&
                                <div style={{ width: "100%" }}>
                                    {filteredCompanyUsers.filter(cuser => cuser.userID !== user?.userID &&
                                        !selectedDocument?.usersWithAccess?.map(uim => uim.userID).includes(cuser.userID) &&
                                        cuser.userID !== selectedDocument.ownerID).map(cuser =>
                                            <div key={"userWOA" + cuser.userID} className='user-doc-access-tile'>
                                                <div style={{ flex: "4" }}>
                                                    {cuser.name}
                                                </div>
                                                {/* Button for add read access */}
                                                <div style={{ flex: "1" }} data-tooltip-id='AddAccessRead'>
                                                    <FontAwesomeIcon icon={faGlasses} style={{ cursor: "pointer", padding: "3px" }}
                                                        onClick={() => {
                                                            if (selectedDocument) {
                                                                const newUser: UserDocumentInfo = {
                                                                    userID: cuser.userID,
                                                                    userName: cuser.name,
                                                                    email: cuser.email,
                                                                    accessByGroup: null,
                                                                    allowEdit: false,
                                                                    allowInvite: false
                                                                };
                                                                const existingUsersWithAccess = selectedDocument.usersWithAccess ? [...selectedDocument.usersWithAccess] : [];
                                                                const updatedUsersWithAccess = [...existingUsersWithAccess, newUser];
                                                                const updatedDocumentInfo: ChatDocument = {
                                                                    ...selectedDocument,
                                                                    usersWithAccess: updatedUsersWithAccess
                                                                };
                                                                //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                                onDocumentMetaDataChanged(updatedDocumentInfo);
                                                            }
                                                        }} />
                                                </div>
                                                <Tooltip id="AddAccessRead" className="custom-tooltip" delayShow={1000}>
                                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                        Give the selected user access rigths with read only mode.
                                                    </div>
                                                </Tooltip>
                                                {/* Button for add write access */}
                                                <div style={{ flex: "1" }} data-tooltip-id='AddAccessWrite'>
                                                    <FontAwesomeIcon icon={faPenToSquare} style={{ cursor: "pointer", padding: "3px" }}
                                                        onClick={() => {
                                                            if (selectedDocument) {
                                                                const newUser: UserDocumentInfo = {
                                                                    userID: cuser.userID,
                                                                    userName: cuser.name,
                                                                    email: cuser.email,
                                                                    accessByGroup: null,
                                                                    allowEdit: true,
                                                                    allowInvite: false
                                                                };
                                                                const existingUsersWithAccess = selectedDocument.usersWithAccess ? [...selectedDocument.usersWithAccess] : [];
                                                                const updatedUsersWithAccess = [...existingUsersWithAccess, newUser];
                                                                const updatedDocumentInfo: ChatDocument = {
                                                                    ...selectedDocument,
                                                                    usersWithAccess: updatedUsersWithAccess
                                                                };
                                                                //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                                onDocumentMetaDataChanged(updatedDocumentInfo);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <Tooltip id="AddAccessWrite" className="custom-tooltip" delayShow={1000}>
                                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                        Give the selected user access rigths with write privileges.
                                                    </div>
                                                </Tooltip>
                                                {/* Button for add invite access (Will include write beacuse invite can decide on write previlege) */}
                                                <div style={{ flex: "1" }} data-tooltip-id='AddAccessInvite'>
                                                    <FontAwesomeIcon icon={faUserPlus} style={{ cursor: "pointer", padding: "3px" }}
                                                        onClick={() => {
                                                            if (selectedDocument) {
                                                                const newUser: UserDocumentInfo = {
                                                                    userID: cuser.userID,
                                                                    userName: cuser.name,
                                                                    email: cuser.email,
                                                                    accessByGroup: null,
                                                                    allowEdit: true,
                                                                    allowInvite: true
                                                                };
                                                                const existingUsersWithAccess = selectedDocument.usersWithAccess ? [...selectedDocument.usersWithAccess] : [];
                                                                const updatedUsersWithAccess = [...existingUsersWithAccess, newUser];
                                                                const updatedDocumentInfo: ChatDocument = {
                                                                    ...selectedDocument,
                                                                    usersWithAccess: updatedUsersWithAccess
                                                                };
                                                                //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                                onDocumentMetaDataChanged(updatedDocumentInfo);
                                                            }
                                                        }} />
                                                </div>
                                                <Tooltip id="AddAccessInvite" className="custom-tooltip" delayShow={1000}>
                                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                        Give the selected user access rigths with invite and write privileges.
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        )}
                                </div>
                            }
                            <div className='animation-protected-text-div' style={{ fontSize: "14px", marginLeft: "5px", minHeight: "20px" }}>Groups:</div>
                            {/* Map Group Add Access Tiles */}
                            {filteredUserGroups &&
                                <div>
                                    {
                                        filteredUserGroups.length > 0 ?
                                            <div>
                                                {
                                                    filteredUserGroups.filter(ug => !selectedDocument.groupsWithAccess?.map(gwa => gwa.groupID).includes(ug.groupID)).map(group =>
                                                        <div key={"groupNoAccess" + group.groupID} className='user-doc-access-tile'>

                                                            <div style={{ flex: "4" }}>
                                                                {group.groupName}
                                                            </div>

                                                            {/* Button for add read access */}
                                                            <div style={{ flex: "1" }} data-tooltip-id='AddAccessReadGoup'>
                                                                <FontAwesomeIcon icon={faGlasses} style={{ cursor: "pointer", padding: "3px" }}
                                                                    onClick={() => {
                                                                        if (selectedDocument) {
                                                                            const newGroup: GroupDocumentInfo = {
                                                                                groupID: group.groupID,
                                                                                groupName: group.groupName,
                                                                                allowEdit: false,
                                                                            };
                                                                            const existingGroupsWithAccess = selectedDocument.groupsWithAccess ? [...selectedDocument.groupsWithAccess] : [];
                                                                            const updatedGroupsWithAccess = [...existingGroupsWithAccess, newGroup];
                                                                            const updatedDocumentInfo: ChatDocument = {
                                                                                ...selectedDocument,
                                                                                groupsWithAccess: updatedGroupsWithAccess
                                                                            };
                                                                            //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                                            onDocumentMetaDataChanged(updatedDocumentInfo);
                                                                        }
                                                                    }} />
                                                            </div>
                                                            <Tooltip id="AddAccessReadGoup" className="custom-tooltip" delayShow={1000}>
                                                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                                    Give the selected group access rigths with read only mode.
                                                                </div>
                                                            </Tooltip>
                                                            {/* Button for add write access */}
                                                            <div style={{ flex: "1" }} data-tooltip-id='AddAccessWriteGroup'>
                                                                <FontAwesomeIcon icon={faPenToSquare} style={{ cursor: "pointer", padding: "3px" }}
                                                                    onClick={() => {
                                                                        if (selectedDocument) {
                                                                            const newGroup: GroupDocumentInfo = {
                                                                                groupID: group.groupID,
                                                                                groupName: group.groupName,
                                                                                allowEdit: true,
                                                                            };
                                                                            const existingGroupsWithAccess = selectedDocument.groupsWithAccess ? [...selectedDocument.groupsWithAccess] : [];
                                                                            const updatedGroupsWithAccess = [...existingGroupsWithAccess, newGroup];
                                                                            const updatedDocumentInfo: ChatDocument = {
                                                                                ...selectedDocument,
                                                                                groupsWithAccess: updatedGroupsWithAccess
                                                                            };
                                                                            //postUpdateDocumentMetaData(updatedDocumentInfo);
                                                                            onDocumentMetaDataChanged(updatedDocumentInfo);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <Tooltip id="AddAccessWriteGroup" className="custom-tooltip" delayShow={1000}>
                                                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                                    Give the selected group access rigths with write privileges.
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                }
                                            </div> :
                                            <div style={{ fontSize: "14px", marginLeft: "5px", minHeight: "20px" }}>
                                                There are no user groups registered in your company
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            {/* Subcontrol Panel for Download */}
            <div className='doc-controll-panel-button'
                ref={downloadDocControllButtonRef}
                onClick={() => { setSubControlPanelMode("download") }}>
                <FaDownload />
            </div>
            {selectedDocument !== null &&
                <div className={`doc-subcontroll-panel ${subcontrolPanelMode === "download" ? 'active' : 'hidden'} downloadDocRow`}
                    ref={downloadDocSubControllRef}>
                    <div className='subcontroll-button downloadButton'
                        onClick={() => {
                            onDownloadButtonClicked(".txt", null)
                        }}>
                        Download as plain .txt
                    </div>
                    <div className='subcontroll-button downloadButton'
                        onClick={() => {
                            onDownloadButtonClicked(".docx", null)
                        }}>
                        Download as .docx
                    </div>
                    <div className='subcontroll-button downloadButton'
                        onClick={() => {
                            onDownloadButtonClicked(".pdf", null)
                        }}>
                        Download as .pdf
                    </div>
                    {/* Template Selection */}
                    {
                        user?.template_files &&
                        <div>
                            <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}> Select template to download file into</div>
                            {
                                user.template_files.map(template =>
                                    <div className='subcontroll-button downloadButton' key={"template" + template}
                                        onClick={() => {
                                            onDownloadButtonClicked("template", template)
                                        }}>
                                        {template}
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>
            }
            <div className='doc-controll-panel-button' data-tooltip-id='ConnectedClients'>
                <div style={{ fontSize: "16px", marginRight: "5px", color: "green" }}>{connectedClients.length > 1 ? `${connectedClients.length}` : ""}</div>
                <FontAwesomeIcon icon={faCircle} style={isConnected ? { fontSize: "15px", color: "green" } : { fontSize: "15px", color: "red" }} />
            </div>
            <Tooltip id="ConnectedClients" className="custom-tooltip" delayShow={1000}>
                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                    {connectedClients.map((cc, index) =>
                        <div
                            key={"ConnectedClient" + cc.user_id}
                            style={{ color: `#${userColors[index % userColors.length].toString(16)}` }}  // Cycle through colors
                        >
                            {cc.user_name}
                        </div>
                    )}
                </div>
            </Tooltip>
        </div>
    );
}

export default DocumentsControlPanel;

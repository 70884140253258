/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import LLMIcon from './Icons/LLMIcon'
const LLMSelectOption = ({ cx, children, getStyles, innerRef, innerProps, ...props }) => {
    const { isDisabled, isFocused, isSelected } = props;
    const styles = getStyles('option', props);
    return (
        <div
            ref={innerRef}
            className={cx(
                css(styles),
                {
                    'option': true,
                    'option--is-disabled': isDisabled,
                    'option--is-focused': isFocused,
                    'option--is-selected': isSelected,
                }
            )}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "2px",
                fontSize: "18px",
                backgroundColor: isSelected ? "var(--background-2)" : isFocused ? "var(--background-3)" : "var(--background)"
            }}

            {...innerProps}
        >
            <LLMIcon
                iconNumber={children[1]}
                maxHeight={"30px"}
                maxWidth={"30px"}
            />
            <div style={{ marginLeft: "5px", textOverflow: "ellipsis" }}>
                {children[0]}
            </div>

        </div>
    );
};

export default LLMSelectOption;
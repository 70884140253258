// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background-2);
    color: var(--text-icons);
}

.login-input{
    background-color: var(--background);
    color: var(--text-icons);
    padding: 14px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: none;
    max-width: 60ch;
    width: 80%;
}

.login-input:focus{
    border: none;
    outline: none;
}

.login-button {
    width: max-content;
    letter-spacing: 2px;
    padding: 10px 20px;
    background-color: darkblue;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    margin: 10px;
  }

  .otp_input{
    width: 32px;
    height: 32px;
    text-align: center;
    border: none;
    border-bottom: 1.5px solid var(--background-4);
    margin: 0 10px;
    color: var(--text-icons);
    background-color: var(--background);
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,qCAAqC;IACrC,wBAAwB;AAC5B;;AAEA;IACI,mCAAmC;IACnC,wBAAwB;IACxB,aAAa;IACb,mBAAmB;IACnB,yCAAyC;IACzC,YAAY;IACZ,eAAe;IACf,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,0BAA0B;IAC1B,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,8CAA8C;IAC9C,cAAc;IACd,wBAAwB;IACxB,mCAAmC;EACrC","sourcesContent":[".login-content {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-content: center;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n    background-color: var(--background-2);\r\n    color: var(--text-icons);\r\n}\r\n\r\n.login-input{\r\n    background-color: var(--background);\r\n    color: var(--text-icons);\r\n    padding: 14px;\r\n    border-radius: 15px;\r\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\r\n    border: none;\r\n    max-width: 60ch;\r\n    width: 80%;\r\n}\r\n\r\n.login-input:focus{\r\n    border: none;\r\n    outline: none;\r\n}\r\n\r\n.login-button {\r\n    width: max-content;\r\n    letter-spacing: 2px;\r\n    padding: 10px 20px;\r\n    background-color: darkblue;\r\n    color: white;\r\n    cursor: pointer;\r\n    border: none;\r\n    border-radius: 5px;\r\n    margin: 10px;\r\n  }\r\n\r\n  .otp_input{\r\n    width: 32px;\r\n    height: 32px;\r\n    text-align: center;\r\n    border: none;\r\n    border-bottom: 1.5px solid var(--background-4);\r\n    margin: 0 10px;\r\n    color: var(--text-icons);\r\n    background-color: var(--background);\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { UserStatisticsData, UserData, UserGroupData, PrivacyRule } from "../SettingsTypedefs";
import Select from "react-select";
import { FaCalendarAlt, FaRegCalendarTimes, FaUser, FaUserFriends } from "react-icons/fa";
import { Circles } from 'react-loader-spinner';
import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

export default function StatisticsSettings({ apiURL, companyUsers, userGroups, rules, isMobile, ...props }:
    { apiURL: string, companyUsers: UserData[], userGroups: UserGroupData[], rules: PrivacyRule[], isMobile: boolean }) {

    const [statisticsData, setStatisticsData] = useState<UserStatisticsData[] | null>(null)
    const [selectedUsers, setSelectedUsers] = useState<UserData[]>([])
    const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroupData[]>([]);

    const [statisticsModi, setStatisticsModi] = useState<"Single User" | "User Groups" | "Pricing Presets">("Single User")
    const [plotOverTime, setPlotOverTime] = useState(true);

    const chartColors = [
        {
            useColor: 'rgba(75, 192, 192, 0.6)',   // Teal
            violationColors: [
                'rgba(255, 99, 132, 0.6)',   // Light Red
                'rgba(255, 77, 101, 0.6)',   // Light Coral
                'rgba(255, 51, 51, 0.6)',    // Indian Red
                'rgba(255, 70, 85, 0.6)',    // Salmon Red
                'rgba(255, 0, 0, 0.6)',      // Bright Red
                'rgba(240, 52, 52, 0.6)',    // Red Punch
                'rgba(255, 69, 96, 0.6)',    // Darker Red
                'rgba(240, 128, 128, 0.6)',  // Light Salmon Red
            ]
        },
        {
            useColor: 'rgba(54, 162, 235, 0.6)',   // Blue
            violationColors: [
                'rgba(255, 69, 96, 0.6)',    // Darker Red
                'rgba(255, 80, 80, 0.6)',    // Soft Red
                'rgba(255, 20, 20, 0.6)',    // Strong Red
                'rgba(255, 0, 102, 0.6)',    // Neon Red
                'rgba(210, 77, 87, 0.6)',    // Crimson Tint
                'rgba(220, 20, 60, 0.6)',    // Crimson Red
                'rgba(245, 66, 66, 0.6)',    // Muted Crimson
                'rgba(240, 52, 52, 0.6)'     // Punch Red
            ]
        },
        {
            useColor: 'rgba(153, 102, 255, 0.6)',  // Purple
            violationColors: [
                'rgba(220, 53, 69, 0.6)',    // Dark Crimson Red
                'rgba(255, 51, 51, 0.6)',    // Bright Red
                'rgba(255, 77, 77, 0.6)',    // Soft Bright Red
                'rgba(210, 45, 45, 0.6)',    // Dark Red
                'rgba(245, 40, 40, 0.6)',    // Punch Red
                'rgba(255, 87, 51, 0.6)',    // Sunset Red
                'rgba(240, 0, 0, 0.6)',      // Dark Bright Red
                'rgba(255, 66, 66, 0.6)'     // Rich Red
            ]
        },
        {
            useColor: 'rgba(255, 205, 86, 0.6)',   // Yellow
            violationColors: [
                'rgba(139, 0, 0, 0.6)',      // Dark Red (Crimson)
                'rgba(178, 34, 34, 0.6)',    // Firebrick Red
                'rgba(165, 42, 42, 0.6)',    // Brownish Red
                'rgba(210, 77, 87, 0.6)',    // Soft Crimson Red
                'rgba(153, 0, 0, 0.6)',      // Burgundy Red
                'rgba(255, 99, 71, 0.6)',    // Tomato Red
                'rgba(200, 50, 80, 0.6)',    // Muted Red
                'rgba(184, 15, 10, 0.6)'     // Dark Fiery Red
            ]
        },
        {
            useColor: 'rgba(102, 204, 153, 0.6)',  // Soft Green
            violationColors: [
                'rgba(200, 50, 80, 0.6)',    // Muted Red
                'rgba(255, 0, 0, 0.6)',      // Pure Red
                'rgba(245, 66, 66, 0.6)',    // Rich Soft Red
                'rgba(255, 69, 96, 0.6)',    // Darker Red
                'rgba(250, 80, 100, 0.6)',   // Bright Punch Red
                'rgba(210, 45, 45, 0.6)',    // Deep Red
                'rgba(230, 20, 50, 0.6)',    // Vivid Red
                'rgba(178, 34, 34, 0.6)'     // Firebrick Red
            ]
        },
        {
            useColor: 'rgba(255, 159, 64, 0.6)',   // Orange
            violationColors: [
                'rgba(178, 34, 34, 0.6)',    // Firebrick Red
                'rgba(240, 52, 52, 0.6)',    // Punch Red
                'rgba(255, 99, 71, 0.6)',    // Tomato Red
                'rgba(220, 20, 60, 0.6)',    // Crimson Red
                'rgba(255, 87, 51, 0.6)',    // Sunset Red
                'rgba(210, 77, 87, 0.6)',    // Soft Crimson
                'rgba(255, 66, 66, 0.6)',    // Rich Red
                'rgba(230, 80, 80, 0.6)'     // Soft Punch Red
            ]
        },
        {
            useColor: 'rgba(100, 149, 237, 0.6)',  // Cornflower Blue
            violationColors: [
                'rgba(220, 20, 60, 0.6)',    // Crimson Red
                'rgba(255, 69, 96, 0.6)',    // Darker Red
                'rgba(240, 52, 52, 0.6)',    // Punch Red
                'rgba(255, 51, 51, 0.6)',    // Indian Red
                'rgba(255, 77, 101, 0.6)',   // Light Coral
                'rgba(255, 20, 20, 0.6)',    // Strong Red
                'rgba(255, 87, 51, 0.6)',    // Soft Red
                'rgba(200, 50, 80, 0.6)'     // Muted Red
            ]
        },
        {
            useColor: 'rgba(70, 130, 180, 0.6)',   // Steel Blue
            violationColors: [
                'rgba(165, 42, 42, 0.6)',    // Brownish Red
                'rgba(220, 20, 60, 0.6)',    // Crimson Red
                'rgba(139, 0, 0, 0.6)',      // Dark Red (Crimson)
                'rgba(210, 45, 45, 0.6)',    // Deep Crimson Red
                'rgba(245, 40, 40, 0.6)',    // Punch Red
                'rgba(255, 0, 0, 0.6)',      // Bright Red
                'rgba(200, 50, 80, 0.6)',    // Muted Red
                'rgba(255, 77, 77, 0.6)'     // Light Red
            ]
        },
        {
            useColor: 'rgba(34, 139, 34, 0.6)',    // Forest Green
            violationColors: [
                'rgba(139, 0, 0, 0.6)',      // Dark Red (Maroon)
                'rgba(255, 66, 66, 0.6)',    // Rich Red
                'rgba(178, 34, 34, 0.6)',    // Firebrick Red
                'rgba(255, 69, 96, 0.6)',    // Darker Red
                'rgba(255, 0, 0, 0.6)',      // Bright Red
                'rgba(220, 20, 60, 0.6)',    // Crimson Red
                'rgba(200, 50, 80, 0.6)',    // Muted Red
                'rgba(240, 52, 52, 0.6)'     // Punch Red
            ]
        }
    ];

    useEffect(() => {
        console.log("Selected users in Statistics changed to :", [companyUsers[0]])
        setSelectedUsers(companyUsers)
    }, [companyUsers])

    useEffect(() => {
        console.log("Fetching ststs data after selected user changed")
        fetchStatisticsDataSingleUsers();
    }, [selectedUsers])

    useEffect(() => {
        fetchStatisticsDataUserGroups();
    }, [selectedUserGroups])

    useEffect(() => {
        console.log("Modi changed too: ", statisticsModi)
        if (statisticsModi === "Single User") {
            fetchStatisticsDataSingleUsers();
        } else if (statisticsModi === "User Groups") {
            fetchStatisticsDataUserGroups();
        }
    }, [statisticsModi])

    async function fetchStatisticsDataSingleUsers() {
        if (selectedUsers.length > 0) {
            const userIdList = selectedUsers.map((user) => user.userID)
            try {
                const response = await fetch(
                    apiURL + "/get_statistics_data_for_users",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify({
                            "ids": userIdList
                        })
                    }
                );

                if (response.status !== 200) {
                    console.log("Response status: " + response.status);
                } else {
                    const data = await response.json();
                    console.log("Stats Data:", data.statistics_data)
                    setStatisticsData(data.statistics_data)
                }
            } catch (error) {
                console.log(error);
                //alert("Error: " + error);
            }
        } else {
            setStatisticsData(null);
        }
    }

    async function fetchStatisticsDataUserGroups() {
        if (selectedUserGroups.length > 0) {
            const userGroupIdList = selectedUserGroups.map((group) => group.groupID)
            try {
                const response = await fetch(
                    apiURL + "/get_statistics_data_for_user_groups",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify({
                            "ids": userGroupIdList
                        })
                    }
                );

                if (response.status !== 200) {
                    console.log("Response status: " + response.status);
                } else {
                    const data = await response.json();
                    console.log("Stats Data:", data.statistics_data)
                    setStatisticsData(data.statistics_data)
                }
            } catch (error) {
                console.log(error);
                //alert("Error: " + error);
            }
        } else {
            setStatisticsData(null);
        }
    }

    function aggregateDataByUser(statisticsData: UserStatisticsData[]) {
        const aggregatedData: { [name: string]: { [monthYear: string]: { num_uses: number, violations: { [ruleID: number]: { num_violations: number } } } } } = {};

        statisticsData.forEach((userStats) => {
            if (!aggregatedData[userStats.name]) {
                aggregatedData[userStats.name] = {};
            }

            userStats.useData.forEach((use) => {
                const monthYear = `${use.month}/${use.year}`;
                if (!aggregatedData[userStats.name][monthYear]) {
                    aggregatedData[userStats.name][monthYear] = { num_uses: 0, violations: {} };
                }
                aggregatedData[userStats.name][monthYear].num_uses += use.num_uses;
            });

            userStats.violationData.forEach((violation) => {
                const monthYear = `${violation.month}/${violation.year}`;
                if (!aggregatedData[userStats.name][monthYear]) {
                    aggregatedData[userStats.name][monthYear] = { num_uses: 0, violations: {} };
                }
                aggregatedData[userStats.name][monthYear].violations[violation.ruleID] = { num_violations: violation.num_violations };
            });
        });

        return aggregatedData;
    }



    const chartData = statisticsData !== null ? (() => {
        const aggregatedData = aggregateDataByUser(statisticsData);

        // Collect unique months (labels) if `plotOverTime` is true
        const allLabelsSet = new Set<string>();
        if (plotOverTime) {
            Object.values(aggregatedData).forEach(userData => {
                Object.keys(userData).forEach(monthYear => {
                    allLabelsSet.add(monthYear);
                });
            });
        }

        // Generate labels
        const labels = plotOverTime
            ? Array.from(allLabelsSet).sort((a, b) => {
                const [monthA, yearA] = a.split('/').map(Number);
                const [monthB, yearB] = b.split('/').map(Number);
                return new Date(yearA, monthA - 1).getTime() - new Date(yearB, monthB - 1).getTime();
            })
            : ["Total Requests and Violations"];  // When `plotOverTime` is false, use user names as labels

        // Create datasets based on the state `plotOverTime`
        const datasets = Object.keys(aggregatedData).flatMap((name, userIdx) => {
            const userData = aggregatedData[name];
            const colorIndex = userIdx % chartColors.length;
            const useColor = chartColors[colorIndex].useColor;
            const violationColors = chartColors[colorIndex].violationColors;

            if (plotOverTime) {
                // Plot data over time (for each month)
                const numUsesData = labels.map((month) => userData[month]?.num_uses || 0);

                // Generate stacked datasets for each violation ruleID
                const ruleIDs = new Set<number>();
                labels.forEach((month) => {
                    if (userData[month]?.violations) {
                        Object.keys(userData[month].violations).forEach(ruleID => ruleIDs.add(Number(ruleID)));
                    }
                });

                const violationDatasets = Array.from(ruleIDs).map((ruleID, idx) => {
                    const violationDataForRule = labels.map((month) => userData[month]?.violations[ruleID]?.num_violations || 0);
                    return {
                        label: `${name} - Rule ${rules.filter(rule => rule.id === Number(ruleID))[0].name} Violations`,
                        data: violationDataForRule,
                        backgroundColor: violationColors[idx % violationColors.length],
                        stack: `violations-${name}`,  // Unique stack for violations by user
                    };
                });

                return [
                    {
                        label: `${name} - Number of Requests`,
                        data: numUsesData,
                        backgroundColor: useColor,
                        stack: `requests-${name}`,  // Unique stack for requests by user
                    },
                    ...violationDatasets
                ];
            } else {
                // Accumulate data per user (ignore months)
                const totalUses = Object.values(userData).reduce((acc, curr) => acc + (curr.num_uses || 0), 0);

                // Accumulate violations by ruleID
                const ruleIDTotals: { [ruleID: number]: number } = {};
                Object.values(userData).forEach(monthData => {
                    Object.entries(monthData.violations).forEach(([ruleID, violationData]) => {
                        ruleIDTotals[Number(ruleID)] = (ruleIDTotals[Number(ruleID)] || 0) + violationData.num_violations;
                    });
                });

                const violationDatasets = Object.entries(ruleIDTotals).map(([ruleID, totalViolations], idx) => {
                    return {
                        label: `${name} - Rule ${rules.filter(rule => rule.id === Number(ruleID))[0].name} Violations`,
                        data: [totalViolations],  // Single data point for each user
                        backgroundColor: violationColors[idx % violationColors.length],
                        stack: `violations-${name}`,  // Unique stack for violations by user
                    };
                });

                return [
                    {
                        label: `${name} - Total Requests`,
                        data: [totalUses],  // Single data point for each user
                        backgroundColor: useColor,
                        stack: `requests-${name}`,  // Unique stack for requests by user
                    },
                    ...violationDatasets
                ];
            }
        });

        return {
            labels: plotOverTime ? labels : ["Total Requests and Violations"],  // Use user names if not plotting over time
            datasets,
        };
    })() : { labels: [], datasets: [] };

    useEffect(() => {
        console.log("Chartdata changed to :", chartData)
    }, [chartData])


    return (
        <div className="subsetting-content">
            <center><h4>Statistics</h4></center>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                {/* Controls Container */}
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        {/* Modi Selection */}
                        <div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
                            <div className={statisticsModi === "Single User" ? "statistics-modi-selection-button selected" : "statistics-modi-selection-button"}
                                onClick={() => {
                                    setStatisticsModi("Single User")
                                }}
                            >
                                <FaUser style={{ marginRight: "5px" }} />
                                Single User
                            </div>
                            <div className={statisticsModi === "User Groups" ? "statistics-modi-selection-button selected" : "statistics-modi-selection-button"}
                                onClick={() => {
                                    setStatisticsModi("User Groups")
                                }}
                            >
                                <FaUserFriends style={{ marginRight: "5px" }} />
                                User Groups
                            </div>
                        </div>
                        {
                            (statisticsModi === "Single User" || statisticsModi === "User Groups") && (
                                <div style={{ marginLeft: "10px", marginRight: "10px", flex: "1" }}>{
                                    statisticsModi === "Single User" ?
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: "var(--background)",
                                                    color: "var(--text-icons)",
                                                    zIndex: 10
                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: 110
                                                }),
                                                group: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    zIndex: 110
                                                }),
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)"
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)",
                                                }),
                                                multiValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: 10
                                                }),
                                                multiValueLabel: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "100%",
                                                    margin: "5px",
                                                })
                                            }}
                                            getOptionLabel={(user) => user.name}
                                            getOptionValue={(user) => user.name}
                                            value={selectedUsers}
                                            options={companyUsers}
                                            onChange={(selectedOptions) => {
                                                console.log("selectedOptions:", selectedOptions);
                                                setSelectedUsers([...selectedOptions]);
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition={'absolute'}
                                            menuPlacement={'auto'}
                                            placeholder={"Select Users to be displayed in Chart"}
                                            isMulti={true}
                                        /> :
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: "var(--background)",
                                                    color: "var(--text-icons)",
                                                    zIndex: 10
                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: 110
                                                }),
                                                group: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    zIndex: 110
                                                }),
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)"
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)",
                                                }),
                                                multiValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: 10
                                                }),
                                                multiValueLabel: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "100%",
                                                    margin: "5px",
                                                })
                                            }}
                                            getOptionLabel={(group) => group.groupName}
                                            getOptionValue={(group) => group.groupName}
                                            value={selectedUserGroups}
                                            options={userGroups}
                                            onChange={(selectedOptions) => {
                                                console.log("selectedOptions:", selectedOptions);
                                                setSelectedUserGroups([...selectedOptions]);
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition={'absolute'}
                                            placeholder={"Select User groups to be displayed in statistics"}
                                            menuPlacement={'auto'}
                                            isMulti={true}
                                        />}
                                </div>

                            )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <div className={plotOverTime ? "statistics-modi-selection-button selected" : "statistics-modi-selection-button"}
                            onClick={() => {
                                setPlotOverTime(true)
                            }}
                        >
                            <FaCalendarAlt style={{ marginRight: "5px" }} />
                            Chart over months
                        </div>
                        <div className={!plotOverTime ? "statistics-modi-selection-button selected" : "statistics-modi-selection-button"}
                            onClick={() => {
                                setPlotOverTime(false)
                            }}
                        >
                            <FaRegCalendarTimes style={{ marginRight: "5px" }} />
                            Chart accumulated
                        </div>
                    </div>
                </div>
                {/* Charts Container */}
                <div style={{ display: "flex", width: "100%" }}>
                    {statisticsData ? (
                        <Bar
                            options={{
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                    }
                                },
                                responsive: true,
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'Number of Requests and Violations',
                                    },
                                    legend: {
                                        display: false,
                                        position: 'top',
                                    },
                                },
                            }}
                            data={chartData}
                        />
                    ) : (
                        <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                            {
                                statisticsModi === "Single User" && selectedUsers.length === 0 || statisticsModi === "User Groups" && selectedUserGroups.length === 0 ?
                                    /* No Elements to plot data for selected */
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        {statisticsModi === "Single User" ? "Please select a user to plot data for" : "Please select a user group to plot data for"}
                                    </div> :
                                    /* No Data recived yet from API */
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="var(--text-icons)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    </div>
                            }
                        </div>

                    )}
                </div>
            </div>
        </div>
    );
}

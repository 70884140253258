import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faHouse } from '@fortawesome/free-solid-svg-icons';
import PrivcyPromptLogo from './../assets/privacyprompt.svg'
import './Navbar.css'
import { Link } from "react-router-dom";
import Select from "react-select";
import LLMSelectOption from "./LLMSelectOption";
import LLMSelectValue from "./LLMSelectValue";
import LLMSelectSingleValueContainer from "./LLMSelectSingleValueContainer";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';

export default function Navbar({ isHome, availableLLMs, onSelectedLLMChanged, availableModi, onSelectedModiChanged }) {

  const [selectedLLM, setSelectedLLM] = useState(availableLLMs ? availableLLMs[0] : null)
  const [llmOptions, setLlmOptions] = useState([]);
  const [modelModeSelectExanded, setModelModeSelectExpanded] = useState(false)
  const [modiStates, setModiStates] = useState(null)

  useEffect(() => {
    setModiStates(availableModi.map((modus) => {
      return {
        "modusID": modus.modusID,
        "name": modus.name,
        "prePrompt": modus.prePrompt,
        "activated": false
      }
    }))
  }, [availableModi])

  useEffect(() => {
    const modelModeSelect = document.getElementById("ModelModusSelection")
    if (modelModeSelect) {
      if (modelModeSelectExanded) {
        modelModeSelect.style.width = "auto"
        modelModeSelect.style.height = "auto"
        const newNaturalWidth = modelModeSelect.scrollWidth;
        const newNaturalHeight = modelModeSelect.scrollHeight;
        modelModeSelect.style.width = "0px";
        modelModeSelect.style.height = "0px";
        modelModeSelect.classList.add("expanded");
        requestAnimationFrame(() => {
          modelModeSelect.style.width = `${newNaturalWidth + 40}px`
          modelModeSelect.style.height = `${newNaturalHeight}px`
        })
      }
      else {
        setTimeout(() => {
          modelModeSelect.classList.remove("expanded");
        }, 300)
        requestAnimationFrame(() => {
          modelModeSelect.style.width = `0px`
          modelModeSelect.style.height = `0px`
        })

      }
    }
    const updateWidth = () => {
      const selectMenu = document.getElementById('ModelModusSelection');
      const grid = selectMenu?.querySelector('.model-modus-select-grid');
      //console.log("Dynimic update width check", selectMenu, grid, modelModeSelectExanded)
      if (selectMenu && grid && modelModeSelectExanded) {
        //console.log("Dynimic update width execute")
        const gridWidth = grid.scrollWidth;
        requestAnimationFrame(() => {
          selectMenu.style.width = `${gridWidth + 40}px`;
        });
      }
    };
    const updateHeight = () => {
      const selectMenu = document.getElementById('ModelModusSelection');
      const grid = selectMenu?.querySelector('.model-modus-select-grid');
      //console.log("Dynimic update heigth check", selectMenu, grid, modelModeSelectExanded)
      if (selectMenu && grid && modelModeSelectExanded) {
        //console.log("Dynimic update heigth execute")
        grid.style.height = "auto"
        const gridHeight = grid.scrollHeight;
        selectMenu.style.height = "auto"
        const containerHeight = selectMenu.scrollHeight;
        grid.style.height = `0px`;
        selectMenu.style.height = `0px`;
        requestAnimationFrame(() => {
          grid.style.height = `${gridHeight}px`;
          selectMenu.style.height = `${containerHeight}px`;
        });
      }
    };
    window.addEventListener('resize', updateWidth);
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('resize', updateWidth);
    }

  }, [modelModeSelectExanded])

  useEffect(() => {
    if (availableLLMs) {
      const previously_selected = Number(localStorage.getItem("selected_llm"));
      //console.log("Previously Selected LLM:", previously_selected);
      //console.log("Availabale LLMS:", availableLLMs)
      //console.log("Filtered Availabale LLMS:", availableLLMs.filter(llm => llm.api_id === previously_selected))
      if (previously_selected && availableLLMs.filter(llm => llm.api_id === previously_selected).length >= 1) {
        //console.log("Setting with previously selected");
        setSelectedLLM(availableLLMs.filter(llm => llm.api_id === previously_selected)[0]);
        onSelectedLLMChanged(availableLLMs.filter(llm => llm.api_id === previously_selected)[0].api_id)
      } else if (availableLLMs.length > 0) {
        setSelectedLLM(availableLLMs[0])
        onSelectedLLMChanged(availableLLMs[0].api_id)
      }
      setLlmOptions(availableLLMs.map(llm => {
        return {
          value: llm.api_id,
          label: [
            llm.api_name,
            llm.icon_number
          ]
        }
      }))
    }
  }, [availableLLMs])

  useEffect(() => {
    //console.log("Navbar selested LLM:", selectedLLM)
    //console.log(llmOptions.find(ele => ele.value === selectedLLM.api_id))
  }, [selectedLLM])

  return (
    <header className="navbar-header">
      {isHome && availableLLMs && selectedLLM &&
        <div className="model-select" data-tooltip-id="SelectLLMTooltip" data-tooltip-place="right-end">
          <Tooltip id="SelectLLMTooltip" className="custom-tooltip" style={{ zIndex: "11111" }} delayShow={1500} place="right-end">
            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
              Here you can select the large language model you want to send your requests to
            </div>
          </Tooltip>
          <Select
            value={llmOptions.find(ele => ele.value === selectedLLM.api_id)}
            styles={
              {
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                valueContainer: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                multiValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                multiValueLabel: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "100%",
                  margin: "5px",
                })
              }}
            options={llmOptions}
            components={{ Option: LLMSelectOption, SingleValue: LLMSelectValue, ValueContainer: LLMSelectSingleValueContainer }}
            onChange={(selectedOptions) => {
              //console.log("selectedOptions:", selectedOptions);
              setSelectedLLM(availableLLMs.find(llm => llm.api_id === selectedOptions.value))
              localStorage.setItem("selected_llm", selectedOptions.value)
              onSelectedLLMChanged(selectedOptions.value)
            }}
          />
        </div>
      }
      {
        isHome && availableModi && selectedLLM && availableModi.length > 0 &&
        <div style={{ position: "relative" }}>
          {!modelModeSelectExanded &&
            <div className="model-modus-select-expand-button"
              onClick={() => {
                setModelModeSelectExpanded(true);
              }}>
              <FaAngleRight />
            </div>
          }
          <div className={`model-modus-select`} id="ModelModusSelection">
            <div style={{ width: "30px", height: "100%", cursor: "pointer", borderRight: "1px solid var(--text-icons)", display: "flex", justifyContent: "center", alignItems: "center", justifySelf: "center", alignSelf: "center", marginRight: "10px" }}
              onClick={() => {
                setModelModeSelectExpanded(false);
              }}
            >
              <FaAngleLeft />
            </div>
            <div className="model-modus-select-grid">
              {modiStates && modiStates.map((modus) =>
                <div className="model-modus-tile" key={modus.modusID}>
                  <div className={`model-modus-name-tile ${modus.activated ? "selected" : ""}`}
                    onClick={() => {
                      console.log("Modus:", modus)
                      const updatedModiStates = modiStates.map((umodus) => {
                        return {
                          ...umodus,
                          "activated": umodus.modusID === modus.modusID ? !umodus.activated : false
                        }
                      });
                      setModiStates(updatedModiStates)
                      onSelectedModiChanged(updatedModiStates.filter((modus) => modus.activated))
                    }}
                  >
                    <div style={{ width: "100%", textOverflow: "ellipsis", overflowX: "hidden" }}>
                      {modus.name}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      }

      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <Link to="/impressum" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", textDecoration: "none", color: "var(--text-icons)" }}>
          <img src={PrivcyPromptLogo} alt="PrivacyPrompt Logo" style={{ maxHeight: "60px" }} />
          <h4 style={{ whiteSpace: "nowrap" }}>{isHome ? "Privacy Prompt" : "Privacy Prompt Settings"}</h4>
        </Link>
      </div>


      <center className="navbar">
        {isHome ?
          <Link to="/settings" data-tooltip-id="SettingsTooltip">
            <FontAwesomeIcon icon={faGear} style={{ margin: '8px', fontSize: '24px', color: 'var(--text-icons) ' }} />
            <Tooltip id="SettingsTooltip" className="custom-tooltip" delayShow={800}>
              <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                Settings
              </div>
            </Tooltip>
          </Link> :
          <Link to="/"  data-tooltip-id="HomeTooltip">
            <FontAwesomeIcon icon={faHouse} style={{ margin: '8px', fontSize: '24px', color: 'var(--text-icons) ' }} />
            <Tooltip id="HomeTooltip" className="custom-tooltip" delayShow={800}>
              <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                Chat Page
              </div>
            </Tooltip>
          </Link>
        }
      </center>
    </header>
  );
}

import { useEffect, useState } from "react";
import { FaPlusCircle, FaTimes, FaInfoCircle } from "react-icons/fa";
import SecurityLevelTile from "./SecurityLevelTile";
import { SecurityLevelData, PrivacyRule, CompanyPolicy } from "../SettingsTypedefs";
import Select from "react-select";
import { Tooltip } from "react-tooltip";

export default function SecurityLevelSettings({ apiURL, securityLevels, onSecurityLevelsChanged, isMobile, ...props }:
    { apiURL: string, securityLevels: SecurityLevelData[], onSecurityLevelsChanged: Function, isMobile: boolean }) {
    const [privacyRules, setPrivacyRules] = useState<PrivacyRule[] | null>(null);
    const [privacyPolicys, setPrivacyPolicys] = useState<CompanyPolicy[] | null>(null)
    const [newSecurityLevelMode, setNewSecurityLevelMode] = useState(false);
    const [newSecurityLevelName, setNewSecurityLevelName] = useState("");
    const [newSecurityLevelSelectedRules, setNewSecuritylevelSelectedRules] = useState<PrivacyRule[]>([]);
    const [newSecurityLevelSelectedpolicys, setNewSecurityLevelSelectedpolicys] = useState<CompanyPolicy[]>([]);

    useEffect(() => {
        if (apiURL) {
            fetchPrivacyPolicys();
            fetchPrivacyRules();
        }
    }, [apiURL])

    async function fetchPrivacyRules() {
        try {
            const response = await fetch(
                apiURL + "/get_privacy_rules",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const data = await response.json();
                console.log("Pre Sortded:", data)
                console.log("Sorted:", data.sort((a: PrivacyRule, b: PrivacyRule) => a.id! - b.id!))
                setPrivacyRules(data.sort((a: PrivacyRule, b: PrivacyRule) => a.id! - b.id!));
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function fetchPrivacyPolicys() {
        try {
            const response = await fetch(
                apiURL + "/get_company_policys",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const data = await response.json();
                setPrivacyPolicys(data);
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function postNewSecurityLevel() {
        try {
            const response = await fetch(
                apiURL + "/add_security_level",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "security_level_name": newSecurityLevelName,
                            "ruleIDs": newSecurityLevelSelectedRules.map(rule => rule.id),
                            "policyIDs": newSecurityLevelSelectedpolicys.map(policy => policy.policyID)
                        }
                    )
                }
            );
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const data = await response.json();
                console.log(data)
                setNewSecurityLevelName("");
                onSecurityLevelsChanged();
                setNewSecurityLevelMode(false)
                clearLevelCreationInputs();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    function clearLevelCreationInputs() {
        setNewSecurityLevelName("");
        setNewSecuritylevelSelectedRules([]);
        setNewSecurityLevelSelectedpolicys([]);
    }

    return (
        <div className="subsetting-content">
            <center><h4>Security Levels</h4></center>
            {!newSecurityLevelMode ?
                <div
                    className={`settings-button ${isMobile ? "mobile" : ""}`}
                    onClick={() => {
                        setNewSecurityLevelMode(true);
                    }}
                    data-tooltip-id="AddNSLTooltip"
                >
                    Add new Security Level
                    <FaPlusCircle className="add-element-icon" />
                    <Tooltip id="AddNSLTooltip" className="custom-tooltip" delayShow={800}>
                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                            You can add a new Security Level here. Security Levels can have assigned Rules and Policys. A Rule assigned to the Security Level defines entries of your Database that should not occur in messages that are sent to Large Language Model APIs. Policys define general Topics that not should be talked about or directives that should be kept to.
                        </div>
                    </Tooltip>
                </div>
                : <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div className="setting-add-element-container">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Level Name</div>
                            <div data-tooltip-id="NSLSLNTooltip">
                                <FaInfoCircle />
                                <Tooltip id="NSLSLNTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        The name under which the Security Level will be shown in further settings.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <input
                            value={newSecurityLevelName}
                            onChange={(e => {
                                setNewSecurityLevelName(e.target.value);
                            })}
                            className="settings-input"
                            placeholder="New Security Level Name"
                        />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Level Rules</div>
                            <div data-tooltip-id="NSLSLRTooltip">
                                <FaInfoCircle />
                                <Tooltip id="NSLSLRTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        The Rules assigned to this Security Level. You can create new rules in the settings below. If the Large Language Model or the Group over which the user has access to it has this Security Level, every Prompt to it will be anonymized according to this Rules.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "var(--background)",
                                    color: "var(--text-icons)",
                                    zIndex: 10
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    zIndex: 110
                                }),
                                group: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    zIndex: 110
                                }),
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: "var(--background)"
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: "var(--background)",
                                }),
                                multiValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    zIndex: 10
                                }),
                                multiValueLabel: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                }),
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                    margin: "5px",
                                    zIndex: 10
                                })
                            }}
                            getOptionLabel={(rule) => rule.name}
                            getOptionValue={(rule) => rule.name}
                            options={privacyRules!}
                            onChange={(selectedOptions) => {
                                console.log("selectedOptions Rule Names:", selectedOptions);
                                setNewSecuritylevelSelectedRules([...selectedOptions]);
                            }}
                            value={newSecurityLevelSelectedRules}

                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'auto'}
                            isMulti={true}
                        />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Level Policys</div>
                            <div data-tooltip-id="NSLSLPTooltip">
                                <FaInfoCircle />
                                <Tooltip id="NSLSLPTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        The Policys assigned to this Security Level. You can create new policys in the company settings tab. If the Large Language Model or the Group over which the user has access to it has this Security Level, every Prompt to it will be checked according to this Policy.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "var(--background)",
                                    color: "var(--text-icons)",
                                    zIndex: 10
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    zIndex: 110
                                }),
                                group: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    zIndex: 110
                                }),
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: "var(--background)"
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: "var(--background)",
                                }),
                                multiValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    zIndex: 10
                                }),
                                multiValueLabel: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                }),
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                    margin: "5px",
                                })
                            }}
                            getOptionLabel={(policy) => policy.policyName}
                            getOptionValue={(policy) => policy.policyName}
                            options={privacyPolicys!}
                            onChange={(selectedOptions) => {
                                console.log("selectedOptions Policy Names:", selectedOptions);
                                setNewSecurityLevelSelectedpolicys([...selectedOptions]);
                            }}
                            value={newSecurityLevelSelectedpolicys}

                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'auto'}
                            isMulti={true}
                        />
                        <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "center", justifyContent: "center" }}>
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                style={{ height: "inherit" }}
                                onClick={() => {
                                    setNewSecurityLevelMode(false);
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>Cancel</div>
                                    <FaTimes className="cancel-delete-icon" />
                                </div>
                            </div>
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                onClick={() => {
                                    postNewSecurityLevel();
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>Add</div>
                                    <FaPlusCircle className="confirm-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={`settings-subheader ${isMobile ? "mobile" : ""}`}>Existing Security Levels:</div>
            <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                {securityLevels && securityLevels.map((sec_lvl) =>
                    <SecurityLevelTile
                        key={"SecLvlTile" + sec_lvl.security_level}
                        apiURL={apiURL}
                        secLvl={sec_lvl}
                        onSecurityLevelsChanged={() => {
                            onSecurityLevelsChanged();
                        }}
                        ruleOptions={privacyRules}
                        policyOptions={privacyPolicys}
                        isMobile={isMobile}
                    />
                )

                }
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import PrivcyPromptLogo from './../../assets/privacyprompt.svg'
import './APISetup.css'
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function APISetup({ onApiURLChanged, apiUrl, onSetupCompleted }) {
    const [newAPI, setNewAPI] = useState(apiUrl);
    const [isAPIAlive, setIsAPIAlive] = useState(false);
    const [isAPIInitalized, setIsAPIInitalized] = useState(false)

    const [companyName, setCompanyName] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminMail, setAdminMail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    const [pwdhintText, setPwdHintText] = useState(null);

    useEffect(() => {
        //console.log("Test API Alive with changed API URL :", apiUrl);
        testAPIAlive();
    }, [apiUrl])

    async function testAPIAlive() {
        if (apiUrl) {
            try {
                const response = await fetch(
                    apiUrl + "/test_alive",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.status !== 200) {
                    setIsAPIAlive(false);
                    console.log("Response status: " + response.status.toString());
                } else {
                    const data = await response.json();
                    setIsAPIAlive(true);
                    localStorage.setItem('pp_api_url', apiUrl)
                    setIsAPIInitalized(data.initalized);
                }
            } catch (error) {
                //window.location.href = "/login";
                setIsAPIAlive(false);
                console.log(error);
                alert("Error: Could not reach API");
            }
        }
    }

    async function initalizeAPI() {
        try {
            const response = await fetch(
                apiUrl + "/initalize_company_data?companyName=" +companyName +
                "&pp_api_url=" + apiUrl +
                "&admin_name=" +adminName+
                "&admin_mail=" +adminMail+
                "&admin_pwd=" +password,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status.toString());
            } else {
                localStorage.setItem('pp_api_url', apiUrl)
                setTimeout(() => {
                    testAPIAlive();
                    onSetupCompleted()
                }, 200);
            }
        } catch (error) {
            //window.location.href = "/login";
            console.log(error);
            alert("Error: Failure During API initalization");
        }
    }


    function initialization_inputs_valid(pwd, pwd_repeat) {
        const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
        const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
        const emailValid = emailRegex.test(adminMail);
        const passwordValid = pwdRegex.test(pwd);
        const passwordsEqual = pwd === pwd_repeat;
        if (!emailValid) {
            setPwdHintText("Email is not valid");
        }
        if (!passwordValid) {
            setPwdHintText("Password needs to be a minimum of 8 characters, needs to contain at least one upper and lowercase letter and a special character");
        }
        if (!passwordsEqual) {
            setPwdHintText("Passwords are not equal");
        }
        if (passwordValid && passwordsEqual && emailValid) {
            setPwdHintText(null);
        }
        return passwordValid && passwordsEqual && emailValid;
    }

    return (
        <div className="page-container">
            <div className='setup-api-content'>
                <h1 style={{ textAlign: 'center' }}> Privacy Prompt API Setupt</h1>
                <div className='logo-container'>
                    <img src={PrivcyPromptLogo} alt="PrivacyPrompt Logo" style={{ maxHeight: '20vh' }} />
                    {/* API Hint */}
                    <div className={isAPIAlive && isAPIInitalized ? 'api-hint-ok' : isAPIAlive ? 'api-hint-init-needed' : 'api-hint-dead'}>
                        {isAPIAlive && isAPIInitalized ?
                            <div>
                                <div>
                                    {'API-URL:' + apiUrl}
                                </div>
                                <div>
                                    Connection: Alive
                                </div>
                                <div>
                                    Initialization: Done
                                </div>
                            </div>
                            :
                            isAPIAlive ?
                                <div>
                                    <div>
                                        {'API-URL:' + apiUrl}
                                    </div>
                                    <div>
                                        Connection: Alive
                                    </div>
                                    <div>
                                        Initialization: Neccessary
                                    </div>
                                </div>
                                :
                                <div>
                                    <div>
                                        {'API-URL:' + apiUrl}
                                    </div>
                                    <div>
                                        Connection: Missing
                                    </div>
                                    <div>
                                        Initialization: Unknown
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className='api-url-input-container'>
                    <h3>Your API URL and port</h3>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <input
                            type='text'
                            value={newAPI ? newAPI : ""}
                            className='api-url-input'
                            placeholder='e.g. "http://localhost:8000"'
                            onChange={(e) => {
                                setNewAPI(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    //console.log("New API:", newAPI)
                                    testAPIAlive()
                                    onApiURLChanged(newAPI)
                                }
                            }}
                        >
                        </input>
                        <button
                            className='setup-api-button'
                            onClick={() => {
                                //console.log("New API:", newAPI)
                                testAPIAlive()
                                onApiURLChanged(newAPI)
                            }}
                        >
                            Confirm
                        </button>
                    </div>

                </div>
                {isAPIAlive && !isAPIInitalized && <div>
                    <h3 style={{ textAlign: 'center' }}>Initial Company Admin Registration</h3>
                    <div className='initalization-container'>
                        <div className='initalization-column'>
                            <h5 style={{ textAlign: 'center' }}>Company Name</h5>
                            <input
                                type='text'
                                value={companyName}
                                className='api-url-input'
                                onChange={(e) => {
                                    setCompanyName(e.target.value)
                                }}
                            >
                            </input>
                            <h5 style={{ textAlign: 'center' }}>Initial Admin Name</h5>
                            <input
                                type='text'
                                value={adminName}
                                className='api-url-input'
                                onChange={(e) => {
                                    setAdminName(e.target.value)
                                }}
                            >
                            </input>
                            <h5 style={{ textAlign: 'center' }}>Initial Admin Email</h5>
                            <input
                                type='text'
                                value={adminMail}
                                className='api-url-input'
                                onChange={(e) => {
                                    setAdminMail(e.target.value)
                                }}
                            >
                            </input>
                        </div>
                        <div className='initalization-column'>
                            <h5 style={{ textAlign: 'center' }}>Initial Admin Password</h5>
                            <input
                                type='password'
                                value={password}
                                className='api-url-input'
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    if(pwdhintText !== "") {
                                        initialization_inputs_valid(e.target.value, passwordRepeat);
                                    }
                                }}
                            >
                            </input>
                            <h5 style={{ textAlign: 'center' }}>Initial Admin Password Repeat</h5>
                            <input
                                type='password'
                                value={passwordRepeat}
                                className='api-url-input'
                                onChange={(e) => {
                                    setPasswordRepeat(e.target.value)
                                    if(pwdhintText !== "") {
                                        initialization_inputs_valid(password, e.target.value);
                                    }
                                }}
                            >
                            </input>
                            {pwdhintText && <label style={{ color: 'red' }}>{pwdhintText}</label>}
                            <button
                                className='setup-api-button'
                                onClick={() => {
                                    // Initalization Call here
                                    if (initialization_inputs_valid(password, passwordRepeat)) {
                                        initalizeAPI();
                                    }
                                }}
                            >
                                Intialize API with Admin
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
            <Link to={'/impressum'} className='back-to-impressum'>
                <FaHome style={{margin:'15px', fontSize:'xx-large'}}/>
            </Link>
        </div>
    );
}

export default APISetup;
import { useRef, useState, useEffect } from 'react';
import { FaTrash, FaAngleUp, FaAngleDown, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { PrivacyRule, SecurityLevelData, CompanyPolicy } from '../SettingsTypedefs';
import { Tooltip } from 'react-tooltip';
import Select from "react-select";




export default function SecurityLevelTile({ apiURL, secLvl, onSecurityLevelsChanged, ruleOptions, policyOptions, isMobile, ...props }:
    { apiURL: string, secLvl: SecurityLevelData, onSecurityLevelsChanged: Function, ruleOptions: PrivacyRule[] | null, policyOptions: CompanyPolicy[] | null, isMobile: boolean }) {
    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);
    const [selectedRules, setSelectedRules] = useState<PrivacyRule[] | null>(null)
    const [selectedPolicys, setSelectedPolicys] = useState<CompanyPolicy[] | null>(null);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        console.log("Rule Options Useeffect")
        if (secLvl && ruleOptions) {
            console.log("Rule Options:", ruleOptions)
            console.log("Setting for", secLvl.security_level_name, " With:", ruleOptions.filter((rule) => secLvl.ruleIDs.includes(rule.id!)))
            setSelectedRules(ruleOptions.filter((rule) => secLvl.ruleIDs.includes(rule.id!)));
        }
        if (secLvl && policyOptions) {
            console.log("policyOptions Options:", policyOptions)
            setSelectedPolicys(policyOptions.filter((policy) => secLvl.policyIDs.includes(policy.policyID!)));
        }
    }, [secLvl, ruleOptions, policyOptions])


    async function deleteSecurityLevel() {
        try {
            const response = await fetch(
                apiURL + "/delete_security_level?sec_lvl_id=" + secLvl.security_level,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const data = await response.json();
                console.log(data)
                onSecurityLevelsChanged()
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function updateSecurityLevel(secLvlID: number, secLvlName: string, ruleIDs: number[], policyIDs: number[]) {
        try {
            const response = await fetch(
                apiURL + "/update_security_level",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "security_level": secLvlID,
                            "security_level_name": secLvlName,
                            "ruleIDs": ruleIDs,
                            "policyIDs": policyIDs
                        }
                    )
                },
            );

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                if (response.status === 401 && errorData.detail == "not logged in") {
                    console.log("Catched expired Session");
                    window.location.href = "/session_expired";
                } else {
                    alert("Error: " + response.status);
                }
            } else {
                const data = await response.json();
                console.log(data)
                onSecurityLevelsChanged()
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    return (
        <AnimateHeight
            {...props}
            height={height}
            contentClassName="auto-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ padding: "10px", width: "90%", border: "solid 1px var(--text-icons-2)", borderRadius: "10px", marginBottom: "10px", overflow: "visible" }}>
            <div ref={contentDiv}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                >   <div style={{ flex: "5" }}>
                        {secLvl.security_level_name}
                    </div>
                    <div style={{ flex: "2" }}>
                        Rules: {secLvl.ruleIDs.length}
                    </div>
                    <div style={{ flex: "1", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                        {expanded ?
                            <FaAngleUp onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} /> :
                            <FaAngleDown />
                        }
                    </div>
                </div>
                <div style={expanded ? { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "15px" } :
                    { display: "none", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    { secLvl.security_level !== 2 ?
                    <div style={{display:"flex", flexDirection:"column", width:"100%", justifyContent:"center", alignItems:"center"}}>
                        <div style={{ width: "90%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", position: "relative" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ margin: "5px", fontWeight: 'bold' }}>Rules for Security Level</div>
                                <div data-tooltip-id="SLRTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="SLRTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            The Rules assigned to this Security Level. You can create new rules in the settings below. If the Large Language Model or the Group over which the user has access to it has this Security Level, every Prompt to it will be anonymized according to this Rules.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    }),
                                    menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: "var(--background)",
                                        color: "var(--text-icons)",
                                        zIndex: 10
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        zIndex: 110
                                    }),
                                    group: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        zIndex: 110
                                    }),
                                    valueContainer: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: "var(--background)"
                                    }),
                                    singleValue: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: "var(--background)",
                                    }),
                                    multiValue: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        zIndex: 10
                                    }),
                                    multiValueLabel: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    }),
                                    container: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: "100%",
                                        margin: "5px",
                                    })
                                }}
                                getOptionLabel={(rule) => rule.name}
                                getOptionValue={(rule) => rule.name}
                                options={ruleOptions!}
                                onChange={(selectedOptions) => {
                                    console.log("selectedOptions Rule Names:", selectedOptions);
                                    setSelectedRules([...selectedOptions]);
                                    updateSecurityLevel(secLvl.security_level, secLvl.security_level_name, selectedOptions.map(option => option.id!), selectedPolicys!.map(policy => policy.policyID))
                                }}
                                value={selectedRules}

                                menuPortalTarget={document.body}
                                menuPosition={'absolute'}
                                menuPlacement={'auto'}
                                isMulti={true}
                            />
                        </div>
                        <div style={{ width: "90%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", position: "relative" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Level Policys</div>
                                <div data-tooltip-id="SLPTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="SLPTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            The Policys assigned to this Security Level. You can create new policys in the company settings tab. If the Large Language Model or the Group over which the user has access to it has this Security Level, every Prompt to it will be checked according to this Policy.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    }),
                                    menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: "var(--background)",
                                        color: "var(--text-icons)",
                                        zIndex: 10
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        zIndex: 110
                                    }),
                                    group: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        zIndex: 110
                                    }),
                                    valueContainer: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: "var(--background)"
                                    }),
                                    singleValue: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: "var(--background)",
                                    }),
                                    multiValue: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        zIndex: 10
                                    }),
                                    multiValueLabel: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: "var(--text-icons)",
                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                    }),
                                    container: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: "100%",
                                        margin: "5px",
                                    })
                                }}
                                getOptionLabel={(policy) => policy.policyName}
                                getOptionValue={(policy) => policy.policyName}
                                options={policyOptions!}
                                onChange={(selectedOptions) => {
                                    console.log("selectedOptions Rule Names:", selectedOptions);
                                    setSelectedPolicys([...selectedOptions]);
                                    updateSecurityLevel(secLvl.security_level, secLvl.security_level_name, selectedRules!.map(rule => rule.id!), selectedOptions.map(option => option.policyID!))
                                }}
                                value={selectedPolicys}

                                menuPortalTarget={document.body}
                                menuPosition={'absolute'}
                                menuPlacement={'auto'}
                                isMulti={true}
                            />
                        </div>
                    </div>
                    :
                    <div>
                        The Internal Security Level can be selected for LLMs, that dont run on third party servers and therefore do not need to be checked by Privacy Prompt.
                    </div>
                    }
                    <div className={`settings-button ${isMobile ? "mobile" : ""}`} style={secLvl.security_level === 1 || secLvl.security_level === 2 ? { color: "gray", borderColor: "gray" } : { color: "red", borderColor: "red" }}
                        onClick={secLvl.security_level === 1 || secLvl.security_level === 2 ? () => { } :
                            () => {
                                deleteSecurityLevel();
                            }}
                    >
                        <FaTrash style={{ marginRight: '5px' }} />
                        Delete Security Level
                    </div>
                    {secLvl.security_level === 1 &&
                        <div style={{ fontSize: "12px" }}>
                            The default Security Level cannot be deleted
                        </div>
                    }
                    {secLvl.security_level === 2 &&
                        <div style={{ fontSize: "12px" }}>
                            The internal Security Level cannot be deleted
                        </div>
                    }
                </div>
            </div>
        </AnimateHeight>
    );
}

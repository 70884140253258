import { useEffect, useState} from 'react'
import LoginSite from './LoginSite';
import RegisterSite from './RegisterSite';
import PasswordForgottenSite from './PasswordForgottenSite';
import OTPVerificationSite from './OTPVeryficationSite';
import { FaHome } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import './Login.css'
import { Link , useLocation } from 'react-router-dom';

function LoginInterface({ onLoginSuceeded, apiURL, user, companyData }) {
    const location = useLocation();
    const isRegisterRedirect = location.state?.isRegisterRedirect;
    const [loggedIn, setLoggedIn] = useState(false)
    const [registered, setRegistered] = useState(!isRegisterRedirect ? true : false);
    const [password_reset, setPasswordReset] = useState(false);
    const [twoFABoolean, setTwoFABoolean] = useState(false);
    const [loguser, setLogUser] = useState(null);
    const [apiReady, setAPIReady] = useState(true);



    async function testAPIAlive() {
        if (apiURL) {
            try {
                const response = await fetch(
                    apiURL + "/test_alive",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.status !== 200) {
                    setAPIReady(false);
                    console.log("Response status: " + response.status);
                } else {
                    const data = await response.json();
                    setAPIReady(data.initalized);
                }
            } catch (error) {
                //window.location.href = "/login";
                setAPIReady(false);
                console.log(error);
                alert("Error: Could not reach API");
            }
        } else {
            setAPIReady(false);
        }
    }


    useEffect(() => {
        testAPIAlive();
    }, [])

    if (!apiURL || !apiReady) {
        console.log("Navigate to Impressum because of missing API")
        return <Navigate to="/impressum" />
    }

    if (user) {
        console.log("LoginInterface : Redirectiong to home with set User");
        return <Navigate to="/" />
    }

    return (<div>
        {registered && !loggedIn && !password_reset && (
            <LoginSite
                onPasswordForgotten={togglePasswordReset}
                onRegisterButtonPressed={toggleRegistered}
                onLoginSuceeded={
                    process_login_succeeded
                }
                apiURL={apiURL}
                companyData={companyData} />)}
        {!registered && !loggedIn && !password_reset && (
            <RegisterSite onLoginButtonPressed={toggleRegistered}
                onRegisterSucceeded={toggleRegistered}
                apiURL={apiURL}
            />
        )}
        {password_reset && (
            <PasswordForgottenSite onPasswordUpdated={togglePasswordReset} apiURL={apiURL} />
        )}
        {twoFABoolean && (
            <OTPVerificationSite apiURL={apiURL} user={loguser} onOTPVeryfied={on2FaAuthVerified} isPasswordReset={false} />
        )}
        <Link to={'/impressum'} className='back-to-impressum'>
            <FaHome style={{ margin: '15px', fontSize: 'xx-large' }} />
        </Link>
    </div>
    );
    function on2FaAuthVerified() {
        console.log("2FA Auth completed");
        console.log("Callback with logged User:", loguser)
        onLoginSuceeded(loguser);
    }
    async function process_login_succeeded(user) {
        console.log("Processing succesful user Login and Setting for 2FA Auth recived Data:")
        console.log(user)
        setLogUser(user);
        if (!localStorage.getItem('token')) {
            setLoggedIn(true);
            setTwoFABoolean(true);
        } else {
            onLoginSuceeded(user);
        }

    }
    function togglePasswordReset() {
        setPasswordReset(!password_reset);
    }
    function toggleRegistered() {
        setRegistered(!registered);
    }
}

export default LoginInterface;
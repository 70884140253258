import { useEffect, useState } from "react";
import "./Mock.css"
import { FaPlusCircle } from "react-icons/fa";

function Mock({ }) {

    const [showAlertContainer, setShowAlertContainer] = useState(false);

    const categorys = ["Dringend", "Terminanfragen", "Spam", "Mitarbeiterinfos"]

    const categoryColors = ["#007AA2", "#00B0F0", "#80DAF4", "#C5ECF9", "#E6F4FB"]

    const mailTopics = ["Lorem", "Ipsum", "Dunno", "Further"]

    useEffect(()=> {
        setTimeout(()=> {
            setShowAlertContainer(true)
        }, 2000 )
    },[])

    return (
        <div className="page">
            <div className="category-alert-container">
                <div className="category-container">
                    {
                        categorys.map((cat, index) =>
                            <div className="category-button"  style={{backgroundColor:categoryColors[index]}}>
                                {cat}
                            </div>
                        )
                    }
                    <div className="add-category-container">
                        <input
                            type="text"
                            className="add-category-input"
                            placeholder="New Catgeory Name"
                        />
                        <FaPlusCircle className="add-category-icon"/>
                    </div>
                </div>
                <div className={`alert-container ${showAlertContainer? "active": "hidden"}`}>
                </div>
            </div>
            <div className="controlls-container">
            </div>
            <div className="email-container">
                <div className="controll-category-divider">
                </div>

            </div>
        </div>
    );
}

export default Mock;
import { useRef, useState, useEffect } from 'react';
import { FaTrash, FaAngleUp, FaAngleDown, FaCheck, FaUser, FaUserFriends, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { PricingPreset, PriceLLMConnection } from '../SettingsTypedefs';
import { Tooltip } from 'react-tooltip';



export default function PricingPresetTile({ apiURL, preset, onPresetChanged, isMobile, ...props }:
    { apiURL: string, preset: PricingPreset, onPresetChanged: Function, isMobile:boolean }) {
    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);

    const [editedPresetName, setEditedPrestName] = useState(preset.name);
    const [editedPresetContingent, setEditedPresetContingent] = useState(preset.contingent);
    const [editedPresetContingentType, setEditedPresetContingentType] = useState(preset.contingentType);

    const [groupLLMConnections, setGroupLLMConnections] = useState<PriceLLMConnection[]>([]);
    const [accountLLMConnections, setAccountLLMConnections] = useState<PriceLLMConnection[]>([]);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);
        fetchPriceLLMConnections();
        return () => resizeObserver.disconnect();

    }, []);

    useEffect(() => {
        console.log("accountLLMConnections.length > 0 || groupLLMConnections.length >0", preset.name, accountLLMConnections.length > 0, groupLLMConnections.length > 0)
    }, [accountLLMConnections, groupLLMConnections])

    async function deletePricingPreset() {
        try {
            const response = await fetch(
                apiURL + "/delete_price_preset?presetID=" + preset.id,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
                onPresetChanged()
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function updatePricingPreset() {
        try {
            const response = await fetch(
                apiURL + "/update_price_preset",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "id": preset.id,
                            "name": editedPresetName,
                            "contingent": editedPresetContingent,
                            "contingentType": editedPresetContingentType
                        }
                    )
                },
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
                onPresetChanged()
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function fetchPriceLLMConnections() {
        try {
            const response = await fetch(
                apiURL + "/get_price_preset_llm_connections?presetID=" + preset.id,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                },
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
                setGroupLLMConnections(data.group_connects)
                setAccountLLMConnections(data.personal_connects)
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }



    return (
        <AnimateHeight
            {...props}
            height={height}
            contentClassName="auto-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ padding: "10px", width: "90%", border: "solid 1px var(--text-icons-2)", borderRadius: "10px", marginBottom: "10px", overflow: "visible" }}>
            <div ref={contentDiv}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                >   <div style={{ flex: "5" }}>
                        {preset.name}
                    </div>
                    <div style={{ flex: "2", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <FaUserFriends />
                        {groupLLMConnections.length}
                    </div>
                    <div style={{ flex: "2", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <FaUser />
                        {accountLLMConnections.length}
                    </div>
                    <div style={{ flex: "1", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                        {expanded ?
                            <FaAngleUp onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} /> :
                            <FaAngleDown />
                        }
                    </div>
                </div>
                <div style={expanded ? { display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginTop: "15px" } :
                    { display: "none" }}>




                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <div style={{ margin: "5px", fontWeight: 'bold' }}>Pricing Preset Name</div>
                        <div data-tooltip-id="NPPPNTooltip">
                            <FaInfoCircle />
                            <Tooltip id="NPPPNTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The name under which this preset will be shown in further settings.
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <input
                        value={editedPresetName}
                        onChange={(e => {
                            setEditedPrestName(e.target.value);
                        })}
                        className="settings-input"
                        placeholder="New Preset Name"
                    />
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <div style={{ margin: "5px", fontWeight: 'bold' }}>Preset Contingent</div>
                        <div data-tooltip-id="NPPPCTooltip">
                            <FaInfoCircle />
                            <Tooltip id="NPPPCTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The contingent that will be assigned for the Large Language Models Accesses controlled by this preset. If the contingent is updated in the Preset it will be updated for all accesses as well.
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <input
                        type="number"
                        value={editedPresetContingent}
                        onChange={(e => {
                            setEditedPresetContingent(Number(e.target.value));
                        })}
                        className="settings-input"
                    />

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <div style={{ margin: "5px", fontWeight: 'bold' }}>Contingent Type</div>
                        <div data-tooltip-id="NPPCTTooltip">
                            <FaInfoCircle />
                            <Tooltip id="NPPCTTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    Large Language Model costs are either calculated per Request or per Token. This preset will only be shown for access to those APIs, that use the same contingent type. For token calculation we use cl100k_base tokenization.
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", minHeight: "50px" }}>
                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <div style={{ marginLeft: "5px" }}>
                                {!editedPresetContingentType ? "Contingent per Request" : "Contingent per Token"}
                            </div>
                        </div>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <div style={{ margin: "5px", fontWeight: 'bold' }}>Total contingent controlled by pricing preset</div>
                        <div data-tooltip-id="NPPCTTooltip">
                            <FaInfoCircle />
                            <Tooltip id="NPPCTTooltip" className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    The total contingent controlled by this preset is the sum of the contingent of every access. As every member of a user groups gets the user groups contingent, the number of members of a group can influence the total contingent.
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ fontWeight: 'bold', marginLeft: "15px" }}>
                        {groupLLMConnections.reduce((sum, con) => {
                            return sum + (con.groupMembers! * preset.contingent);
                        }, 0) + accountLLMConnections.length * preset.contingent}{preset.contingentType ? " Tokens per Month" : " Requests per Month"}
                    </div>
                    {(accountLLMConnections.length > 0 || groupLLMConnections.length > 0) &&
                        <div style={{ width: "100%", marginLeft: "5px" }}>
                            <h5>Applies to Connections:</h5>
                            {groupLLMConnections.length > 0 &&
                                <div>
                                    <h6>Group LLM API Conntections:</h6>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: "100%", fontWeight: "bold" }}>
                                        <div style={{ flex: "1" }}>LLM API Name</div>
                                        <div style={{ flex: "1" }}>Group Name</div>
                                        <div style={{ flex: "1" }}>Number of Members</div>
                                    </div>
                                    {groupLLMConnections.map((glac) => (
                                        <div
                                            style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: "100%" }}
                                            key={"groupconnection" + glac.llmName + glac.name}>
                                            <div style={{ flex: "1" }}>{glac.llmName}</div>
                                            <div style={{ flex: "1" }}>{glac.name}</div>
                                            <div style={{ flex: "1" }}>Members: {glac.groupMembers}</div>
                                        </div>
                                    ))}
                                </div>
                            }
                            {accountLLMConnections.length > 0 &&
                                <div>
                                    <h6>Account LLM API Conntections:</h6>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: "100%", fontWeight: "bold" }}>
                                        <div style={{ flex: "1" }}>LLM API Name</div>
                                        <div style={{ flex: "1" }}>User Name</div>
                                    </div>
                                    {accountLLMConnections.map((alac) => (
                                        <div
                                            style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: "100%" }}
                                            key={"persconnection" + alac.llmName + alac.name}>
                                            <div style={{ flex: "1" }}>{alac.llmName}</div>
                                            <div style={{ flex: "1" }}>{alac.name}</div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }




                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <div className={`settings-button ${isMobile? "mobile" : ""}`} style={{ color: "red", borderColor: "red" }}
                            onClick={() => {
                                deletePricingPreset();
                            }}
                        >
                            <FaTrash style={{ marginRight: '5px' }} />
                            Delete Preset
                        </div>
                        <div className={`settings-button ${isMobile? "mobile" : ""}`}
                            onClick={
                                () => {
                                    updatePricingPreset();
                                }}
                        >
                            Confirm Changes
                            <FaCheck style={{ color: "green", borderColor: "green" }} />

                        </div>
                    </div>

                </div>
            </div>
        </AnimateHeight>
    );
}

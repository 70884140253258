import React, { useEffect, useState, useRef } from "react";
import { FaTrash } from 'react-icons/fa';
import { FaEllipsisV } from "react-icons/fa";
import { FaPen } from "react-icons/fa";
import './TopicSelection.css'
export default function TopicSelectionTile({ topic, isSelected, topicIndex, onTopicSelected, onTopicRemoved, apiURL }) {
    const [topicMenuActive, setTopicMenuActive] = useState(false);
    const [isRenameMode, setIsRenameMode] = useState(false);
    const [renameText, setRenameText] = useState("")
    const topicMenuRef = useRef(null);
    const renameInputRef = useRef(null);



    useEffect(() => {
        function handleClickOutside(event) {
            //console.log("END RENAME MODE LOGIC:", topicMenuActive, isRenameMode, renameInputRef.current, renameInputRef.current && !renameInputRef.current.contains(event.target));
            if (topicMenuActive && topicMenuRef.current && !topicMenuRef.current.contains(event.target)) {
                setTopicMenuActive(false);
            }
            if (isRenameMode && renameInputRef.current && !renameInputRef.current.contains(event.target)) {
                //console.log("Click outside of renaming field");
                setIsRenameMode(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isRenameMode, topicMenuActive]);

    async function remove_topic(topicID) {
        var apiLink = apiURL + "/delete_topic?topicID=" + topicID;
        fetch(apiLink, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((response) => {
            // process response
            // console.log(response);
            if (response.status !== 200) {
                // error
                response.json().then((data) => {
                    //console.log(data);
                    alert("Error: " + response.status);
                });
            } else {
                // success
                response.json().then((data) => {
                    //console.log(data);
                    onTopicRemoved();
                });
            }
        });
    }

    async function rename_topic() {
        console.log("Renaming call")
        const encodedTopic = encodeURIComponent(renameText);
        var apiLink = apiURL + "/rename_topic?topic_name=" + encodedTopic + "&topic_id=" + topic.topic_id;
        fetch(apiLink, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((response) => {
            // process response
            // console.log(response);
            if (response.status !== 200) {
                // error
                response.json().then((data) => {
                    console.log(data);
                    alert("Error: " + response.status);
                });
            } else {
                // success
                response.json().then((data) => {
                    //console.log(data);
                    onTopicRemoved();
                });
            }
        });

    }

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            {isRenameMode ? <div className="topic-bubble-container-selected" style={{ alignItems: 'center', justifyContent: 'center' }}>
                <input
                    ref={renameInputRef}
                    className="rename-topic-input"
                    type="text"
                    value={renameText}
                    onChange={(e) => setRenameText(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            rename_topic();
                            setIsRenameMode(false); // Exit rename mode after Enter key is pressed
                        }
                    }}
                ></input>
            </div> :
                <div className={isSelected || topicMenuActive ? 'topic-bubble-container-selected' : 'topic-bubble-container'} key={'topic-' + topicIndex}>
                    <button className="topic-bubble" key={topicIndex}
                        onClick={() => {
                            onTopicSelected();
                        }}>
                        {topic.topic_name}
                    </button>
                    <button className="topic-menu-button" onClick={(event) => {
                        event.stopPropagation();
                        //console.log("Sttiong Topic Menu active with:", !topicMenuActive)
                        setTopicMenuActive(!topicMenuActive);
                        //remove_topic(topic[0])
                    }}>
                        <FaEllipsisV className="customize-menu-icon" />
                    </button>

                </div>}
            {topicMenuActive &&
                <div className="topic-menu-wrapper" ref={topicMenuRef}>
                    <div className="topic-menu">
                        <div
                            className="topic-menu-tile"
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsRenameMode(true);
                                setRenameText(topic.topic_name);
                                setTopicMenuActive(false);
                            }
                            }>
                            <FaPen className="customize-trash-icon" style={{ margin: '20px' }} />
                            <label style={{ marginRight: '20px' }}>Rename Topic</label>
                        </div>
                        <div
                            className="topic-menu-tile"
                            style={{ color: 'red' }}
                            onClick={() => {
                                remove_topic(topic.topic_id);
                                onTopicRemoved();
                                setTopicMenuActive(false);
                            }}>
                            <FaTrash className="customize-trash-icon" style={{ margin: '20px' }} />
                            <label style={{ marginRight: '20px' }}>Delete Topic</label>
                        </div>
                    </div>
                </div>}
        </div>
    );
}

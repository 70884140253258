import { useState } from 'react'
import OTPVerificationSite from './OTPVeryficationSite';
import './Login.css'

function PasswordForgottenSite({ onPasswordUpdated, apiURL }) {
    const [user, setUser] = useState(null);
    const [email_verified, setEmailVerified] = useState(false);
    const [email_known, setEmailKnown] = useState(false);
    const [email, setEmail] = useState("");
    const [errorText, setErrorText] = useState(""); // Add state for error text
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");


    async function checkMailAvailable() {
        console.log("Checking if User is existing for Passsword reset");
        const userData = {
            'email': email,
            'otp': "",
        };
        try {
            const response = await fetch(apiURL + '/getuserverification', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(userData)
            });
            const data = await response.json();
            if (data.status === "success") {
                // API call succeeded (status code 200)
                console.log(JSON.stringify(data));
                console.log("Succes Respone from Login API");
                if (await generateNewOTPToken()) {
                    setEmailKnown(true);
                    setUser(data.user)
                } else {
                    setErrorText("No such email known");
                }
            } else {
                // API call failed
                const detail = data.detail;
                console.log(data);
                if (Array.isArray(detail)) {
                    const errorMessages = detail.map((info) => info.msg);
                    setErrorText(errorMessages.join(" "));
                } else {
                    setErrorText("No such email known");
                } // Display the error message from the server
            }
        } catch (error) {
            // Handle any unexpected errors (e.g., network error)
            setErrorText("An error occurred while processing your request. Please try again later.");
        }
    }

    async function generateNewOTPToken() {
        console.log("Calling for new OTP token");
        const userData = {
            'email': email,
            'otp': "",
        };
        try {
            const response = await fetch(apiURL + '/generate_otp_password_reset', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(userData)
            });
            const data = await response.json();
            if (data.status === "success") {
                // API call succeeded (status code 200)
                console.log(JSON.stringify(data));
                console.log("Succes Respone from Login API");
                return true;
            } else {
                // API call failed
                const detail = data.detail;
                console.log(data);
                if (Array.isArray(detail)) {
                    const errorMessages = detail.map((info) => info.msg);
                    setErrorText(errorMessages.join(" "));
                    return false;
                } else {
                    setErrorText("No such email known");
                    return false;
                } // Display the error message from the server
            }
        } catch (error) {
            // Handle any unexpected errors (e.g., network error)
            setErrorText("An error occurred while processing your request. Please try again later.");
            return false;
        }
    }

    async function onOTPVeryfied() {
        setEmailVerified(true);
    }
    async function updatePassword() {
        if (password !== passwordRepeat) {
            setErrorText("Passwords are not equal");
        } else {
            const userData = {
                'email': user.email,
                'password': password,
            };
            try {
                const response = await fetch(apiURL + '/updatepassword', {
                    method: "POST",
                    headers: { "Content-Type": "application/json", 
                    Authorization: "Bearer " + localStorage.getItem("token"),},
                    body: JSON.stringify(userData)
                });
                const data = await response.json();
                if (data.status === "success") {
                    // API call succeeded (status code 200)
                    console.log(JSON.stringify(data));
                    console.log("Succes Respone from Login API");
                    onPasswordUpdated(); // Call the success callback
                } else {
                    // API call failed
                    const detail = data.detail;
                    console.log("No Succes from API Response");
                    if (Array.isArray(detail)) {
                        const errorMessages = detail.map((info) => info.msg);
                        setErrorText(errorMessages.join(" "));
                    } else {
                        setErrorText(data.detail);
                    } // Display the error message from the server
                }
            } catch (error) {
                // Handle any unexpected errors (e.g., network error)
                setErrorText("An error occurred while processing your request. Please try again later.");
            }
        }
    }

    return (
        email_known ?
            email_verified ?
                <header className='login-content'>
                    <h3>Password</h3>
                    <input
                        type='password'
                        placeholder='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}>
                    </input>
                    <h3>Repeat password</h3>
                    <input
                        type='password'
                        placeholder='repeat password'
                        value={passwordRepeat}
                        onChange={(e) => setPasswordRepeat(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                updatePassword();
                            }
                        }}
                        >
                    </input>
                    {errorText && <p className="error-text">{errorText}</p>} {/* Display error text if available */}
                    <button className="button button1" onClick={updatePassword}>
                        UpdatePassword
                    </button>
                </header> :
                <header className='login-content'>
                    <OTPVerificationSite apiURL={apiURL} onOTPVeryfied={onOTPVeryfied} user={user} isPasswordReset={true} />
                </header> :
            <header className='login-content'>
                <h3>Please enter mail adress to reset Password</h3>
                <input
                    type='text'
                    className='login-input'
                    placeholder='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            checkMailAvailable();
                        }
                    }}
                    >
                </input>
                {errorText && <p className="error-text">{errorText}</p>} {/* Display error text if available */}
                <button className="login-button" onClick={checkMailAvailable}>
                    Send verification code to mail
                </button>
            </header>
    );

}

export default PasswordForgottenSite;
import React, { useRef, useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faArrowRightFromBracket, faHome } from '@fortawesome/free-solid-svg-icons';
import './AccountTile.css'
import { Link } from "react-router-dom";

export default function AccountTile({ userData, onLogout, isSettingsContext = false }) {
    const [accountMenuActiv, setAccountMenuActive] = useState(false)
    const accountMenuRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (accountMenuActiv && accountMenuRef.current && !accountMenuRef.current.contains(event.target)) {
                setAccountMenuActive(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [accountMenuActiv]);

    return (
        <div className='account-tile' ref={accountMenuRef}
            onClick={() => {
                setAccountMenuActive(!accountMenuActiv)
            }}
        >
            <FaUser className='account-icon' style={{ margin: '8px' }} />
            {userData.name}
            {accountMenuActiv && <div className='account-menu'>
                <div className='account-menu-mail'>{userData.email}</div>
                {isSettingsContext ?
                    <Link className='account-menu-tile' to="/" >
                        <FontAwesomeIcon icon={faHome} style={{ margin: '8px', fontSize: '24px' }} />
                        <span>Home</span>
                    </Link>
                    :
                    <Link className='account-menu-tile' to="/settings" >
                        <FontAwesomeIcon icon={faGear} style={{ margin: '8px', fontSize: '24px' }} />
                        <span>Settings</span>
                    </Link>}
                <div className='account-menu-tile'
                    onClick={() => {
                        onLogout();
                    }}
                >
                    <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ margin: '8px', fontSize: '24px' }} />
                    Log out
                </div>
            </div>}
        </div>
    );
};


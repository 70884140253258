import { useEffect, useState } from "react";
import { FaPlusCircle, FaTimes, FaTrash, FaInfoCircle } from "react-icons/fa";
import { PrivacyRule, Bucket, BucketConnection, SecuredDataTable } from "../SettingsTypedefs";
import Select from "react-select";
import Switch from 'react-switch';
import SecurityRuleTile from "./SecurityRuleTile";
import BucketColumnTile from "./BucketColumnTile";
import { Tooltip } from "react-tooltip";

export default function SecurityRuleSettings({ apiURL, securityRules, securedTables, onSecurityRulesChanged, isMobile, ...props }:
    { apiURL: string, securityRules: PrivacyRule[], securedTables: SecuredDataTable[], onSecurityRulesChanged: Function, isMobile: boolean }) {

    type OptionType = {
        value: number;
        label: string;
    };
    const ruleTypeOptions: OptionType[] = [
        {
            value: 0,
            label: "Not in Chat History"

        },
        {
            value: 1,
            label: "Not in Chat Message"

        },
        {
            value: 2,
            label: "Not in Sentence"

        }
    ]

    const [newSecurityRuleMode, setNewSecurityRuleMode] = useState(false);
    const [newSecurityRuleName, setNewSecurityRuleName] = useState("")
    const [newSecurityRuleType, setNewSecurityRuleType] = useState(ruleTypeOptions[0]);
    const [newSecurityRuleBuckets, setNewSecurityRulesBuckets] = useState<Bucket[]>([{
        columns: [],
        type: false,
        id: null,
    }])
    const [newSecurityRuleBucketConnections, setNewSecurityRuleBucketConnections] = useState<BucketConnection[]>([]);


    useEffect(() => {
        console.log("Security Rules changed to :", securityRules)
    }, [securityRules])

    async function postNewSecurityRule() {
        try {
            const response = await fetch(
                apiURL + "/add_new_privacy_rule",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "name": newSecurityRuleName,
                            "level": newSecurityRuleType.value,
                            "buckets": newSecurityRuleBuckets,
                            "bucket_connects": newSecurityRuleBucketConnections
                        }
                    )
                }
            );
            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
                clearRuleCreationInputs();
                setTimeout(() => {
                    onSecurityRulesChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    function clearRuleCreationInputs() {
        setNewSecurityRuleName("");
        setNewSecurityRuleType(ruleTypeOptions[0]);
        setNewSecurityRulesBuckets([{
            columns: [],
            type: false,
            id: null,
        }]);
        setNewSecurityRuleBucketConnections([]);
        setNewSecurityRuleMode(false);
    }

    return (
        <div className="subsetting-content">
            <center><h4>Security Rules</h4></center>
            {!newSecurityRuleMode ?
                <div
                    className={`settings-button ${isMobile ? "mobile" : ""}`}
                    onClick={() => {
                        setNewSecurityRuleMode(true);
                    }}
                    data-tooltip-id="AddNSRTooltip"
                >
                    Add new Security Rule
                    <FaPlusCircle className="add-element-icon" />
                    <Tooltip id="AddNSRTooltip" className="custom-tooltip" delayShow={800}>
                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                            You can add a new Security Rule here. Rules have Buckets in which you can define the columns of your table, which should not occur in a message to the Large Language Model API. If you have multiple Buckets you can define logic connections between them to declare when the Rule is broken.
                        </div>
                    </Tooltip>
                </div>
                :
                <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div className="setting-add-element-container">
                        <div style={isMobile ? { display: "flex", flexDirection: "column", width: "90%", alignItems: "flex-start", justifyContent: "center" } : 
                            { display: "flex", flexDirection: "row", width: "90%", alignItems: "flex-start", justifyContent: "center" }}>
                            <div style={{ display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                    <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Rule Name</div>
                                    <div data-tooltip-id="NSRRNTooltip">
                                        <FaInfoCircle />
                                        <Tooltip id="NSRRNTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The name this Security Rule will be diplayed under in further settings.
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <input
                                    value={newSecurityRuleName}
                                    onChange={(e => {
                                        setNewSecurityRuleName(e.target.value);
                                    })}
                                    className="settings-input"
                                    placeholder="New Security Rule Name"
                                />
                            </div>
                            <div style={{ display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                    <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Rule Type</div>
                                    <div data-tooltip-id="NSRRTTooltip">
                                        <FaInfoCircle />
                                        <Tooltip id="NSRRTTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The Rule Type defines, how much of a conversation with a Large Language Model is checked before the prompt is sent to the API. For example, two messages which each contain part of a whole Bucket will fulfill the Rule if this value is set to "Not in Chat Message", but break the Rule if its set to "Not in Chat History"
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <Select
                                    styles={
                                        {
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                            }),
                                            menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "var(--background)",
                                                color: "var(--text-icons)"
                                            }),
                                            option: (baseStyles, state) => ({
                                                ...baseStyles,
                                                zIndex: 110,
                                                color: "var(--text-icons)",
                                                backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                overflowX: "hidden",
                                                textOverflow: "ellipsis"
                                            }),
                                            valueContainer: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: "var(--background)",
                                                overflowX: "hidden"
                                            }),
                                            singleValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: "var(--background)"
                                            }),
                                            multiValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                            }),
                                            multiValueLabel: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                            }),
                                            container: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: "100%",
                                                margin: "5px"
                                            })
                                        }}
                                    options={ruleTypeOptions}
                                    onChange={(selectedOptions) => {
                                        console.log("selectedOptions:", selectedOptions);
                                        console.log("selectedOptions VALUE:", selectedOptions!.value)
                                        setNewSecurityRuleType(selectedOptions!)
                                    }}
                                    value={newSecurityRuleType}
                                    isMulti={false}
                                />
                            </div>
                        </div>



                        {newSecurityRuleBuckets.map((bucket, index) => (
                            <div
                                style={isMobile ? { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", borderTop: "solid 2px var(--gray-500)", marginTop: "20px", paddingTop: "15px", position: "relative", alignSelf: "center", justifySelf: "center" } :
                                    { display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", width: "100%", borderTop: "solid 2px var(--gray-500)", marginTop: "20px", paddingTop: "20px", position: "relative", alignSelf: "center", justifySelf: "center" }}
                                key={"new_bucket" + index}>
                                <div style={isMobile ? { display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", marginBottom: "5px", width:"100%" } :
                                    { display: "flex", flex: "3", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", marginBottom: "20px" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Rule Bucket</div>
                                        <div data-tooltip-id={"NSRTooltipBucket:" + index} >
                                            <FaInfoCircle />
                                            <Tooltip id={"NSRTooltipBucket:" + index} className="custom-tooltip">
                                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                    A security Rule Bucket defines Column name that should not occure. If the Bucket logic is OR none of the Values in the Column are allowed to occure. If the Bucket logic is AND the Values of a single table row are not allowed to occure together.
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div style={{ width: "90%" }}>
                                        <Select
                                            styles={
                                                {
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    }),
                                                    menu: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        backgroundColor: "var(--background)",
                                                        color: "var(--text-icons)",
                                                        zIndex: 10
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                        zIndex: 110,
                                                        overflowX: "hidden",
                                                        textOverflow: "ellipsis"
                                                    }),
                                                    group: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        zIndex: 110
                                                    }),
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: "var(--background)"
                                                    }),
                                                    singleValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: "var(--background)",
                                                    }),
                                                    multiValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    }),
                                                    multiValueLabel: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",

                                                    }),
                                                    container: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        width: "100%",
                                                        margin: "5px",

                                                    })
                                                }}
                                            options={newSecurityRuleBuckets[index].columns.length > 0 ?
                                                securedTables.filter(table => table.tableID === newSecurityRuleBuckets[index].columns[0].tableID)
                                                    .flatMap(table => table.columns.map((column) => ({
                                                        columnID: column.id,
                                                        name: column.name,
                                                        tableID: column.tableID,
                                                        phoneticCheck: false,
                                                        spellCheck: false
                                                    }))) :
                                                securedTables.flatMap(table => table.columns.map((column) => ({
                                                    columnID: column.id,
                                                    name: column.name,
                                                    tableID: column.tableID,
                                                    phoneticCheck: false,
                                                    spellCheck: false
                                                })))
                                            }
                                            getOptionLabel={(column) => column.name + "(" + securedTables.filter(table => table.tableID === column.tableID)[0].tableName + ")"}
                                            getOptionValue={(column) => column.name}
                                            onChange={(selectedOptions) => {
                                                console.log("selectedOptions Column Names:", selectedOptions);

                                                const updatedBuckets = [...newSecurityRuleBuckets];
                                                updatedBuckets[index] = { ...updatedBuckets[index], columns: [...selectedOptions] || [] };
                                                setNewSecurityRulesBuckets(updatedBuckets);

                                                console.log("Bucket Column Names:", newSecurityRuleBuckets[index].columns);
                                            }}
                                            value={newSecurityRuleBuckets[index].columns}
                                            isMulti={true}
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", width: "100%", marginLeft:"10px" }}>
                                        {bucket.columns.map((bucketColumn, bcIdx) => (
                                            <BucketColumnTile
                                                key={"new_bucket" + index + "bucketColumn" + bcIdx}
                                                bucketColumn={bucketColumn}
                                                onBucketColumnTileChanged={(updatedBucketColumn) => {
                                                    console.log("Button Column Changed")
                                                    var updatedBuckets = [...newSecurityRuleBuckets];
                                                    var updatedColumns = [...updatedBuckets[index].columns]
                                                    updatedColumns[bcIdx] = updatedBucketColumn
                                                    updatedBuckets[index] = { ...updatedBuckets[index], columns: updatedColumns };
                                                    setNewSecurityRulesBuckets(updatedBuckets);
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                                {/* Bucket Logic and delete components */}
                                <div style={{ display: "flex", flex: "1", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "flex-start", marginBottom:"15px" }}>
                                    <div style={{ margin: "5px", fontWeight: 'bold' }}>Bucket Logic</div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "80%", justifyContent: "center" }}>
                                        <div style={{ flex: "1", marginRight: "10px", textAlign: "center" }}> {newSecurityRuleBuckets[index].type ? "AND" : "OR"} </div>
                                        <div style={{ flex: "1" }}>
                                            <Switch
                                                checked={newSecurityRuleBuckets[index].type}
                                                onChange={() => {
                                                    console.log("Switching Bucket Type")
                                                    const updatedBuckets = [...newSecurityRuleBuckets];
                                                    updatedBuckets[index] = { ...updatedBuckets[index], type: !updatedBuckets[index].type };
                                                    setNewSecurityRulesBuckets(updatedBuckets);
                                                }}
                                                offColor="#676767"
                                                onColor="#676767"
                                                checkedIcon={
                                                    <div style={{ textAlign: "center" }}>
                                                        &
                                                    </div>
                                                }
                                                uncheckedIcon={
                                                    <div style={{ textAlign: "center" }}>
                                                        |
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`settings-button ${isMobile ? "mobile" : ""}`}
                                        style={{ height: "inherit", maxHeight: "40px" }}
                                        onClick={() => {
                                            const updatedBuckets = [...newSecurityRuleBuckets].filter((val, idx) => index !== idx);
                                            setNewSecurityRulesBuckets(updatedBuckets);
                                            setNewSecurityRuleBucketConnections((prevConnects) => prevConnects.filter((_, i) => {
                                                if (index > 0) {
                                                    return i !== index - 1
                                                } else
                                                    return i !== 0

                                            }));
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div>Remove Bucket</div>
                                            <FaTrash className="cancel-delete-icon" />
                                        </div>
                                    </div>
                                </div>
                                {index < newSecurityRuleBuckets.length - 1 &&
                                    <div className="bucket-connection-tile" data-tooltip-id={"NewRuleBucket" + index}>Connection Logic
                                        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                                            {newSecurityRuleBucketConnections[index].connectionType ? "AND" : "OR"}
                                        </div>
                                        <Switch
                                            checked={newSecurityRuleBucketConnections[index].connectionType}
                                            onChange={() => {
                                                console.log("Switching Bucket Connection Type")

                                                const updatedBucketConnections = [...newSecurityRuleBucketConnections];
                                                updatedBucketConnections[index] = { ...newSecurityRuleBucketConnections[index], connectionType: !newSecurityRuleBucketConnections[index].connectionType };
                                                setNewSecurityRuleBucketConnections(updatedBucketConnections);

                                            }}
                                            offColor="#676767"
                                            onColor="#676767"
                                            checkedIcon={
                                                <div style={{ textAlign: "center" }}>
                                                    &
                                                </div>
                                            }
                                            uncheckedIcon={
                                                <div style={{ textAlign: "center" }}>
                                                    |
                                                </div>
                                            }
                                        />
                                        <Tooltip id={"NewRuleBucket" + index} className="custom-tooltip" delayShow={500}>
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The Bucket Connection Logic defines which Buckets need to have matches for the Rule to be broken.
                                            </div>
                                        </Tooltip>
                                    </div>}
                            </div>
                        ))}


                        <div style={{ width: "100%", borderTop: "solid 2px var(--gray-500)" }} />
                        <div
                            className={`settings-button ${isMobile ? "mobile" : ""}`}
                            style={{ height: "inherit" }}
                            onClick={() => {
                                const updatedBuckets = [...newSecurityRuleBuckets,
                                {
                                    columns: [],
                                    type: false,
                                    id: null,
                                }
                                ];

                                if (updatedBuckets.length >= 2) {
                                    const updatedBucketConnections = [...newSecurityRuleBucketConnections,
                                    {
                                        idA: null,
                                        idB: null,
                                        connectionType: false
                                    }]
                                    setNewSecurityRuleBucketConnections(updatedBucketConnections);
                                }
                                setNewSecurityRulesBuckets(updatedBuckets);
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>New Logic Bucket</div>
                                <div style={{ color: "blue", marginLeft: "15px", textAlign: "center" }}> &| </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "center", justifyContent: "center", alignSelf: "center" }}>
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                style={{ height: "inherit" }}
                                onClick={() => {
                                    setNewSecurityRuleMode(false);
                                    clearRuleCreationInputs();
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>Cancel</div>
                                    <FaTimes className="cancel-delete-icon" />
                                </div>
                            </div>
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                onClick={() => {
                                    postNewSecurityRule();
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>Add</div>
                                    <FaPlusCircle className="confirm-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={`settings-subheader ${isMobile? "mobile" : ""}`}>Existing Security Rules:</div>
            <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                {securityRules && securityRules.map((rule, ruleidx) =>
                    <SecurityRuleTile
                        key={"existing_rule" + ruleidx}
                        apiURL={apiURL}
                        rule={rule}
                        securedTables={securedTables}
                        onSecurityRulesChanged={() => {
                            onSecurityRulesChanged();
                        }}
                        isMobile={isMobile}
                    />
                )}
            </div>
        </div>
    );
}

import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/free-regular-svg-icons";
import { faMicrophone, faStop, faImage, faPaperclip, faTrash, faFile } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from 'react-tooltip';

const ChatInput = forwardRef(({ apiURL, queryText, onChatInputChanged, onChatInputSubmit, imagesAllowed, isGenerating }, ref) => {
    const [attachments, setAttachments] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [attachementMenuExpanded, setAttachmentMenuExpanded] = useState(false);
    const chatInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const docInputRef = useRef(null);
    const attachmentMenuRef = useRef(null);
    const attachmentMenuButtonRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    const [audioDataBuffer, setAudiDataBuffer] = useState(null);
    const audioBufferRef = useRef(null);
    const isRecordingRef = useRef(null);
    const queryTextRef = useRef(null);
    const [lastTranscription, setLastTranscription] = useState(null);
    const lastTranscriptionRef = useRef(null);

    useEffect(() => {
        lastTranscriptionRef.current = lastTranscription;
    }, [lastTranscription])

    useEffect(() => {
        queryTextRef.current = queryText
    }, [queryText])

    useEffect(() => {
        console.log("IsRecordingRef changed to:", isRecording)
        isRecordingRef.current = isRecording;
    }, [isRecording])


    useEffect(() => {
        audioBufferRef.current = audioDataBuffer;
    }, [audioDataBuffer])

    const handleStartRecording = async () => {
        try {
            setIsRecording(true);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            var options = {
                audioBitsPerSecond: 32000,
                mimeType: 'audio/webm;codecs=opus',
            }

            const mediaRecorder = new MediaRecorder(stream, options);
            mediaRecorderRef.current = mediaRecorder;


            let chunks = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                    const audioBlob = new Blob(chunks, { type: 'audio/webm;codecs=opus' });
                    console.log("Sending audio chunk...");
                    sendAudioChunk(audioBlob);
                    console.log("Post fetch resetting chunks");
                }
            }

            mediaRecorder.onstop = async (event) => {
                console.log("OnAudioStop Event:", event);
                console.log("Len of chunks in stop:", chunks.length)
                if (stream) {
                    stream.getTracks().forEach(track => track.stop());
                }
                if (chunks.length > 0) {
                    if (!isRecordingRef.current) {
                        chunks = []
                    }
                }
            }
            mediaRecorder.start(2000);
        } catch {
            console.log("Error setting up microphone")
            setIsRecording(false);
            alert("Could not find Microphone")
        }
    };

    const handleStopRecording = () => {
        console.log("mediaRecorderRef", mediaRecorderRef.current)
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        setIsRecording(false);
    };

    const sendAudioChunk = async (chunk) => {
        const formData = new FormData();
        console.log("MIME type of the chunk:", chunk.type);
        formData.append('file', chunk, 'recording.webm');
        console.log("chunk:", chunk);
        console.log("FormData entries:", [...formData.entries()]);
        try {
            const response = await fetch(apiURL + '/transcribe', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const transcription = data.transcription;
            insertTextAtCursor(transcription)
            setLastTranscription(data.transcription);
            console.log('Server response:', transcription);
        } catch (error) {
            console.error('Error sending audio data:', error);
        }
    };



    useEffect(() => {
        if (!imagesAllowed) {
            setAttachments(attachments.filter(attachment => attachment.attachmentType !== 0))
        }
    }, [imagesAllowed])


    useEffect(() => {
        console.log("Attachments:", attachments)
    }, [attachments])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (attachmentMenuRef.current && !attachmentMenuRef.current.contains(event.target) &&
                attachmentMenuButtonRef.current && !attachmentMenuButtonRef.current.contains(event.target)) {
                console.log("Click outside Attachment Menu")
                setAttachmentMenuExpanded(false);
            }
        };
        if (attachmentMenuRef.current) {
            if (attachementMenuExpanded) {
                attachmentMenuRef.current.classList.add("expanded")
                attachmentMenuRef.current.style.width = "auto";
                attachmentMenuRef.current.style.height = "auto";
                const newNaturalWidth = attachmentMenuRef.current.scrollWidth;
                const newNaturalHeight = attachmentMenuRef.current.scrollHeight;
                attachmentMenuRef.current.style.width = "0px";
                attachmentMenuRef.current.style.height = "0px";
                requestAnimationFrame(() => {
                    attachmentMenuRef.current.style.width = `${newNaturalWidth}px`
                    attachmentMenuRef.current.style.height = `${newNaturalHeight}px`
                })
                document.addEventListener("mousedown", handleClickOutside);
            }
            else {
                setTimeout(() => {
                    attachmentMenuRef.current.classList.remove("expanded")
                }, 500)
                requestAnimationFrame(() => {
                    attachmentMenuRef.current.style.width = `0px`
                    attachmentMenuRef.current.style.height = `0px`
                })
            }
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [attachementMenuExpanded])

    useImperativeHandle(ref, () => ({
        add_attachment(attachment) {
            console.log("Imperative handle ChatInput recived attachment:", attachment)
            setAttachments(prevAttachments => [...prevAttachments, {
                "attachmentType": attachment.attachmentType,
                "encodedData": attachment.encodedData,
                "attachmentFileName": attachment.attachmentFileName,
                "attachmentID": null,
                "conversationID": null,
                "file": attachment.file
            }
            ]);
        }
    }));

    const insertTextAtCursor = (text) => {
        const textarea = chatInputRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const lastTranscription = lastTranscriptionRef.current || "";
        const textBefore = queryTextRef.current ? queryTextRef.current.slice(0, start) : "";
        const textAfter = queryTextRef.current ? queryTextRef.current.slice(end) : "";
        console.log("textBeforeCursor:", textBefore, "last Transcription:", lastTranscription);
        let newTextBefore = textBefore.trimEnd();
        if (newTextBefore.endsWith(lastTranscription)) {
            newTextBefore = textBefore.slice(0, textBefore.length - lastTranscription.length);
        }
        const updatedText = newTextBefore + text + " " + textAfter;
        const updated_and_trimmed = updatedText.trim()
        onChatInputChanged(updated_and_trimmed);
        const newCursorPosition = updated_and_trimmed.length + 1;
        textarea.selectionStart = textarea.selectionEnd = newCursorPosition;
        textarea.focus();
        handleTextareaChange();
    };


    const handleTextareaChange = () => {
        const textarea = document.getElementById("chatInput");
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (e.shiftKey) {
                const textarea = e.target;
                const cursorPosition = textarea.selectionStart;
                const textBeforeCursor = queryText.slice(0, cursorPosition);
                const textAfterCursor = queryText.slice(cursorPosition);
                onChatInputChanged(textBeforeCursor + textAfterCursor);
                setTimeout(() => {
                    textarea.selectionStart = textarea.selectionEnd = cursorPosition + 1;
                }, 0);
            } else if (queryText.trim() !== "" && !isGenerating) {
                onChatInputSubmit(attachments);
                const textarea = e.target;
                textarea.style.height = "auto";
                textarea.style.height = textarea.scrollHeight + "px";
                setAttachments([]);
                setTimeout(() => {
                    handleTextareaChange();
                    onChatInputChanged("");
                }, 100)

            }
        }
    };



    const handleImageInputChange = (e) => {
        const imageInput = e.target;
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name
            const reader = new FileReader();
            reader.onloadend = () => {
                let base64String = reader.result;
                if (base64String.startsWith('data:image/')) {
                    base64String = base64String.split(',')[1];
                }
                setAttachments(prevAttachments => [...prevAttachments, {
                    "attachmentType": 0,
                    "encodedData": base64String,
                    "attachmentFileName": fileName,
                    "attachmentID": null,
                    "conversationID": null,
                    "file": file
                }
                ]);
            };
            reader.readAsDataURL(file);
        }
        imageInput.value = null;
    };

    const handleDocInputChange = (e) => {
        const docInput = e.target;
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name
            setAttachments(prevAttachments => [...prevAttachments, {
                "attachmentType": 1,
                "encodedData": null,
                "attachmentFileName": fileName,
                "attachmentID": null,
                "conversationID": null,
                "file": file
            }
            ]);
        }
        docInput.value = null;
    };


    return (
        <div className="bottom-container" id="chatInputContainer">
            <div className="chat-input-container">
                <div style={{ width: "80%" }}>
                    {/* Attachments Display */}
                    {attachments.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-start" }}>
                            {attachments.map((attachment, index) => (
                                <div key={index} className="attachment-preview" style={{ margin: '5px' }}>
                                    {attachment.attachmentType === 0 ?
                                        <img
                                            src={`data:image/png;base64,${attachment.encodedData}`}
                                            alt={`Uploaded preview ${index}`}
                                            style={{
                                                maxHeight: '100px',
                                                maxWidth: '200px',
                                                width: 'auto',
                                                height: 'auto',
                                                objectFit: 'contain'
                                            }}
                                        /> :
                                        <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <FontAwesomeIcon icon={faFile} size="xl" />
                                        </div>
                                    }
                                    <div className="attachment-preview-label">
                                        {attachment.attachmentFileName}
                                    </div>
                                    <FontAwesomeIcon icon={faTrash} className="remove-attachment-button"
                                        onClick={() => {
                                            setAttachments(attachments.filter((uatt, uidx) => uidx !== index))
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Textinput */}
                    <textarea
                        id="chatInput"
                        ref={chatInputRef}
                        className="new_privat_data_input"
                        style={{ height: "100%", borderRadius: "25px" }}
                        type="text"
                        placeholder="Enter Text to Filter for Private Data"
                        value={queryText}
                        maxLength={50000}
                        onChange={(e) => {
                            onChatInputChanged(e.target.value);
                            handleTextareaChange();
                        }}
                        onKeyPress={handleKeyPress}
                    />
                </div>
                {isRecording ? (
                    <button className="submitt-button" onClick={handleStopRecording}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            {"("}
                            <FontAwesomeIcon icon={faMicrophone} size="xl" style={{ marginRight: "5px" }} />
                            <FontAwesomeIcon icon={faStop} size="xl" />
                            {")"}
                        </div>
                    </button>
                ) : (
                    <button className="submitt-button" onClick={handleStartRecording}>
                        <FontAwesomeIcon icon={faMicrophone} size="xl" data-tooltip-id="SpeechTooltip" />
                        <Tooltip id="SpeechTooltip" className="custom-tooltip" delayShow={1500}>
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                Here you can start recording to convert speech to text
                            </div>
                        </Tooltip>
                    </button>
                )}
                <button className="submitt-button"
                    ref={attachmentMenuButtonRef}
                    onClick={() => {
                        if (!attachementMenuExpanded) {
                            setAttachmentMenuExpanded(true);
                        }
                        console.log("Click on Button")
                    }}>
                    <FontAwesomeIcon icon={faPaperclip} size="xl" data-tooltip-id="AttachmentTooltip" />
                    <Tooltip id="AttachmentTooltip" className="custom-tooltip" delayShow={1500}>
                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                            Here you can add attachments to your Message
                        </div>
                    </Tooltip>
                    {/* Attachment Menu */}
                    <div className={`attachment-menu`} id="attachmentMenu" ref={attachmentMenuRef}>
                        {imagesAllowed &&
                            <div className="attachment-menu-item">
                                <FontAwesomeIcon icon={faImage} size="xl" onClick={() => {
                                    const currentImageIput = imageInputRef.current;
                                    currentImageIput.click();
                                }} />
                            </div>
                        }
                        <div className="attachment-menu-item">
                            <FontAwesomeIcon icon={faFile} size="xl" onClick={() => {
                                const currentDocIput = docInputRef.current;
                                currentDocIput.click();
                            }} />
                        </div>
                    </div>
                </button>

                <button
                    className={`submitt-button ${isGenerating ? "disabled" : ""}`}
                    data-tooltip-id="SubmitTooltip"
                    onClick={() => {
                        onChatInputSubmit(attachments);
                        setAttachments([]);
                    }}
                    disabled={isGenerating}
                >
                    <FontAwesomeIcon icon={faShareFromSquare} size="xl" />
                    <Tooltip id="SubmitTooltip" className="custom-tooltip" delayShow={1500}>
                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                            Here you can submit your Message
                        </div>
                    </Tooltip>
                </button>

            </div>
            <input
                type="file"
                ref={imageInputRef}
                style={{ display: 'none' }}
                onChange={handleImageInputChange}
                accept='.png,.jpg,.jpeg'
            />
            <input
                type="file"
                ref={docInputRef}
                style={{ display: 'none' }}
                onChange={handleDocInputChange}
                accept=".docx, .doc, .pdf, .txt, .xlsx, .pptx, .ppt"
            />
            {/* Disclaimer */}
            <div style={{
                position: "absolute",
                bottom: "3px", // Optional: specify top position if needed
                left: "50%",
                transform: "translateX(-35%)", // Center horizontally by shifting left 50% of its own width
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                color: "var(--text-icons-4)",
                overflow: "hidden",
                maxWidth: "calc(100vw - 150px)"
            }}>
                <div style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" }}>
                    LLMs can make mistakes. Please check for important inforamtion.
                </div>
            </div>
        </div>
    );
});

export default ChatInput
